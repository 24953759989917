var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import React from 'react';
import { Header } from './Header';
import { layoutStyles } from './layout-styles';
import makeStyles from '@mui/styles/makeStyles';
var useClasses = makeStyles(__assign({}, layoutStyles));
export var LayoutResource = function (_a) {
    var children = _a.children;
    var classes = useClasses();
    // TODO: as new UI design is rolled out throughout the app, NoNavbar layout & styling should be replaced with this
    return (_jsxs(Box, __assign({ sx: {
            '&::-webkit-scrollbar': {
                display: 'null',
            },
        }, id: "resources" }, { children: [_jsx(Header, { signinLink: false }), _jsx(Box, { className: classes.baseBg }), _jsx(Box, { children: children })] })));
};

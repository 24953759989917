import { Status } from 'lib/api/api-types';
import React from 'react';
import { ClientLogger } from '../client-logger';
var UserContextUtils = /** @class */ (function () {
    function UserContextUtils(me) {
        this.me = me;
    }
    UserContextUtils.prototype.currentPersonOrg = function () {
        var retVal = undefined;
        if (this.me) {
            var currentOrgId = this.me.currentOrg || this.getCurrentOrgId() || this.me.personOrgs[0].orgId;
            for (var _i = 0, _a = this.me.personOrgs; _i < _a.length; _i++) {
                var personOrg = _a[_i];
                if (personOrg.orgId === currentOrgId) {
                    return personOrg;
                }
            }
        }
        return retVal;
    };
    UserContextUtils.prototype.saveCurrentOrgId = function (orgId) {
        sessionStorage.setItem('currentOrgId', orgId);
    };
    UserContextUtils.prototype.getCurrentOrgId = function () {
        return sessionStorage.getItem('currentOrgId');
    };
    UserContextUtils.prototype.currentOrgId = function () {
        var currentPersonOrgVar = this.currentPersonOrg();
        return currentPersonOrgVar ? currentPersonOrgVar.org.id : undefined;
    };
    UserContextUtils.prototype.isJurisdictionAdmin = function () {
        var index = this.getPersonOrgs().findIndex(function (perOrg) { return perOrg.org.type === 'JURISDICTION' && perOrg.role === 'ADMIN'; });
        return index >= 0;
    };
    UserContextUtils.prototype.isProviderOrgAdmin = function () {
        var index = this.getPersonOrgs().findIndex(function (perOrg) { return perOrg.org.type === 'PROVIDER' && perOrg.role === 'ADMIN'; });
        return index >= 0;
    };
    UserContextUtils.prototype.isOrgAdmin = function () {
        var index = this.getPersonOrgs().findIndex(function (perOrg) { return perOrg.role === 'ADMIN'; });
        return index >= 0;
    };
    UserContextUtils.prototype.getOrgs = function () {
        var _a, _b;
        return (_b = (_a = this.me) === null || _a === void 0 ? void 0 : _a.personOrgs.map(function (personOrg) { return personOrg.org; })) !== null && _b !== void 0 ? _b : [];
    };
    UserContextUtils.prototype.getPersonOrgs = function () {
        var _a, _b;
        return (_b = (_a = this.me) === null || _a === void 0 ? void 0 : _a.personOrgs) !== null && _b !== void 0 ? _b : [];
    };
    return UserContextUtils;
}());
export { UserContextUtils };
export var blankUserState = {
    me: {
        createdAt: new Date(),
        updatedAt: new Date(),
        id: '',
        title: null,
        firstName: null,
        middleName: null,
        lastName: null,
        gender: null,
        dateOfBirth: null,
        isTest: false,
        status: Status.DELETED,
        attributes: {},
        personOrgs: [],
        currentOrg: null,
        systemAccount: null,
    },
    isLoggedIn: false,
    ready: false,
};
export var UserContext = React.createContext({
    userState: blankUserState,
    setUserState: function (v) {
        ClientLogger.error('UserContext.setUserState', 'ignored', v);
    },
    $: new UserContextUtils(undefined),
});

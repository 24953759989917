var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { gql } from '@apollo/client';
import { useApi } from './use-api';
import { PERSON_FIELDS, PERSON_ORG_FIELDS, PERSON_VERIFICATION_FIELDS, useAuthService } from './use-auth-service';
import { VERIFICATION_METHOD_FIELDS } from './use-verification-method-service';
var DEBUG = true;
export function usePersonOrgService() {
    var api = useApi();
    var authService = useAuthService();
    var personsQuery = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    ", "\n    ", "\n    query persons($orderBy: PersonOrder, $first: Int, $after: String, $query: PersonSearchInput!) {\n      persons(query: $query, orderBy: $orderBy, first: $first, after: $after) {\n        edges {\n          cursor\n          node {\n            ...PersonFragment\n            personOrgs {\n              ...PersonOrgFragment\n            }\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n          hasPreviousPage\n          startCursor\n        }\n        totalCount\n      }\n    }\n  "], ["\n    ", "\n    ", "\n    query persons($orderBy: PersonOrder, $first: Int, $after: String, $query: PersonSearchInput!) {\n      persons(query: $query, orderBy: $orderBy, first: $first, after: $after) {\n        edges {\n          cursor\n          node {\n            ...PersonFragment\n            personOrgs {\n              ...PersonOrgFragment\n            }\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n          hasPreviousPage\n          startCursor\n        }\n        totalCount\n      }\n    }\n  "])), PERSON_ORG_FIELDS, PERSON_FIELDS);
    return {
        usePersonsQuery: function (options) {
            function fct() {
                return api.useQuery(personsQuery, options);
            }
            return fct;
        },
        personOrg: function (id) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, api.query({
                            query: gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n          ", "\n          ", "\n          query personOrg($id: String!) {\n            personOrg(id: $id) {\n              ...PersonOrgFragment\n              person {\n                ...PersonFragment\n              }\n            }\n          }\n        "], ["\n          ", "\n          ", "\n          query personOrg($id: String!) {\n            personOrg(id: $id) {\n              ...PersonOrgFragment\n              person {\n                ...PersonFragment\n              }\n            }\n          }\n        "])), PERSON_FIELDS, PERSON_ORG_FIELDS),
                            variables: { id: id },
                            fetchPolicy: 'network-only',
                        })];
                });
            });
        },
        personVerifications: function (orgId, id) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, api.query({
                            query: gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n          ", "\n          ", "\n          ", "\n          ", "\n          query personVerifications($orgId: String!, $id: String!) {\n            personVerifications(orgId: $orgId, id: $id) {\n              ...PersonVerificationFragment\n              createdBy {\n                ...PersonOrgFragment\n              }\n              person {\n                ...PersonFragment\n              }\n              method {\n                ...VerificationMethodFragment\n              }\n            }\n          }\n        "], ["\n          ", "\n          ", "\n          ", "\n          ", "\n          query personVerifications($orgId: String!, $id: String!) {\n            personVerifications(orgId: $orgId, id: $id) {\n              ...PersonVerificationFragment\n              createdBy {\n                ...PersonOrgFragment\n              }\n              person {\n                ...PersonFragment\n              }\n              method {\n                ...VerificationMethodFragment\n              }\n            }\n          }\n        "])), PERSON_VERIFICATION_FIELDS, PERSON_FIELDS, PERSON_ORG_FIELDS, VERIFICATION_METHOD_FIELDS),
                            variables: { orgId: orgId, id: id },
                            fetchPolicy: 'network-only',
                        })];
                });
            });
        },
        personOrgCreateNewPerson: function (personOrg) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, api.mutate({
                            mutation: gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n          mutation personOrgCreateNewPerson($data: PersonOrgCreateNewPersonInput!) {\n            personOrgCreateNewPerson(data: $data) {\n              id\n            }\n          }\n        "], ["\n          mutation personOrgCreateNewPerson($data: PersonOrgCreateNewPersonInput!) {\n            personOrgCreateNewPerson(data: $data) {\n              id\n            }\n          }\n        "]))),
                            variables: { data: personOrg },
                            refetchQueries: ['persons'],
                        })];
                });
            });
        },
        personOrgCreateConnectPerson: function (personOrg) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, api.mutate({
                            mutation: gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n          mutation personOrgCreateConnectPerson($data: PersonOrgCreateConnectPersonInput!) {\n            personOrgCreateConnectPerson(data: $data) {\n              id\n            }\n          }\n        "], ["\n          mutation personOrgCreateConnectPerson($data: PersonOrgCreateConnectPersonInput!) {\n            personOrgCreateConnectPerson(data: $data) {\n              id\n            }\n          }\n        "]))),
                            variables: { data: personOrg },
                            refetchQueries: ['persons'],
                        })];
                });
            });
        },
        personOrgUpsert: function (data) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, api.mutate({
                            mutation: gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n          mutation personOrgUpsert($data: PersonOrgUpsertInput!) {\n            personOrgUpsert(data: $data) {\n              created\n              entity {\n                id\n              }\n              success\n              updated\n            }\n          }\n        "], ["\n          mutation personOrgUpsert($data: PersonOrgUpsertInput!) {\n            personOrgUpsert(data: $data) {\n              created\n              entity {\n                id\n              }\n              success\n              updated\n            }\n          }\n        "]))),
                            variables: { data: data },
                            refetchQueries: ['persons'],
                        })];
                });
            });
        },
        personOrgChangeRole: function (data) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, api.mutate({
                            mutation: gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n          mutation personOrgChangeRole($data: PersonOrgChangeRoleInput!) {\n            personOrgChangeRole(data: $data) {\n              created\n              entity {\n                id\n              }\n              success\n              updated\n            }\n          }\n        "], ["\n          mutation personOrgChangeRole($data: PersonOrgChangeRoleInput!) {\n            personOrgChangeRole(data: $data) {\n              created\n              entity {\n                id\n              }\n              success\n              updated\n            }\n          }\n        "]))),
                            variables: { data: data },
                            refetchQueries: ['persons'],
                        })];
                });
            });
        },
        personUpdate: function (data) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, api.mutate({
                            mutation: gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n          mutation personUpdate($data: PersonUpdateInput!) {\n            personUpdate(data: $data) {\n              id\n            }\n          }\n        "], ["\n          mutation personUpdate($data: PersonUpdateInput!) {\n            personUpdate(data: $data) {\n              id\n            }\n          }\n        "]))),
                            variables: { data: data },
                            refetchQueries: ['persons'],
                        })];
                });
            });
        },
        personOrgDelete: function (personOrgId) {
            return __awaiter(this, void 0, Promise, function () {
                var deletedPersonOrg;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, api.mutate({
                                mutation: gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n          mutation personOrgDelete($id: String!) {\n            personOrgDelete(id: $id) {\n              id\n            }\n          }\n        "], ["\n          mutation personOrgDelete($id: String!) {\n            personOrgDelete(id: $id) {\n              id\n            }\n          }\n        "]))),
                                variables: { id: personOrgId },
                                refetchQueries: ['persons'],
                            })];
                        case 1:
                            deletedPersonOrg = _a.sent();
                            return [4 /*yield*/, authService.getMe()];
                        case 2:
                            _a.sent();
                            return [2 /*return*/, deletedPersonOrg];
                    }
                });
            });
        },
        personDelete: function (personId) {
            return __awaiter(this, void 0, Promise, function () {
                var deletePerson;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, api.mutate({
                                mutation: gql(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n          mutation personDelete($id: String!) {\n            personDelete(id: $id) {\n              id\n            }\n          }\n        "], ["\n          mutation personDelete($id: String!) {\n            personDelete(id: $id) {\n              id\n            }\n          }\n        "]))),
                                variables: { id: personId },
                                refetchQueries: ['persons'],
                            })];
                        case 1:
                            deletePerson = _a.sent();
                            return [4 /*yield*/, authService.getMe()];
                        case 2:
                            _a.sent();
                            return [2 /*return*/, deletePerson];
                    }
                });
            });
        },
        personOrgCalc: function (person, orgId) {
            if (!orgId) {
                return undefined;
            }
            var personOrg = person.personOrgs.find(function (p) { return p.orgId === orgId; });
            return personOrg;
        },
        sysAccountsForOrg: function (orgId) {
            return api.query({
                query: gql(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n          query personOrgs($orderBy: PersonOrgOrder, $first: Int, $after: String, $query: PersonOrgSearchInput!) {\n            personOrgs(query: $query, orderBy: $orderBy, first: $first, after: $after) {\n              edges {\n                cursor\n                node {\n                  id\n                  person {\n                    firstName\n                    lastName\n                    id\n                  }\n                }\n              }\n              pageInfo {\n                endCursor\n                hasNextPage\n                hasPreviousPage\n                startCursor\n              }\n              totalCount\n            }\n          }\n        "], ["\n          query personOrgs($orderBy: PersonOrgOrder, $first: Int, $after: String, $query: PersonOrgSearchInput!) {\n            personOrgs(query: $query, orderBy: $orderBy, first: $first, after: $after) {\n              edges {\n                cursor\n                node {\n                  id\n                  person {\n                    firstName\n                    lastName\n                    id\n                  }\n                }\n              }\n              pageInfo {\n                endCursor\n                hasNextPage\n                hasPreviousPage\n                startCursor\n              }\n              totalCount\n            }\n          }\n        "]))),
                variables: { query: { orgId: orgId } },
            });
        },
    };
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;

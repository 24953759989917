var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
var useClasses = makeStyles({
    markdownContainer: {
        fontFamily: "'PoppinsRegular', sans-serif !important",
        padding: 16,
        '& h1, h2, h3, h4, h5': {
            fontWeight: 'bold',
            color: '#005BAA',
        },
        '& img': {
            display: 'block',
            margin: '0 auto',
            maxWidth: '100%',
        },
    },
});
export var MarkdownStyles = function (_a) {
    var children = _a.children;
    var classes = useClasses();
    return _jsx(Box, __assign({ className: classes.markdownContainer }, { children: children }));
};

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { gql } from '@apollo/client';
import { ClientLogger } from 'lib/client-logger';
import { OrgType, } from './api-types';
import { useApi } from './use-api';
import { useAuthService } from './use-auth-service';
var DEBUG = true;
var STANDARD_FIELDS = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment StandardFragment on Org {\n    id\n    createdAt\n    updatedAt\n  }\n"], ["\n  fragment StandardFragment on Org {\n    id\n    createdAt\n    updatedAt\n  }\n"])));
export var ORG_FIELDS = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  fragment OrgFragment on Org {\n    name\n    attributes\n    authConfig\n    extMessageConfig\n    autoOnboard\n    description\n    emailDomain\n    externalId\n    isTest\n    status\n    type\n  }\n"], ["\n  fragment OrgFragment on Org {\n    name\n    attributes\n    authConfig\n    extMessageConfig\n    autoOnboard\n    description\n    emailDomain\n    externalId\n    isTest\n    status\n    type\n  }\n"])));
export function useOrgService() {
    var api = useApi();
    var authService = useAuthService();
    var orgsQuery = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    ", "\n    ", "\n    query orgs($name: String, $relation: OrgRelationSearchInput, $type: OrgType, $orderBy: OrgOrder, $first: Int, $after: String) {\n      orgs(query: { name: $name, type: $type, relation: $relation }, orderBy: $orderBy, first: $first, after: $after) {\n        edges {\n          cursor\n          node {\n            ...StandardFragment\n            ...OrgFragment\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n          hasPreviousPage\n          startCursor\n        }\n        totalCount\n      }\n    }\n  "], ["\n    ", "\n    ", "\n    query orgs($name: String, $relation: OrgRelationSearchInput, $type: OrgType, $orderBy: OrgOrder, $first: Int, $after: String) {\n      orgs(query: { name: $name, type: $type, relation: $relation }, orderBy: $orderBy, first: $first, after: $after) {\n        edges {\n          cursor\n          node {\n            ...StandardFragment\n            ...OrgFragment\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n          hasPreviousPage\n          startCursor\n        }\n        totalCount\n      }\n    }\n  "])), ORG_FIELDS, STANDARD_FIELDS);
    return {
        useOrgsQuery: function (options) {
            return api.useQuery(orgsQuery, options);
        },
        useOrgsQueryFactory: function (options) {
            return function () { return api.useQuery(orgsQuery, options); };
        },
        useOrgsQueryProvider: function (options) {
            if (options === null || options === void 0 ? void 0 : options.variables) {
                options.variables.type = OrgType.PROVIDER;
            }
            else {
                ClientLogger.error('useOrgService.useOrgsQuery', 'options.variables is undefined');
            }
            return api.useQuery(orgsQuery, options);
        },
        useOrgsQueryApp: function (options) {
            if (options === null || options === void 0 ? void 0 : options.variables) {
                options.variables.type = OrgType.APP;
            }
            else {
                ClientLogger.error('useOrgService.useOrgsQuery', 'options.variables is undefined');
            }
            return api.useQuery(orgsQuery, options);
        },
        useOrgsQueryJurisdiction: function (options) {
            if (options === null || options === void 0 ? void 0 : options.variables) {
                options.variables.type = OrgType.JURISDICTION;
            }
            else {
                ClientLogger.error('useOrgService.useOrgsQuery', 'options.variables is undefined');
            }
            return api.useQuery(orgsQuery, options);
        },
        orgs: function () {
            return __awaiter(this, void 0, void 0, function () {
                var resp;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            DEBUG && ClientLogger.debug('useOrgService.orgs', "started");
                            return [4 /*yield*/, api.query({
                                    query: orgsQuery,
                                    variables: {},
                                    fetchPolicy: 'network-only',
                                })];
                        case 1:
                            resp = _a.sent();
                            DEBUG && ClientLogger.debug('useCsaApi.getMe', 'Response', resp);
                            return [2 /*return*/, resp];
                    }
                });
            });
        },
        org: function (id) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, api.query({
                            query: gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n          ", "\n          ", "\n          query org($id: String!) {\n            org(id: $id) {\n              ...StandardFragment\n              ...OrgFragment\n            }\n          }\n        "], ["\n          ", "\n          ", "\n          query org($id: String!) {\n            org(id: $id) {\n              ...StandardFragment\n              ...OrgFragment\n            }\n          }\n        "])), STANDARD_FIELDS, ORG_FIELDS),
                            variables: { id: id },
                            fetchPolicy: 'network-only',
                        })];
                });
            });
        },
        getAllOrgs: function (orgType) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, api.query({
                            query: gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n          query getAllOrgs($data: OrgType!) {\n            getAllOrgs(data: $data) {\n              id\n              name\n              authConfig\n            }\n          }\n        "], ["\n          query getAllOrgs($data: OrgType!) {\n            getAllOrgs(data: $data) {\n              id\n              name\n              authConfig\n            }\n          }\n        "]))),
                            variables: { data: orgType },
                            fetchPolicy: 'network-only',
                        })];
                });
            });
        },
        orgCreate: function (org) {
            return __awaiter(this, void 0, Promise, function () {
                var retVal;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, api.mutate({
                                mutation: gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n          mutation orgCreate($data: OrgCreateInput!) {\n            orgCreate(data: $data) {\n              id\n            }\n          }\n        "], ["\n          mutation orgCreate($data: OrgCreateInput!) {\n            orgCreate(data: $data) {\n              id\n            }\n          }\n        "]))),
                                variables: { data: org },
                                refetchQueries: ['orgs'],
                            })];
                        case 1:
                            retVal = _a.sent();
                            return [4 /*yield*/, authService.getMe()];
                        case 2:
                            _a.sent();
                            return [2 /*return*/, retVal];
                    }
                });
            });
        },
        orgUpdate: function (orgId, org) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, api.mutate({
                            mutation: gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n          mutation orgUpdate($id: String!, $data: OrgUpdateInput!) {\n            orgUpdate(id: $id, data: $data) {\n              id\n            }\n          }\n        "], ["\n          mutation orgUpdate($id: String!, $data: OrgUpdateInput!) {\n            orgUpdate(id: $id, data: $data) {\n              id\n            }\n          }\n        "]))),
                            variables: { data: org, id: orgId },
                            refetchQueries: ['orgs'],
                        })];
                });
            });
        },
        generateHMACSecret: function (orgId, authAlgorithm) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, api.mutate({
                            mutation: gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n          mutation generateHMACSecret($id: String!, $authAlgorithm: String!) {\n            generateHMACSecret(id: $id, authAlgorithm: $authAlgorithm)\n          }\n        "], ["\n          mutation generateHMACSecret($id: String!, $authAlgorithm: String!) {\n            generateHMACSecret(id: $id, authAlgorithm: $authAlgorithm)\n          }\n        "]))),
                            variables: { authAlgorithm: authAlgorithm, id: orgId },
                            refetchQueries: ['orgs'],
                        })];
                });
            });
        },
        orgOrgCreate: function (orgRelation, org1Id, org2Id) {
            return __awaiter(this, void 0, Promise, function () {
                var orgRelationInput;
                return __generator(this, function (_a) {
                    orgRelationInput = {
                        type: orgRelation,
                        org1Id: org1Id,
                        org2Id: org2Id,
                    };
                    return [2 /*return*/, api.mutate({
                            mutation: gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n          mutation orgOrgCreate($data: OrgRelationInput!) {\n            orgOrgCreate(data: $data) {\n              id\n              relation\n              status\n            }\n          }\n        "], ["\n          mutation orgOrgCreate($data: OrgRelationInput!) {\n            orgOrgCreate(data: $data) {\n              id\n              relation\n              status\n            }\n          }\n        "]))),
                            variables: { data: orgRelationInput },
                            refetchQueries: ['orgs'],
                        })];
                });
            });
        },
        orgOrgDelete: function (orgRelation, org1Id, org2Id) {
            return __awaiter(this, void 0, Promise, function () {
                var orgRelationInput;
                return __generator(this, function (_a) {
                    orgRelationInput = {
                        type: orgRelation,
                        org1Id: org1Id,
                        org2Id: org2Id,
                    };
                    return [2 /*return*/, api.mutate({
                            mutation: gql(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n          mutation orgOrgDelete($data: OrgRelationInput!) {\n            orgOrgDelete(data: $data) {\n              id\n              relation\n              status\n              org1Id\n              org2Id\n            }\n          }\n        "], ["\n          mutation orgOrgDelete($data: OrgRelationInput!) {\n            orgOrgDelete(data: $data) {\n              id\n              relation\n              status\n              org1Id\n              org2Id\n            }\n          }\n        "]))),
                            variables: { data: orgRelationInput },
                            refetchQueries: ['orgs'],
                        })];
                });
            });
        },
        orgDelete: function (orgId) {
            return __awaiter(this, void 0, Promise, function () {
                var deletedOrg;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, api.mutate({
                                mutation: gql(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n          mutation orgDelete($id: String!) {\n            orgDelete(id: $id) {\n              id\n            }\n          }\n        "], ["\n          mutation orgDelete($id: String!) {\n            orgDelete(id: $id) {\n              id\n            }\n          }\n        "]))),
                                variables: { id: orgId },
                                refetchQueries: ['orgs'],
                            })];
                        case 1:
                            deletedOrg = _a.sent();
                            return [4 /*yield*/, authService.getMe()];
                        case 2:
                            _a.sent();
                            return [2 /*return*/, deletedOrg];
                    }
                });
            });
        },
    };
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11;

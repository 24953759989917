/** Properties by which agreement acceptance connections can be ordered. */
export var AgreementAcceptanceOrderField;
(function (AgreementAcceptanceOrderField) {
    AgreementAcceptanceOrderField["createdAt"] = "createdAt";
    AgreementAcceptanceOrderField["updatedAt"] = "updatedAt";
})(AgreementAcceptanceOrderField || (AgreementAcceptanceOrderField = {}));
/** Properties by which agreement connections can be ordered. */
export var AgreementOrderField;
(function (AgreementOrderField) {
    AgreementOrderField["createdAt"] = "createdAt";
    AgreementOrderField["name"] = "name";
    AgreementOrderField["updatedAt"] = "updatedAt";
})(AgreementOrderField || (AgreementOrderField = {}));
/** Status of agreement acceptance. */
export var AgreementStatus;
(function (AgreementStatus) {
    AgreementStatus["ACCEPTED"] = "ACCEPTED";
    AgreementStatus["REJECTED"] = "REJECTED";
})(AgreementStatus || (AgreementStatus = {}));
/** The Auth Algorithm. */
export var AuthAlgorithm;
(function (AuthAlgorithm) {
    AuthAlgorithm["HMAC_SHA_256"] = "HMAC_SHA_256";
    AuthAlgorithm["HMAC_SHA_384"] = "HMAC_SHA_384";
    AuthAlgorithm["HMAC_SHA_512"] = "HMAC_SHA_512";
})(AuthAlgorithm || (AuthAlgorithm = {}));
/** Properties by which auth api key connections can be ordered. */
export var AuthApiKeyOrderField;
(function (AuthApiKeyOrderField) {
    AuthApiKeyOrderField["createdAt"] = "createdAt";
    AuthApiKeyOrderField["enabled"] = "enabled";
    AuthApiKeyOrderField["name"] = "name";
})(AuthApiKeyOrderField || (AuthApiKeyOrderField = {}));
/** The Auth API Key Type. */
export var AuthApiKeyType;
(function (AuthApiKeyType) {
    AuthApiKeyType["ADMIN"] = "ADMIN";
    AuthApiKeyType["READONLY"] = "READONLY";
})(AuthApiKeyType || (AuthApiKeyType = {}));
/** Properties by which auth events connections can be ordered. */
export var AuthEventOrderField;
(function (AuthEventOrderField) {
    AuthEventOrderField["createdAt"] = "createdAt";
})(AuthEventOrderField || (AuthEventOrderField = {}));
/** The Auth Event Type. */
export var AuthEventType;
(function (AuthEventType) {
    AuthEventType["CHANGE_PASSWORD"] = "CHANGE_PASSWORD";
    AuthEventType["LOGIN"] = "LOGIN";
    AuthEventType["REFRESH_TOKEN"] = "REFRESH_TOKEN";
    AuthEventType["RESET_PASSWORD_COMPLETE"] = "RESET_PASSWORD_COMPLETE";
    AuthEventType["RESET_PASSWORD_START"] = "RESET_PASSWORD_START";
})(AuthEventType || (AuthEventType = {}));
/** The Org Auth Config Cookie Type. */
export var CookieType;
(function (CookieType) {
    CookieType["SET_COOKIE"] = "SET_COOKIE";
    CookieType["SET_HEADER"] = "SET_HEADER";
    CookieType["SET_URL_PARAMS"] = "SET_URL_PARAMS";
})(CookieType || (CookieType = {}));
/** Properties by which person connections can be ordered. */
export var DesignationOrderField;
(function (DesignationOrderField) {
    DesignationOrderField["createdAt"] = "createdAt";
    DesignationOrderField["description"] = "description";
    DesignationOrderField["updatedAt"] = "updatedAt";
})(DesignationOrderField || (DesignationOrderField = {}));
/** Properties by which person connections can be ordered. */
export var IdTypeOrderField;
(function (IdTypeOrderField) {
    IdTypeOrderField["createdAt"] = "createdAt";
    IdTypeOrderField["description"] = "description";
    IdTypeOrderField["updatedAt"] = "updatedAt";
})(IdTypeOrderField || (IdTypeOrderField = {}));
/** Possible directions in which to order a list of items when provided an `orderBy` argument. */
export var OrderDirection;
(function (OrderDirection) {
    OrderDirection["asc"] = "asc";
    OrderDirection["desc"] = "desc";
})(OrderDirection || (OrderDirection = {}));
/** The Org Auth Config Type. */
export var OrgAuthConfigType;
(function (OrgAuthConfigType) {
    OrgAuthConfigType["EXTERNALLY_PROVIDED"] = "EXTERNALLY_PROVIDED";
    OrgAuthConfigType["IDENTITY_PROVIDER"] = "IDENTITY_PROVIDER";
    OrgAuthConfigType["LOCALLY_PROVIDED"] = "LOCALLY_PROVIDED";
    OrgAuthConfigType["OPEN_ID"] = "OPEN_ID";
    OrgAuthConfigType["SAML"] = "SAML";
})(OrgAuthConfigType || (OrgAuthConfigType = {}));
/** Properties by which org connections can be ordered. */
export var OrgOrderField;
(function (OrgOrderField) {
    OrgOrderField["createdAt"] = "createdAt";
    OrgOrderField["name"] = "name";
    OrgOrderField["updatedAt"] = "updatedAt";
})(OrgOrderField || (OrgOrderField = {}));
/** The Relationship Type Between Two Organizations */
export var OrgRelation;
(function (OrgRelation) {
    OrgRelation["PROVIDER1_APP2"] = "PROVIDER1_APP2";
    OrgRelation["PROVIDER1_JURISDICTION2"] = "PROVIDER1_JURISDICTION2";
})(OrgRelation || (OrgRelation = {}));
/** The Organization Type. */
export var OrgType;
(function (OrgType) {
    OrgType["APP"] = "APP";
    OrgType["JURISDICTION"] = "JURISDICTION";
    OrgType["PROVIDER"] = "PROVIDER";
})(OrgType || (OrgType = {}));
/** Properties by which person connections can be ordered. */
export var PersonOrderField;
(function (PersonOrderField) {
    PersonOrderField["createdAt"] = "createdAt";
    PersonOrderField["firstName"] = "firstName";
    PersonOrderField["lastName"] = "lastName";
    PersonOrderField["updatedAt"] = "updatedAt";
})(PersonOrderField || (PersonOrderField = {}));
/** Properties by which person org connections can be ordered. */
export var PersonOrgOrderField;
(function (PersonOrgOrderField) {
    PersonOrgOrderField["createdAt"] = "createdAt";
    PersonOrgOrderField["firstName"] = "firstName";
    PersonOrgOrderField["lastName"] = "lastName";
    PersonOrgOrderField["updatedAt"] = "updatedAt";
})(PersonOrgOrderField || (PersonOrgOrderField = {}));
/** User role */
export var Role;
(function (Role) {
    Role["ADMIN"] = "ADMIN";
    Role["STAFF"] = "STAFF";
    Role["USER"] = "USER";
})(Role || (Role = {}));
/** The records Status (ACTIVE or DELETED). */
export var Status;
(function (Status) {
    Status["ACTIVE"] = "ACTIVE";
    Status["DELETED"] = "DELETED";
})(Status || (Status = {}));
/** Properties by which team member connections can be ordered. */
export var TeamMemberOrderField;
(function (TeamMemberOrderField) {
    TeamMemberOrderField["createdAt"] = "createdAt";
    TeamMemberOrderField["lastName"] = "lastName";
    TeamMemberOrderField["updatedAt"] = "updatedAt";
})(TeamMemberOrderField || (TeamMemberOrderField = {}));
/** Properties by which team connections can be ordered. */
export var TeamOrderField;
(function (TeamOrderField) {
    TeamOrderField["createdAt"] = "createdAt";
    TeamOrderField["name"] = "name";
    TeamOrderField["updatedAt"] = "updatedAt";
})(TeamOrderField || (TeamOrderField = {}));
/** Team role */
export var TeamRole;
(function (TeamRole) {
    TeamRole["ADMIN"] = "ADMIN";
    TeamRole["STAFF"] = "STAFF";
})(TeamRole || (TeamRole = {}));
/** Properties by which verification methods connections can be ordered. */
export var VerificationMethodOrderField;
(function (VerificationMethodOrderField) {
    VerificationMethodOrderField["createdAt"] = "createdAt";
    VerificationMethodOrderField["name"] = "name";
    VerificationMethodOrderField["updatedAt"] = "updatedAt";
})(VerificationMethodOrderField || (VerificationMethodOrderField = {}));
/** The Verification Method Type. */
export var VerificationMethodType;
(function (VerificationMethodType) {
    VerificationMethodType["MANUAL"] = "MANUAL";
    VerificationMethodType["OPEN_ID"] = "OPEN_ID";
    VerificationMethodType["PERSONAL_KNOWLEDGE"] = "PERSONAL_KNOWLEDGE";
    VerificationMethodType["SELF_DECLARED"] = "SELF_DECLARED";
    VerificationMethodType["SELF_SOVEREIGN_IDENTITY"] = "SELF_SOVEREIGN_IDENTITY";
})(VerificationMethodType || (VerificationMethodType = {}));
/** The Verification Status. */
export var VerificationStatus;
(function (VerificationStatus) {
    VerificationStatus["PENDING"] = "PENDING";
    VerificationStatus["VERIFICATION_FAILED"] = "VERIFICATION_FAILED";
    VerificationStatus["VERIFIED"] = "VERIFIED";
})(VerificationStatus || (VerificationStatus = {}));

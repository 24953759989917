var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Grid, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { theme } from 'style/theme';
import { OrgAuth, SEVERITY, ToastDispatchContext, Util } from 'ui-lib';
import canadaFlag from '../../assets/Canada-Flag.svg';
import hrhLogo from '../../assets/HRH_Horizontal_Logo_withTag_Blue.png';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'Routes';
import { getVeridApolloClientWithoutContext } from 'lib/api/apollo-client';
export function LandingOrg() {
    var _a, _b, _c, _d, _e;
    var navigate = useNavigate();
    var toastDispatch = useContext(ToastDispatchContext);
    var _f = useParams(), orgId = _f.orgId, authConfigId = _f.authConfigId;
    var veridApolloClient = getVeridApolloClientWithoutContext();
    var spacing = theme.spacing(2);
    var cssClasses = {
        formHolder: {
            position: 'relative',
        },
        formContainer: (_a = {
                margin: '20vh auto 0 auto',
                width: '400px',
                maxWidth: '90vw',
                position: 'relative',
                minHeight: '40vh',
                padding: '24px'
            },
            _a[theme === null || theme === void 0 ? void 0 : theme.breakpoints.down('sm')] = {
                margin: '4px auto',
                padding: '48px 16px 16px 16px',
            },
            _a),
        btnHolder: {
            width: '100%',
            marginBottom: spacing,
        },
        orgLogo: {
            width: '100%',
        },
        orgSubtitle: {
            width: '100%',
            position: 'absolute',
            left: 0,
            right: 0,
        },
        ctaBox: (_b = {
                maxWidth: '90vw',
                padding: '24px'
            },
            _b[theme === null || theme === void 0 ? void 0 : theme.breakpoints.up('sm')] = {
                marginTop: '48px',
                width: '380px',
            },
            _b),
        hrhLogo: {
            width: '200px',
        },
        lpTR: (_c = {
                pointerEvents: 'none',
                position: 'fixed',
                top: 0,
                right: 0,
                width: '250px'
            },
            _c[theme === null || theme === void 0 ? void 0 : theme.breakpoints.down('md')] = {
                display: 'none',
            },
            _c),
        lpBL: (_d = {
                pointerEvents: 'none',
                position: 'fixed',
                left: '20px',
                bottom: '76px',
                width: '250px'
            },
            _d[theme === null || theme === void 0 ? void 0 : theme.breakpoints.down('md')] = {
                display: 'none',
            },
            _d),
        lpBR: (_e = {
                pointerEvents: 'none',
                position: 'fixed',
                right: '4px',
                bottom: '90px',
                width: '260px'
            },
            _e[theme === null || theme === void 0 ? void 0 : theme.breakpoints.down('md')] = {
                display: 'none',
            },
            _e),
        flag: {
            height: '24px',
            marginRight: '4px',
            verticalAlign: 'middle',
        },
        orContainer: {
            width: '100%',
            flexWrap: 'nowrap',
            overflow: 'hidden',
        },
        btnPrimary: {
            padding: "16px 8px",
        },
        containerTitle: {
            fontWeight: 'bold',
            fontSize: '1.5rem',
        },
    };
    useEffect(function () {
        var queryStrings = window.location.search;
        var errorMessage = Util.parseQueryString(queryStrings, 'errorMessage') || '';
        if (errorMessage) {
            toastDispatch({
                severity: SEVERITY.ERROR,
                msg: errorMessage,
            });
        }
    });
    var onClickSignIn = function (role) {
        navigate("".concat(ROUTES.LOGIN, "?orgId=").concat(orgId, "&authConfigId=").concat(authConfigId, "&role=").concat(role));
    };
    return (_jsx(_Fragment, { children: _jsx(Box, __assign({ sx: cssClasses.formHolder }, { children: _jsx(Box, __assign({ sx: cssClasses.formContainer }, { children: _jsxs(Grid, __assign({ container: true, alignItems: "center", flexDirection: "column" }, { children: [_jsx(Box, { my: 2, component: "img", src: hrhLogo, alt: "Healix\u2122", sx: cssClasses.orgLogo }), orgId && authConfigId && (_jsx(OrgAuth, { orgId: orgId, orgAuthId: authConfigId, veridApolloClient: veridApolloClient, theme: theme, onClickSignIn: onClickSignIn })), _jsxs(Box, __assign({ mt: 1 }, { children: [_jsx(Box, { component: "img", src: canadaFlag, alt: "Canada Flag", sx: cssClasses.flag }), _jsx(Typography, __assign({ component: "span", variant: "subtitle2" }, { children: "Proudly Canadian" }))] }))] })) })) })) }));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Loading } from 'components/widgets/Loading';
import { ErrorMessage, Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { Status, VerificationMethodType } from 'lib/api/api-types';
import { ClientLogger } from 'lib/client-logger';
import { useErrorHandler } from 'lib/use-error-handler';
import { Util } from 'ui-lib';
import React, { useEffect, useRef, useState } from 'react';
import { dialogClasses, formClasses } from '../../style/sharedCssClasses';
import CloseIcon from '@mui/icons-material/Clear';
import { useVerificationMethodService } from 'lib/api/use-verification-method-service';
import { VerificationMethodTypeSelector } from 'components/form/VerificationMethodTypeSelector';
import { VerificationOpenIDConfigSelector } from 'components/form/VerificationOpenIDConfigSelector';
import 'react-quill/dist/quill.snow.css';
import 'style/reactQuill/style.css';
import { IdTypeSelector } from 'components/form/IdTypeSelector';
import { useOrgService } from 'lib/api/use-org-service';
import { RichTextField } from 'components/form/RichTextField';
var DEBUG = false;
var useClasses = makeStyles(__assign({}, formClasses));
export function EditVerificationMethod(props) {
    var verificationMethodService = useVerificationMethodService();
    var orgService = useOrgService();
    var errorHandler = useErrorHandler('EditTeam');
    var formRef = useRef(null);
    var _a = useState(true), loading = _a[0], setLoading = _a[1];
    var _b = useState([]), authNames = _b[0], setAuthNames = _b[1];
    var dialogTitle = props.editingId ? "Edit ".concat(props.dialogTitle, " item") : "Add to ".concat(props.dialogTitle);
    var classes = useClasses();
    useEffect(function () {
        if (formRef.current) {
            if (props.editingId) {
                verificationMethodService
                    .verificationMethod(props.editingId)
                    .then(function (resp) {
                    var _a;
                    var verificationMethod = Util.convertBlanks(resp.data.verificationMethod, null, '');
                    delete verificationMethod.__typename;
                    var idTypeIds = [];
                    resp.data.verificationMethod.idTypes.forEach(function (idType) {
                        idTypeIds.push(idType.id);
                    });
                    verificationMethod.idTypeIds = idTypeIds;
                    DEBUG && ClientLogger.debug('EditTeam', 'load verification method', { resp: resp, verificationMethod: verificationMethod });
                    if (verificationMethod) {
                        (_a = formRef.current) === null || _a === void 0 ? void 0 : _a.setValues(verificationMethod);
                    }
                    else {
                        errorHandler.handleErrors({ graphQLErrors: resp.errors });
                    }
                    setLoading(false);
                })
                    .catch(function (err) {
                    errorHandler.handleErrors({ error: err });
                    setLoading(false);
                });
            }
            else {
                setLoading(false);
            }
        }
    }, [props.editingId, formRef.current]);
    useEffect(function () {
        if (props.orgId) {
            orgService
                .org(props.orgId)
                .then(function (resp) {
                var org = Util.convertBlanks(resp.data.org, null, '');
                DEBUG && ClientLogger.debug('EditTeam', 'load org authNames', { resp: resp, org: org });
                if (org) {
                    var names = getAuthNames(org.authConfig);
                    setAuthNames(names);
                }
                else {
                    errorHandler.handleErrors({ graphQLErrors: resp.errors });
                }
                setLoading(false);
            })
                .catch(function (err) {
                errorHandler.handleErrors({ error: err });
                setLoading(false);
            });
        }
    }, [props.orgId]);
    function save(values) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var data, resp, resp;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!props.editingId) return [3 /*break*/, 2];
                        data = {
                            name: values.name,
                            status: values.status,
                            description: values.description,
                            requiresPicture: values.requiresPicture,
                            instructions: VerificationMethodType.MANUAL ? values.instructions : undefined,
                            idTypeIds: VerificationMethodType.MANUAL ? values.idTypeIds : undefined,
                            openIdConfig: VerificationMethodType.OPEN_ID ? values.openIdConfig : undefined,
                        };
                        return [4 /*yield*/, verificationMethodService.verificationMethodUpdate(props.editingId, data)];
                    case 1:
                        resp = _c.sent();
                        DEBUG && ClientLogger.debug('EditTean', 'update', { resp: resp });
                        if ((_a = resp.data) === null || _a === void 0 ? void 0 : _a.verificationMethodUpdate.id) {
                            props.onClose();
                        }
                        else {
                            errorHandler.handleErrors({ graphQLErrors: resp.errors });
                        }
                        return [3 /*break*/, 4];
                    case 2:
                        DEBUG && ClientLogger.debug('EditTean', 'creating org');
                        return [4 /*yield*/, verificationMethodService.verificationMethodCreate(__assign({}, values))];
                    case 3:
                        resp = _c.sent();
                        DEBUG && ClientLogger.debug('EditTean', 'create', { resp: resp });
                        if ((_b = resp.data) === null || _b === void 0 ? void 0 : _b.verificationMethodCreate.id) {
                            props.onClose();
                        }
                        else {
                            errorHandler.handleErrors({ graphQLErrors: resp.errors });
                        }
                        _c.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    function getAuthNames(orgAuthConfig) {
        var authNames = orgAuthConfig.length
            ? orgAuthConfig
                .filter(function (config) { var _a; return ((_a = config.authConfig) === null || _a === void 0 ? void 0 : _a.type) === 'OPEN_ID'; })
                .map(function (config) { return config.authName; })
            : [];
        return authNames;
    }
    DEBUG && ClientLogger.debug('Edit VerificationMethod', 'render', { props: props, formRef: JSON.stringify(formRef.current) });
    return (_jsx(Formik, __assign({ innerRef: formRef, initialValues: {
            name: '',
            description: '',
            status: Status.ACTIVE,
            orgId: props.orgId || '',
            requiresPicture: false,
            instructions: '',
            type: VerificationMethodType.PERSONAL_KNOWLEDGE,
            idTypeIds: [],
            openIdConfig: '',
        }, validate: function (values) {
            var errors = {};
            if (values.type === VerificationMethodType.OPEN_ID) {
                var openIdConfig_1 = values.openIdConfig;
                if (!authNames.length) {
                    errors['type'] = 'No OpenID configured to the Org';
                }
                else if (!openIdConfig_1) {
                    errors['openIdConfig'] = 'Must be selected OpenID configure';
                }
                else if (!!openIdConfig_1) {
                    var existingOpenIdConfigs = props.existingOpenIdConfigs;
                    var duplicateConfig = existingOpenIdConfigs.find(function (existingOpenIdConfig) { return props.editingId != existingOpenIdConfig.id && existingOpenIdConfig.config === openIdConfig_1; });
                    if (duplicateConfig != undefined) {
                        errors['openIdConfig'] = 'OpenID configure is already existed';
                    }
                }
            }
            return errors;
        }, onSubmit: function (values, actions) {
            DEBUG && ClientLogger.debug('EditTeam', "onSubmit values", values);
            save(values);
        } }, { children: function (_a) {
            var submitForm = _a.submitForm, isSubmitting = _a.isSubmitting, errors = _a.errors, values = _a.values, setFieldValue = _a.setFieldValue, touched = _a.touched, setFieldError = _a.setFieldError, setFieldTouched = _a.setFieldTouched, dirty = _a.dirty;
            DEBUG && ClientLogger.debug('EditTeam', 'render form', { isSubmitting: isSubmitting, errors: errors, values: values, touched: touched, dirty: dirty, submitForm: submitForm });
            if (loading) {
                return _jsx(Loading, {});
            }
            return (_jsxs(_Fragment, { children: [_jsxs(DialogTitle, __assign({ sx: dialogClasses.dialogTitle }, { children: [dialogTitle, _jsx(IconButton, __assign({ onClick: props.onClose, size: "large" }, { children: _jsx(CloseIcon, { sx: dialogClasses.closeIcon }) }))] })), _jsxs(DialogContent, { children: [_jsx(Field, { component: TextField, name: "name", label: "Name (required)", placeholder: "Enter name", type: "text", fullWidth: true, variant: "outlined", "data-test": "name-textfield", sx: dialogClasses.inputField }), _jsx(Field, { component: TextField, name: "description", label: "Description (optional)", placeholder: "Enter a description of this organization", type: "text", fullWidth: true, variant: "outlined", "data-test": "new-patient-middle-name-textfield", sx: dialogClasses.inputField }), _jsx(VerificationMethodTypeSelector, { name: "type", disabled: !!props.editingId }), _jsx(ErrorMessage, { component: Typography, className: classes.formError, name: "type" }), values.type === VerificationMethodType.MANUAL && (_jsxs(_Fragment, { children: [_jsx(RichTextField, { name: "instructions", label: "Instructions" }), _jsx(IdTypeSelector, { selectMultiple: true, name: "idTypeIds" })] })), values.type === VerificationMethodType.OPEN_ID && !!authNames.length && (_jsxs(_Fragment, { children: [_jsx(VerificationOpenIDConfigSelector, { authNames: authNames, name: "openIdConfig" }), _jsx(ErrorMessage, { component: Typography, className: classes.formError, name: "openIdConfig" })] }))] }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ color: "primary", disabled: isSubmitting, onClick: function () { return props.onClose(); } }, { children: "Cancel" })), _jsx(Button, __assign({ type: "submit", color: "primary", disabled: isSubmitting, onClick: function () { return submitForm(); } }, { children: "Save" }))] })] }));
        } })));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from 'Routes';
import healixLogo from '../../assets/Logo.png';
var useClasses = makeStyles({
    container: {
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        padding: '12px 24px',
        backgroundColor: '#fff',
        zIndex: 1,
        sm: {
            padding: '12px 96px',
        },
    },
    logo: {
        height: 16,
        cursor: 'pointer',
    },
});
export function Header(props) {
    var classes = useClasses();
    var signinLink = props.signinLink;
    var navigate = useNavigate();
    var goHome = function () {
        navigate(ROUTES.HOME);
    };
    return (_jsxs(Grid, __assign({ container: true, justifyContent: "space-between", className: classes.container }, { children: [_jsx(Box, { children: _jsx("img", { className: classes.logo, onClick: goHome, src: healixLogo, alt: "Healix\u2122" }) }), signinLink && (_jsx(Box, { children: _jsx(Link, __assign({ to: ROUTES.LOGIN }, { children: "Sign In" })) }))] })));
}

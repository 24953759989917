var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Observable } from '@apollo/client';
import { authHeader } from './token-refresh-link';
var OperationQueuing = /** @class */ (function () {
    function OperationQueuing() {
        this.queuedRequests = [];
        this.queuedRequests = [];
    }
    OperationQueuing.prototype.enqueueRequest = function (request) {
        var _this = this;
        var requestCopy = __assign({}, request);
        requestCopy.observable =
            requestCopy.observable ||
                new Observable(function (observer) {
                    _this.queuedRequests.push(requestCopy);
                    if (typeof requestCopy.subscriber === 'undefined') {
                        requestCopy.subscriber = {};
                    }
                    requestCopy.subscriber.next = requestCopy.next || observer.next.bind(observer);
                    requestCopy.subscriber.error = requestCopy.error || observer.error.bind(observer);
                    requestCopy.subscriber.complete = requestCopy.complete || observer.complete.bind(observer);
                });
        return requestCopy.observable;
    };
    OperationQueuing.prototype.consumeQueue = function () {
        this.queuedRequests.forEach(function (request) {
            if (!request.forward) {
                throw new Error('OperationQueuing.consumeQueue request had missing .forward');
            }
            if (!request.subscriber) {
                throw new Error('OperationQueuing.consumeQueue request had missing .subscriber');
            }
            request.operation.setContext(__assign({}, authHeader()));
            request.forward(request.operation).subscribe(request.subscriber);
        });
        this.queuedRequests = [];
    };
    OperationQueuing.prototype.nukeQueue = function () {
        this.queuedRequests = [];
    };
    return OperationQueuing;
}());
export { OperationQueuing };

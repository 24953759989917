var _a, _b, _c;
import { theme } from '../../style/theme';
export var layoutStyles = {
    baseBg: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundImage: 'url("/assets/lp-bg.svg")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
    },
    wrapperPadding: {
        height: 'calc(100vh - 48px)',
        width: 'calc(100vw - 48px)',
        margin: '24px',
        overflowY: 'auto',
    },
    scroller: {
        scrollbarWidth: 'none',
    },
    contents: {
        marginTop: '10vh',
    },
    hamburgerMenuWrapper: (_a = {
            width: '100vw',
            padding: '40px 0 0 0'
        },
        _a[theme.breakpoints.down('sm')] = {
            padding: '40px 24px 0 24px',
        },
        _a),
    hamburgBar: (_b = {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: '#fff',
            zIndex: 1200
        },
        _b[theme.breakpoints.down('sm')] = {
            padding: '0 24px',
        },
        _b),
    appWrapper: (_c = {},
        _c[theme.breakpoints.down('sm')] = {
            position: 'fixed',
            top: 40,
            left: 0,
            right: 0,
        },
        _c),
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Dialog, Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { DataTable } from 'ui-lib';
import { ClientLogger } from 'lib/client-logger';
import { TopBackBtnBar } from '../../components/navigation/TopBackBtnBar';
import makeStyles from '@mui/styles/makeStyles';
import { tableClasses } from '../../style/sharedCssClasses';
import { SelectorNoForm } from 'ui-lib';
import { UserContext } from 'lib/auth/user-context';
import { Loading } from 'components/widgets/Loading';
import { useAgreementService } from 'lib/api/use-agreement-service';
import { AddAgreement } from './AddAgreement';
import { EditAgreement } from './EditAgreement';
import { dateDisplay } from 'lib/util';
var useClasses = makeStyles(__assign({}, tableClasses));
var DEBUG = false;
export var AgreementList = function (props) {
    var classes = useClasses();
    var agreementService = useAgreementService();
    var _a = useState(false), addOpen = _a[0], setAddOpen = _a[1];
    var _b = useState(undefined), editOpenWithId = _b[0], setEditOpenWithId = _b[1]; // agreement id
    var userContext = useContext(UserContext);
    var orgs = userContext.$.getOrgs();
    var orgsSelectList = orgs.map(function (org) { return ({ id: org.id, name: org.name }); });
    var _c = useState(userContext.$.currentOrgId()), currentOrgId = _c[0], setCurrentOrgId = _c[1];
    var setSelectedOrgId = function (orgId) {
        setCurrentOrgId(orgId);
        userContext.$.saveCurrentOrgId(orgId);
    };
    var dataTableOptions = {
        pagination: true,
        download: true,
        print: true,
        filter: false,
        search: false,
        viewColumns: false,
        sort: true,
        selectableRows: 'none',
    };
    var columns = {
        id: { display: 'excluded' },
        content: { display: 'excluded' },
        name: { label: 'Name' },
        updatedAt: { display: 'excluded' },
        createdAt: { label: 'Created At', customBodyRender: function (value) { return dateDisplay(value); } },
        latestVersion: { label: 'Latest' },
        roles: {
            label: 'Roles',
            customBodyRender: function (value) { return value.map(function (x) { return "".concat(x); }).join(', '); },
        },
        requiresAcceptance: { display: 'excluded' },
        status: { display: 'excluded' },
    };
    var transformResponseToRows = function (resp) {
        var _a;
        if (!resp || !resp.agreements || !((_a = resp.agreements) === null || _a === void 0 ? void 0 : _a.edges)) {
            return [];
        }
        return resp.agreements.edges.map(function (agreement) { return (__assign({}, agreement.node)); });
    };
    var tableProps = {
        useQuery: agreementService.useAgreementQuery({ variables: { orgId: currentOrgId || '' } }),
        initialQuery: {},
        transformResponseToRows: transformResponseToRows,
        columnAttributes: columns,
        tableAttributes: dataTableOptions,
        emptyMessage: 'No agreements found. Click the + button to create a new agreement.',
        onAdd: function () {
            DEBUG && ClientLogger.debug('onAdd', '', {});
            setAddOpen(true);
        },
        onRowClick: function (row) {
            DEBUG && ClientLogger.debug('onRowClick', '', { row: row });
            setEditOpenWithId(row.id);
        },
    };
    DEBUG && ClientLogger.debug('AgreementsList', 'render', { tableProps: tableProps });
    return (_jsx(_Fragment, { children: _jsxs(Grid, __assign({ container: true }, { children: [_jsx(TopBackBtnBar, { title: 'Agreements' }), _jsxs(Box, __assign({ mx: 2, mt: 6, mb: 1, className: classes.tableHolder }, { children: [_jsx(SelectorNoForm, { items: orgsSelectList, value: currentOrgId, onChange: function (item) { return setSelectedOrgId(item.id); } }), !currentOrgId ? (_jsx(Loading, {})) : (_jsxs(_Fragment, { children: [_jsx(DataTable, __assign({}, tableProps)), _jsx(Dialog, __assign({ open: !(addOpen === false), onClose: function () { return setAddOpen(false); }, maxWidth: "sm", fullWidth: true }, { children: _jsx(AddAgreement, { onClose: function () {
                                            setAddOpen(false);
                                        }, orgId: currentOrgId }) })), _jsx(Dialog, __assign({ open: !!editOpenWithId, onClose: function () { return setEditOpenWithId(undefined); }, maxWidth: "sm", fullWidth: true }, { children: _jsx(EditAgreement, { onClose: function () {
                                            setEditOpenWithId(undefined);
                                        }, agreementId: editOpenWithId || '' }) }))] }))] }))] })) }));
};

import { DefaultCalculation } from './default-calculation';
import { FrequencyParams } from './frequency-params';
import { NotificationParams, NotificationProtocol } from './notification-params';
import { FieldSpec } from './path-parser';
import { MultiEnvLogger } from './MultiEnvLogger';

export interface FieldAndValue {
  fieldSpec: FieldSpec;
  value: string;
}

export enum ASSIGN_WHILE_TYPE {
  NO_CRITERIA = 'NO_CRITERIA',
  FIELD_EQUAL_TO_VALUE = 'FIELD_EQUAL_TO_VALUE',
  FIELD_NOT_EQUAL_TO_VALUE = 'FIELD_NOT_EQUAL_TO_VALUE',
  EXPRESSION_IS_TRUE = 'EXPRESSION_IS_TRUE',
}

export interface AssignWhile {
  editMode: ASSIGN_WHILE_TYPE;
  assignWhileExpression?: string;
  assignWhileLastValEqual?: FieldAndValue; // Allows UI to specify a field and value without complexity of writing an equation
}

export interface SubmittedFormVisibility {
  visibleToRoles?: string[]; // If absent, visible to all roles
  announceSubmissionInChat?: boolean;
}

export interface SubmittedFormOptions {
  showSuccessToast?: boolean;
  optionalToastText?: string;
}

export function isSubmittedVisible(circleOfCareRole: string, attributes: FormAssignmentAttributes | undefined): boolean {
  if (
    !attributes ||
    !attributes.submittedFormVisibility ||
    !attributes.submittedFormVisibility.visibleToRoles ||
    attributes.submittedFormVisibility.visibleToRoles.length === 0
  ) {
    return false;
  }
  return attributes.submittedFormVisibility.visibleToRoles.includes(circleOfCareRole);
}

function getEditMode(assignWhile: AssignWhile | undefined): ASSIGN_WHILE_TYPE {
  if (!assignWhile) {
    return ASSIGN_WHILE_TYPE.NO_CRITERIA;
  }
  if (assignWhile.editMode) {
    return assignWhile.editMode;
  }
  if (assignWhile.assignWhileExpression) {
    return ASSIGN_WHILE_TYPE.EXPRESSION_IS_TRUE;
  } else if (assignWhile.assignWhileLastValEqual) {
    return ASSIGN_WHILE_TYPE.FIELD_EQUAL_TO_VALUE;
  }
  return ASSIGN_WHILE_TYPE.NO_CRITERIA;
}

/**
 * Earlier saved versions may not have had editMode
 * @param assignWhile
 */
export function checkForMissingEditMode(assignWhile: AssignWhile | undefined): AssignWhile {
  const editMode = getEditMode(assignWhile);
  if (assignWhile && assignWhile.editMode && assignWhile.editMode !== editMode) {
    MultiEnvLogger.warning(
      'AssignWhile.checkForMissingEditMode',
      `Found unexpected edit mode.  Correcting ${JSON.stringify({ assignWhile, editMode })}`
    );
  }
  return { ...assignWhile, editMode };
}

export interface FormAssignmentAttributes {
  frequencyParams?: FrequencyParams;
  notificationProtocol: NotificationProtocol;
  notificationParams?: NotificationParams;
  defaultCalculations?: DefaultCalculation[];
  assignWhile?: AssignWhile;
  taskCardFormView?: string;
  submittedFormVisibility?: SubmittedFormVisibility;
  submittedFormOptions?: SubmittedFormOptions;
  notDoneUponCarePlanUpdate?: boolean;
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BackButton } from './BackButton';
import makeStyles from '@mui/styles/makeStyles';
var useClasses = makeStyles({
    navBar: {
        position: 'fixed',
        left: 0,
        right: 0,
        top: 0,
        zIndex: 1010,
        background: '#fff',
    },
});
export function TopBackBtnBar(props) {
    var classes = useClasses();
    var navigate = useNavigate();
    var goBack = function () {
        navigate(-1);
    };
    return (_jsx(Box, __assign({ px: 2, py: 1, className: classes.navBar }, { children: _jsx(BackButton, { goBack: goBack, title: props.title }) })));
}

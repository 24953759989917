var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Clear';
import React, { useContext, useRef, useState } from 'react';
import { DataTable, EmailOnlyField, Password, SEVERITY, ToastDispatchContext } from 'ui-lib';
import { ClientLogger } from 'lib/client-logger';
import { usePersonOrgService } from 'lib/api/use-person-org-serivce';
import { dialogClasses } from 'style/sharedCssClasses';
import { Formik } from 'formik';
import { useErrorHandler } from 'lib/use-error-handler';
import { Util } from 'verid-shared-lib';
var DEBUG = true;
export var PersonOrgSearchResult = function (props) {
    var personOrgService = usePersonOrgService();
    var formRef = useRef(null);
    var errorHandler = useErrorHandler('EditOrg');
    var _a = useState(false), emailModal = _a[0], setEmailModal = _a[1];
    var _b = useState(), personData = _b[0], setPersonData = _b[1];
    var toastDispatch = useContext(ToastDispatchContext);
    var dataTableOptions = {
        pagination: false,
        download: false,
        print: false,
        filter: false,
        search: false,
        viewColumns: false,
        sort: false,
        selectableRows: 'none',
    };
    var columns = {
        firstName: { label: 'First Name' },
        lastName: { label: 'Last Name' },
        updatedAt: { display: 'excluded' },
        createdAt: { display: 'excluded' },
        personOrgs: { display: 'excluded' },
        id: { display: 'excluded' },
        attributes: { display: 'excluded' },
        currentOrg: { display: 'excluded' },
        isTest: { display: 'excluded' },
        status: { display: 'excluded' },
        buttons: {
            label: '',
            customBodyRender: function (value) {
                if (value !== undefined && value !== null) {
                    var buttons = [];
                    var buttonSpec = props.buttons || [{ id: 'use', name: 'Use' }];
                    for (var _i = 0, buttonSpec_1 = buttonSpec; _i < buttonSpec_1.length; _i++) {
                        var button = buttonSpec_1[_i];
                        buttons.push(_jsx(Button, __assign({ color: "primary", variant: "contained", onClick: function () {
                                DEBUG && ClientLogger.debug('custom render click', '', value);
                                setEmailModal(true);
                                setPersonData(value.person);
                            } }, { children: button.name }), button.id));
                    }
                    return buttons;
                }
                else {
                    return _jsx(_Fragment, {});
                }
            },
            customHeadRender: function () { return _jsx("td", {}, "edit"); },
        },
    };
    function save(values) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function () {
            var role, resp, id, respConnect;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        DEBUG && ClientLogger.debug('Add Existing person Org', 'save', { values: values });
                        role = props.role;
                        if (!values.email) {
                            errorHandler.handleErrors({ error: 'Email ID is required' });
                            return [2 /*return*/];
                        }
                        if (props.org && !Util.checkValidEmailDomain(props.org.emailDomain, role, values.email)) {
                            errorHandler.handleErrors({ error: "Email domain must end with ".concat(props.org.emailDomain[role]) });
                            return [2 /*return*/];
                        }
                        if (!personData.id) return [3 /*break*/, 2];
                        return [4 /*yield*/, personOrgService.personOrgCreateConnectPerson({
                                orgId: props.org.id,
                                role: role,
                                existingPersonId: personData.id,
                                email: values.email,
                                password: values.password,
                            })];
                    case 1:
                        respConnect = _c.sent();
                        id = (_b = (_a = respConnect.data) === null || _a === void 0 ? void 0 : _a.personOrgCreateConnectPerson) === null || _b === void 0 ? void 0 : _b.id;
                        resp = respConnect;
                        if (id) {
                            if (personData.id) {
                                toastDispatch({
                                    severity: SEVERITY.SUCCESS,
                                    msg: "Existing person record for ".concat(personData.firstName, " ").concat(personData.lastName, " as added to this organization"),
                                });
                            }
                            DEBUG && ClientLogger.debug('AddPersonOrg', 'closing');
                            setEmailModal(false);
                            props.onClose();
                        }
                        else {
                            setEmailModal(false);
                            errorHandler.handleErrors({ graphQLErrors: resp.errors });
                        }
                        _c.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    }
    var transformResponseToRows = function (resp) {
        var _a;
        if (!resp || !resp.persons || !((_a = resp.persons) === null || _a === void 0 ? void 0 : _a.edges)) {
            return [];
        }
        return resp.persons.edges.map(function (person) { return (__assign(__assign({}, person.node), { buttons: { person: person.node } })); });
    };
    var tableProps = {
        useQuery: personOrgService.usePersonsQuery({
            errorPolicy: 'all',
            variables: { query: { firstName: props.searchCriteria.firstName, lastName: props.searchCriteria.lastName } },
        }),
        initialQuery: {},
        transformResponseToRows: transformResponseToRows,
        columnAttributes: columns,
        tableAttributes: dataTableOptions,
    };
    DEBUG && ClientLogger.debug('PersonOrgSearchResult', 'render', { props: props });
    if (!props.searchCriteria || JSON.stringify(props.searchCriteria) === '{}') {
        return _jsx(_Fragment, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(DataTable, __assign({}, tableProps)), _jsx(Formik, __assign({ innerRef: formRef, initialValues: {
                    email: '',
                    password: '',
                }, onSubmit: function (values, actions) {
                    DEBUG && ClientLogger.debug('Create Email for existing user', "onSubmit values", values);
                    save(values);
                } }, { children: function (_a) {
                    var values = _a.values;
                    return (_jsx(_Fragment, { children: _jsxs(Dialog, __assign({ open: emailModal, fullWidth: true }, { children: [_jsxs(DialogTitle, __assign({ sx: dialogClasses.dialogTitle }, { children: ["Add Email", _jsx(IconButton, __assign({ size: "large" }, { children: _jsx(CloseIcon, { sx: dialogClasses.closeIcon, onClick: function () { return setEmailModal(false); } }) }))] })), _jsxs(DialogContent, { children: [_jsx(EmailOnlyField, {}), _jsx(Password, { name: "password", fullWidth: true })] }), _jsx(DialogActions, { children: _jsxs(Box, __assign({ display: "flex", justifyContent: "space-between", alignItems: "center" }, { children: [_jsx(Button, __assign({ color: "primary", onClick: function () { return setEmailModal(false); } }, { children: "Cancel" })), _jsx(Button, __assign({ color: "primary", onClick: function () { return save(values); } }, { children: "Submit" }))] })) })] })) }));
                } }))] }));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, FormControl, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import verifyEmail from '../../assets/verify-email.svg';
import { FormContainer } from '../../components/form/FormContainer';
import { ClientLogger } from '../../lib/client-logger';
import { useAuthService } from '../../lib/api/use-auth-service';
import { SEVERITY, ToastDispatchContext, EMAIL_REGEX } from 'ui-lib';
import { theme } from '../../style/theme';
var DEBUG = false;
var useClasses = makeStyles({
    formContainer: (_a = {
            margin: '20vh auto 0 auto',
            width: 400,
            maxWidth: '90vw',
            position: 'relative',
            minHeight: '20vh',
            padding: 24
        },
        _a[theme.breakpoints.down('sm')] = {
            margin: '4px auto',
            padding: '48px 16px 16px 16px',
        },
        _a),
    itemHolder: {
        marginBottom: 16,
        width: '100%',
    },
    formField: {
        marginBottom: 12,
    },
});
export function ResetPasswordStart() {
    var _this = this;
    var classes = useClasses();
    var navigate = useNavigate();
    var toastDispatch = useContext(ToastDispatchContext);
    var authApi = useAuthService();
    var _a = useState(false), requestSent = _a[0], setRequestSent = _a[1];
    var handleSubmit = function (values, _a) {
        var setSubmitting = _a.setSubmitting;
        return __awaiter(_this, void 0, void 0, function () {
            var debugLocation, result, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        debugLocation = 'ResetPasswordStart.handleSubmit';
                        ClientLogger.debug(debugLocation, 'start', DEBUG);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, authApi.passwordResetStart(values.email)];
                    case 2:
                        result = _b.sent();
                        if (result) {
                            setRequestSent(true);
                        }
                        else {
                            toastDispatch({ msg: 'Invalid email or password', severity: SEVERITY.ERROR, autoClose: false });
                        }
                        setSubmitting(false);
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _b.sent();
                        ClientLogger.error('Login', 'Error', e_1);
                        // toast
                        toastDispatch({ msg: 'Login failed', severity: SEVERITY.ERROR });
                        setSubmitting(false);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return (_jsx(Grid, __assign({ container: true, flexDirection: "column", justifyContent: "center" }, { children: _jsx(Paper, __assign({ className: classes.formContainer }, { children: _jsx(Grid, __assign({ container: true, justifyContent: "center" }, { children: !requestSent ? (_jsx(Grid, __assign({ item: true, className: classes.itemHolder }, { children: _jsxs(Grid, __assign({ container: true, direction: "column", justifyContent: "center" }, { children: [_jsx(Box, __assign({ mt: 1, mb: 3 }, { children: _jsx(Typography, __assign({ variant: "h3" }, { children: "Reset Password" })) })), _jsx(Formik, __assign({ initialValues: {
                                    email: '',
                                    password: '',
                                }, validate: function (values) {
                                    var errors = {};
                                    if (!values.email) {
                                        errors.email = 'Required';
                                    }
                                    else if (!EMAIL_REGEX.test(values.email)) {
                                        errors.email = 'Invalid email address';
                                    }
                                    return errors;
                                }, onSubmit: handleSubmit }, { children: function (_a) {
                                    var submitForm = _a.submitForm, isSubmitting = _a.isSubmitting;
                                    return (_jsxs(FormContainer, { children: [_jsx(FormControl, __assign({ fullWidth: true, required: true, variant: "filled" }, { children: _jsx(Field, { component: TextField, name: "email", type: "text", label: "Email Address", "data-test": "input-email", variant: "outlined", className: classes.formField }) })), isSubmitting && _jsx(LinearProgress, {}), _jsx(Button, __assign({ type: "submit", variant: "contained", color: "primary", disabled: isSubmitting, onClick: submitForm, fullWidth: true, "data-test": "button-sign-in" }, { children: "Send Reset Link" }))] }));
                                } }))] })) }))) : (_jsx(_Fragment, { children: _jsxs(Grid, __assign({ container: true, flexDirection: "column" }, { children: [_jsx(Typography, __assign({ variant: "h4", color: "textPrimary" }, { children: "Check your email" })), _jsx(Box, __assign({ my: 2 }, { children: _jsx(Typography, __assign({ variant: "body1", color: "textPrimary" }, { children: "We have sent you an email to set or change your Healix password." })) })), _jsx(Grid, __assign({ container: true, justifyContent: "center" }, { children: _jsx("img", { style: {
                                        width: 100,
                                        marginBottom: 12,
                                    }, src: verifyEmail, alt: "" }) }))] })) })) })) })) })));
}

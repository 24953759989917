var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/Logo.png';
import { HamburgerMenu } from './HamburgerMenu';
import { ROUTES } from 'Routes';
import makeStyles from '@mui/styles/makeStyles';
var useClasses = makeStyles({
    logo: {
        height: 16,
        cursor: 'pointer',
        verticalAlign: 'middle',
    },
});
export function Navbar() {
    var classes = useClasses();
    var navigate = useNavigate();
    var goHome = function () {
        navigate(ROUTES.HOME);
    };
    return (_jsxs(Box, __assign({ my: 0.5, display: "flex", flexDirection: "row" }, { children: [_jsx(Box, { children: _jsx(HamburgerMenu, {}) }), _jsx(Box, __assign({ ml: 1 }, { children: _jsx("img", { src: logo, alt: "Healix\u2122", onClick: goHome, className: classes.logo }) }))] })));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import React, { useState } from 'react';
import { DataTable } from 'ui-lib';
import { ClientLogger } from 'lib/client-logger';
import { useOrgService } from 'lib/api/use-org-service';
import { OrgRelation, OrgType } from 'lib/api/api-types';
import { tableClasses } from '../../style/sharedCssClasses';
import { OrgSelector } from 'components/form/OrgSelector';
var DEBUG = true;
export var OrgRelationList = function (props) {
    var orgsService = useOrgService();
    var _a = useState(false), removeOpen = _a[0], setRemoveOpen = _a[1]; //false not open, ButtonColumnData  thing being removed
    var _b = useState(false), addOrgOpen = _b[0], setAddOrgOpen = _b[1];
    var _c = useState(undefined), selectedOrgId = _c[0], setSelectedOrgId = _c[1];
    var orgType = (function (orgRelation) {
        switch (orgRelation) {
            case OrgRelation.PROVIDER1_APP2: {
                if (props.org2Id) {
                    return OrgType.PROVIDER;
                }
                else {
                    return OrgType.APP;
                }
            }
            case OrgRelation.PROVIDER1_JURISDICTION2: {
                if (props.org2Id) {
                    return OrgType.PROVIDER;
                }
                else {
                    return OrgType.JURISDICTION;
                }
            }
            default:
                throw new Error("OrgRelationList: unknown orgRelation ".concat(orgRelation));
        }
    })(props.orgRelation);
    function addRelation(addedOrgId) {
        return __awaiter(this, void 0, void 0, function () {
            var org1Id, org12d, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        org1Id = props.org1Id || addedOrgId;
                        org12d = props.org2Id || addedOrgId;
                        return [4 /*yield*/, orgsService.orgOrgCreate(props.orgRelation, org1Id, org12d)];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    function removeRelation(addedOrgId) {
        return __awaiter(this, void 0, void 0, function () {
            var org1Id, org12d, resp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        org1Id = props.org1Id || addedOrgId;
                        org12d = props.org2Id || addedOrgId;
                        return [4 /*yield*/, orgsService.orgOrgDelete(props.orgRelation, org1Id, org12d)];
                    case 1:
                        resp = _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    var orgsDataTableOptions = {
        pagination: true,
        download: false,
        print: false,
        filter: false,
        search: false,
        viewColumns: false,
        sort: true,
        selectableRows: 'none',
    };
    var columns = {
        id: { display: 'excluded' },
        name: { label: 'Name' },
        updatedAt: { display: 'excluded' },
        emailDomain: { display: 'excluded' },
        externalId: { display: 'excluded' },
        isTest: { display: 'excluded' },
        attributes: { display: 'excluded' },
        authConfig: { display: 'excluded' },
        extMessageConfig: { display: 'excluded' },
        type: { display: 'excluded' },
        autoOnboard: { display: 'excluded' },
        description: { display: 'excluded' },
        createdAt: { label: 'Created At' },
        status: { display: 'excluded' },
        buttons: {
            label: '',
            customBodyRender: function (value) {
                if (value !== undefined && value !== null) {
                    return (_jsx(Box, __assign({ display: "flex", justifyContent: "space-evenly", alignItems: "center" }, { children: _jsx(IconButton, __assign({ onClick: function () {
                                DEBUG && ClientLogger.debug('custom render click', '', value);
                                setRemoveOpen(value);
                            }, color: "primary" }, { children: _jsx(DeleteIcon, {}) })) })));
                }
                else {
                    return _jsx(_Fragment, {});
                }
            },
            customHeadRender: function () { return _jsx("td", {}, "edit"); },
        },
    };
    var transformResponseToRows = function (resp) {
        var _a;
        if (!resp || !resp.orgs || !((_a = resp.orgs) === null || _a === void 0 ? void 0 : _a.edges)) {
            return [];
        }
        return resp.orgs.edges.map(function (org) { return (__assign(__assign({}, org.node), { buttons: { id: org.node.id, name: org.node.name } })); });
    };
    var tableProps = {
        useQuery: orgsService.useOrgsQueryFactory({
            variables: { relation: { type: props.orgRelation, org1Id: props.org1Id, org2Id: props.org2Id } },
            fetchPolicy: 'network-only',
        }),
        initialQuery: { where: { relation: { type: props.orgRelation, org1Id: props.org1Id, org2Id: props.org2Id } } },
        transformResponseToRows: transformResponseToRows,
        columnAttributes: columns,
        tableAttributes: orgsDataTableOptions,
    };
    DEBUG && ClientLogger.debug('OrgList', 'render', { tableProps: tableProps, props: props });
    return (_jsxs(_Fragment, { children: [_jsx(Grid, __assign({ container: true }, { children: _jsxs(Box, __assign({ sx: tableClasses.tableHolder }, { children: [_jsx(DataTable, __assign({}, tableProps)), _jsx(DialogActions, { children: _jsx(IconButton, __assign({ style: { float: 'right', background: '#00539D', color: '#ffffff' }, size: "large" }, { children: _jsx(AddIcon, { onClick: function () {
                                        setAddOrgOpen(true);
                                    } }) })) })] })) })), _jsxs(Dialog, __assign({ open: !!removeOpen, onClose: function () { return setRemoveOpen(false); }, maxWidth: "sm", fullWidth: true }, { children: [_jsxs(DialogTitle, { children: ["Remove ", removeOpen && removeOpen.name] }), _jsx(DialogContent, { children: _jsxs(Typography, __assign({ variant: "body1" }, { children: ["Are you sure you want to remove ", removeOpen && removeOpen.name, "?"] })) }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ onClick: function () {
                                    setRemoveOpen(false);
                                } }, { children: "Cancel" })), _jsx(Button, __assign({ onClick: function (item) {
                                    DEBUG && ClientLogger.debug('OrgList', 'org remove related', { item: item });
                                    setRemoveOpen(false);
                                    if (removeOpen) {
                                        removeRelation(removeOpen.id);
                                    }
                                } }, { children: "OK" }))] })] })), _jsxs(Dialog, __assign({ open: addOrgOpen, onClose: function () { return setRemoveOpen(false); }, maxWidth: "sm", fullWidth: true }, { children: [_jsxs(DialogTitle, { children: ["Add a ", orgType] }), _jsx(DialogContent, { children: _jsx(OrgSelector, { onChange: function (selected) {
                                DEBUG && ClientLogger.debug('OrgList', 'org add related', { selected: selected });
                                setSelectedOrgId(selected.id);
                            }, orgType: orgType }) }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ onClick: function () {
                                    setAddOrgOpen(false);
                                } }, { children: "Cancel" })), _jsx(Button, __assign({ disabled: !selectedOrgId, onClick: function (item) {
                                    DEBUG && ClientLogger.debug('OrgList', 'org add related', { item: item });
                                    setAddOrgOpen(false);
                                    if (selectedOrgId) {
                                        addRelation(selectedOrgId);
                                    }
                                } }, { children: "OK" }))] })] }))] }));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Dialog, Grid } from '@mui/material';
import React, { useState } from 'react';
import { DataTable } from 'ui-lib';
import { ClientLogger } from 'lib/client-logger';
import { EditIdType } from './EditIdType';
import { TopBackBtnBar } from '../../components/navigation/TopBackBtnBar';
import { tableClasses } from '../../style/sharedCssClasses';
import { useIdTypeService } from 'lib/api/use-id-type-service';
var DEBUG = false;
export var IdTypeList = function () {
    var idTypeService = useIdTypeService();
    var _a = useState(false), editOpen = _a[0], setEditOpen = _a[1]; //false not open, undefined open with new, string orgId beiong edited
    var dataTableOptions = {
        pagination: true,
        download: true,
        print: true,
        filter: false,
        search: false,
        viewColumns: false,
        sort: true,
        selectableRows: 'none',
    };
    var columns = {
        id: { label: 'Name (id)' },
        updatedAt: { display: 'excluded' },
        description: { label: 'Description' },
        createdAt: { display: 'excluded' },
        label: { display: 'excluded' },
        placeHolder: { display: 'excluded' },
        format: { display: 'excluded' },
        useExpiryDate: { display: 'excluded' },
        expiryDateFormat: { display: 'excluded' },
        expiryDateLabel: { display: 'excluded' },
        expiryDatePlaceHolder: { display: 'excluded' },
        status: { label: 'Status' },
    };
    var transformResponseToRows = function (resp) {
        var _a;
        if (!resp || !resp.idTypes || !((_a = resp.idTypes) === null || _a === void 0 ? void 0 : _a.edges)) {
            return [];
        }
        return resp.idTypes.edges.map(function (idType) { return (__assign({}, idType.node)); });
    };
    var rowClicked = function (row) {
        setEditOpen(row.id);
    };
    var tableProps = {
        useQuery: idTypeService.useIdTypesQuery,
        initialQuery: {},
        transformResponseToRows: transformResponseToRows,
        columnAttributes: columns,
        tableAttributes: dataTableOptions,
        onAdd: function () {
            DEBUG && ClientLogger.debug('onAdd', '', {});
            setEditOpen(undefined);
        },
        onRowClick: rowClicked,
    };
    DEBUG && ClientLogger.debug('OrgList', 'render', { tableProps: tableProps });
    return (_jsxs(_Fragment, { children: [_jsxs(Grid, __assign({ container: true }, { children: [_jsx(TopBackBtnBar, { title: "Identification Types" }), _jsx(Box, __assign({ mx: 2, mt: 6, mb: 1, sx: tableClasses.tableHolder }, { children: _jsx(DataTable, __assign({}, tableProps)) }))] })), _jsx(Dialog, __assign({ open: !(editOpen === false), onClose: function () { return setEditOpen(false); }, maxWidth: "sm", fullWidth: true }, { children: _jsx(EditIdType, { onClose: function () {
                        setEditOpen(false);
                    }, editingId: typeof editOpen === 'string' ? editOpen : undefined }) }))] }));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Field } from 'formik';
import { ClientLogger } from 'lib/client-logger';
import * as React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'style/reactQuill/style.css';
import { dialogClasses } from 'style/sharedCssClasses';
var DEBUG = false;
export function RichTextField(props) {
    var label = props.label, name = props.name;
    return (_jsx(_Fragment, { children: _jsx(Field, __assign({ name: name, sx: dialogClasses.inputField, type: "text", fullWidth: true, variant: "outlined" }, { children: function (formikProps) {
                var _a = formikProps.form, values = _a.values, setFieldValue = _a.setFieldValue;
                DEBUG && ClientLogger.debug('RichTextField', 'render', { formikProps: formikProps, values: values, setFieldValue: setFieldValue });
                return (_jsxs(_Fragment, { children: [_jsx("label", { children: label }), _jsx(ReactQuill, { style: { whiteSpace: 'pre-wrap', height: '200px', marginBottom: '30px' }, value: values[name] || '', formats: ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image'], modules: {
                                toolbar: [
                                    [{ header: [1, 2, 3, 4, 5, false] }],
                                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                                ],
                            }, onChange: function (value) {
                                DEBUG && ClientLogger.debug('RichTextField', 'ReactQuill onChange', { value: value });
                                setFieldValue(name, value);
                            } })] }));
            } })) }));
}

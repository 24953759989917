export type ENTITY_ID = 'plan' | 'submission' | 'integration' | 'workQueueItem' | 'user';
export const SUBMISSION_ENTITY_ID: ENTITY_ID = 'submission';
export const INTEGRATION_ENTITY_ID: ENTITY_ID = 'integration';
export const DS_CARE_PLAN_ENTITY: ENTITY_ID = 'plan';
export const WORK_QUEUE_ITEM_ENTITY: ENTITY_ID = 'workQueueItem';
export const USER_ENTITY: ENTITY_ID = 'user';

export const allEntities: ENTITY_ID[] = ['plan', 'submission', 'integration', 'workQueueItem', 'user'];

export function getEntityLevels(entityId: ENTITY_ID): 2 | 3 {
  switch (entityId) {
    case 'integration':
      return 3;
    case 'plan':
      return 2;
    case 'submission':
      return 3;
    case 'workQueueItem':
      return 3;
    case 'user':
      return 3;
    default:
      throw new Error(`Unexpected entity ID ${entityId}`);
  }
}

export function shouldSuppressRuleIfNotChanged(entity: ENTITY_ID): boolean {
  switch (entity) {
    case 'integration':
      return true;
    case 'plan':
      return false;
    case 'submission':
      return true;
    case 'workQueueItem':
      return true;
    case 'user':
      return true;
    default:
      throw new Error(`Unexpected entity ID ${entity}`);
  }
}

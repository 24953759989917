import { useContext, useState } from 'react';
import { SEVERITY, ToastDispatchContext } from 'ui-lib';
import { ClientLogger } from './client-logger';
var DEBUG = false;
export function useErrorHandler(location) {
    var toastDispatch = useContext(ToastDispatchContext);
    var _a = useState(undefined), errorData = _a[0], setErrorData = _a[1];
    function handleErrors(newErrorData, toastFlag) {
        if (toastFlag === void 0) { toastFlag = true; }
        setErrorData(newErrorData);
        var errors = new ErrorFormatter(newErrorData);
        ClientLogger.error(location, errors.displayErrors());
        toastFlag && toastDispatch({ msg: errors.displayErrors(), severity: SEVERITY.ERROR, autoClose: true });
    }
    function handleQueryResponse(result) {
        if (result) {
            if (result.error) {
                handleErrors({ apolloErrors: result.error, graphQLErrors: result.errors });
            }
            if (result.errors) {
                handleErrors({ graphQLErrors: result.errors });
            }
        }
        else {
            handleErrors({ error: 'Unexpected null result' });
        }
    }
    function handleMutateResponse(result) {
        if (result) {
            if (result.errors) {
                handleErrors({ graphQLErrors: result.errors });
            }
        }
        else {
            handleErrors({ error: 'Unexpected null result' });
        }
    }
    return { errorData: errorData, handleErrors: handleErrors, handleQueryResponse: handleQueryResponse, handleMutateResponse: handleMutateResponse };
}
var ErrorFormatter = /** @class */ (function () {
    function ErrorFormatter(errorData) {
        this.errorData = errorData;
    }
    ErrorFormatter.prototype.displayErrors = function () {
        var _a, _b;
        var errors = [];
        (_a = this.errorData.errors) === null || _a === void 0 ? void 0 : _a.forEach(function (e) { return errors.push(JSON.stringify(e)); });
        (_b = this.errorData.graphQLErrors) === null || _b === void 0 ? void 0 : _b.forEach(function (e) { return errors.push(JSON.stringify(e.message)); });
        if (this.errorData.apolloErrors) {
            errors.push(ClientLogger.errorToText(this.errorData.apolloErrors));
        }
        if (this.errorData.error) {
            errors.push(ClientLogger.errorToText(this.errorData.error));
        }
        DEBUG && ClientLogger.debug('ErrorFormatter', 'displayErrors', { errorData: this.errorData, errors: errors });
        return errors.join(', ');
    };
    ErrorFormatter.prototype.toastAndLogError = function (toastDispatch, location, whenAttempting) {
        if (!this.errorData.errors) {
            this.errorData.errors = [];
        }
        var newMessage = "Error ".concat(whenAttempting, " in module ").concat(location);
        this.errorData.errors.push(newMessage);
        var msg = this.displayErrors();
        ClientLogger.error(location, msg);
        msg = this.displayErrors();
        toastDispatch({ msg: msg, severity: SEVERITY.ERROR, autoClose: true });
    };
    return ErrorFormatter;
}());

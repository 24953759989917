import { ENTITY_ID } from './entity';
import { LocalizedString } from './localized-string';
import { ServiceLocations, WorkQueueStatus, WorkQueueStatuses } from './work-queue';

type WorkListDisplayDataSourceType = 'work-queue-item' | 'dynamic-data-model';
type WorkListDisplayDynamicDataModelType = 'field-reference' | 'expression';

export interface WorkListDisplayDataSource {
  dynamicDataModelType: WorkListDisplayDynamicDataModelType;
  expression?: string;
  entityId?: ENTITY_ID;
  categoryId?: string;
  fieldName?: string;
}

export interface WorkQueueDisplayIdentifier {
  deviceName: string;
  orgId: string;
}

export type WorkQueueItemDisplayFieldId =
  | 'name'
  | 'ticketNumber'
  | 'priority'
  | 'serviceLocation'
  | 'status'
  | 'notes'
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'queuePosition'
  | 'createdAgo'
  | 'planId'
  | 'phoneNumber'
  | 'email'
  | 'age';

export type WorkListOrderFieldId =
  | 'name'
  | 'ticketNumber'
  | 'priority'
  | 'serviceLocation'
  | 'status'
  | 'createdAt'
  | 'updatedAt'
  | 'queuePosition'
  | 'none';
export interface WorkListDisplayColumn {
  label: LocalizedString;
  dataSourceType: WorkListDisplayDataSourceType;
  workQueueItemFieldName?: WorkQueueItemDisplayFieldId;
  dataSource?: WorkListDisplayDataSource;
  colors?: WorkListDisplayColumnColorMap[];
  defaultColor?: ColorPair;
}

export interface ColorPair {
  textColor?: string;
  backgroundColor?: string;
}

export interface StringColorMap extends ColorPair {
  value: string;
}

export interface RangeColorMap extends ColorPair {
  greaterThanOrEqual: number;
  lessThan: number;
}

export interface WorkListOrder {
  field: WorkListOrderFieldId;
  order: 'asc' | 'desc';
}

// export interface WorkListDisplayColumnColor extends StringColorMap, RangeColorMap {}
export type WorkListDisplayColumnColorMap = StringColorMap | RangeColorMap;
export interface WorkListDisplay {
  columns: WorkListDisplayColumn[];
  defaultOrder: WorkListOrder[];
}

export const EMPTY_WORK_LIST_DISPLAY: WorkListDisplay = {
  columns: [],
  defaultOrder: [],
};

export function checkWorkListDisplay(workListDisplay: WorkListDisplay | undefined | null): WorkListDisplay {
  if (!workListDisplay) {
    workListDisplay = EMPTY_WORK_LIST_DISPLAY;
  }
  if (!workListDisplay.columns) {
    workListDisplay.columns = [
      {
        dataSourceType: 'work-queue-item',
        label: [{ l: 'en', s: 'Status' }],
      },
    ];
  }
  if (!workListDisplay.defaultOrder?.length) {
    workListDisplay.defaultOrder = [{ field: 'createdAt', order: 'asc' }];
  }
  return workListDisplay;
}

// key for each StatusDisplay
export interface WorkQueueStatusDisplay {
  statusId: string;
  includeInDisplay: boolean;
  textColor?: string;
  backgroundColor?: string;
  showServiceLocation?: boolean | null;
  serviceLocations?: ServiceLocations | null;
}

export interface MergeResult {
  statusDisplays: WorkQueueStatusDisplay[];
  notFoundStatuses: WorkQueueStatus[];
}

export const mergeWorkQueueStatusesWithDisplayConfig = (
  statuses: WorkQueueStatuses,
  statusDisplays: WorkQueueStatusDisplay[]
): MergeResult => {
  const merged: WorkQueueStatusDisplay[] = [];
  let notFoundStatuses: WorkQueueStatus[] = [...statuses];

  for (const status of statuses) {
    const found = statusDisplays.find((a) => a.statusId === status.id);
    if (!found) {
      // New status not found - add to display config
      merged.push({
        statusId: status.id,
        includeInDisplay: false,
        showServiceLocation: status?.serviceLocationsActive,
        serviceLocations: status?.serviceLocations,
      });
    } else {
      merged.push({
        ...found,
        showServiceLocation: status?.serviceLocationsActive,
        serviceLocations: status?.serviceLocations,
      });
    }
    // remove the status from the not found list so orphans can be reported
    const added = merged[merged.length - 1];
    notFoundStatuses = notFoundStatuses.filter((a) => a.id !== added.statusId);
  }
  return {
    statusDisplays: merged,
    notFoundStatuses: notFoundStatuses,
  };
};

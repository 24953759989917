export enum AuthUserRole {
  system = 'system',
  user = 'user',
  admin = 'admin',
  beta = 'beta',
  'content-manager' = 'content-manager',
  clinician = 'clinician',
}

export interface VerifiedJwtToken {
  exp: number;
  email: string;
  sub: string; // this is the FusionAuth user id
  userId?: string;
  aud: string;
  iat: number;
  iss: string;
  authenticationType:
    | 'GOOGLE'
    | 'APPLICATION_TOKEN'
    | 'FEDERATED_JWT'
    | 'JWT_SSO'
    | 'ONE_TIME_PASSWORD'
    | 'PASSWORD'
    | 'PASSWORDLESS'
    | 'REFRESH_TOKEN'
    | 'FACEBOOK'
    | 'TWITTER'
    | 'OPENID_CONNECT';
  email_verified: boolean;
  applicationId: string;
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Card, CardActions, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from '@mui/material';
import { AgreementStatus } from 'lib/api/api-types';
import { useAgreementService } from 'lib/api/use-agreement-service';
import { UserContext } from 'lib/auth/user-context';
import { ClientLogger } from 'lib/client-logger';
import { useErrorHandler } from 'lib/use-error-handler';
import React, { useContext, useEffect, useState } from 'react';
import { dialogClasses } from 'style/sharedCssClasses';
import { SEVERITY, ToastDispatchContext } from 'ui-lib';
var DEBUG = false;
function calcTitle(agreement) {
    if (!agreement) {
        return '';
    }
    return "".concat(agreement === null || agreement === void 0 ? void 0 : agreement.agreementName, " (v").concat(agreement.version, ") for ").concat(agreement.orgName);
}
export function AgreementCheck(props) {
    var _this = this;
    var _a, _b;
    var agreementSerivce = useAgreementService();
    var userContext = useContext(UserContext);
    var errorHandler = useErrorHandler('AgreementCheck');
    var _c = useState([]), agreementsRequired = _c[0], setAgreementsRequired = _c[1];
    var _d = useState(false), isSubmitting = _d[0], setIsSubmitting = _d[1];
    var _e = useState(undefined), viewAgreement = _e[0], setViewAgreement = _e[1];
    var _f = useState(undefined), rejectAgreement = _f[0], setRejectAgreement = _f[1];
    var agreementOpen = agreementsRequired.length > 0;
    var toastDispatch = useContext(ToastDispatchContext);
    useEffect(function () {
        var _a, _b;
        function load() {
            var _a;
            return __awaiter(this, void 0, void 0, function () {
                var resp;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0: return [4 /*yield*/, agreementSerivce.agreementsRequired()];
                        case 1:
                            resp = _b.sent();
                            DEBUG && ClientLogger.debug('AgreementCheck', 'load', { resp: resp });
                            if ((_a = resp.data) === null || _a === void 0 ? void 0 : _a.agreementsRequired) {
                                setAgreementsRequired(__spreadArray([], resp.data.agreementsRequired, true));
                            }
                            else {
                                errorHandler.handleErrors({ graphQLErrors: resp.errors });
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
        if ((_b = (_a = userContext.userState) === null || _a === void 0 ? void 0 : _a.me) === null || _b === void 0 ? void 0 : _b.id) {
            load();
        }
    }, [(_b = (_a = userContext.userState) === null || _a === void 0 ? void 0 : _a.me) === null || _b === void 0 ? void 0 : _b.id]);
    var agreeToAll = function () { return __awaiter(_this, void 0, void 0, function () {
        var _i, _a, agreement;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _i = 0, _a = __spreadArray([], agreementsRequired, true);
                    _b.label = 1;
                case 1:
                    if (!(_i < _a.length)) return [3 /*break*/, 4];
                    agreement = _a[_i];
                    return [4 /*yield*/, agreementAcceptanceCreate(AgreementStatus.ACCEPTED, agreement)];
                case 2:
                    _b.sent();
                    _b.label = 3;
                case 3:
                    _i++;
                    return [3 /*break*/, 1];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var agreementAcceptanceCreate = function (status, agreement) { return __awaiter(_this, void 0, void 0, function () {
        var resp;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setIsSubmitting(true);
                    return [4 /*yield*/, agreementSerivce.agreementAcceptanceCreate({ agreementVersionId: agreement.versionId, status: status })];
                case 1:
                    resp = _b.sent();
                    DEBUG && ClientLogger.debug('AgreementCheck', 'agreementAcceptanceCreate', { resp: resp });
                    if ((_a = resp.data) === null || _a === void 0 ? void 0 : _a.agreementAcceptanceCreate) {
                        // remove the agreement from the list
                        agreementsRequired.splice(agreementsRequired.findIndex(function (agreementSearch) { return agreementSearch.agreementId === agreement.agreementId; }), 1);
                        setAgreementsRequired(__spreadArray([], agreementsRequired, true));
                        if (status === AgreementStatus.REJECTED) {
                            toastDispatch({ severity: SEVERITY.ERROR, msg: 'Agreement Rejected. Logging out...' });
                            props.logout();
                        }
                        else {
                            toastDispatch({
                                severity: SEVERITY.SUCCESS,
                                msg: "Acceptance of ".concat(agreement.agreementName, " for ").concat(agreement.orgName, "  Recorded"),
                                autoClose: true,
                            });
                        }
                    }
                    else {
                        errorHandler.handleErrors({ graphQLErrors: resp.errors });
                    }
                    setIsSubmitting(false);
                    return [2 /*return*/];
            }
        });
    }); };
    if (!agreementOpen) {
        return null;
    }
    var dialogTitle = "Agreement Acknowledgement Required";
    DEBUG && ClientLogger.debug('AgreementCheck', 'render', { agreementOpen: agreementOpen, agreementsRequired: agreementsRequired });
    return (_jsxs(_Fragment, { children: [_jsxs(Dialog, __assign({ open: agreementOpen, onClose: function () { }, maxWidth: "sm", fullWidth: true }, { children: [_jsx(DialogTitle, __assign({ sx: dialogClasses.dialogTitle }, { children: dialogTitle })), _jsx(DialogContent, { children: agreementsRequired.map(function (agreement) {
                            return (_jsxs(Card, __assign({ sx: { minWidth: 275 } }, { children: [_jsx(CardContent, { children: _jsxs(Typography, __assign({ sx: { fontSize: 14 }, color: "text.secondary", gutterBottom: true }, { children: [agreement.orgName, ' ', _jsxs(Link, __assign({ onClick: function () { return setViewAgreement(agreement); } }, { children: [agreement.agreementName, " (v", agreement.version, ")"] }))] })) }), _jsxs(CardActions, { children: [_jsx(Button, __assign({ onClick: function () { return setRejectAgreement(agreement); }, size: "small" }, { children: "Reject" })), _jsx(Button, __assign({ color: "primary", variant: "contained", onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                                                    return __generator(this, function (_a) {
                                                        switch (_a.label) {
                                                            case 0:
                                                                DEBUG && ClientLogger.debug('AgreementCheck', 'save');
                                                                return [4 /*yield*/, agreementAcceptanceCreate(AgreementStatus.ACCEPTED, agreement)];
                                                            case 1:
                                                                _a.sent();
                                                                return [2 /*return*/];
                                                        }
                                                    });
                                                }); }, size: "small" }, { children: "Accept" }))] })] }), agreement.agreementId));
                        }) }), _jsx(DialogActions, { children: _jsx(Button, __assign({ type: "submit", color: "primary", variant: "contained", disabled: isSubmitting, onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            DEBUG && ClientLogger.debug('AgreementCheck', 'save');
                                            return [4 /*yield*/, agreeToAll()];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); } }, { children: "Agree to All" })) })] })), _jsxs(Dialog, __assign({ open: !!viewAgreement, onClose: function () {
                    setViewAgreement(undefined);
                }, maxWidth: "sm", fullWidth: true }, { children: [_jsx(DialogTitle, __assign({ sx: dialogClasses.dialogTitle }, { children: calcTitle(viewAgreement) })), _jsx(DialogContent, { children: _jsx(Typography, { dangerouslySetInnerHTML: { __html: (viewAgreement === null || viewAgreement === void 0 ? void 0 : viewAgreement.content) || '' } }) }), _jsx(DialogActions, { children: _jsx(Button, __assign({ color: "primary", onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    DEBUG && ClientLogger.debug('AgreementCheck', 'done reading');
                                    setViewAgreement(undefined);
                                    return [2 /*return*/];
                                });
                            }); } }, { children: "Done Reading" })) })] })), _jsxs(Dialog, __assign({ open: !!rejectAgreement, onClose: function () {
                    setRejectAgreement(undefined);
                }, maxWidth: "sm", fullWidth: true }, { children: [_jsx(DialogTitle, __assign({ sx: dialogClasses.dialogTitle }, { children: "Confirm" })), _jsx(DialogContent, { children: _jsxs(Typography, { children: ["Are you sure you want to reject this agreement? If you do, your ", rejectAgreement === null || rejectAgreement === void 0 ? void 0 : rejectAgreement.orgName, " account will be removed."] }) }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ color: "primary", disabled: isSubmitting, onClick: function () {
                                    DEBUG && ClientLogger.debug('AgreementCheck', 'cancel confirm');
                                    setRejectAgreement(undefined);
                                } }, { children: "Cancel" })), _jsx(Button, __assign({ type: "submit", color: "primary", disabled: isSubmitting, onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                DEBUG && ClientLogger.debug('AgreementCheck', 'save');
                                                return [4 /*yield*/, agreementAcceptanceCreate(AgreementStatus.REJECTED, rejectAgreement)];
                                            case 1:
                                                _a.sent();
                                                setRejectAgreement(undefined);
                                                return [2 /*return*/];
                                        }
                                    });
                                }); } }, { children: "Reject" }))] })] }))] }));
}

// Generated from ./grammars/ExpressionsParserGrammar.g4 by ANTLR 4.9.0-SNAPSHOT


import { ATN } from "antlr4ts/atn/ATN";
import { ATNDeserializer } from "antlr4ts/atn/ATNDeserializer";
import { FailedPredicateException } from "antlr4ts/FailedPredicateException";
import { NotNull } from "antlr4ts/Decorators";
import { NoViableAltException } from "antlr4ts/NoViableAltException";
import { Override } from "antlr4ts/Decorators";
import { Parser } from "antlr4ts/Parser";
import { ParserRuleContext } from "antlr4ts/ParserRuleContext";
import { ParserATNSimulator } from "antlr4ts/atn/ParserATNSimulator";
import { ParseTreeListener } from "antlr4ts/tree/ParseTreeListener";
import { ParseTreeVisitor } from "antlr4ts/tree/ParseTreeVisitor";
import { RecognitionException } from "antlr4ts/RecognitionException";
import { RuleContext } from "antlr4ts/RuleContext";
//import { RuleVersion } from "antlr4ts/RuleVersion";
import { TerminalNode } from "antlr4ts/tree/TerminalNode";
import { Token } from "antlr4ts/Token";
import { TokenStream } from "antlr4ts/TokenStream";
import { Vocabulary } from "antlr4ts/Vocabulary";
import { VocabularyImpl } from "antlr4ts/VocabularyImpl";

import * as Utils from "antlr4ts/misc/Utils";

import { ExpressionsParserGrammarListener } from "./ExpressionsParserGrammarListener";
import { ExpressionsParserGrammarVisitor } from "./ExpressionsParserGrammarVisitor";


export class ExpressionsParserGrammarParser extends Parser {
	public static readonly UNDEFINED = 1;
	public static readonly NULL = 2;
	public static readonly DATE_FORMAT_DISTANCE = 3;
	public static readonly SECONDS = 4;
	public static readonly MINUTES = 5;
	public static readonly HOURS = 6;
	public static readonly DAYS = 7;
	public static readonly WEEKS = 8;
	public static readonly MONTHS = 9;
	public static readonly YEARS = 10;
	public static readonly OR = 11;
	public static readonly AND = 12;
	public static readonly NOT = 13;
	public static readonly DIFFERENCE_IN_MINUTES = 14;
	public static readonly DIFFERENCE_IN_DAYS = 15;
	public static readonly DIFFERENCE_IN_HOURS = 16;
	public static readonly DATE_ADD = 17;
	public static readonly DATE_SUBTRACT = 18;
	public static readonly INCLUDE_SECONDS = 19;
	public static readonly ADD_SUFFIX = 20;
	public static readonly COLON = 21;
	public static readonly QUERY_OP = 22;
	public static readonly NOW = 23;
	public static readonly NEW = 24;
	public static readonly DATE = 25;
	public static readonly PLUS = 26;
	public static readonly MINUS = 27;
	public static readonly MUL = 28;
	public static readonly DIV = 29;
	public static readonly LPAREN = 30;
	public static readonly RPAREN = 31;
	public static readonly NUMBER_LITERAL = 32;
	public static readonly HexIntegerLiteral = 33;
	public static readonly OctalIntegerLiteral = 34;
	public static readonly OctalIntegerLiteral2 = 35;
	public static readonly BinaryIntegerLiteral = 36;
	public static readonly BigHexIntegerLiteral = 37;
	public static readonly BigOctalIntegerLiteral = 38;
	public static readonly BigBinaryIntegerLiteral = 39;
	public static readonly BigDecimalIntegerLiteral = 40;
	public static readonly HexDigit = 41;
	public static readonly ExponentPart = 42;
	public static readonly DecimalIntegerLiteral = 43;
	public static readonly IDENTITY = 44;
	public static readonly NOT_IDENTITY = 45;
	public static readonly EQUALS = 46;
	public static readonly NOT_EQUALS = 47;
	public static readonly MORE_THAN = 48;
	public static readonly LESS = 49;
	public static readonly MORE_OR_EQUALS = 50;
	public static readonly LESS_OR_EQUALS = 51;
	public static readonly FIRST_VAL_FUNC = 52;
	public static readonly FIRST_STR_FUNC = 53;
	public static readonly LAST_VAL_FUNC = 54;
	public static readonly LAST_STR_FUNC = 55;
	public static readonly SUBMISSION_VAL_FUNC = 56;
	public static readonly SUBMISSION_STR_FUNC = 57;
	public static readonly POWER_FUNC = 58;
	public static readonly DATE_FORMAT_FUNC = 59;
	public static readonly MAX = 60;
	public static readonly MIN = 61;
	public static readonly COMMA = 62;
	public static readonly L_CURLY_BRACE = 63;
	public static readonly R_CURLY_BRACE = 64;
	public static readonly PRINT = 65;
	public static readonly TRUE = 66;
	public static readonly FALSE = 67;
	public static readonly STRING_VALUE = 68;
	public static readonly WS = 69;
	public static readonly COMMENT = 70;
	public static readonly LINE_COMMENT = 71;
	public static readonly RULE_expression = 0;
	public static readonly RULE_duration = 1;
	public static readonly RULE_duration_element = 2;
	public static readonly RULE_options = 3;
	public static readonly RULE_option_element = 4;
	public static readonly RULE_years_element = 5;
	public static readonly RULE_month_element = 6;
	public static readonly RULE_weeks_element = 7;
	public static readonly RULE_days_element = 8;
	public static readonly RULE_hours_element = 9;
	public static readonly RULE_minutes_element = 10;
	public static readonly RULE_seconds_element = 11;
	public static readonly RULE_include_seconds = 12;
	public static readonly RULE_add_suffix = 13;
	// tslint:disable:no-trailing-whitespace
	public static readonly ruleNames: string[] = [
		"expression", "duration", "duration_element", "options", "option_element", 
		"years_element", "month_element", "weeks_element", "days_element", "hours_element", 
		"minutes_element", "seconds_element", "include_seconds", "add_suffix",
	];

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, "'undefined'", "'null'", "'dateFormatDistance'", "'seconds'", 
		"'minutes'", "'hours'", "'days'", "'weeks'", "'months'", "'years'", "'||'", 
		"'&&'", "'!'", "'differenceInMinutes'", "'differenceInDays'", "'differenceInHours'", 
		"'dateAdd'", "'dateSubtract'", "'includeSeconds'", "'addSuffix'", "':'", 
		"'?'", "'now'", "'new'", "'Date'", "'+'", "'-'", "'*'", "'/'", "'('", 
		"')'", undefined, undefined, undefined, undefined, undefined, undefined, 
		undefined, undefined, undefined, undefined, undefined, undefined, "'==='", 
		"'!=='", "'=='", "'!='", "'>'", "'<'", "'>='", "'<='", "'firstVal'", "'firstStr'", 
		"'lastVal'", "'lastStr'", "'submissionVal'", "'submissionStr'", "'power'", 
		"'dateFormat'", "'max'", "'min'", "','", "'{'", "'}'", "'print'", "'true'", 
		"'false'",
	];
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, "UNDEFINED", "NULL", "DATE_FORMAT_DISTANCE", "SECONDS", "MINUTES", 
		"HOURS", "DAYS", "WEEKS", "MONTHS", "YEARS", "OR", "AND", "NOT", "DIFFERENCE_IN_MINUTES", 
		"DIFFERENCE_IN_DAYS", "DIFFERENCE_IN_HOURS", "DATE_ADD", "DATE_SUBTRACT", 
		"INCLUDE_SECONDS", "ADD_SUFFIX", "COLON", "QUERY_OP", "NOW", "NEW", "DATE", 
		"PLUS", "MINUS", "MUL", "DIV", "LPAREN", "RPAREN", "NUMBER_LITERAL", "HexIntegerLiteral", 
		"OctalIntegerLiteral", "OctalIntegerLiteral2", "BinaryIntegerLiteral", 
		"BigHexIntegerLiteral", "BigOctalIntegerLiteral", "BigBinaryIntegerLiteral", 
		"BigDecimalIntegerLiteral", "HexDigit", "ExponentPart", "DecimalIntegerLiteral", 
		"IDENTITY", "NOT_IDENTITY", "EQUALS", "NOT_EQUALS", "MORE_THAN", "LESS", 
		"MORE_OR_EQUALS", "LESS_OR_EQUALS", "FIRST_VAL_FUNC", "FIRST_STR_FUNC", 
		"LAST_VAL_FUNC", "LAST_STR_FUNC", "SUBMISSION_VAL_FUNC", "SUBMISSION_STR_FUNC", 
		"POWER_FUNC", "DATE_FORMAT_FUNC", "MAX", "MIN", "COMMA", "L_CURLY_BRACE", 
		"R_CURLY_BRACE", "PRINT", "TRUE", "FALSE", "STRING_VALUE", "WS", "COMMENT", 
		"LINE_COMMENT",
	];
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(ExpressionsParserGrammarParser._LITERAL_NAMES, ExpressionsParserGrammarParser._SYMBOLIC_NAMES, []);

	// @Override
	// @NotNull
	public get vocabulary(): Vocabulary {
		return ExpressionsParserGrammarParser.VOCABULARY;
	}
	// tslint:enable:no-trailing-whitespace

	// @Override
	public get grammarFileName(): string { return "ExpressionsParserGrammar.g4"; }

	// @Override
	public get ruleNames(): string[] { return ExpressionsParserGrammarParser.ruleNames; }

	// @Override
	public get serializedATN(): string { return ExpressionsParserGrammarParser._serializedATN; }

	protected createFailedPredicateException(predicate?: string, message?: string): FailedPredicateException {
		return new FailedPredicateException(this, predicate, message);
	}

	constructor(input: TokenStream) {
		super(input);
		this._interp = new ParserATNSimulator(ExpressionsParserGrammarParser._ATN, this);
	}

	public expression(): ExpressionContext;
	public expression(_p: number): ExpressionContext;
	// @RuleVersion(0)
	public expression(_p?: number): ExpressionContext {
		if (_p === undefined) {
			_p = 0;
		}

		let _parentctx: ParserRuleContext = this._ctx;
		let _parentState: number = this.state;
		let _localctx: ExpressionContext = new ExpressionContext(this._ctx, _parentState);
		let _prevctx: ExpressionContext = _localctx;
		let _startState: number = 0;
		this.enterRecursionRule(_localctx, 0, ExpressionsParserGrammarParser.RULE_expression, _p);
		let _la: number;
		try {
			let _alt: number;
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 188;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case ExpressionsParserGrammarParser.NOT:
				{
				_localctx = new Not_exprContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;

				this.state = 29;
				this.match(ExpressionsParserGrammarParser.NOT);
				this.state = 30;
				(_localctx as Not_exprContext)._expr = this.expression(29);
				}
				break;
			case ExpressionsParserGrammarParser.LPAREN:
				{
				_localctx = new Brackets_exprContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 31;
				this.match(ExpressionsParserGrammarParser.LPAREN);
				this.state = 32;
				(_localctx as Brackets_exprContext)._expr = this.expression(0);
				this.state = 33;
				this.match(ExpressionsParserGrammarParser.RPAREN);
				}
				break;
			case ExpressionsParserGrammarParser.POWER_FUNC:
				{
				_localctx = new Power_exprContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 35;
				this.match(ExpressionsParserGrammarParser.POWER_FUNC);
				this.state = 36;
				this.match(ExpressionsParserGrammarParser.LPAREN);
				this.state = 37;
				(_localctx as Power_exprContext)._expr1 = this.expression(0);
				this.state = 38;
				this.match(ExpressionsParserGrammarParser.COMMA);
				this.state = 39;
				(_localctx as Power_exprContext)._expr2 = this.expression(0);
				this.state = 40;
				this.match(ExpressionsParserGrammarParser.RPAREN);
				}
				break;
			case ExpressionsParserGrammarParser.FIRST_VAL_FUNC:
				{
				_localctx = new First_val_funcContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 42;
				this.match(ExpressionsParserGrammarParser.FIRST_VAL_FUNC);
				this.state = 43;
				this.match(ExpressionsParserGrammarParser.LPAREN);
				this.state = 44;
				(_localctx as First_val_funcContext)._first_str_func = this.match(ExpressionsParserGrammarParser.STRING_VALUE);
				{
				this.state = 47;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === ExpressionsParserGrammarParser.COMMA) {
					{
					this.state = 45;
					this.match(ExpressionsParserGrammarParser.COMMA);
					this.state = 46;
					(_localctx as First_val_funcContext)._config = this.match(ExpressionsParserGrammarParser.STRING_VALUE);
					}
				}

				}
				this.state = 49;
				this.match(ExpressionsParserGrammarParser.RPAREN);
				}
				break;
			case ExpressionsParserGrammarParser.FIRST_STR_FUNC:
				{
				_localctx = new First_str_funcContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 50;
				this.match(ExpressionsParserGrammarParser.FIRST_STR_FUNC);
				this.state = 51;
				this.match(ExpressionsParserGrammarParser.LPAREN);
				this.state = 52;
				(_localctx as First_str_funcContext)._first_str_func = this.match(ExpressionsParserGrammarParser.STRING_VALUE);
				this.state = 53;
				this.match(ExpressionsParserGrammarParser.RPAREN);
				}
				break;
			case ExpressionsParserGrammarParser.LAST_VAL_FUNC:
				{
				_localctx = new Last_val_funcContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 54;
				this.match(ExpressionsParserGrammarParser.LAST_VAL_FUNC);
				this.state = 55;
				this.match(ExpressionsParserGrammarParser.LPAREN);
				this.state = 56;
				(_localctx as Last_val_funcContext)._last_str_func = this.match(ExpressionsParserGrammarParser.STRING_VALUE);
				{
				this.state = 59;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === ExpressionsParserGrammarParser.COMMA) {
					{
					this.state = 57;
					this.match(ExpressionsParserGrammarParser.COMMA);
					this.state = 58;
					(_localctx as Last_val_funcContext)._config = this.match(ExpressionsParserGrammarParser.STRING_VALUE);
					}
				}

				}
				this.state = 61;
				this.match(ExpressionsParserGrammarParser.RPAREN);
				}
				break;
			case ExpressionsParserGrammarParser.LAST_STR_FUNC:
				{
				_localctx = new Last_str_funcContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 62;
				this.match(ExpressionsParserGrammarParser.LAST_STR_FUNC);
				this.state = 63;
				this.match(ExpressionsParserGrammarParser.LPAREN);
				this.state = 64;
				(_localctx as Last_str_funcContext)._last_str_func = this.match(ExpressionsParserGrammarParser.STRING_VALUE);
				this.state = 65;
				this.match(ExpressionsParserGrammarParser.RPAREN);
				}
				break;
			case ExpressionsParserGrammarParser.SUBMISSION_VAL_FUNC:
				{
				_localctx = new Submission_val_funcContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 66;
				this.match(ExpressionsParserGrammarParser.SUBMISSION_VAL_FUNC);
				this.state = 67;
				this.match(ExpressionsParserGrammarParser.LPAREN);
				this.state = 68;
				(_localctx as Submission_val_funcContext)._sumission_val_str = this.match(ExpressionsParserGrammarParser.STRING_VALUE);
				this.state = 69;
				this.match(ExpressionsParserGrammarParser.RPAREN);
				}
				break;
			case ExpressionsParserGrammarParser.SUBMISSION_STR_FUNC:
				{
				_localctx = new Submission_str_funcContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 70;
				this.match(ExpressionsParserGrammarParser.SUBMISSION_STR_FUNC);
				this.state = 71;
				this.match(ExpressionsParserGrammarParser.LPAREN);
				this.state = 72;
				(_localctx as Submission_str_funcContext)._submission_str = this.match(ExpressionsParserGrammarParser.STRING_VALUE);
				this.state = 73;
				this.match(ExpressionsParserGrammarParser.RPAREN);
				}
				break;
			case ExpressionsParserGrammarParser.DATE_FORMAT_FUNC:
				{
				_localctx = new Date_format_funcContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 74;
				this.match(ExpressionsParserGrammarParser.DATE_FORMAT_FUNC);
				this.state = 75;
				this.match(ExpressionsParserGrammarParser.LPAREN);
				this.state = 76;
				(_localctx as Date_format_funcContext)._date_format_expr = this.expression(0);
				this.state = 77;
				this.match(ExpressionsParserGrammarParser.COMMA);
				this.state = 78;
				(_localctx as Date_format_funcContext)._formatParam = this.match(ExpressionsParserGrammarParser.STRING_VALUE);
				this.state = 79;
				this.match(ExpressionsParserGrammarParser.RPAREN);
				}
				break;
			case ExpressionsParserGrammarParser.NEW:
				{
				_localctx = new DateContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 81;
				this.match(ExpressionsParserGrammarParser.NEW);
				this.state = 82;
				this.match(ExpressionsParserGrammarParser.DATE);
				this.state = 83;
				this.match(ExpressionsParserGrammarParser.LPAREN);
				this.state = 94;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				while (((((_la - 1)) & ~0x1F) === 0 && ((1 << (_la - 1)) & ((1 << (ExpressionsParserGrammarParser.UNDEFINED - 1)) | (1 << (ExpressionsParserGrammarParser.NULL - 1)) | (1 << (ExpressionsParserGrammarParser.DATE_FORMAT_DISTANCE - 1)) | (1 << (ExpressionsParserGrammarParser.NOT - 1)) | (1 << (ExpressionsParserGrammarParser.DIFFERENCE_IN_MINUTES - 1)) | (1 << (ExpressionsParserGrammarParser.DIFFERENCE_IN_DAYS - 1)) | (1 << (ExpressionsParserGrammarParser.DIFFERENCE_IN_HOURS - 1)) | (1 << (ExpressionsParserGrammarParser.DATE_ADD - 1)) | (1 << (ExpressionsParserGrammarParser.DATE_SUBTRACT - 1)) | (1 << (ExpressionsParserGrammarParser.NOW - 1)) | (1 << (ExpressionsParserGrammarParser.NEW - 1)) | (1 << (ExpressionsParserGrammarParser.LPAREN - 1)) | (1 << (ExpressionsParserGrammarParser.NUMBER_LITERAL - 1)))) !== 0) || ((((_la - 52)) & ~0x1F) === 0 && ((1 << (_la - 52)) & ((1 << (ExpressionsParserGrammarParser.FIRST_VAL_FUNC - 52)) | (1 << (ExpressionsParserGrammarParser.FIRST_STR_FUNC - 52)) | (1 << (ExpressionsParserGrammarParser.LAST_VAL_FUNC - 52)) | (1 << (ExpressionsParserGrammarParser.LAST_STR_FUNC - 52)) | (1 << (ExpressionsParserGrammarParser.SUBMISSION_VAL_FUNC - 52)) | (1 << (ExpressionsParserGrammarParser.SUBMISSION_STR_FUNC - 52)) | (1 << (ExpressionsParserGrammarParser.POWER_FUNC - 52)) | (1 << (ExpressionsParserGrammarParser.DATE_FORMAT_FUNC - 52)) | (1 << (ExpressionsParserGrammarParser.MAX - 52)) | (1 << (ExpressionsParserGrammarParser.MIN - 52)) | (1 << (ExpressionsParserGrammarParser.PRINT - 52)) | (1 << (ExpressionsParserGrammarParser.TRUE - 52)) | (1 << (ExpressionsParserGrammarParser.FALSE - 52)) | (1 << (ExpressionsParserGrammarParser.STRING_VALUE - 52)))) !== 0)) {
					{
					{
					{
					this.state = 84;
					(_localctx as DateContext)._expr = this.expression(0);
					this.state = 89;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
					while (_la === ExpressionsParserGrammarParser.COMMA) {
						{
						{
						this.state = 85;
						this.match(ExpressionsParserGrammarParser.COMMA);
						this.state = 86;
						(_localctx as DateContext)._expr = this.expression(0);
						}
						}
						this.state = 91;
						this._errHandler.sync(this);
						_la = this._input.LA(1);
					}
					}
					}
					}
					this.state = 96;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
				}
				this.state = 97;
				this.match(ExpressionsParserGrammarParser.RPAREN);
				}
				break;
			case ExpressionsParserGrammarParser.DATE_FORMAT_DISTANCE:
				{
				_localctx = new Date_format_distance_funcContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 98;
				this.match(ExpressionsParserGrammarParser.DATE_FORMAT_DISTANCE);
				this.state = 99;
				this.match(ExpressionsParserGrammarParser.LPAREN);
				this.state = 100;
				(_localctx as Date_format_distance_funcContext)._left = this.expression(0);
				this.state = 101;
				this.match(ExpressionsParserGrammarParser.COMMA);
				this.state = 102;
				(_localctx as Date_format_distance_funcContext)._right = this.expression(0);
				{
				this.state = 105;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				if (_la === ExpressionsParserGrammarParser.COMMA) {
					{
					this.state = 103;
					this.match(ExpressionsParserGrammarParser.COMMA);
					this.state = 104;
					(_localctx as Date_format_distance_funcContext)._opt = this.options();
					}
				}

				}
				this.state = 107;
				this.match(ExpressionsParserGrammarParser.RPAREN);
				}
				break;
			case ExpressionsParserGrammarParser.DIFFERENCE_IN_HOURS:
				{
				_localctx = new Dif_in_hoursContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 109;
				this.match(ExpressionsParserGrammarParser.DIFFERENCE_IN_HOURS);
				this.state = 110;
				this.match(ExpressionsParserGrammarParser.LPAREN);
				this.state = 111;
				(_localctx as Dif_in_hoursContext)._left = this.expression(0);
				this.state = 112;
				this.match(ExpressionsParserGrammarParser.COMMA);
				this.state = 113;
				(_localctx as Dif_in_hoursContext)._right = this.expression(0);
				this.state = 114;
				this.match(ExpressionsParserGrammarParser.RPAREN);
				}
				break;
			case ExpressionsParserGrammarParser.DIFFERENCE_IN_DAYS:
				{
				_localctx = new Dif_in_daysContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 116;
				this.match(ExpressionsParserGrammarParser.DIFFERENCE_IN_DAYS);
				this.state = 117;
				this.match(ExpressionsParserGrammarParser.LPAREN);
				this.state = 118;
				(_localctx as Dif_in_daysContext)._left = this.expression(0);
				this.state = 119;
				this.match(ExpressionsParserGrammarParser.COMMA);
				this.state = 120;
				(_localctx as Dif_in_daysContext)._right = this.expression(0);
				this.state = 121;
				this.match(ExpressionsParserGrammarParser.RPAREN);
				}
				break;
			case ExpressionsParserGrammarParser.DIFFERENCE_IN_MINUTES:
				{
				_localctx = new Dif_in_minutesContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 123;
				this.match(ExpressionsParserGrammarParser.DIFFERENCE_IN_MINUTES);
				this.state = 124;
				this.match(ExpressionsParserGrammarParser.LPAREN);
				this.state = 125;
				(_localctx as Dif_in_minutesContext)._left = this.expression(0);
				this.state = 126;
				this.match(ExpressionsParserGrammarParser.COMMA);
				this.state = 127;
				(_localctx as Dif_in_minutesContext)._right = this.expression(0);
				this.state = 128;
				this.match(ExpressionsParserGrammarParser.RPAREN);
				}
				break;
			case ExpressionsParserGrammarParser.DATE_ADD:
				{
				_localctx = new DateAddFuncContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 130;
				this.match(ExpressionsParserGrammarParser.DATE_ADD);
				this.state = 131;
				this.match(ExpressionsParserGrammarParser.LPAREN);
				this.state = 132;
				(_localctx as DateAddFuncContext)._date = this.expression(0);
				this.state = 133;
				this.match(ExpressionsParserGrammarParser.COMMA);
				this.state = 134;
				(_localctx as DateAddFuncContext)._d = this.duration();
				this.state = 135;
				this.match(ExpressionsParserGrammarParser.RPAREN);
				}
				break;
			case ExpressionsParserGrammarParser.DATE_SUBTRACT:
				{
				_localctx = new DateSubtractFuncContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 137;
				this.match(ExpressionsParserGrammarParser.DATE_SUBTRACT);
				this.state = 138;
				this.match(ExpressionsParserGrammarParser.LPAREN);
				this.state = 139;
				(_localctx as DateSubtractFuncContext)._date = this.expression(0);
				this.state = 140;
				this.match(ExpressionsParserGrammarParser.COMMA);
				this.state = 141;
				(_localctx as DateSubtractFuncContext)._d = this.duration();
				this.state = 142;
				this.match(ExpressionsParserGrammarParser.RPAREN);
				}
				break;
			case ExpressionsParserGrammarParser.MAX:
				{
				_localctx = new Max_exprContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 144;
				this.match(ExpressionsParserGrammarParser.MAX);
				this.state = 145;
				this.match(ExpressionsParserGrammarParser.LPAREN);
				this.state = 146;
				(_localctx as Max_exprContext)._args = this.expression(0);
				this.state = 151;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				while (_la === ExpressionsParserGrammarParser.COMMA) {
					{
					{
					this.state = 147;
					this.match(ExpressionsParserGrammarParser.COMMA);
					this.state = 148;
					(_localctx as Max_exprContext)._args = this.expression(0);
					}
					}
					this.state = 153;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
				}
				this.state = 154;
				this.match(ExpressionsParserGrammarParser.RPAREN);
				}
				break;
			case ExpressionsParserGrammarParser.MIN:
				{
				_localctx = new Min_exprContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 156;
				this.match(ExpressionsParserGrammarParser.MIN);
				this.state = 157;
				this.match(ExpressionsParserGrammarParser.LPAREN);
				this.state = 158;
				(_localctx as Min_exprContext)._args = this.expression(0);
				this.state = 163;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				while (_la === ExpressionsParserGrammarParser.COMMA) {
					{
					{
					this.state = 159;
					this.match(ExpressionsParserGrammarParser.COMMA);
					this.state = 160;
					(_localctx as Min_exprContext)._args = this.expression(0);
					}
					}
					this.state = 165;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
				}
				this.state = 166;
				this.match(ExpressionsParserGrammarParser.RPAREN);
				}
				break;
			case ExpressionsParserGrammarParser.NOW:
				{
				_localctx = new NowContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 168;
				this.match(ExpressionsParserGrammarParser.NOW);
				this.state = 169;
				this.match(ExpressionsParserGrammarParser.LPAREN);
				this.state = 170;
				this.match(ExpressionsParserGrammarParser.RPAREN);
				}
				break;
			case ExpressionsParserGrammarParser.NUMBER_LITERAL:
				{
				_localctx = new Process_numberContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 171;
				(_localctx as Process_numberContext)._numLiteral = this.match(ExpressionsParserGrammarParser.NUMBER_LITERAL);
				}
				break;
			case ExpressionsParserGrammarParser.STRING_VALUE:
				{
				_localctx = new Process_strContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 172;
				(_localctx as Process_strContext)._strValueLiteral = this.match(ExpressionsParserGrammarParser.STRING_VALUE);
				}
				break;
			case ExpressionsParserGrammarParser.PRINT:
				{
				_localctx = new Print_exprContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 173;
				this.match(ExpressionsParserGrammarParser.PRINT);
				this.state = 174;
				this.match(ExpressionsParserGrammarParser.LPAREN);
				{
				this.state = 175;
				(_localctx as Print_exprContext)._expr = this.expression(0);
				}
				this.state = 180;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
				while (_la === ExpressionsParserGrammarParser.COMMA) {
					{
					{
					this.state = 176;
					this.match(ExpressionsParserGrammarParser.COMMA);
					this.state = 177;
					(_localctx as Print_exprContext)._expr = this.expression(0);
					}
					}
					this.state = 182;
					this._errHandler.sync(this);
					_la = this._input.LA(1);
				}
				this.state = 183;
				this.match(ExpressionsParserGrammarParser.RPAREN);
				}
				break;
			case ExpressionsParserGrammarParser.TRUE:
			case ExpressionsParserGrammarParser.FALSE:
				{
				_localctx = new Process_boolContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 185;
				(_localctx as Process_boolContext)._boolLiteral = this._input.LT(1);
				_la = this._input.LA(1);
				if (!(_la === ExpressionsParserGrammarParser.TRUE || _la === ExpressionsParserGrammarParser.FALSE)) {
					(_localctx as Process_boolContext)._boolLiteral = this._errHandler.recoverInline(this);
				} else {
					if (this._input.LA(1) === Token.EOF) {
						this.matchedEOF = true;
					}

					this._errHandler.reportMatch(this);
					this.consume();
				}
				}
				break;
			case ExpressionsParserGrammarParser.NULL:
				{
				_localctx = new Null_exprContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 186;
				this.match(ExpressionsParserGrammarParser.NULL);
				}
				break;
			case ExpressionsParserGrammarParser.UNDEFINED:
				{
				_localctx = new UndefinedContext(_localctx);
				this._ctx = _localctx;
				_prevctx = _localctx;
				this.state = 187;
				this.match(ExpressionsParserGrammarParser.UNDEFINED);
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
			this._ctx._stop = this._input.tryLT(-1);
			this.state = 216;
			this._errHandler.sync(this);
			_alt = this.interpreter.adaptivePredict(this._input, 10, this._ctx);
			while (_alt !== 2 && _alt !== ATN.INVALID_ALT_NUMBER) {
				if (_alt === 1) {
					if (this._parseListeners != null) {
						this.triggerExitRuleEvent();
					}
					_prevctx = _localctx;
					{
					this.state = 214;
					this._errHandler.sync(this);
					switch ( this.interpreter.adaptivePredict(this._input, 9, this._ctx) ) {
					case 1:
						{
						_localctx = new Identity_operationContext(new ExpressionContext(_parentctx, _parentState));
						(_localctx as Identity_operationContext)._left = _prevctx;
						this.pushNewRecursionContext(_localctx, _startState, ExpressionsParserGrammarParser.RULE_expression);
						this.state = 190;
						if (!(this.precpred(this._ctx, 33))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 33)");
						}
						this.state = 191;
						(_localctx as Identity_operationContext)._identity_operation = this._input.LT(1);
						_la = this._input.LA(1);
						if (!(_la === ExpressionsParserGrammarParser.IDENTITY || _la === ExpressionsParserGrammarParser.NOT_IDENTITY)) {
							(_localctx as Identity_operationContext)._identity_operation = this._errHandler.recoverInline(this);
						} else {
							if (this._input.LA(1) === Token.EOF) {
								this.matchedEOF = true;
							}

							this._errHandler.reportMatch(this);
							this.consume();
						}
						this.state = 192;
						(_localctx as Identity_operationContext)._right = this.expression(34);
						}
						break;

					case 2:
						{
						_localctx = new Equality_operationContext(new ExpressionContext(_parentctx, _parentState));
						(_localctx as Equality_operationContext)._left = _prevctx;
						this.pushNewRecursionContext(_localctx, _startState, ExpressionsParserGrammarParser.RULE_expression);
						this.state = 193;
						if (!(this.precpred(this._ctx, 32))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 32)");
						}
						this.state = 194;
						(_localctx as Equality_operationContext)._equality_operation = this._input.LT(1);
						_la = this._input.LA(1);
						if (!(((((_la - 46)) & ~0x1F) === 0 && ((1 << (_la - 46)) & ((1 << (ExpressionsParserGrammarParser.EQUALS - 46)) | (1 << (ExpressionsParserGrammarParser.NOT_EQUALS - 46)) | (1 << (ExpressionsParserGrammarParser.MORE_THAN - 46)) | (1 << (ExpressionsParserGrammarParser.LESS - 46)) | (1 << (ExpressionsParserGrammarParser.MORE_OR_EQUALS - 46)) | (1 << (ExpressionsParserGrammarParser.LESS_OR_EQUALS - 46)))) !== 0))) {
							(_localctx as Equality_operationContext)._equality_operation = this._errHandler.recoverInline(this);
						} else {
							if (this._input.LA(1) === Token.EOF) {
								this.matchedEOF = true;
							}

							this._errHandler.reportMatch(this);
							this.consume();
						}
						this.state = 195;
						(_localctx as Equality_operationContext)._right = this.expression(33);
						}
						break;

					case 3:
						{
						_localctx = new Mul_div_exprContext(new ExpressionContext(_parentctx, _parentState));
						(_localctx as Mul_div_exprContext)._left = _prevctx;
						this.pushNewRecursionContext(_localctx, _startState, ExpressionsParserGrammarParser.RULE_expression);
						this.state = 196;
						if (!(this.precpred(this._ctx, 31))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 31)");
						}
						this.state = 197;
						(_localctx as Mul_div_exprContext)._mul_or_div = this._input.LT(1);
						_la = this._input.LA(1);
						if (!(_la === ExpressionsParserGrammarParser.MUL || _la === ExpressionsParserGrammarParser.DIV)) {
							(_localctx as Mul_div_exprContext)._mul_or_div = this._errHandler.recoverInline(this);
						} else {
							if (this._input.LA(1) === Token.EOF) {
								this.matchedEOF = true;
							}

							this._errHandler.reportMatch(this);
							this.consume();
						}
						this.state = 198;
						(_localctx as Mul_div_exprContext)._right = this.expression(32);
						}
						break;

					case 4:
						{
						_localctx = new Add_exprContext(new ExpressionContext(_parentctx, _parentState));
						(_localctx as Add_exprContext)._left = _prevctx;
						this.pushNewRecursionContext(_localctx, _startState, ExpressionsParserGrammarParser.RULE_expression);
						this.state = 199;
						if (!(this.precpred(this._ctx, 30))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 30)");
						}
						this.state = 200;
						(_localctx as Add_exprContext)._plus_minus = this._input.LT(1);
						_la = this._input.LA(1);
						if (!(_la === ExpressionsParserGrammarParser.PLUS || _la === ExpressionsParserGrammarParser.MINUS)) {
							(_localctx as Add_exprContext)._plus_minus = this._errHandler.recoverInline(this);
						} else {
							if (this._input.LA(1) === Token.EOF) {
								this.matchedEOF = true;
							}

							this._errHandler.reportMatch(this);
							this.consume();
						}
						this.state = 201;
						(_localctx as Add_exprContext)._right = this.expression(31);
						}
						break;

					case 5:
						{
						_localctx = new And_exprContext(new ExpressionContext(_parentctx, _parentState));
						(_localctx as And_exprContext)._left = _prevctx;
						this.pushNewRecursionContext(_localctx, _startState, ExpressionsParserGrammarParser.RULE_expression);
						this.state = 202;
						if (!(this.precpred(this._ctx, 28))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 28)");
						}
						this.state = 203;
						this.match(ExpressionsParserGrammarParser.AND);
						this.state = 204;
						(_localctx as And_exprContext)._right = this.expression(29);
						}
						break;

					case 6:
						{
						_localctx = new Or_exprContext(new ExpressionContext(_parentctx, _parentState));
						(_localctx as Or_exprContext)._left = _prevctx;
						this.pushNewRecursionContext(_localctx, _startState, ExpressionsParserGrammarParser.RULE_expression);
						this.state = 205;
						if (!(this.precpred(this._ctx, 27))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 27)");
						}
						this.state = 206;
						this.match(ExpressionsParserGrammarParser.OR);
						this.state = 207;
						(_localctx as Or_exprContext)._right = this.expression(28);
						}
						break;

					case 7:
						{
						_localctx = new Question_exprContext(new ExpressionContext(_parentctx, _parentState));
						(_localctx as Question_exprContext)._condition = _prevctx;
						this.pushNewRecursionContext(_localctx, _startState, ExpressionsParserGrammarParser.RULE_expression);
						this.state = 208;
						if (!(this.precpred(this._ctx, 25))) {
							throw this.createFailedPredicateException("this.precpred(this._ctx, 25)");
						}
						this.state = 209;
						this.match(ExpressionsParserGrammarParser.QUERY_OP);
						this.state = 210;
						(_localctx as Question_exprContext)._true_exp = this.expression(0);
						this.state = 211;
						this.match(ExpressionsParserGrammarParser.COLON);
						this.state = 212;
						(_localctx as Question_exprContext)._false_exp = this.expression(25);
						}
						break;
					}
					}
				}
				this.state = 218;
				this._errHandler.sync(this);
				_alt = this.interpreter.adaptivePredict(this._input, 10, this._ctx);
			}
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.unrollRecursionContexts(_parentctx);
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public duration(): DurationContext {
		let _localctx: DurationContext = new DurationContext(this._ctx, this.state);
		this.enterRule(_localctx, 2, ExpressionsParserGrammarParser.RULE_duration);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 219;
			this.match(ExpressionsParserGrammarParser.L_CURLY_BRACE);
			this.state = 220;
			this.duration_element();
			this.state = 225;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === ExpressionsParserGrammarParser.COMMA) {
				{
				{
				this.state = 221;
				this.match(ExpressionsParserGrammarParser.COMMA);
				this.state = 222;
				this.duration_element();
				}
				}
				this.state = 227;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 228;
			this.match(ExpressionsParserGrammarParser.R_CURLY_BRACE);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public duration_element(): Duration_elementContext {
		let _localctx: Duration_elementContext = new Duration_elementContext(this._ctx, this.state);
		this.enterRule(_localctx, 4, ExpressionsParserGrammarParser.RULE_duration_element);
		try {
			this.state = 237;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case ExpressionsParserGrammarParser.YEARS:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 230;
				this.years_element();
				}
				break;
			case ExpressionsParserGrammarParser.MONTHS:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 231;
				this.month_element();
				}
				break;
			case ExpressionsParserGrammarParser.WEEKS:
				this.enterOuterAlt(_localctx, 3);
				{
				this.state = 232;
				this.weeks_element();
				}
				break;
			case ExpressionsParserGrammarParser.DAYS:
				this.enterOuterAlt(_localctx, 4);
				{
				this.state = 233;
				this.days_element();
				}
				break;
			case ExpressionsParserGrammarParser.HOURS:
				this.enterOuterAlt(_localctx, 5);
				{
				this.state = 234;
				this.hours_element();
				}
				break;
			case ExpressionsParserGrammarParser.MINUTES:
				this.enterOuterAlt(_localctx, 6);
				{
				this.state = 235;
				this.minutes_element();
				}
				break;
			case ExpressionsParserGrammarParser.SECONDS:
				this.enterOuterAlt(_localctx, 7);
				{
				this.state = 236;
				this.seconds_element();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public options(): OptionsContext {
		let _localctx: OptionsContext = new OptionsContext(this._ctx, this.state);
		this.enterRule(_localctx, 6, ExpressionsParserGrammarParser.RULE_options);
		let _la: number;
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 239;
			this.match(ExpressionsParserGrammarParser.L_CURLY_BRACE);
			this.state = 240;
			this.option_element();
			this.state = 245;
			this._errHandler.sync(this);
			_la = this._input.LA(1);
			while (_la === ExpressionsParserGrammarParser.COMMA) {
				{
				{
				this.state = 241;
				this.match(ExpressionsParserGrammarParser.COMMA);
				this.state = 242;
				this.option_element();
				}
				}
				this.state = 247;
				this._errHandler.sync(this);
				_la = this._input.LA(1);
			}
			this.state = 248;
			this.match(ExpressionsParserGrammarParser.R_CURLY_BRACE);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public option_element(): Option_elementContext {
		let _localctx: Option_elementContext = new Option_elementContext(this._ctx, this.state);
		this.enterRule(_localctx, 8, ExpressionsParserGrammarParser.RULE_option_element);
		try {
			this.state = 252;
			this._errHandler.sync(this);
			switch (this._input.LA(1)) {
			case ExpressionsParserGrammarParser.INCLUDE_SECONDS:
				this.enterOuterAlt(_localctx, 1);
				{
				this.state = 250;
				this.include_seconds();
				}
				break;
			case ExpressionsParserGrammarParser.ADD_SUFFIX:
				this.enterOuterAlt(_localctx, 2);
				{
				this.state = 251;
				this.add_suffix();
				}
				break;
			default:
				throw new NoViableAltException(this);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public years_element(): Years_elementContext {
		let _localctx: Years_elementContext = new Years_elementContext(this._ctx, this.state);
		this.enterRule(_localctx, 10, ExpressionsParserGrammarParser.RULE_years_element);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 254;
			this.match(ExpressionsParserGrammarParser.YEARS);
			this.state = 255;
			this.match(ExpressionsParserGrammarParser.COLON);
			this.state = 256;
			_localctx._value = this.expression(0);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public month_element(): Month_elementContext {
		let _localctx: Month_elementContext = new Month_elementContext(this._ctx, this.state);
		this.enterRule(_localctx, 12, ExpressionsParserGrammarParser.RULE_month_element);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 258;
			this.match(ExpressionsParserGrammarParser.MONTHS);
			this.state = 259;
			this.match(ExpressionsParserGrammarParser.COLON);
			this.state = 260;
			_localctx._value = this.expression(0);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public weeks_element(): Weeks_elementContext {
		let _localctx: Weeks_elementContext = new Weeks_elementContext(this._ctx, this.state);
		this.enterRule(_localctx, 14, ExpressionsParserGrammarParser.RULE_weeks_element);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 262;
			this.match(ExpressionsParserGrammarParser.WEEKS);
			this.state = 263;
			this.match(ExpressionsParserGrammarParser.COLON);
			this.state = 264;
			_localctx._value = this.expression(0);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public days_element(): Days_elementContext {
		let _localctx: Days_elementContext = new Days_elementContext(this._ctx, this.state);
		this.enterRule(_localctx, 16, ExpressionsParserGrammarParser.RULE_days_element);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 266;
			this.match(ExpressionsParserGrammarParser.DAYS);
			this.state = 267;
			this.match(ExpressionsParserGrammarParser.COLON);
			this.state = 268;
			_localctx._value = this.expression(0);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public hours_element(): Hours_elementContext {
		let _localctx: Hours_elementContext = new Hours_elementContext(this._ctx, this.state);
		this.enterRule(_localctx, 18, ExpressionsParserGrammarParser.RULE_hours_element);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 270;
			this.match(ExpressionsParserGrammarParser.HOURS);
			this.state = 271;
			this.match(ExpressionsParserGrammarParser.COLON);
			this.state = 272;
			_localctx._value = this.expression(0);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public minutes_element(): Minutes_elementContext {
		let _localctx: Minutes_elementContext = new Minutes_elementContext(this._ctx, this.state);
		this.enterRule(_localctx, 20, ExpressionsParserGrammarParser.RULE_minutes_element);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 274;
			this.match(ExpressionsParserGrammarParser.MINUTES);
			this.state = 275;
			this.match(ExpressionsParserGrammarParser.COLON);
			this.state = 276;
			_localctx._value = this.expression(0);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public seconds_element(): Seconds_elementContext {
		let _localctx: Seconds_elementContext = new Seconds_elementContext(this._ctx, this.state);
		this.enterRule(_localctx, 22, ExpressionsParserGrammarParser.RULE_seconds_element);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 278;
			this.match(ExpressionsParserGrammarParser.SECONDS);
			this.state = 279;
			this.match(ExpressionsParserGrammarParser.COLON);
			this.state = 280;
			_localctx._value = this.expression(0);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public include_seconds(): Include_secondsContext {
		let _localctx: Include_secondsContext = new Include_secondsContext(this._ctx, this.state);
		this.enterRule(_localctx, 24, ExpressionsParserGrammarParser.RULE_include_seconds);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 282;
			this.match(ExpressionsParserGrammarParser.INCLUDE_SECONDS);
			this.state = 283;
			this.match(ExpressionsParserGrammarParser.COLON);
			this.state = 284;
			_localctx._seconds = this.expression(0);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}
	// @RuleVersion(0)
	public add_suffix(): Add_suffixContext {
		let _localctx: Add_suffixContext = new Add_suffixContext(this._ctx, this.state);
		this.enterRule(_localctx, 26, ExpressionsParserGrammarParser.RULE_add_suffix);
		try {
			this.enterOuterAlt(_localctx, 1);
			{
			this.state = 286;
			this.match(ExpressionsParserGrammarParser.ADD_SUFFIX);
			this.state = 287;
			this.match(ExpressionsParserGrammarParser.COLON);
			this.state = 288;
			_localctx._suffix = this.expression(0);
			}
		}
		catch (re) {
			if (re instanceof RecognitionException) {
				_localctx.exception = re;
				this._errHandler.reportError(this, re);
				this._errHandler.recover(this, re);
			} else {
				throw re;
			}
		}
		finally {
			this.exitRule();
		}
		return _localctx;
	}

	public sempred(_localctx: RuleContext, ruleIndex: number, predIndex: number): boolean {
		switch (ruleIndex) {
		case 0:
			return this.expression_sempred(_localctx as ExpressionContext, predIndex);
		}
		return true;
	}
	private expression_sempred(_localctx: ExpressionContext, predIndex: number): boolean {
		switch (predIndex) {
		case 0:
			return this.precpred(this._ctx, 33);

		case 1:
			return this.precpred(this._ctx, 32);

		case 2:
			return this.precpred(this._ctx, 31);

		case 3:
			return this.precpred(this._ctx, 30);

		case 4:
			return this.precpred(this._ctx, 28);

		case 5:
			return this.precpred(this._ctx, 27);

		case 6:
			return this.precpred(this._ctx, 25);
		}
		return true;
	}

	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x03I\u0125\x04\x02" +
		"\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06\x04\x07" +
		"\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r\t\r\x04" +
		"\x0E\t\x0E\x04\x0F\t\x0F\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02" +
		"\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02" +
		"\x03\x02\x03\x02\x03\x02\x03\x02\x05\x022\n\x02\x03\x02\x03\x02\x03\x02" +
		"\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x05\x02>\n\x02" +
		"\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02" +
		"\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02" +
		"\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x07\x02" +
		"Z\n\x02\f\x02\x0E\x02]\v\x02\x07\x02_\n\x02\f\x02\x0E\x02b\v\x02\x03\x02" +
		"\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x05\x02l\n\x02" +
		"\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02" +
		"\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02" +
		"\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02" +
		"\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02" +
		"\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x07\x02\x98\n\x02\f\x02" +
		"\x0E\x02\x9B\v\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03" +
		"\x02\x07\x02\xA4\n\x02\f\x02\x0E\x02\xA7\v\x02\x03\x02\x03\x02\x03\x02" +
		"\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02" +
		"\x07\x02\xB5\n\x02\f\x02\x0E\x02\xB8\v\x02\x03\x02\x03\x02\x03\x02\x03" +
		"\x02\x03\x02\x05\x02\xBF\n\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02" +
		"\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02" +
		"\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02\x03\x02" +
		"\x03\x02\x07\x02\xD9\n\x02\f\x02\x0E\x02\xDC\v\x02\x03\x03\x03\x03\x03" +
		"\x03\x03\x03\x07\x03\xE2\n\x03\f\x03\x0E\x03\xE5\v\x03\x03\x03\x03\x03" +
		"\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x03\x04\x05\x04\xF0\n" +
		"\x04\x03\x05\x03\x05\x03\x05\x03\x05\x07\x05\xF6\n\x05\f\x05\x0E\x05\xF9" +
		"\v\x05\x03\x05\x03\x05\x03\x06\x03\x06\x05\x06\xFF\n\x06\x03\x07\x03\x07" +
		"\x03\x07\x03\x07\x03\b\x03\b\x03\b\x03\b\x03\t\x03\t\x03\t\x03\t\x03\n" +
		"\x03\n\x03\n\x03\n\x03\v\x03\v\x03\v\x03\v\x03\f\x03\f\x03\f\x03\f\x03" +
		"\r\x03\r\x03\r\x03\r\x03\x0E\x03\x0E\x03\x0E\x03\x0E\x03\x0F\x03\x0F\x03" +
		"\x0F\x03\x0F\x03\x0F\x02\x02\x03\x02\x10\x02\x02\x04\x02\x06\x02\b\x02" +
		"\n\x02\f\x02\x0E\x02\x10\x02\x12\x02\x14\x02\x16\x02\x18\x02\x1A\x02\x1C" +
		"\x02\x02\x07\x03\x02DE\x03\x02./\x03\x0205\x03\x02\x1E\x1F\x03\x02\x1C" +
		"\x1D\x02\u0147\x02\xBE\x03\x02\x02\x02\x04\xDD\x03\x02\x02\x02\x06\xEF" +
		"\x03\x02\x02\x02\b\xF1\x03\x02\x02\x02\n\xFE\x03\x02\x02\x02\f\u0100\x03" +
		"\x02\x02\x02\x0E\u0104\x03\x02\x02\x02\x10\u0108\x03\x02\x02\x02\x12\u010C" +
		"\x03\x02\x02\x02\x14\u0110\x03\x02\x02\x02\x16\u0114\x03\x02\x02\x02\x18" +
		"\u0118\x03\x02\x02\x02\x1A\u011C\x03\x02\x02\x02\x1C\u0120\x03\x02\x02" +
		"\x02\x1E\x1F\b\x02\x01\x02\x1F \x07\x0F\x02\x02 \xBF\x05\x02\x02\x1F!" +
		"\"\x07 \x02\x02\"#\x05\x02\x02\x02#$\x07!\x02\x02$\xBF\x03\x02\x02\x02" +
		"%&\x07<\x02\x02&\'\x07 \x02\x02\'(\x05\x02\x02\x02()\x07@\x02\x02)*\x05" +
		"\x02\x02\x02*+\x07!\x02\x02+\xBF\x03\x02\x02\x02,-\x076\x02\x02-.\x07" +
		" \x02\x02.1\x07F\x02\x02/0\x07@\x02\x0202\x07F\x02\x021/\x03\x02\x02\x02" +
		"12\x03\x02\x02\x0223\x03\x02\x02\x023\xBF\x07!\x02\x0245\x077\x02\x02" +
		"56\x07 \x02\x0267\x07F\x02\x027\xBF\x07!\x02\x0289\x078\x02\x029:\x07" +
		" \x02\x02:=\x07F\x02\x02;<\x07@\x02\x02<>\x07F\x02\x02=;\x03\x02\x02\x02" +
		"=>\x03\x02\x02\x02>?\x03\x02\x02\x02?\xBF\x07!\x02\x02@A\x079\x02\x02" +
		"AB\x07 \x02\x02BC\x07F\x02\x02C\xBF\x07!\x02\x02DE\x07:\x02\x02EF\x07" +
		" \x02\x02FG\x07F\x02\x02G\xBF\x07!\x02\x02HI\x07;\x02\x02IJ\x07 \x02\x02" +
		"JK\x07F\x02\x02K\xBF\x07!\x02\x02LM\x07=\x02\x02MN\x07 \x02\x02NO\x05" +
		"\x02\x02\x02OP\x07@\x02\x02PQ\x07F\x02\x02QR\x07!\x02\x02R\xBF\x03\x02" +
		"\x02\x02ST\x07\x1A\x02\x02TU\x07\x1B\x02\x02U`\x07 \x02\x02V[\x05\x02" +
		"\x02\x02WX\x07@\x02\x02XZ\x05\x02\x02\x02YW\x03\x02\x02\x02Z]\x03\x02" +
		"\x02\x02[Y\x03\x02\x02\x02[\\\x03\x02\x02\x02\\_\x03\x02\x02\x02][\x03" +
		"\x02\x02\x02^V\x03\x02\x02\x02_b\x03\x02\x02\x02`^\x03\x02\x02\x02`a\x03" +
		"\x02\x02\x02ac\x03\x02\x02\x02b`\x03\x02\x02\x02c\xBF\x07!\x02\x02de\x07" +
		"\x05\x02\x02ef\x07 \x02\x02fg\x05\x02\x02\x02gh\x07@\x02\x02hk\x05\x02" +
		"\x02\x02ij\x07@\x02\x02jl\x05\b\x05\x02ki\x03\x02\x02\x02kl\x03\x02\x02" +
		"\x02lm\x03\x02\x02\x02mn\x07!\x02\x02n\xBF\x03\x02\x02\x02op\x07\x12\x02" +
		"\x02pq\x07 \x02\x02qr\x05\x02\x02\x02rs\x07@\x02\x02st\x05\x02\x02\x02" +
		"tu\x07!\x02\x02u\xBF\x03\x02\x02\x02vw\x07\x11\x02\x02wx\x07 \x02\x02" +
		"xy\x05\x02\x02\x02yz\x07@\x02\x02z{\x05\x02\x02\x02{|\x07!\x02\x02|\xBF" +
		"\x03\x02\x02\x02}~\x07\x10\x02\x02~\x7F\x07 \x02\x02\x7F\x80\x05\x02\x02" +
		"\x02\x80\x81\x07@\x02\x02\x81\x82\x05\x02\x02\x02\x82\x83\x07!\x02\x02" +
		"\x83\xBF\x03\x02\x02\x02\x84\x85\x07\x13\x02\x02\x85\x86\x07 \x02\x02" +
		"\x86\x87\x05\x02\x02\x02\x87\x88\x07@\x02\x02\x88\x89\x05\x04\x03\x02" +
		"\x89\x8A\x07!\x02\x02\x8A\xBF\x03\x02\x02\x02\x8B\x8C\x07\x14\x02\x02" +
		"\x8C\x8D\x07 \x02\x02\x8D\x8E\x05\x02\x02\x02\x8E\x8F\x07@\x02\x02\x8F" +
		"\x90\x05\x04\x03\x02\x90\x91\x07!\x02\x02\x91\xBF\x03\x02\x02\x02\x92" +
		"\x93\x07>\x02\x02\x93\x94\x07 \x02\x02\x94\x99\x05\x02\x02\x02\x95\x96" +
		"\x07@\x02\x02\x96\x98\x05\x02\x02\x02\x97\x95\x03\x02\x02\x02\x98\x9B" +
		"\x03\x02\x02\x02\x99\x97\x03\x02\x02\x02\x99\x9A\x03\x02\x02\x02\x9A\x9C" +
		"\x03\x02\x02\x02\x9B\x99\x03\x02\x02\x02\x9C\x9D\x07!\x02\x02\x9D\xBF" +
		"\x03\x02\x02\x02\x9E\x9F\x07?\x02\x02\x9F\xA0\x07 \x02\x02\xA0\xA5\x05" +
		"\x02\x02\x02\xA1\xA2\x07@\x02\x02\xA2\xA4\x05\x02\x02\x02\xA3\xA1\x03" +
		"\x02\x02\x02\xA4\xA7\x03\x02\x02\x02\xA5\xA3\x03\x02\x02\x02\xA5\xA6\x03" +
		"\x02\x02\x02\xA6\xA8\x03\x02\x02\x02\xA7\xA5\x03\x02\x02\x02\xA8\xA9\x07" +
		"!\x02\x02\xA9\xBF\x03\x02\x02\x02\xAA\xAB\x07\x19\x02\x02\xAB\xAC\x07" +
		" \x02\x02\xAC\xBF\x07!\x02\x02\xAD\xBF\x07\"\x02\x02\xAE\xBF\x07F\x02" +
		"\x02\xAF\xB0\x07C\x02\x02\xB0\xB1\x07 \x02\x02\xB1\xB6\x05\x02\x02\x02" +
		"\xB2\xB3\x07@\x02\x02\xB3\xB5\x05\x02\x02\x02\xB4\xB2\x03\x02\x02\x02" +
		"\xB5\xB8\x03\x02\x02\x02\xB6\xB4\x03\x02\x02\x02\xB6\xB7\x03\x02\x02\x02" +
		"\xB7\xB9\x03\x02\x02\x02\xB8\xB6\x03\x02\x02\x02\xB9\xBA\x07!\x02\x02" +
		"\xBA\xBF\x03\x02\x02\x02\xBB\xBF\t\x02\x02\x02\xBC\xBF\x07\x04\x02\x02" +
		"\xBD\xBF\x07\x03\x02\x02\xBE\x1E\x03\x02\x02\x02\xBE!\x03\x02\x02\x02" +
		"\xBE%\x03\x02\x02\x02\xBE,\x03\x02\x02\x02\xBE4\x03\x02\x02\x02\xBE8\x03" +
		"\x02\x02\x02\xBE@\x03\x02\x02\x02\xBED\x03\x02\x02\x02\xBEH\x03\x02\x02" +
		"\x02\xBEL\x03\x02\x02\x02\xBES\x03\x02\x02\x02\xBEd\x03\x02\x02\x02\xBE" +
		"o\x03\x02\x02\x02\xBEv\x03\x02\x02\x02\xBE}\x03\x02\x02\x02\xBE\x84\x03" +
		"\x02\x02\x02\xBE\x8B\x03\x02\x02\x02\xBE\x92\x03\x02\x02\x02\xBE\x9E\x03" +
		"\x02\x02\x02\xBE\xAA\x03\x02\x02\x02\xBE\xAD\x03\x02\x02\x02\xBE\xAE\x03" +
		"\x02\x02\x02\xBE\xAF\x03\x02\x02\x02\xBE\xBB\x03\x02\x02\x02\xBE\xBC\x03" +
		"\x02\x02\x02\xBE\xBD\x03\x02\x02\x02\xBF\xDA\x03\x02\x02\x02\xC0\xC1\f" +
		"#\x02\x02\xC1\xC2\t\x03\x02\x02\xC2\xD9\x05\x02\x02$\xC3\xC4\f\"\x02\x02" +
		"\xC4\xC5\t\x04\x02\x02\xC5\xD9\x05\x02\x02#\xC6\xC7\f!\x02\x02\xC7\xC8" +
		"\t\x05\x02\x02\xC8\xD9\x05\x02\x02\"\xC9\xCA\f \x02\x02\xCA\xCB\t\x06" +
		"\x02\x02\xCB\xD9\x05\x02\x02!\xCC\xCD\f\x1E\x02\x02\xCD\xCE\x07\x0E\x02" +
		"\x02\xCE\xD9\x05\x02\x02\x1F\xCF\xD0\f\x1D\x02\x02\xD0\xD1\x07\r\x02\x02" +
		"\xD1\xD9\x05\x02\x02\x1E\xD2\xD3\f\x1B\x02\x02\xD3\xD4\x07\x18\x02\x02" +
		"\xD4\xD5\x05\x02\x02\x02\xD5\xD6\x07\x17\x02\x02\xD6\xD7\x05\x02\x02\x1B" +
		"\xD7\xD9\x03\x02\x02\x02\xD8\xC0\x03\x02\x02\x02\xD8\xC3\x03\x02\x02\x02" +
		"\xD8\xC6\x03\x02\x02\x02\xD8\xC9\x03\x02\x02\x02\xD8\xCC\x03\x02\x02\x02" +
		"\xD8\xCF\x03\x02\x02\x02\xD8\xD2\x03\x02\x02\x02\xD9\xDC\x03\x02\x02\x02" +
		"\xDA\xD8\x03\x02\x02\x02\xDA\xDB\x03\x02\x02\x02\xDB\x03\x03\x02\x02\x02" +
		"\xDC\xDA\x03\x02\x02\x02\xDD\xDE\x07A\x02\x02\xDE\xE3\x05\x06\x04\x02" +
		"\xDF\xE0\x07@\x02\x02\xE0\xE2\x05\x06\x04\x02\xE1\xDF\x03\x02\x02\x02" +
		"\xE2\xE5\x03\x02\x02\x02\xE3\xE1\x03\x02\x02\x02\xE3\xE4\x03\x02\x02\x02" +
		"\xE4\xE6\x03\x02\x02\x02\xE5\xE3\x03\x02\x02\x02\xE6\xE7\x07B\x02\x02" +
		"\xE7\x05\x03\x02\x02\x02\xE8\xF0\x05\f\x07\x02\xE9\xF0\x05\x0E\b\x02\xEA" +
		"\xF0\x05\x10\t\x02\xEB\xF0\x05\x12\n\x02\xEC\xF0\x05\x14\v\x02\xED\xF0" +
		"\x05\x16\f\x02\xEE\xF0\x05\x18\r\x02\xEF\xE8\x03\x02\x02\x02\xEF\xE9\x03" +
		"\x02\x02\x02\xEF\xEA\x03\x02\x02\x02\xEF\xEB\x03\x02\x02\x02\xEF\xEC\x03" +
		"\x02\x02\x02\xEF\xED\x03\x02\x02\x02\xEF\xEE\x03\x02\x02\x02\xF0\x07\x03" +
		"\x02\x02\x02\xF1\xF2\x07A\x02\x02\xF2\xF7\x05\n\x06\x02\xF3\xF4\x07@\x02" +
		"\x02\xF4\xF6\x05\n\x06\x02\xF5\xF3\x03\x02\x02\x02\xF6\xF9\x03\x02\x02" +
		"\x02\xF7\xF5\x03\x02\x02\x02\xF7\xF8\x03\x02\x02\x02\xF8\xFA\x03\x02\x02" +
		"\x02\xF9\xF7\x03\x02\x02\x02\xFA\xFB\x07B\x02\x02\xFB\t\x03\x02\x02\x02" +
		"\xFC\xFF\x05\x1A\x0E\x02\xFD\xFF\x05\x1C\x0F\x02\xFE\xFC\x03\x02\x02\x02" +
		"\xFE\xFD\x03\x02\x02\x02\xFF\v\x03\x02\x02\x02\u0100\u0101\x07\f\x02\x02" +
		"\u0101\u0102\x07\x17\x02\x02\u0102\u0103\x05\x02\x02\x02\u0103\r\x03\x02" +
		"\x02\x02\u0104\u0105\x07\v\x02\x02\u0105\u0106\x07\x17\x02\x02\u0106\u0107" +
		"\x05\x02\x02\x02\u0107\x0F\x03\x02\x02\x02\u0108\u0109\x07\n\x02\x02\u0109" +
		"\u010A\x07\x17\x02\x02\u010A\u010B\x05\x02\x02\x02\u010B\x11\x03\x02\x02" +
		"\x02\u010C\u010D\x07\t\x02\x02\u010D\u010E\x07\x17\x02\x02\u010E\u010F" +
		"\x05\x02\x02\x02\u010F\x13\x03\x02\x02\x02\u0110\u0111\x07\b\x02\x02\u0111" +
		"\u0112\x07\x17\x02\x02\u0112\u0113\x05\x02\x02\x02\u0113\x15\x03\x02\x02" +
		"\x02\u0114\u0115\x07\x07\x02\x02\u0115\u0116\x07\x17\x02\x02\u0116\u0117" +
		"\x05\x02\x02\x02\u0117\x17\x03\x02\x02\x02\u0118\u0119\x07\x06\x02\x02" +
		"\u0119\u011A\x07\x17\x02\x02\u011A\u011B\x05\x02\x02\x02\u011B\x19\x03" +
		"\x02\x02\x02\u011C\u011D\x07\x15\x02\x02\u011D\u011E\x07\x17\x02\x02\u011E" +
		"\u011F\x05\x02\x02\x02\u011F\x1B\x03\x02\x02\x02\u0120\u0121\x07\x16\x02" +
		"\x02\u0121\u0122\x07\x17\x02\x02\u0122\u0123\x05\x02\x02\x02\u0123\x1D" +
		"\x03\x02\x02\x02\x111=[`k\x99\xA5\xB6\xBE\xD8\xDA\xE3\xEF\xF7\xFE";
	public static __ATN: ATN;
	public static get _ATN(): ATN {
		if (!ExpressionsParserGrammarParser.__ATN) {
			ExpressionsParserGrammarParser.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(ExpressionsParserGrammarParser._serializedATN));
		}

		return ExpressionsParserGrammarParser.__ATN;
	}

}

export class ExpressionContext extends ParserRuleContext {
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ExpressionsParserGrammarParser.RULE_expression; }
	public copyFrom(ctx: ExpressionContext): void {
		super.copyFrom(ctx);
	}
}
export class Identity_operationContext extends ExpressionContext {
	public _left!: ExpressionContext;
	public _identity_operation!: Token;
	public _right!: ExpressionContext;
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public IDENTITY(): TerminalNode | undefined { return this.tryGetToken(ExpressionsParserGrammarParser.IDENTITY, 0); }
	public NOT_IDENTITY(): TerminalNode | undefined { return this.tryGetToken(ExpressionsParserGrammarParser.NOT_IDENTITY, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterIdentity_operation) {
			listener.enterIdentity_operation(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitIdentity_operation) {
			listener.exitIdentity_operation(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitIdentity_operation) {
			return visitor.visitIdentity_operation(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class Equality_operationContext extends ExpressionContext {
	public _left!: ExpressionContext;
	public _equality_operation!: Token;
	public _right!: ExpressionContext;
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public EQUALS(): TerminalNode | undefined { return this.tryGetToken(ExpressionsParserGrammarParser.EQUALS, 0); }
	public NOT_EQUALS(): TerminalNode | undefined { return this.tryGetToken(ExpressionsParserGrammarParser.NOT_EQUALS, 0); }
	public MORE_THAN(): TerminalNode | undefined { return this.tryGetToken(ExpressionsParserGrammarParser.MORE_THAN, 0); }
	public LESS(): TerminalNode | undefined { return this.tryGetToken(ExpressionsParserGrammarParser.LESS, 0); }
	public MORE_OR_EQUALS(): TerminalNode | undefined { return this.tryGetToken(ExpressionsParserGrammarParser.MORE_OR_EQUALS, 0); }
	public LESS_OR_EQUALS(): TerminalNode | undefined { return this.tryGetToken(ExpressionsParserGrammarParser.LESS_OR_EQUALS, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterEquality_operation) {
			listener.enterEquality_operation(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitEquality_operation) {
			listener.exitEquality_operation(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitEquality_operation) {
			return visitor.visitEquality_operation(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class Mul_div_exprContext extends ExpressionContext {
	public _left!: ExpressionContext;
	public _mul_or_div!: Token;
	public _right!: ExpressionContext;
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public MUL(): TerminalNode | undefined { return this.tryGetToken(ExpressionsParserGrammarParser.MUL, 0); }
	public DIV(): TerminalNode | undefined { return this.tryGetToken(ExpressionsParserGrammarParser.DIV, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterMul_div_expr) {
			listener.enterMul_div_expr(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitMul_div_expr) {
			listener.exitMul_div_expr(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitMul_div_expr) {
			return visitor.visitMul_div_expr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class Add_exprContext extends ExpressionContext {
	public _left!: ExpressionContext;
	public _plus_minus!: Token;
	public _right!: ExpressionContext;
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public PLUS(): TerminalNode | undefined { return this.tryGetToken(ExpressionsParserGrammarParser.PLUS, 0); }
	public MINUS(): TerminalNode | undefined { return this.tryGetToken(ExpressionsParserGrammarParser.MINUS, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterAdd_expr) {
			listener.enterAdd_expr(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitAdd_expr) {
			listener.exitAdd_expr(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitAdd_expr) {
			return visitor.visitAdd_expr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class Not_exprContext extends ExpressionContext {
	public _expr!: ExpressionContext;
	public NOT(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.NOT, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterNot_expr) {
			listener.enterNot_expr(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitNot_expr) {
			listener.exitNot_expr(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitNot_expr) {
			return visitor.visitNot_expr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class And_exprContext extends ExpressionContext {
	public _left!: ExpressionContext;
	public _right!: ExpressionContext;
	public AND(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.AND, 0); }
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterAnd_expr) {
			listener.enterAnd_expr(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitAnd_expr) {
			listener.exitAnd_expr(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitAnd_expr) {
			return visitor.visitAnd_expr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class Or_exprContext extends ExpressionContext {
	public _left!: ExpressionContext;
	public _right!: ExpressionContext;
	public OR(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.OR, 0); }
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterOr_expr) {
			listener.enterOr_expr(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitOr_expr) {
			listener.exitOr_expr(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitOr_expr) {
			return visitor.visitOr_expr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class Brackets_exprContext extends ExpressionContext {
	public _expr!: ExpressionContext;
	public LPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.LPAREN, 0); }
	public RPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.RPAREN, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterBrackets_expr) {
			listener.enterBrackets_expr(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitBrackets_expr) {
			listener.exitBrackets_expr(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitBrackets_expr) {
			return visitor.visitBrackets_expr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class Question_exprContext extends ExpressionContext {
	public _condition!: ExpressionContext;
	public _true_exp!: ExpressionContext;
	public _false_exp!: ExpressionContext;
	public QUERY_OP(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.QUERY_OP, 0); }
	public COLON(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.COLON, 0); }
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterQuestion_expr) {
			listener.enterQuestion_expr(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitQuestion_expr) {
			listener.exitQuestion_expr(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitQuestion_expr) {
			return visitor.visitQuestion_expr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class Power_exprContext extends ExpressionContext {
	public _expr1!: ExpressionContext;
	public _expr2!: ExpressionContext;
	public POWER_FUNC(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.POWER_FUNC, 0); }
	public LPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.LPAREN, 0); }
	public COMMA(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.COMMA, 0); }
	public RPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.RPAREN, 0); }
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterPower_expr) {
			listener.enterPower_expr(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitPower_expr) {
			listener.exitPower_expr(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitPower_expr) {
			return visitor.visitPower_expr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class First_val_funcContext extends ExpressionContext {
	public _first_str_func!: Token;
	public _config!: Token;
	public FIRST_VAL_FUNC(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.FIRST_VAL_FUNC, 0); }
	public LPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.LPAREN, 0); }
	public RPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.RPAREN, 0); }
	public STRING_VALUE(): TerminalNode[];
	public STRING_VALUE(i: number): TerminalNode;
	public STRING_VALUE(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(ExpressionsParserGrammarParser.STRING_VALUE);
		} else {
			return this.getToken(ExpressionsParserGrammarParser.STRING_VALUE, i);
		}
	}
	public COMMA(): TerminalNode | undefined { return this.tryGetToken(ExpressionsParserGrammarParser.COMMA, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterFirst_val_func) {
			listener.enterFirst_val_func(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitFirst_val_func) {
			listener.exitFirst_val_func(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitFirst_val_func) {
			return visitor.visitFirst_val_func(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class First_str_funcContext extends ExpressionContext {
	public _first_str_func!: Token;
	public FIRST_STR_FUNC(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.FIRST_STR_FUNC, 0); }
	public LPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.LPAREN, 0); }
	public RPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.RPAREN, 0); }
	public STRING_VALUE(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.STRING_VALUE, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterFirst_str_func) {
			listener.enterFirst_str_func(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitFirst_str_func) {
			listener.exitFirst_str_func(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitFirst_str_func) {
			return visitor.visitFirst_str_func(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class Last_val_funcContext extends ExpressionContext {
	public _last_str_func!: Token;
	public _config!: Token;
	public LAST_VAL_FUNC(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.LAST_VAL_FUNC, 0); }
	public LPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.LPAREN, 0); }
	public RPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.RPAREN, 0); }
	public STRING_VALUE(): TerminalNode[];
	public STRING_VALUE(i: number): TerminalNode;
	public STRING_VALUE(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(ExpressionsParserGrammarParser.STRING_VALUE);
		} else {
			return this.getToken(ExpressionsParserGrammarParser.STRING_VALUE, i);
		}
	}
	public COMMA(): TerminalNode | undefined { return this.tryGetToken(ExpressionsParserGrammarParser.COMMA, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterLast_val_func) {
			listener.enterLast_val_func(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitLast_val_func) {
			listener.exitLast_val_func(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitLast_val_func) {
			return visitor.visitLast_val_func(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class Last_str_funcContext extends ExpressionContext {
	public _last_str_func!: Token;
	public LAST_STR_FUNC(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.LAST_STR_FUNC, 0); }
	public LPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.LPAREN, 0); }
	public RPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.RPAREN, 0); }
	public STRING_VALUE(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.STRING_VALUE, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterLast_str_func) {
			listener.enterLast_str_func(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitLast_str_func) {
			listener.exitLast_str_func(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitLast_str_func) {
			return visitor.visitLast_str_func(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class Submission_val_funcContext extends ExpressionContext {
	public _sumission_val_str!: Token;
	public SUBMISSION_VAL_FUNC(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.SUBMISSION_VAL_FUNC, 0); }
	public LPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.LPAREN, 0); }
	public RPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.RPAREN, 0); }
	public STRING_VALUE(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.STRING_VALUE, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterSubmission_val_func) {
			listener.enterSubmission_val_func(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitSubmission_val_func) {
			listener.exitSubmission_val_func(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitSubmission_val_func) {
			return visitor.visitSubmission_val_func(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class Submission_str_funcContext extends ExpressionContext {
	public _submission_str!: Token;
	public SUBMISSION_STR_FUNC(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.SUBMISSION_STR_FUNC, 0); }
	public LPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.LPAREN, 0); }
	public RPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.RPAREN, 0); }
	public STRING_VALUE(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.STRING_VALUE, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterSubmission_str_func) {
			listener.enterSubmission_str_func(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitSubmission_str_func) {
			listener.exitSubmission_str_func(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitSubmission_str_func) {
			return visitor.visitSubmission_str_func(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class Date_format_funcContext extends ExpressionContext {
	public _date_format_expr!: ExpressionContext;
	public _formatParam!: Token;
	public DATE_FORMAT_FUNC(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.DATE_FORMAT_FUNC, 0); }
	public LPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.LPAREN, 0); }
	public COMMA(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.COMMA, 0); }
	public RPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.RPAREN, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	public STRING_VALUE(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.STRING_VALUE, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterDate_format_func) {
			listener.enterDate_format_func(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitDate_format_func) {
			listener.exitDate_format_func(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitDate_format_func) {
			return visitor.visitDate_format_func(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class DateContext extends ExpressionContext {
	public _expr!: ExpressionContext;
	public NEW(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.NEW, 0); }
	public DATE(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.DATE, 0); }
	public LPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.LPAREN, 0); }
	public RPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.RPAREN, 0); }
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public COMMA(): TerminalNode[];
	public COMMA(i: number): TerminalNode;
	public COMMA(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(ExpressionsParserGrammarParser.COMMA);
		} else {
			return this.getToken(ExpressionsParserGrammarParser.COMMA, i);
		}
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterDate) {
			listener.enterDate(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitDate) {
			listener.exitDate(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitDate) {
			return visitor.visitDate(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class Date_format_distance_funcContext extends ExpressionContext {
	public _left!: ExpressionContext;
	public _right!: ExpressionContext;
	public _opt!: OptionsContext;
	public DATE_FORMAT_DISTANCE(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.DATE_FORMAT_DISTANCE, 0); }
	public LPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.LPAREN, 0); }
	public COMMA(): TerminalNode[];
	public COMMA(i: number): TerminalNode;
	public COMMA(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(ExpressionsParserGrammarParser.COMMA);
		} else {
			return this.getToken(ExpressionsParserGrammarParser.COMMA, i);
		}
	}
	public RPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.RPAREN, 0); }
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public options(): OptionsContext | undefined {
		return this.tryGetRuleContext(0, OptionsContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterDate_format_distance_func) {
			listener.enterDate_format_distance_func(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitDate_format_distance_func) {
			listener.exitDate_format_distance_func(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitDate_format_distance_func) {
			return visitor.visitDate_format_distance_func(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class Dif_in_hoursContext extends ExpressionContext {
	public _left!: ExpressionContext;
	public _right!: ExpressionContext;
	public DIFFERENCE_IN_HOURS(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.DIFFERENCE_IN_HOURS, 0); }
	public LPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.LPAREN, 0); }
	public COMMA(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.COMMA, 0); }
	public RPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.RPAREN, 0); }
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterDif_in_hours) {
			listener.enterDif_in_hours(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitDif_in_hours) {
			listener.exitDif_in_hours(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitDif_in_hours) {
			return visitor.visitDif_in_hours(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class Dif_in_daysContext extends ExpressionContext {
	public _left!: ExpressionContext;
	public _right!: ExpressionContext;
	public DIFFERENCE_IN_DAYS(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.DIFFERENCE_IN_DAYS, 0); }
	public LPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.LPAREN, 0); }
	public COMMA(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.COMMA, 0); }
	public RPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.RPAREN, 0); }
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterDif_in_days) {
			listener.enterDif_in_days(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitDif_in_days) {
			listener.exitDif_in_days(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitDif_in_days) {
			return visitor.visitDif_in_days(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class Dif_in_minutesContext extends ExpressionContext {
	public _left!: ExpressionContext;
	public _right!: ExpressionContext;
	public DIFFERENCE_IN_MINUTES(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.DIFFERENCE_IN_MINUTES, 0); }
	public LPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.LPAREN, 0); }
	public COMMA(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.COMMA, 0); }
	public RPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.RPAREN, 0); }
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterDif_in_minutes) {
			listener.enterDif_in_minutes(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitDif_in_minutes) {
			listener.exitDif_in_minutes(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitDif_in_minutes) {
			return visitor.visitDif_in_minutes(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class DateAddFuncContext extends ExpressionContext {
	public _date!: ExpressionContext;
	public _d!: DurationContext;
	public DATE_ADD(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.DATE_ADD, 0); }
	public LPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.LPAREN, 0); }
	public COMMA(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.COMMA, 0); }
	public RPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.RPAREN, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	public duration(): DurationContext {
		return this.getRuleContext(0, DurationContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterDateAddFunc) {
			listener.enterDateAddFunc(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitDateAddFunc) {
			listener.exitDateAddFunc(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitDateAddFunc) {
			return visitor.visitDateAddFunc(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class DateSubtractFuncContext extends ExpressionContext {
	public _date!: ExpressionContext;
	public _d!: DurationContext;
	public DATE_SUBTRACT(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.DATE_SUBTRACT, 0); }
	public LPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.LPAREN, 0); }
	public COMMA(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.COMMA, 0); }
	public RPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.RPAREN, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	public duration(): DurationContext {
		return this.getRuleContext(0, DurationContext);
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterDateSubtractFunc) {
			listener.enterDateSubtractFunc(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitDateSubtractFunc) {
			listener.exitDateSubtractFunc(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitDateSubtractFunc) {
			return visitor.visitDateSubtractFunc(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class Max_exprContext extends ExpressionContext {
	public _args!: ExpressionContext;
	public MAX(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.MAX, 0); }
	public LPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.LPAREN, 0); }
	public RPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.RPAREN, 0); }
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public COMMA(): TerminalNode[];
	public COMMA(i: number): TerminalNode;
	public COMMA(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(ExpressionsParserGrammarParser.COMMA);
		} else {
			return this.getToken(ExpressionsParserGrammarParser.COMMA, i);
		}
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterMax_expr) {
			listener.enterMax_expr(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitMax_expr) {
			listener.exitMax_expr(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitMax_expr) {
			return visitor.visitMax_expr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class Min_exprContext extends ExpressionContext {
	public _args!: ExpressionContext;
	public MIN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.MIN, 0); }
	public LPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.LPAREN, 0); }
	public RPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.RPAREN, 0); }
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public COMMA(): TerminalNode[];
	public COMMA(i: number): TerminalNode;
	public COMMA(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(ExpressionsParserGrammarParser.COMMA);
		} else {
			return this.getToken(ExpressionsParserGrammarParser.COMMA, i);
		}
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterMin_expr) {
			listener.enterMin_expr(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitMin_expr) {
			listener.exitMin_expr(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitMin_expr) {
			return visitor.visitMin_expr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class NowContext extends ExpressionContext {
	public NOW(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.NOW, 0); }
	public LPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.LPAREN, 0); }
	public RPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.RPAREN, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterNow) {
			listener.enterNow(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitNow) {
			listener.exitNow(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitNow) {
			return visitor.visitNow(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class Process_numberContext extends ExpressionContext {
	public _numLiteral!: Token;
	public NUMBER_LITERAL(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.NUMBER_LITERAL, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterProcess_number) {
			listener.enterProcess_number(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitProcess_number) {
			listener.exitProcess_number(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitProcess_number) {
			return visitor.visitProcess_number(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class Process_strContext extends ExpressionContext {
	public _strValueLiteral!: Token;
	public STRING_VALUE(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.STRING_VALUE, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterProcess_str) {
			listener.enterProcess_str(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitProcess_str) {
			listener.exitProcess_str(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitProcess_str) {
			return visitor.visitProcess_str(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class Print_exprContext extends ExpressionContext {
	public _expr!: ExpressionContext;
	public PRINT(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.PRINT, 0); }
	public LPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.LPAREN, 0); }
	public RPAREN(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.RPAREN, 0); }
	public expression(): ExpressionContext[];
	public expression(i: number): ExpressionContext;
	public expression(i?: number): ExpressionContext | ExpressionContext[] {
		if (i === undefined) {
			return this.getRuleContexts(ExpressionContext);
		} else {
			return this.getRuleContext(i, ExpressionContext);
		}
	}
	public COMMA(): TerminalNode[];
	public COMMA(i: number): TerminalNode;
	public COMMA(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(ExpressionsParserGrammarParser.COMMA);
		} else {
			return this.getToken(ExpressionsParserGrammarParser.COMMA, i);
		}
	}
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterPrint_expr) {
			listener.enterPrint_expr(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitPrint_expr) {
			listener.exitPrint_expr(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitPrint_expr) {
			return visitor.visitPrint_expr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class Process_boolContext extends ExpressionContext {
	public _boolLiteral!: Token;
	public TRUE(): TerminalNode | undefined { return this.tryGetToken(ExpressionsParserGrammarParser.TRUE, 0); }
	public FALSE(): TerminalNode | undefined { return this.tryGetToken(ExpressionsParserGrammarParser.FALSE, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterProcess_bool) {
			listener.enterProcess_bool(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitProcess_bool) {
			listener.exitProcess_bool(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitProcess_bool) {
			return visitor.visitProcess_bool(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class Null_exprContext extends ExpressionContext {
	public NULL(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.NULL, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterNull_expr) {
			listener.enterNull_expr(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitNull_expr) {
			listener.exitNull_expr(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitNull_expr) {
			return visitor.visitNull_expr(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}
export class UndefinedContext extends ExpressionContext {
	public UNDEFINED(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.UNDEFINED, 0); }
	constructor(ctx: ExpressionContext) {
		super(ctx.parent, ctx.invokingState);
		this.copyFrom(ctx);
	}
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterUndefined) {
			listener.enterUndefined(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitUndefined) {
			listener.exitUndefined(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitUndefined) {
			return visitor.visitUndefined(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class DurationContext extends ParserRuleContext {
	public L_CURLY_BRACE(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.L_CURLY_BRACE, 0); }
	public duration_element(): Duration_elementContext[];
	public duration_element(i: number): Duration_elementContext;
	public duration_element(i?: number): Duration_elementContext | Duration_elementContext[] {
		if (i === undefined) {
			return this.getRuleContexts(Duration_elementContext);
		} else {
			return this.getRuleContext(i, Duration_elementContext);
		}
	}
	public R_CURLY_BRACE(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.R_CURLY_BRACE, 0); }
	public COMMA(): TerminalNode[];
	public COMMA(i: number): TerminalNode;
	public COMMA(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(ExpressionsParserGrammarParser.COMMA);
		} else {
			return this.getToken(ExpressionsParserGrammarParser.COMMA, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ExpressionsParserGrammarParser.RULE_duration; }
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterDuration) {
			listener.enterDuration(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitDuration) {
			listener.exitDuration(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitDuration) {
			return visitor.visitDuration(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Duration_elementContext extends ParserRuleContext {
	public years_element(): Years_elementContext | undefined {
		return this.tryGetRuleContext(0, Years_elementContext);
	}
	public month_element(): Month_elementContext | undefined {
		return this.tryGetRuleContext(0, Month_elementContext);
	}
	public weeks_element(): Weeks_elementContext | undefined {
		return this.tryGetRuleContext(0, Weeks_elementContext);
	}
	public days_element(): Days_elementContext | undefined {
		return this.tryGetRuleContext(0, Days_elementContext);
	}
	public hours_element(): Hours_elementContext | undefined {
		return this.tryGetRuleContext(0, Hours_elementContext);
	}
	public minutes_element(): Minutes_elementContext | undefined {
		return this.tryGetRuleContext(0, Minutes_elementContext);
	}
	public seconds_element(): Seconds_elementContext | undefined {
		return this.tryGetRuleContext(0, Seconds_elementContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ExpressionsParserGrammarParser.RULE_duration_element; }
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterDuration_element) {
			listener.enterDuration_element(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitDuration_element) {
			listener.exitDuration_element(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitDuration_element) {
			return visitor.visitDuration_element(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class OptionsContext extends ParserRuleContext {
	public L_CURLY_BRACE(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.L_CURLY_BRACE, 0); }
	public option_element(): Option_elementContext[];
	public option_element(i: number): Option_elementContext;
	public option_element(i?: number): Option_elementContext | Option_elementContext[] {
		if (i === undefined) {
			return this.getRuleContexts(Option_elementContext);
		} else {
			return this.getRuleContext(i, Option_elementContext);
		}
	}
	public R_CURLY_BRACE(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.R_CURLY_BRACE, 0); }
	public COMMA(): TerminalNode[];
	public COMMA(i: number): TerminalNode;
	public COMMA(i?: number): TerminalNode | TerminalNode[] {
		if (i === undefined) {
			return this.getTokens(ExpressionsParserGrammarParser.COMMA);
		} else {
			return this.getToken(ExpressionsParserGrammarParser.COMMA, i);
		}
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ExpressionsParserGrammarParser.RULE_options; }
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterOptions) {
			listener.enterOptions(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitOptions) {
			listener.exitOptions(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitOptions) {
			return visitor.visitOptions(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Option_elementContext extends ParserRuleContext {
	public include_seconds(): Include_secondsContext | undefined {
		return this.tryGetRuleContext(0, Include_secondsContext);
	}
	public add_suffix(): Add_suffixContext | undefined {
		return this.tryGetRuleContext(0, Add_suffixContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ExpressionsParserGrammarParser.RULE_option_element; }
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterOption_element) {
			listener.enterOption_element(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitOption_element) {
			listener.exitOption_element(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitOption_element) {
			return visitor.visitOption_element(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Years_elementContext extends ParserRuleContext {
	public _value!: ExpressionContext;
	public YEARS(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.YEARS, 0); }
	public COLON(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.COLON, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ExpressionsParserGrammarParser.RULE_years_element; }
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterYears_element) {
			listener.enterYears_element(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitYears_element) {
			listener.exitYears_element(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitYears_element) {
			return visitor.visitYears_element(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Month_elementContext extends ParserRuleContext {
	public _value!: ExpressionContext;
	public MONTHS(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.MONTHS, 0); }
	public COLON(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.COLON, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ExpressionsParserGrammarParser.RULE_month_element; }
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterMonth_element) {
			listener.enterMonth_element(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitMonth_element) {
			listener.exitMonth_element(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitMonth_element) {
			return visitor.visitMonth_element(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Weeks_elementContext extends ParserRuleContext {
	public _value!: ExpressionContext;
	public WEEKS(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.WEEKS, 0); }
	public COLON(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.COLON, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ExpressionsParserGrammarParser.RULE_weeks_element; }
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterWeeks_element) {
			listener.enterWeeks_element(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitWeeks_element) {
			listener.exitWeeks_element(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitWeeks_element) {
			return visitor.visitWeeks_element(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Days_elementContext extends ParserRuleContext {
	public _value!: ExpressionContext;
	public DAYS(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.DAYS, 0); }
	public COLON(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.COLON, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ExpressionsParserGrammarParser.RULE_days_element; }
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterDays_element) {
			listener.enterDays_element(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitDays_element) {
			listener.exitDays_element(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitDays_element) {
			return visitor.visitDays_element(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Hours_elementContext extends ParserRuleContext {
	public _value!: ExpressionContext;
	public HOURS(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.HOURS, 0); }
	public COLON(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.COLON, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ExpressionsParserGrammarParser.RULE_hours_element; }
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterHours_element) {
			listener.enterHours_element(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitHours_element) {
			listener.exitHours_element(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitHours_element) {
			return visitor.visitHours_element(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Minutes_elementContext extends ParserRuleContext {
	public _value!: ExpressionContext;
	public MINUTES(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.MINUTES, 0); }
	public COLON(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.COLON, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ExpressionsParserGrammarParser.RULE_minutes_element; }
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterMinutes_element) {
			listener.enterMinutes_element(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitMinutes_element) {
			listener.exitMinutes_element(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitMinutes_element) {
			return visitor.visitMinutes_element(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Seconds_elementContext extends ParserRuleContext {
	public _value!: ExpressionContext;
	public SECONDS(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.SECONDS, 0); }
	public COLON(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.COLON, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ExpressionsParserGrammarParser.RULE_seconds_element; }
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterSeconds_element) {
			listener.enterSeconds_element(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitSeconds_element) {
			listener.exitSeconds_element(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitSeconds_element) {
			return visitor.visitSeconds_element(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Include_secondsContext extends ParserRuleContext {
	public _seconds!: ExpressionContext;
	public INCLUDE_SECONDS(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.INCLUDE_SECONDS, 0); }
	public COLON(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.COLON, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ExpressionsParserGrammarParser.RULE_include_seconds; }
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterInclude_seconds) {
			listener.enterInclude_seconds(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitInclude_seconds) {
			listener.exitInclude_seconds(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitInclude_seconds) {
			return visitor.visitInclude_seconds(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}


export class Add_suffixContext extends ParserRuleContext {
	public _suffix!: ExpressionContext;
	public ADD_SUFFIX(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.ADD_SUFFIX, 0); }
	public COLON(): TerminalNode { return this.getToken(ExpressionsParserGrammarParser.COLON, 0); }
	public expression(): ExpressionContext {
		return this.getRuleContext(0, ExpressionContext);
	}
	constructor(parent: ParserRuleContext | undefined, invokingState: number) {
		super(parent, invokingState);
	}
	// @Override
	public get ruleIndex(): number { return ExpressionsParserGrammarParser.RULE_add_suffix; }
	// @Override
	public enterRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.enterAdd_suffix) {
			listener.enterAdd_suffix(this);
		}
	}
	// @Override
	public exitRule(listener: ExpressionsParserGrammarListener): void {
		if (listener.exitAdd_suffix) {
			listener.exitAdd_suffix(this);
		}
	}
	// @Override
	public accept<Result>(visitor: ExpressionsParserGrammarVisitor<Result>): Result {
		if (visitor.visitAdd_suffix) {
			return visitor.visitAdd_suffix(this);
		} else {
			return visitor.visitChildren(this);
		}
	}
}



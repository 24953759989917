import safeJsonStringify from 'safe-json-stringify';
var ClientLogger = /** @class */ (function () {
    function ClientLogger() {
    }
    ClientLogger.debug = function (source, message, objectOut) {
        if (objectOut) {
            console.log("".concat(new Date().toISOString(), ": ").concat(source, ":").concat(message), objectOut); // eslint-disable-line no-console
        }
        else {
            console.log("".concat(new Date().toISOString(), ": ").concat(source, ":").concat(message)); // eslint-disable-line no-console
        }
    };
    ClientLogger.log = function (source, message) {
        console.log("".concat(new Date().toISOString(), ": ").concat(source, ": ").concat(message)); // eslint-disable-line no-console
    };
    ClientLogger.warning = function (source, message) {
        console.warn("Warning: ".concat(source, " ").concat(message)); // eslint-disable-line no-console
    };
    ClientLogger.error = function (source, message, e) {
        console.error("".concat(new Date().toISOString(), ": ").concat(source, ":").concat(message, " ")); // eslint-disable-line no-console
        if (e) {
            console.error(ClientLogger.errorToText(e)); // eslint-disable-line no-console
        }
    };
    ClientLogger.timerStart = function (operation, timerOn, level) {
        if (level === void 0) { level = 0; }
        return timerOn ? { start: new Date(), operation: operation, level: level } : undefined;
    };
    ClientLogger.timerEnd = function (timer) {
        if (timer) {
            var seconds = (new Date().getTime() - timer.start.getTime()) / 1000;
            ClientLogger.log("".concat(' '.repeat(timer.level), " timer: ").concat(timer.operation), "Completed in ".concat(seconds));
        }
    };
    ClientLogger.errorToText = function (e) {
        try {
            return JSON.stringify(e, Object.getOwnPropertyNames(e));
        }
        catch (_ee) {
            return ClientLogger.safeStringIfy(e);
        }
    };
    ClientLogger.safeStringIfy = function (o) {
        return safeJsonStringify(o, null, 2);
    };
    return ClientLogger;
}());
export { ClientLogger };

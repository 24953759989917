var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import React, { useState } from 'react';
import { dialogClasses } from 'style/sharedCssClasses';
import CloseIcon from '@mui/icons-material/Clear';
import { PersonOrgSearchResult } from './PersonOrgSearchResult';
export function PersonOrgSearch(props) {
    var _a = useState({}), searchCriteriaEdit = _a[0], setSearchCriteriaEdit = _a[1];
    var _b = useState({}), searchCriteria = _b[0], setSearchCriteria = _b[1];
    return (_jsxs(_Fragment, { children: [_jsxs(DialogTitle, __assign({ sx: dialogClasses.dialogTitle }, { children: ["Search", _jsx(IconButton, __assign({ onClick: props.onClose, size: "large" }, { children: _jsx(CloseIcon, { sx: dialogClasses.closeIcon }) }))] })), _jsxs(DialogContent, { children: [_jsx(TextField, { name: "lastName", label: "Last Name", placeholder: "Enter letters in the last name", type: "text", fullWidth: true, variant: "outlined", "data-test": "name-textfield", sx: dialogClasses.inputField, onChange: function (e) {
                            setSearchCriteriaEdit(__assign(__assign({}, searchCriteriaEdit), { lastName: e.target.value }));
                        } }), _jsx(TextField, { name: "firstName", label: "First Name", placeholder: "Enter letters in the first name", type: "text", fullWidth: true, variant: "outlined", "data-test": "new-patient-middle-name-textfield", sx: dialogClasses.inputField, onChange: function (e) {
                            setSearchCriteriaEdit(__assign(__assign({}, searchCriteriaEdit), { firstName: e.target.value }));
                        } }), _jsx(Button, __assign({ onClick: function () {
                            setSearchCriteria(searchCriteriaEdit);
                            if (props.onSearch) {
                                props.onSearch(searchCriteriaEdit);
                            }
                        }, variant: "contained", fullWidth: true, color: "primary", sx: dialogClasses.inputField }, { children: "Search" })), _jsx(PersonOrgSearchResult, { role: props.role, org: props.org, searchCriteria: searchCriteria || {}, buttons: props.buttons, selected: function (person, id) {
                            props.onSelect(person, id);
                        }, onClose: props.onClose })] })] }));
}

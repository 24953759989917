var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { DataTable } from 'ui-lib';
import { Loading } from 'components/widgets/Loading';
import { ClientLogger } from 'lib/client-logger';
import { useOrgService } from 'lib/api/use-org-service';
import { OrgType } from 'lib/api/api-types';
import { useErrorHandler } from 'lib/use-error-handler';
import { EditOrgOverview } from './EditOrgOverview';
import { TopBackBtnBar } from '../../components/navigation/TopBackBtnBar';
import { tableClasses } from '../../style/sharedCssClasses';
import { OrgDetails } from './OrgDetails';
import { dateDisplay } from 'lib/util';
var DEBUG = false;
export var OrgList = function (props) {
    var _a;
    var orgsService = useOrgService();
    var errorHandler = useErrorHandler('OrgList');
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var _c = useState(false), editOrgOpen = _c[0], setEditOrgOpen = _c[1]; //false not open, undefined open with new, string orgId beiong edited
    var _d = useState(false), removeOrgOpen = _d[0], setRemoveOrgOpen = _d[1]; //false not open, undefined open with new, string orgId beiong edited
    var orgsDataTableOptions = {
        pagination: true,
        download: true,
        print: true,
        filter: false,
        search: false,
        viewColumns: false,
        sort: true,
        selectableRows: 'none',
    };
    var columns = {
        id: { label: 'Id' },
        name: { label: 'Name' },
        updatedAt: { display: 'excluded' },
        emailDomain: { display: 'excluded' },
        externalId: { display: 'excluded' },
        isTest: { display: 'excluded' },
        attributes: { display: 'excluded' },
        authConfig: { display: 'excluded' },
        extMessageConfig: { display: 'excluded' },
        type: { display: 'excluded' },
        autoOnboard: { display: 'excluded' },
        description: { display: 'excluded' },
        createdAt: { label: 'Created At', customBodyRender: function (value) { return dateDisplay(value); } },
        status: { display: 'excluded' },
        buttons: {
            label: '',
            customBodyRender: function (value) {
                if (value !== undefined && value !== null) {
                    return (_jsx(Box, __assign({ display: "flex", justifyContent: "space-evenly", alignItems: "start" }, { children: _jsx(IconButton, __assign({ onClick: function (e) {
                                DEBUG && ClientLogger.debug('custom render click', '', value);
                                e.stopPropagation();
                                setRemoveOrgOpen(value);
                            }, color: "primary" }, { children: _jsx(DeleteIcon, {}) })) })));
                }
                else {
                    return _jsx(_Fragment, {});
                }
            },
            customHeadRender: function () { return _jsx("td", {}, "edit"); },
        },
    };
    var transformResponseToRows = function (resp) {
        var _a;
        if (!resp || !resp.orgs || !((_a = resp.orgs) === null || _a === void 0 ? void 0 : _a.edges)) {
            return [];
        }
        return resp.orgs.edges.map(function (org) { return (__assign(__assign({}, org.node), { buttons: { id: org.node.id, name: org.node.name } })); });
    };
    var queryMap = (_a = {},
        _a[OrgType.PROVIDER] = orgsService.useOrgsQueryProvider,
        _a[OrgType.APP] = orgsService.useOrgsQueryApp,
        _a[OrgType.JURISDICTION] = orgsService.useOrgsQueryJurisdiction,
        _a);
    var rowClicked = function (row) {
        setEditOrgOpen(row.id);
    };
    var tableProps = {
        useQuery: queryMap[props.orgType],
        initialQuery: {},
        transformResponseToRows: transformResponseToRows,
        columnAttributes: columns,
        tableAttributes: orgsDataTableOptions,
        onRowClick: rowClicked,
        onAdd: function () {
            DEBUG && ClientLogger.debug('onAdd', '', {});
            setEditOrgOpen(undefined);
        },
    };
    function removeOrg(orgId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, orgsService
                            .orgDelete(orgId)
                            .then(function (resp) {
                            if (resp.errors) {
                                errorHandler.handleErrors({ graphQLErrors: resp.errors });
                            }
                            setLoading(false);
                        })
                            .catch(function (err) {
                            errorHandler.handleErrors({ error: err });
                            setLoading(false);
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    DEBUG && ClientLogger.debug('OrgList', 'render', { tableProps: tableProps });
    if (loading) {
        return _jsx(Loading, {});
    }
    return (_jsxs(_Fragment, { children: [_jsxs(Grid, __assign({ container: true }, { children: [_jsx(TopBackBtnBar, { title: props.title }), _jsx(Box, __assign({ mx: 2, mt: 6, sx: tableClasses.tableHolder }, { children: _jsx(DataTable, __assign({}, tableProps)) }))] })), _jsx(Dialog, __assign({ open: typeof editOrgOpen === 'string', onClose: function () { return setEditOrgOpen(false); }, maxWidth: "sm", fullWidth: true }, { children: _jsx(OrgDetails, { onClose: function () {
                        setEditOrgOpen(false);
                    }, editingId: editOrgOpen || '' }) })), _jsx(Dialog, __assign({ open: editOrgOpen === undefined, onClose: function () { return setEditOrgOpen(false); }, maxWidth: "sm", fullWidth: true }, { children: _jsx(EditOrgOverview, { orgType: props.orgType, initialValues: { name: '', description: '', id: '', isTest: false }, onClose: function () {
                        setEditOrgOpen(false);
                    }, editingId: typeof editOrgOpen === 'string' ? editOrgOpen : undefined }) })), _jsxs(Dialog, __assign({ open: !!removeOrgOpen, onClose: function () { return setRemoveOrgOpen(false); }, maxWidth: "sm", fullWidth: true }, { children: [_jsxs(DialogTitle, { children: ["Remove ", removeOrgOpen && removeOrgOpen.name] }), _jsx(DialogContent, { children: _jsxs(Typography, __assign({ variant: "body1" }, { children: ["Are you sure you want to remove ", removeOrgOpen && removeOrgOpen.name, "?"] })) }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ onClick: function () {
                                    setRemoveOrgOpen(false);
                                } }, { children: "Cancel" })), _jsx(Button, __assign({ onClick: function (item) {
                                    DEBUG && ClientLogger.debug('OrgList', 'org remove related', { item: item });
                                    setRemoveOrgOpen(false);
                                    setLoading(true);
                                    if (removeOrgOpen && removeOrgOpen.id) {
                                        removeOrg(removeOrgOpen.id);
                                    }
                                } }, { children: "OK" }))] })] }))] }));
};

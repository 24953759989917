import { RuleAction } from './DecisionTableTypes';

export enum NotificationProtocol {
  NONE = 'NONE',
  URGENT = 'URGENT',
}

export interface NotificationParams {
  external: boolean;
  messageText?: string;
  emailSentActions?: RuleAction[];
  smsSentActions?: RuleAction[];
  sendFailureActions?: RuleAction[];
}

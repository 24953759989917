var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { UserContext } from 'lib/auth/user-context';
import { ClientLogger } from 'lib/client-logger';
import { useErrorHandler } from 'lib/use-error-handler';
import React, { useContext, useEffect, useRef } from 'react';
import { dialogClasses } from '../../style/sharedCssClasses';
import CloseIcon from '@mui/icons-material/Clear';
import { useAgreementService } from 'lib/api/use-agreement-service';
import { Status, Role } from 'lib/api/api-types';
import { Switch, RoleSelector, ConfirmDialog } from 'ui-lib';
import { RichTextField } from 'components/form/RichTextField';
var DEBUG = false;
export function explanation(args) {
    var currentRequiresAcceptance = args.currentRequiresAcceptance, saveAsNewVersion = args.saveAsNewVersion, history = args.history;
    var isFirst = history.length <= 1 && !saveAsNewVersion;
    if (isFirst) {
        if (currentRequiresAcceptance) {
            return 'All users must accept the agreement.';
        }
        else {
            return 'No users will be required to accept the agreement.';
        }
    }
    else {
        if (currentRequiresAcceptance) {
            return 'All users must accept the new version of the agreement.';
        }
        else {
            return "Users who have already accepted a previous version of the agreement will not be required to accept the new version.";
        }
    }
}
export function EditAgreement(props) {
    var _this = this;
    var agreementSerivce = useAgreementService();
    var errorHandler = useErrorHandler('EditAgreement');
    var formRef = useRef(null);
    var userContext = useContext(UserContext);
    var dialogTitle = "Edit Agreement";
    var _a = React.useState(false), showConfirmDelete = _a[0], setShowConfirmDelete = _a[1];
    var _b = React.useState([]), history = _b[0], setHistory = _b[1];
    useEffect(function () {
        function load() {
            var _a, _b;
            return __awaiter(this, void 0, void 0, function () {
                var resp;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0: return [4 /*yield*/, agreementSerivce.agreement(props.agreementId)];
                        case 1:
                            resp = _c.sent();
                            if ((_a = resp.data) === null || _a === void 0 ? void 0 : _a.agreement) {
                                (_b = formRef.current) === null || _b === void 0 ? void 0 : _b.setValues({
                                    name: resp.data.agreement.name,
                                    roles: resp.data.agreement.roles || [],
                                    content: resp.data.agreement.content,
                                    saveAsNewVersion: false,
                                    requiresAcceptance: resp.data.agreement.requiresAcceptance,
                                });
                                setHistory(resp.data.agreement.versions);
                            }
                            else {
                                errorHandler.handleErrors({ graphQLErrors: resp.errors });
                            }
                            return [2 /*return*/];
                    }
                });
            });
        }
        if (props.agreementId) {
            load();
        }
    }, [props.agreementId]);
    function save(values) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, agreementSerivce.agreementUpdate(props.agreementId, {
                            name: values.name,
                            roles: values.roles,
                            content: values.content,
                            saveAsNewVersion: values.saveAsNewVersion,
                            requiresAcceptance: values.requiresAcceptance,
                        })];
                    case 1:
                        resp = _b.sent();
                        DEBUG && ClientLogger.debug('EditAgreement', 'save', { resp: resp });
                        if ((_a = resp.data) === null || _a === void 0 ? void 0 : _a.agreementUpdate.id) {
                            props.onClose();
                        }
                        else {
                            errorHandler.handleErrors({ graphQLErrors: resp.errors });
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    function deleteAgreement(values) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, agreementSerivce.agreementUpdate(props.agreementId, {
                            name: values.name,
                            roles: values.roles,
                            content: values.content,
                            saveAsNewVersion: values.saveAsNewVersion,
                            requiresAcceptance: values.requiresAcceptance,
                            status: Status.DELETED,
                        })];
                    case 1:
                        resp = _b.sent();
                        DEBUG && ClientLogger.debug('EditAgreement', 'save', { resp: resp });
                        if ((_a = resp.data) === null || _a === void 0 ? void 0 : _a.agreementUpdate.id) {
                            props.onClose();
                        }
                        else {
                            errorHandler.handleErrors({ graphQLErrors: resp.errors });
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    DEBUG && ClientLogger.debug('EditAgreement', 'render', { props: props, formRef: JSON.stringify(formRef.current) });
    return (_jsx(Formik, __assign({ innerRef: formRef, initialValues: {
            name: '',
            roles: [Role.USER, Role.STAFF, Role.ADMIN],
            content: '',
            saveAsNewVersion: false,
            requiresAcceptance: false,
        }, validate: function (values) {
            var errors = {};
            if (!values.name) {
                errors.name = 'Required';
            }
            if (!values.content) {
                errors.content = 'Required';
            }
            DEBUG && ClientLogger.debug('EditAgreement', 'validate', { values: values, errors: errors });
            return errors;
        }, onSubmit: function (values, actions) {
            DEBUG && ClientLogger.debug('EditAgreement', "onSubmit values", values);
            save(values);
        } }, { children: function (_a) {
            var submitForm = _a.submitForm, isSubmitting = _a.isSubmitting, errors = _a.errors, values = _a.values, isValid = _a.isValid, touched = _a.touched, validateForm = _a.validateForm, dirty = _a.dirty, setErrors = _a.setErrors;
            DEBUG && ClientLogger.debug('EditAgreement', 'render form', { isSubmitting: isSubmitting, errors: errors, values: values, touched: touched, dirty: dirty, submitForm: submitForm, isValid: isValid });
            return (_jsxs(_Fragment, { children: [_jsxs(DialogTitle, __assign({ sx: dialogClasses.dialogTitle }, { children: [dialogTitle, _jsx(IconButton, __assign({ onClick: props.onClose, size: "large" }, { children: _jsx(CloseIcon, { sx: dialogClasses.closeIcon }) }))] })), _jsxs(DialogContent, { children: [_jsx(Field, { component: TextField, name: "name", label: "Name (required)", placeholder: "Enter name", type: "text", fullWidth: true, variant: "outlined", "data-test": "name-textfield", sx: dialogClasses.inputField }), _jsx(RichTextField, { name: "content", label: "Agreement Text (required)" }), _jsx(RoleSelector, { selectMultiple: true, name: "roles" }), _jsx(Switch, { name: "saveAsNewVersion", label: "Save as new version" }), _jsx(Switch, { name: "requiresAcceptance", label: "Requires acceptance" }), _jsxs("div", { children: [_jsx(Typography, __assign({ variant: "body1" }, { children: explanation({
                                            saveAsNewVersion: values.saveAsNewVersion,
                                            currentRequiresAcceptance: values.requiresAcceptance,
                                            history: history,
                                        }) })), _jsx(Typography, __assign({ variant: "h6" }, { children: "History" })), history.map(function (version) {
                                        return (_jsxs(Typography, __assign({ variant: "body2" }, { children: ["V", version.version, ", created ", version.createdAt, ",", ' ', version.requiresAcceptance ? 'requires acceptance' : 'Does not require acceptance'] }), version.id));
                                    })] })] }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ color: "primary", disabled: isSubmitting, onClick: function () { return setShowConfirmDelete(true); } }, { children: "Delete" })), _jsx("div", { style: { flex: '1 0 0' } }), _jsx(Button, __assign({ color: "primary", disabled: isSubmitting, onClick: function () { return props.onClose(); } }, { children: "Cancel" })), _jsx(Button, __assign({ type: "submit", color: "primary", variant: "contained", disabled: isSubmitting && !isValid, onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                                    var valid;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, validateForm()];
                                            case 1:
                                                valid = _a.sent();
                                                DEBUG && ClientLogger.debug('EditAgreement', 'save', { valid: valid });
                                                if (Object.keys(valid).length === 0) {
                                                    save(values);
                                                }
                                                else {
                                                    setErrors(valid);
                                                }
                                                return [2 /*return*/];
                                        }
                                    });
                                }); } }, { children: "Save" }))] }), _jsx(ConfirmDialog, { showDialog: showConfirmDelete, message: "Are you sure that you want to delete this agreement?", title: "Delete Agreement", confirmed: function (confirmed) {
                            if (confirmed) {
                                deleteAgreement(values);
                                setShowConfirmDelete(false);
                            }
                            else {
                                setShowConfirmDelete(false);
                            }
                        }, btnConfirm: "Delete", btnCancel: "Cancel" })] }));
        } })));
}

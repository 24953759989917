var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import BackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Clear';
import { Box, Typography } from '@mui/material';
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
var useClasses = makeStyles({
    btnLabel: {
        display: 'inline-block',
        fontWeight: 'bold',
        marginRight: 6,
    },
    btnIcon: {
        marginRight: 8,
    },
    closeIcon: {
        marginRight: 8,
        color: '#000',
    },
});
export function BackButton(props) {
    var classes = useClasses();
    var linkClicked = function (event) {
        event.preventDefault();
        props.goBack();
    };
    return (_jsxs(Box, __assign({ display: "flex" }, { children: [_jsxs("a", __assign({ onClick: function (event) { return linkClicked(event); }, href: "" }, { children: [props.btnType && props.btnType === 'close' ? (_jsx(CloseIcon, { className: classes.closeIcon })) : (_jsx(BackIcon, { color: "primary", className: classes.btnIcon })), props.label && (_jsx(Typography, __assign({ color: "primary", display: "inline", className: classes.btnLabel }, { children: props.label })))] })), props.title && _jsx(Typography, __assign({ display: "inline" }, { children: props.title }))] })));
}

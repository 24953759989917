var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { createTheme } from '@mui/material';
var themeAdditions = {
    starColor: '#ff9900',
    inactiveColour: '#667C8F',
    bgColours: {
        bgLight: '#eeeeee',
        disabled: '#bbbbbb',
        selected: '#cadbfd',
    },
    chat: {
        self: '#337CBB',
        other: '#E4F2FD',
    },
    borders: {
        primary: '#e0e0Ee',
    },
    tooltip: {
        background: '#ccf9fd',
    },
};
var themeOptions = {
    palette: {
        common: {
            black: '#5b5b5b',
            white: '#fff',
        },
        primary: {
            light: '#0060c1',
            main: '#00539D',
            dark: '#002444',
            contrastText: '#fff',
        },
        secondary: {
            light: '#CCE4BF',
            main: '#0ec403',
            dark: '#075801',
            contrastText: '#fff',
        },
        warning: {
            main: '#FCCD89',
            light: '#FFEED2',
            dark: '#f57c00',
            contrastText: '#fff',
        },
        info: {
            main: '#2196f3',
            light: '#64b5f6',
            dark: '#1976d2',
            contrastText: '#fff',
        },
        error: {
            light: '#B00020',
            main: '#B00020',
            dark: '#B71C1C',
            contrastText: '#fff',
        },
        text: {
            primary: '#5e5e5e',
        },
    },
    typography: {
        h1: {
            fontFamily: 'PoppinsSemiBold',
            fontSize: '2.25rem',
            lineHeight: '132%',
        },
        h2: {
            fontFamily: 'PoppinsMedium',
            fontSize: '2rem',
            lineHeight: '130%',
        },
        h3: {
            fontFamily: 'PoppinsMedium',
            fontSize: '1.625rem',
            letterSpacing: 0,
            lineHeight: '125%',
        },
        h4: {
            fontFamily: 'PoppinsMedium',
            fontSize: '1.5rem',
            letterSpacing: 0,
            lineHeight: '125%',
        },
        h5: {
            fontFamily: 'PoppinsMedium',
            fontSize: '1.25rem',
            letterSpacing: 0,
            lineHeight: '125%',
        },
        h6: {
            fontFamily: 'PoppinsMedium',
            fontSize: '1.125rem',
            letterSpacing: 0,
            lineHeight: '125%',
        },
        subtitle1: {
            fontFamily: 'PoppinsLight',
            fontSize: '1.125rem',
            letterSpacing: 0,
            lineHeight: '130%',
        },
        subtitle2: {
            fontFamily: 'PoppinsLight',
            fontSize: '1rem',
            letterSpacing: 0,
            lineHeight: '130%',
        },
        body1: {
            fontFamily: 'PoppinsRegular',
            fontSize: '1rem',
            letterSpacing: '0.5px',
            lineHeight: '150%',
        },
        body2: {
            fontFamily: 'PoppinsRegular',
            fontSize: '.875rem',
            letterSpacing: '0.5px',
            lineHeight: '150%',
        },
        caption: {
            fontFamily: 'PoppinsRegular',
            fontSize: '.75rem',
            letterSpacing: '0.5px',
            lineHeight: '140%',
        },
        button: {
            fontFamily: 'PoppinsRegular',
            fontSize: '1.25rem',
            letterSpacing: 0,
            lineHeight: '130%',
        },
        overline: {
            fontFamily: 'PoppinsRegular',
            fontSize: '.75rem',
            letterSpacing: '1px',
            lineHeight: '130%',
            textDecoration: 'uppercase',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
};
var themeOverrides = {
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '2rem',
                    textTransform: 'capitalize',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: '#667C8F',
                    '&.Mui-selected': {
                        color: themeOptions.palette.primary.dark,
                    },
                },
            },
        },
    },
};
// @ts-ignore -bug in mui fails to handle textTransform.
export var theme = createTheme(__assign(__assign(__assign({}, themeOptions), themeAdditions), themeOverrides));

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { Routes } from './Routes';
import { blankUserState, UserContext, UserContextUtils } from 'lib/auth/user-context';
import { theme } from './style/theme';
import { Toaster } from 'ui-lib';
function App() {
    var _a = useState(blankUserState), userState = _a[0], setUserState = _a[1];
    return (_jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsx(Toaster, { children: _jsx(UserContext.Provider, __assign({ value: { userState: userState, setUserState: setUserState, $: new UserContextUtils(userState.me) } }, { children: _jsx(Routes, {}) })) }) })));
}
export default App;

import {
  CircleOfCareRole,
  CircleOfCareRoleLocalized,
  CircleOfCareRoles,
  CircleOfCareAssignment,
  UserType,
  IdentityVisibilityType,
} from './circle-of-care-role';
import { MultiEnvLogger } from './MultiEnvLogger';
import * as _ from 'lodash';
import { LocalizedString, SupportedLanguage, getLocalizedString } from './localized-string';

export interface RoleVisibilityComparisonInput {
  viewerRoleId: string;
  viewedRoleId: string;
  roles: CircleOfCareRoles;
}
export class RoleUtil {
  public static enumDisplayName(enumText: string): string {
    return _.startCase(enumText?.toLowerCase());
  }

  public static isLegacyRoleFormat(roles: any): boolean {
    if (typeof roles === 'string') {
      roles = JSON.parse(roles);
    }
    if (Array.isArray(roles)) {
      if (typeof roles[0] === 'string') {
        return true;
      } else {
        return false;
      }
    }
    MultiEnvLogger.error('RoleUtil.isLegacyRoleFormat', `Unexpected roles format ${MultiEnvLogger.safeStringIfy(roles)}`);
    return false;
  }

  public static checkAndConvertCocRoles(roles: any): CircleOfCareRoles {
    if (RoleUtil.isLegacyRoleFormat(roles)) {
      throw new Error('Legacy roles format not supported');
    }
    return roles || [];
  }

  public static defaultRolesFromArray(roles: string[]): CircleOfCareRoles {
    // Legacy Enum:
    //
    // export enum EnumCircleOfCareAssignmentRole {
    //   PATIENT_OR_DELEGATE = 'PATIENT_OR_DELEGATE',
    //   PROVIDER = 'PROVIDER',
    //   PHYSICIAN_PRIMARY = 'PHYSICIAN_PRIMARY',
    //   PHYSICIAN_OTHER = 'PHYSICIAN_OTHER',
    //   PHYSIOTHERAPIST = 'PHYSIOTHERAPIST',
    //   SOCIAL_WORKER = 'SOCIAL_WORKER',
    //   PSYCHOLOGIST = 'PSYCHOLOGIST',
    //   PSYCHIATRIST = 'PSYCHIATRIST',
    //   CBT_GROUP_COLEADER = 'CBT_GROUP_COLEADER',
    //   ORTHOPAEDIC_CARE_NAVIGATOR = 'ORTHOPAEDIC_CARE_NAVIGATOR',
    //   ADVANCED_PRACTICE_PHYSIOTHERAPIST = 'ADVANCED_PRACTICE_PHYSIOTHERAPIST',
    //   ORTHOPEDIC_SURGEON = 'ORTHOPEDIC_SURGEON',
    //   OCCUPATIONAL_THERAPIST = 'OCCUPATIONAL_THERAPIST',
    //   CLINICIAN = 'CLINICIAN',
    //   ADMINISTRATION = 'ADMINISTRATION',
    //   TECHNICIAN = 'TECHNICIAN',
    //   DERMATOLOGIST = 'DERMATOLOGIST',
    // }
    return roles.map((role) => ({
      id: role,
      name: [{ l: 'en', s: RoleUtil.enumDisplayName(role) }],
      description: [{ l: 'en', s: '' }],
      userTypes: role === 'PATIENT_OR_DELEGATE' ? ['USER'] : ['ADMIN', 'STAFF'],
      primary: role === 'PATIENT_OR_DELEGATE',
      required: false,
      visibility: 'DEFAULT',
      chatSettings: 'NOTIFICATIONS_NEVER',
    }));
  }

  public static localizeRole(role: CircleOfCareRole, language: SupportedLanguage = 'en'): CircleOfCareRoleLocalized {
    return {
      id: role.id,
      name: getLocalizedString(role.name, language),
      description: getLocalizedString(role.description, language),
      userTypes: role.userTypes,
      primary: role.primary,
      required: role.required || false,
      visibility: role.visibility || 'DEFAULT',
    };
  }

  public static getRole(roles: CircleOfCareRoles, roleId: string): CircleOfCareRole | undefined {
    return roles.find((r) => r.id === roleId);
  }

  public static deleteRole(roles: CircleOfCareRoles, roleId: string): CircleOfCareRoles {
    return roles.filter((r) => r.id !== roleId);
  }

  public static isUserType(role: CircleOfCareRole, userType: UserType): boolean {
    return role.userTypes.find((u) => u === userType) !== undefined;
  }

  public static isLeadUser(role: CircleOfCareRole): boolean {
    return RoleUtil.isUserType(role, 'USER') && role.primary;
  }

  public static isLeadStaff(role: CircleOfCareRole): boolean {
    return RoleUtil.isUserType(role, 'STAFF') && role.primary;
  }

  public static isPrimary(roles: CircleOfCareRoles, roleId: string): boolean {
    const role = RoleUtil.getRole(roles, roleId);
    if (!role) {
      return false;
    }
    return role.primary;
  }

  public static getLeadRole(planRoles: CircleOfCareRoles): CircleOfCareRole | undefined {
    return planRoles.find((r) => RoleUtil.isLeadStaff(r));
  }

  public static getPrimaryRole(planRoles: CircleOfCareRoles): CircleOfCareRole | undefined {
    const leadUser = planRoles.find((r) => RoleUtil.isLeadUser(r));
    if (leadUser) {
      return leadUser;
    }
    return planRoles.find((r) => RoleUtil.isLeadStaff(r));
  }

  public static getPatientOrDelegateRole(planRoles: CircleOfCareRoles): CircleOfCareRole | undefined {
    return planRoles.find((r) => RoleUtil.isLeadUser(r));
  }

  public static getLeadAssignment<T extends { roleId: string }>(
    planRoles: CircleOfCareRoles | undefined,
    assignments: T[] | undefined
  ): T | undefined {
    if (!planRoles || !assignments) {
      return undefined;
    }
    const leadRole = RoleUtil.getLeadRole(planRoles);
    if (leadRole) {
      for (const assignment of assignments) {
        if (assignment.roleId === leadRole.id) {
          return assignment;
        }
      }
    }
  }

  private static visibilityRank(role: RoleVisibilityComparisonInput): { rank: number; visibility: IdentityVisibilityType } {
    const { viewerRoleId, viewedRoleId, roles } = role;
    const relevantRole = roles.find((r) => r.id === viewedRoleId);
    if (!relevantRole) {
      throw new Error(`Role ${viewedRoleId} not found in role visibility comparison role=${JSON.stringify(role)}}`);
    }
    if (relevantRole.visibility === 'FIRST_NAME_ONLY') {
      return { rank: 0, visibility: 'FIRST_NAME_ONLY' };
    }
    return { rank: 1, visibility: 'DEFAULT' };
  }

  public static getMostPermissiveRoleVisibility(roles: RoleVisibilityComparisonInput[]): IdentityVisibilityType {
    let retValVisibility: IdentityVisibilityType = 'DEFAULT';
    let visibilityRank = 0;
    for (const role of roles) {
      const { rank, visibility } = RoleUtil.visibilityRank(role);
      if (rank >= visibilityRank) {
        retValVisibility = visibility;
        visibilityRank = rank;
      }
    }
    return retValVisibility;
  }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import gfm from 'remark-gfm';
import { ROUTES } from 'Routes';
import { MarkdownStyles } from 'style/markdownStyles';
import helpMd from './content/help.md';
import touMd from './content/tou.md';
import ppMd from './content/pp.md';
import aboutMd from './content/about.md';
export var RESOURCE_NAMES;
(function (RESOURCE_NAMES) {
    RESOURCE_NAMES["HELP"] = "HELP";
    RESOURCE_NAMES["TOU"] = "TOU";
    RESOURCE_NAMES["PP"] = "PP";
    RESOURCE_NAMES["ABOUT"] = "ABOUT";
})(RESOURCE_NAMES || (RESOURCE_NAMES = {}));
export function ResourcePage(props) {
    var navigate = useNavigate();
    var _a = React.useState(0), clickCount = _a[0], setClickCount = _a[1];
    var resetScroll = function () {
        window.scrollTo(0, 0);
    };
    var _b = useState(''), markdownContent = _b[0], setMarkdownContent = _b[1];
    // https://stackoverflow.com/questions/65395125/how-to-load-an-md-file-on-build-when-using-create-react-app-and-typescript
    useEffect(function () {
        var markdown = function () {
            switch (props.resourceName) {
                case RESOURCE_NAMES.HELP:
                    return helpMd;
                case RESOURCE_NAMES.TOU:
                    return touMd;
                case RESOURCE_NAMES.PP:
                    return ppMd;
                case RESOURCE_NAMES.ABOUT:
                    return aboutMd;
            }
        };
        fetch(markdown())
            .then(function (response) { return response.text(); })
            .then(function (text) {
            resetScroll();
            setMarkdownContent(text);
        });
    }, [props.resourceName]);
    return (_jsxs(Grid, __assign({ container: true, direction: "row", justifyContent: "center", alignItems: "center", margin: "auto" }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 10, lg: 8 }, { children: _jsx(Box, __assign({ mt: 6, mb: 2 }, { children: _jsx(MarkdownStyles, { children: _jsx(ReactMarkdown, { remarkPlugins: [gfm], children: markdownContent }) }) })) })), props.resourceName === RESOURCE_NAMES.ABOUT && (_jsx(_Fragment, { children: _jsx(Button, __assign({ style: { width: '100%', height: '100px' }, onClick: function () {
                        if (clickCount === 3) {
                            navigate(ROUTES.USER_INFO);
                            setClickCount(0);
                        }
                        setClickCount(clickCount + 1);
                    } }, { children: "\u00A0" })) }))] })));
}

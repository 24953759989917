import { getLocalizedString, LocalizedString } from './localized-string';

export interface WorkQueueItemDataPayload {
  updatePayload?: WorkQueueItemUpdateData;
  createPayload?: WorkQueueItemCreateData;
  action: string;
}

export interface WorkQueueItemUpdateData {
  priority: number | null | undefined;
  serviceLocation: string | null | undefined;
  status: string | null | undefined;
  openBy: string | null | undefined;
  workQueueItemId: string;
  updateCount: number;
  notes: string | null | undefined;
  queuePosition: number | null | undefined;
  ticketNumber: string | null | undefined;
  createdAt: Date;
  updatedAt: Date;
  simulationId: string | null | undefined;
  planId: string;
  templateName: string;
  workQueueName: string;
  orgId: string;
  User: WorkQueueItemUser;
  sessionId?: string | null;
}

export interface WorkQueueItemUser {
  id: string;
  title?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  avatarColour?: string | null;
  avatarLink?: string | null;
  email?: string | null;
  phone?: string | null;
  dateOfBirth?: Date | null;
}

export interface WorkQueueItemCreateData extends WorkQueueItemUpdateData {
  status: string;
  queuePosition: number;
  createdAt: Date;
  userId: string;
}

export interface WorkQueueStatus {
  id: string;
  label: LocalizedString;
  changeToLabel: LocalizedString;
  description?: LocalizedString | null;
  step: number;
  autoCloseHours?: number | null;
  serviceLocationsActive?: boolean | null;
  serviceLocations?: ServiceLocations | null;
}

export type WorkQueueStatuses = WorkQueueStatus[];

export interface WorkQueueStatusLocalized {
  id: string;
  label: string;
  changeToLabel: string;
  description?: string | null;
  step: number;
  autoCloseHours?: number | null;
}

export interface WorkQueueTemplateNamePair {
  workQueueName: string;
  templateName: string;
}

export const workQueueTemplateNamePairToString = (pair: WorkQueueTemplateNamePair | undefined): string => {
  if (!pair) {
    return '';
  }
  return `${pair.workQueueName}/${pair.templateName}`;
};

export const parseWorkQueueTemplateNamePair = (str: string): WorkQueueTemplateNamePair | undefined => {
  if (!str) {
    return undefined;
  }
  const [workQueueName, templateName] = str.split('/');
  return { workQueueName, templateName };
};

export interface ServiceLocation {
  id: string;
  title: LocalizedString;
  description?: LocalizedString;
}

export interface ServiceLocationLocalized {
  id: string;
  title: string;
  description?: string;
}

export function getLocalizedServiceLocation(serviceLocation: ServiceLocation): ServiceLocationLocalized {
  return {
    id: serviceLocation.id,
    title: getLocalizedString(serviceLocation.title),
    description: getLocalizedString(serviceLocation.description),
  };
}

export type ServiceLocations = ServiceLocation[];

export enum FrequencyType {
  ONCE_ONLY = 'ONCE_ONLY',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  ON_DEMAND = 'ON_DEMAND',
}

// Each regular interval option converted to milliseconds
export const MILLI_FREQUENCYTYPE_INTERVALS = {
  DAILY: 86400000,
  WEEKLY: 604800000,
  MONTHLY: 2629800000,
};

export enum SystemFrequencyAnchorDateType {
  CARE_PLAN_START = 'CARE_PLAN_START',
  CARE_PLAN_END = 'CARE_PLAN_END',
}

export type FrequencyAnchorDateType = SystemFrequencyAnchorDateType | string;

export interface KeyDate {
  dateType: FrequencyAnchorDateType;
  date: Date | null;
}

export interface FrequencyParams {
  frequencyType: FrequencyType;
  anchorDateType: FrequencyAnchorDateType;
  startDaysFromAnchor: number; // Negative numbers indicate starts prior to date
  endDaysFromAnchor?: number | null | undefined; // If null | undefined, does not end
}

/**
 * Given start/end date and frequency params, determine the next start date
 */
export function nextDueDateWithFrequency(now: Date, startTimestamp: Date, endTimestamp: Date, frequencyParams: FrequencyParams): Date {
  const DAY_IN_MILLIS = 86400000;
  const NOW = now.getTime();

  if (frequencyParams.frequencyType == FrequencyType.ONCE_ONLY) return endTimestamp;
  if (frequencyParams.frequencyType == FrequencyType.ON_DEMAND) return new Date(); // TODO: what is "on demand???

  // Due on a regular interval from the start date
  const adjustedStartDate = DAY_IN_MILLIS * frequencyParams.startDaysFromAnchor + startTimestamp.getTime();
  const frequencyInterval = MILLI_FREQUENCYTYPE_INTERVALS[frequencyParams.frequencyType];
  return new Date(adjustedStartDate + Math.ceil((NOW - adjustedStartDate) / frequencyInterval) * frequencyInterval);
}

/**
 * Given start/frequency determine the start date
 */
export function startDateWithFrequency(startTimestamp: Date, frequencyParams: FrequencyParams): Date {
  const DAY_IN_MILLIS = 86400000;
  return new Date(DAY_IN_MILLIS * frequencyParams.startDaysFromAnchor + startTimestamp.getTime());
}

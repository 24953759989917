import { FormFieldComponent } from './FormParser';
import { ENTITY_ID } from './entity';
import { CircleOfCareRole } from './circle-of-care-role';

export type RuleEntry = string;
// RuleEntry is the unparsed rule cell entry. e.g. ">10", ">10, <=20", "true", "A", or even ">10 /* comment */".
// No operator means "=" and the left side is derived from the column it sits in.
// Original kept to preserve comments and formatting

export interface DecisionTableColumnHeader {
  entityId: ENTITY_ID;
  categoryIds?: string[]; // Array because user may specify field name that exists on >1 form, restricted to 1 for now
  fieldName: string;
  formFieldDataType: FormFieldComponent;
}

export enum EnumWhenToEvaluate {
  ON_CHANGE = 'ON_CHANGE',
  TIME_ELAPSED = 'TIME_ELAPSED',
}

export enum EnumEvaluationTimeUnit {
  HOUR = 'HOUR',
  DAY = 'DAY',
}

export interface WhenToEvaluate {
  event: EnumWhenToEvaluate;
  entityId: ENTITY_ID;
  timeUnit?: EnumEvaluationTimeUnit;
  timeAmount?: number;
  categoryId?: string;
  fieldName?: string;
}

export enum EnumRuleActionType {
  INTERVENTION_PROTOCOL = 'INTERVENTION_PROTOCOL',
  ADD_DATA_POINT = 'ADD_DATA_POINT',
  USER_ACTION = 'USER_ACTION',
}

export enum EnumUserRuleActionType {
  LOGOUT = 'LOGOUT',
  CLOSE_PLAN = 'CLOSE_PLAN',
}

export interface ClosePlanActionMessage {
  carePlanId: string;
}

export interface UserActionType {
  action: EnumUserRuleActionType;
  cocRoles: CircleOfCareRole[];
}

export interface RuleAction {
  actionType: EnumRuleActionType;
  interventionProtocol?: string;
  userAction?: UserActionType;
  entityToUpdate?: ENTITY_ID;
  category?: string;
  fieldName?: string;
  valueExpression?: string;
}

export interface DecisionTableRow {
  ruleName: string;
  whenToEvaluate: WhenToEvaluate;
  entries: RuleEntry[]; // One per column, (sparse matrix) in order to simplify insert, cut and paste, etc
  ruleActions: RuleAction[];
}

export interface DecisionTableDataType {
  ruleData: {
    rows: DecisionTableRow[];
    columns: DecisionTableColumnHeader[];
  };
}

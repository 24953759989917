import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { SelectorNoForm } from 'ui-lib';
import { OrgType } from 'lib/api/api-types';
import { useOrgService } from 'lib/api/use-org-service';
import { ClientLogger } from 'lib/client-logger';
import * as React from 'react';
var DEBUG = false;
export function OrgSelector(props) {
    var _a;
    var orgService = useOrgService();
    var orgsQuery = orgService.useOrgsQuery({ variables: { type: props.orgType } });
    var data = ((_a = orgsQuery.data) === null || _a === void 0 ? void 0 : _a.orgs.edges) || [];
    var choices = data.map(function (r) { return ({ id: r.node.id, name: r.node.name }); }) || [];
    var loading = orgsQuery.loading;
    var _b = React.useState(''), selected = _b[0], setSelected = _b[1];
    var label = (function (orgType) {
        switch (orgType) {
            case OrgType.APP:
                return 'Application';
            case OrgType.JURISDICTION:
                return 'Jurisdiction';
            case OrgType.PROVIDER:
                return 'Provider';
            default:
                throw new Error("Unknown org type: ".concat(orgType));
        }
    })(props.orgType);
    DEBUG &&
        ClientLogger.debug('OrgSelector', 'render', {
            choices: choices,
            label: label,
        });
    if (loading) {
        return _jsx(_Fragment, { children: " " });
    }
    return (_jsx(_Fragment, { children: _jsx(SelectorNoForm, { items: choices, label: label, onChange: function (newVal) {
                props.onChange(newVal);
                setSelected(newVal.id);
            }, value: selected }) }));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, InputAdornment, Tooltip } from '@mui/material';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { useOrgService } from 'lib/api/use-org-service';
import { ClientLogger } from 'lib/client-logger';
import { useErrorHandler } from 'lib/use-error-handler';
import { Util, Selector, Switch } from 'ui-lib';
import React from 'react';
import { dialogClasses, authConfigClasses } from '../../style/sharedCssClasses';
import InfoIcon from '@mui/icons-material/Info';
import { AuthAlgorithm, OrgAuthConfigType, CookieType } from 'verid-shared-lib';
var DEBUG = true;
function TooltipIcon(props) {
    return (_jsx(Tooltip, __assign({ title: props.text, arrow: true, placement: "top" }, { children: _jsx(InfoIcon, { sx: dialogClasses.infoIcon, fontSize: "small" }) })));
}
function SelectorField(props) {
    var choices = Util.idNamePairsFromEnum(props.data);
    return _jsx(Selector, { name: props.name, items: choices, label: props.label });
}
export function EditAuthConfig(props) {
    var _this = this;
    var orgService = useOrgService();
    var errorHandler = useErrorHandler('EditOrg');
    var baseName = props.baseName, values = props.values, setFieldValue = props.setFieldValue, editingId = props.editingId;
    if (!values.type) {
        return null;
    }
    DEBUG && ClientLogger.debug('EditAuthConfig', 'render', { props: props });
    function generateHMACSecret(authAlgorithm) {
        return __awaiter(this, void 0, void 0, function () {
            var secretResponse, generateHMACSecret;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!authAlgorithm)
                            return [2 /*return*/];
                        return [4 /*yield*/, orgService.generateHMACSecret(editingId, authAlgorithm)];
                    case 1:
                        secretResponse = _a.sent();
                        if (!secretResponse || !secretResponse.data || secretResponse.errors) {
                            errorHandler.handleErrors({ graphQLErrors: secretResponse.errors });
                            return [2 /*return*/];
                        }
                        generateHMACSecret = secretResponse.data.generateHMACSecret;
                        return [2 /*return*/, generateHMACSecret];
                }
            });
        });
    }
    return (_jsxs(_Fragment, { children: [values.type && (values.type === OrgAuthConfigType.LOCALLY_PROVIDED || values.type === OrgAuthConfigType.EXTERNALLY_PROVIDED) && (_jsxs(_Fragment, { children: [_jsx(SelectorField, { name: "".concat(baseName, ".authAlgorithm"), label: "Algorithm", data: AuthAlgorithm }), _jsxs("div", __assign({ style: authConfigClasses.secretField }, { children: [_jsx(Field, { component: TextField, name: "".concat(baseName, ".HMAC_secret"), label: "Secret (Leave blank for no change)", placeholder: "Enter HMAC_secret", value: values.HMAC_secret || '', type: "text", fullWidth: true, variant: "outlined", sx: dialogClasses.inputField }), _jsx(Button, __assign({ color: "primary", onClick: function () { return __awaiter(_this, void 0, void 0, function () { var _a, _b; return __generator(this, function (_c) {
                                    switch (_c.label) {
                                        case 0:
                                            _a = setFieldValue;
                                            _b = ["".concat(baseName, ".HMAC_secret")];
                                            return [4 /*yield*/, generateHMACSecret(values.authAlgorithm)];
                                        case 1: return [2 /*return*/, _a.apply(void 0, _b.concat([_c.sent()]))];
                                    }
                                }); }); }, size: "small", sx: authConfigClasses.generateButton, disabled: values.authAlgorithm ? false : true }, { children: "Generate HMAC" }))] })), values.type === OrgAuthConfigType.LOCALLY_PROVIDED && (_jsxs(_Fragment, { children: [_jsx(Field, { component: TextField, name: "".concat(baseName, ".jwtTokenDurationSeconds"), label: "JWT Duration (seconds)", placeholder: "Enter how long the JWT token should be valid", type: "number", fullWidth: true, variant: "outlined", sx: dialogClasses.inputField }), _jsx(Field, { component: TextField, name: "".concat(baseName, ".refreshTokenDurationMinutes"), label: "Refresh Token Duration (minutes)", placeholder: "Enter how long the refresh token should be valid", type: "number", fullWidth: true, variant: "outlined", sx: dialogClasses.inputField }), _jsx(Field, { component: TextField, name: "".concat(baseName, ".passwordResetTokenDurationMinutes"), label: "Password Reset Link Duration (minutes)", placeholder: "Enter how long the password reset token should be valid", type: "number", fullWidth: true, variant: "outlined", sx: dialogClasses.inputField })] }))] })), values.type && values.type === OrgAuthConfigType.OPEN_ID && (_jsxs(_Fragment, { children: [_jsx(Field, { component: TextField, name: "".concat(baseName, ".clientId"), label: "Client Id", placeholder: "Enter Client Id", type: "text", fullWidth: true, variant: "outlined", sx: dialogClasses.inputField, validateOnBlur: true, validateOnChange: true, InputProps: {
                            endAdornment: (_jsx(InputAdornment, __assign({ position: "end", disablePointerEvents: false }, { children: _jsx(TooltipIcon, { text: "The OpenId client_id that has been provided by your identity provider" }) }))),
                        } }), _jsxs("div", __assign({ style: authConfigClasses.secretField }, { children: [_jsx(Field, { component: TextField, name: "".concat(baseName, ".clientSecret"), label: "Client Secret", placeholder: "Client Secret (Leave blank for no change)", value: values.clientSecret || '', type: "text", fullWidth: true, variant: "outlined", sx: dialogClasses.inputField, validateOnBlur: true, validateOnChange: true, InputProps: {
                                    endAdornment: (_jsx(InputAdornment, __assign({ position: "end", disablePointerEvents: false }, { children: _jsx(TooltipIcon, { text: "The OpenId client_secret that has been provided by your identity provider" }) }))),
                                } }), _jsx(Button, __assign({ color: "primary", onClick: function () { return __awaiter(_this, void 0, void 0, function () { var _a, _b; return __generator(this, function (_c) {
                                    switch (_c.label) {
                                        case 0:
                                            _a = setFieldValue;
                                            _b = ["".concat(baseName, ".clientSecret")];
                                            return [4 /*yield*/, generateHMACSecret(AuthAlgorithm.HMAC_SHA_256)];
                                        case 1: return [2 /*return*/, _a.apply(void 0, _b.concat([_c.sent()]))];
                                    }
                                }); }); }, size: "small", sx: authConfigClasses.generateButton }, { children: "Generate Secret" }))] })), _jsx(Field, { component: TextField, name: "".concat(baseName, ".authorizationEndpoint"), label: "Authorization Endpoint", placeholder: "Enter Authorization Endpoint", type: "text", fullWidth: true, variant: "outlined", sx: dialogClasses.inputField, validateOnBlur: true, validateOnChange: true, InputProps: {
                            endAdornment: (_jsx(InputAdornment, __assign({ position: "end", disablePointerEvents: false }, { children: _jsx(TooltipIcon, { text: "The full URL to the OpenId Authorize endpoint" }) }))),
                        } }), _jsx(Field, { component: TextField, name: "".concat(baseName, ".tokenEndpoint"), label: "Token Endpoint", placeholder: "Enter Token Endpoint", type: "text", fullWidth: true, variant: "outlined", sx: dialogClasses.inputField, validateOnBlur: true, validateOnChange: true, InputProps: {
                            endAdornment: (_jsx(InputAdornment, __assign({ position: "end", disablePointerEvents: false }, { children: _jsx(TooltipIcon, { text: "The full URL to the OpenId Token endpoint" }) }))),
                        } }), _jsx(Field, { component: TextField, name: "".concat(baseName, ".userInfoEndpoint"), label: "User Info Endpoint", placeholder: "Enter User Info Endpoint", type: "text", fullWidth: true, variant: "outlined", sx: dialogClasses.inputField, validateOnBlur: true, validateOnChange: true, InputProps: {
                            endAdornment: (_jsx(InputAdornment, __assign({ position: "end", disablePointerEvents: false }, { children: _jsx(TooltipIcon, { text: "The full URL to the OpenId Userinfo endpoint" }) }))),
                        } }), _jsx(Field, { component: TextField, name: "".concat(baseName, ".buttonName"), label: "Button Name", placeholder: "Enter Button Name", type: "text", fullWidth: true, variant: "outlined", sx: dialogClasses.inputField, validateOnBlur: true, validateOnChange: true, InputProps: {
                            endAdornment: (_jsx(InputAdornment, __assign({ position: "end", disablePointerEvents: false }, { children: _jsx(TooltipIcon, { text: "The text to display in the login button" }) }))),
                        } }), _jsx(Field, { component: TextField, name: "".concat(baseName, ".loginPageSlug"), label: "Login Page Slug", placeholder: "Enter Login Page Slug", type: "text", fullWidth: true, variant: "outlined", sx: dialogClasses.inputField, validateOnBlur: true, validateOnChange: true, InputProps: {
                            endAdornment: (_jsx(InputAdornment, __assign({ position: "end", disablePointerEvents: false }, { children: _jsx(TooltipIcon, { text: "Short name shown in the URL / browser address bar" }) }))),
                        } }), _jsx(Field, { component: TextField, name: "".concat(baseName, ".openIdRoles"), label: "Open Id Roles", placeholder: "Enter Open Id Roles", type: "text", fullWidth: true, variant: "outlined", sx: dialogClasses.inputField, validateOnBlur: true, validateOnChange: true, InputProps: {
                            endAdornment: (_jsx(InputAdornment, __assign({ position: "end", disablePointerEvents: false }, { children: _jsx(TooltipIcon, { text: "Roles provided by thirdParty" }) }))),
                        } })] })), values.type && values.type === OrgAuthConfigType.IDENTITY_PROVIDER && (_jsxs(_Fragment, { children: [_jsx(Field, { component: TextField, name: "".concat(baseName, ".identityProviderId"), label: "Identity Provider Id", placeholder: "Enter Identity Provider Id", type: "text", fullWidth: true, variant: "outlined", sx: dialogClasses.inputField, validateOnBlur: true, validateOnChange: true }), _jsx(Field, { component: TextField, name: "".concat(baseName, ".identityProviderName"), label: "Identity Provider Name", placeholder: "Enter Identity Provider Name", type: "text", fullWidth: true, variant: "outlined", sx: dialogClasses.inputField, validateOnBlur: true, validateOnChange: true }), _jsxs("div", __assign({ style: authConfigClasses.secretField }, { children: [_jsx(Field, { component: TextField, name: "".concat(baseName, ".identityProviderSecret"), label: "Identity Provider Secret", placeholder: "Identity Provider Secret", value: values.identityProviderSecret || '', type: "text", fullWidth: true, variant: "outlined", sx: dialogClasses.inputField }), _jsx(Button, __assign({ color: "primary", onClick: function () { return __awaiter(_this, void 0, void 0, function () { var _a, _b; return __generator(this, function (_c) {
                                    switch (_c.label) {
                                        case 0:
                                            _a = setFieldValue;
                                            _b = ["".concat(baseName, ".identityProviderSecret")];
                                            return [4 /*yield*/, generateHMACSecret(AuthAlgorithm.HMAC_SHA_256)];
                                        case 1: return [2 /*return*/, _a.apply(void 0, _b.concat([_c.sent()]))];
                                    }
                                }); }); }, size: "small", sx: authConfigClasses.generateButton }, { children: "Generate Secret" }))] })), _jsx(Field, { component: TextField, name: "".concat(baseName, ".identityProviderKeyId"), label: "Identity Provider KeyId", placeholder: "Enter Identity Provider KeyId", type: "text", fullWidth: true, variant: "outlined", sx: dialogClasses.inputField, validateOnBlur: true, validateOnChange: true }), _jsx(Field, { component: TextField, name: "".concat(baseName, ".identityProviderClientId"), label: "Identity Provider ClientId", placeholder: "Enter Identity Provider ClientId", type: "text", fullWidth: true, variant: "outlined", sx: dialogClasses.inputField, validateOnBlur: true, validateOnChange: true }), _jsx(Field, { component: TextField, name: "".concat(baseName, ".identityProviderApiKey"), label: "Identity Provider ApiKey", placeholder: "Enter Identity Provider ApiKey", type: "text", fullWidth: true, variant: "outlined", sx: dialogClasses.inputField }), _jsx(Field, { component: TextField, name: "".concat(baseName, ".reconcileEndpoint"), label: "Reconcile Endpoint", placeholder: "Enter Reconcile Endpoint", type: "text", fullWidth: true, variant: "outlined", sx: dialogClasses.inputField, validateOnBlur: true, validateOnChange: true }), _jsx(Box, __assign({ mt: 2 }, { children: _jsx(Switch, { name: "".concat(baseName, ".reconcileTokenMode"), label: "Reconcile Token Mode" }) })), _jsx(SelectorField, { name: "".concat(baseName, ".cookieType"), label: "Set Cookie Type", data: CookieType }), values.cookieType && (_jsx(Field, { component: TextField, name: "".concat(baseName, ".cookieName"), label: "Cookie Name", placeholder: "Enter Cookie Name", type: "text", fullWidth: true, variant: "outlined", sx: dialogClasses.inputField, validateOnBlur: true, validateOnChange: true }))] }))] }));
}

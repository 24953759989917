export type SupportedLanguage = 'en';

export type OneLocalizedString = { l: SupportedLanguage | string; s: string };
export type LocalizedString = OneLocalizedString[];

export function getLocalizedString(str: LocalizedString | undefined | null, language: SupportedLanguage | string = 'en'): string {
  if (!str) {
    return '';
  }
  const localized = str.find((s) => s.l === language);
  if (localized) {
    return localized.s;
  }
  return '';
}

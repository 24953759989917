import safeJsonStringify from 'safe-json-stringify';

export class MultiEnvLogger {
  private static logger: any = console;
  public static debug(source: string, message: string, DEBUG = true) {
    if (DEBUG) {
      MultiEnvLogger.logger.log(`${new Date().toISOString()}: ${source}:${message}`);
    }
  }

  public static log(source: string, message: string): void {
    MultiEnvLogger.logger.log(`${new Date().toISOString()}: ${source}: ${message}`);
  }

  public static warning(source: string, message: string): void {
    MultiEnvLogger.logger.warn(`Warning: ${new Date().toISOString()}: ${source} ${message} `);
  }

  public static error(source: string, message: string, e?: any) {
    MultiEnvLogger.logger.error(`${new Date().toISOString()}: ${source}:${message}}`);
    if (e) {
      MultiEnvLogger.logger.error(e);
      try {
        MultiEnvLogger.logger.error(JSON.stringify(e, null, 2));
      } catch (se) {
        MultiEnvLogger.logger.error('Error stringifying error');
        MultiEnvLogger.logger.error(se);
      }
    }
  }

  public static errorToText(e: any): string {
    try {
      return JSON.stringify(e, Object.getOwnPropertyNames(e));
    } catch (_ee) {
      return MultiEnvLogger.safeStringIfy(e);
    }
  }

  public static safeStringIfy(o: any): string {
    return safeJsonStringify(o, null, 2);
  }
}

import { FormFieldDataType } from './FormParser';
export interface IntegrationTemplateField {
  name: string;
  type: FormFieldDataType;
}

export type IntegrationTemplateFields = IntegrationTemplateField[];

export function checkIntegrationTemplateFields(fields: any): IntegrationTemplateFields {
  if (!fields) {
    return [];
  }
  if (!Array.isArray(fields)) {
    throw new Error('Integration template fields must be an array');
  }
  for (const field of fields) {
    if (!field.name || !field.type) {
      throw new Error('Integration template field must have name and type');
    }
  }
  return fields;
}

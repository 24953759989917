var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Dialog, Grid } from '@mui/material';
import React, { useState } from 'react';
import { DataTable } from 'ui-lib';
import { ClientLogger } from 'lib/client-logger';
import { TopBackBtnBar } from '../../components/navigation/TopBackBtnBar';
import makeStyles from '@mui/styles/makeStyles';
import { tableClasses } from '../../style/sharedCssClasses';
import { useTeamMemberService } from 'lib/api/use-team-member-service';
import { PersonOrgSearch } from 'pages/person-org/PersonOrgSearch';
import { EditTeamMember } from './EditTeamMember';
import { useErrorHandler } from 'lib/use-error-handler';
import { Util } from 'verid-shared-lib';
var useClasses = makeStyles(__assign({}, tableClasses));
var DEBUG = false;
export var TeamMemberList = function (props) {
    var classes = useClasses();
    var teamMemberService = useTeamMemberService();
    var _a = useState(false), editMemberOpen = _a[0], setEditMemberOpen = _a[1]; //false not open, undefined open with new, string teamId beiong edited
    var _b = useState(undefined), editingTeamMember = _b[0], setEditingTeamMember = _b[1];
    var errorHandler = useErrorHandler('TeamMemberList');
    var dataTableOptions = {
        pagination: true,
        download: true,
        print: true,
        filter: false,
        search: false,
        viewColumns: false,
        sort: true,
        selectableRows: 'none',
    };
    var columns = {
        id: { display: 'excluded' },
        name: { label: 'Name' },
        description: { display: 'excluded' },
        updatedAt: { display: 'excluded' },
        createdAt: { display: 'excluded' },
        status: { display: 'excluded' },
        title: { display: 'excluded' },
        firstName: { label: 'First Name' },
        middleName: { display: 'excluded' },
        lastName: { label: 'Last Name' },
        orgName: { label: 'Organization' },
        role: { label: 'Role' },
        buttons: {
            label: '',
            customBodyRender: function (value) {
                if (value !== undefined && value !== null) {
                    return (_jsx(_Fragment, { children: _jsx(Button, __assign({ color: "primary", variant: "contained", onClick: function () {
                                DEBUG && ClientLogger.debug('custom render click', '', value);
                                setEditMemberOpen(value.teamMember.id);
                                setEditingTeamMember(value.teamMember);
                            } }, { children: "Edit" })) }));
                }
                else {
                    return _jsx(_Fragment, {});
                }
            },
            customHeadRender: function () { return _jsx("td", {}, "edit"); },
        },
    };
    var transformResponseToRows = function (resp) {
        var _a;
        if (!resp || !resp.teamMembers || !((_a = resp.teamMembers) === null || _a === void 0 ? void 0 : _a.edges)) {
            return [];
        }
        return resp.teamMembers.edges.map(function (teamMember) { return (__assign(__assign({}, teamMember.node), { buttons: { teamMember: teamMember.node } })); });
    };
    var tableProps = {
        useQuery: teamMemberService.useTeamMemberQuery({ variables: { teamId: props.teamId } }),
        initialQuery: {},
        transformResponseToRows: transformResponseToRows,
        columnAttributes: columns,
        tableAttributes: dataTableOptions,
        emptyMessage: 'No team members found.  Click the + button to add a new team member.',
        onAdd: function () {
            DEBUG && ClientLogger.debug('onAdd', '', {});
            setEditMemberOpen(undefined);
        },
    };
    function addMember(person, role) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var personOrgId, resp;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        personOrgId = (_a = person.personOrgs.find(function (po) { return po.orgId; })) === null || _a === void 0 ? void 0 : _a.id;
                        if (!personOrgId) {
                            throw new Error('No personOrgId found for person');
                        }
                        DEBUG && ClientLogger.debug('addMember', '', { person: person });
                        return [4 /*yield*/, teamMemberService.teamMemberCreate({
                                teamId: props.teamId,
                                role: role,
                                personOrgId: personOrgId,
                            })];
                    case 1:
                        resp = _b.sent();
                        if (resp.errors) {
                            errorHandler.handleMutateResponse(resp);
                        }
                        setEditMemberOpen(false);
                        return [2 /*return*/];
                }
            });
        });
    }
    DEBUG && ClientLogger.debug('OrgList', 'render', { tableProps: tableProps, props: props });
    return (_jsxs(_Fragment, { children: [_jsxs(Grid, __assign({ container: true }, { children: [_jsx(TopBackBtnBar, { title: props.title }), _jsx(Box, __assign({ mx: 2, mt: 6, mb: 1, className: classes.tableHolder }, { children: _jsx(DataTable, __assign({}, tableProps)) }))] })), _jsx(Dialog, __assign({ open: !(editMemberOpen === false), onClose: function () { return setEditMemberOpen(false); }, maxWidth: "sm", fullWidth: true }, { children: _jsx(EditTeamMember, { onClose: function () {
                        setEditMemberOpen(false);
                    }, editingId: editMemberOpen || '', dialogTitle: "Editing ".concat(Util.nameObjToString(editingTeamMember)) }) })), _jsx(Dialog, __assign({ open: editMemberOpen === undefined, onClose: function () { return setEditMemberOpen(false); }, maxWidth: "sm", fullWidth: true }, { children: _jsx(PersonOrgSearch, { onClose: function () {
                        setEditMemberOpen(false);
                    }, onSelect: function (person, role) {
                        addMember(person, role);
                    }, buttons: [
                        { id: 'ADMIN', name: 'Add as Admin' },
                        { id: 'STAFF', name: 'Add as Staff' },
                    ] }) }))] }));
};

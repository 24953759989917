var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { SEVERITY, ToastDispatchContext } from 'ui-lib';
import MUIDataTable from 'mui-datatables';
import React, { useState, useContext } from 'react';
import CloseIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { OrgAuthTypes } from 'verid-shared-lib';
import { useOrgService } from 'lib/api/use-org-service';
import { ClientLogger } from 'lib/client-logger';
import { useErrorHandler } from 'lib/use-error-handler';
import { FormContainer } from 'components/form/FormContainer';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { Selector, Util } from 'ui-lib';
import { dialogClasses } from 'style/sharedCssClasses';
var DEBUG = false;
function EditEmailDomainModal(props) {
    var _this = this;
    console.log('AuthConfigModal: ', props.initialValues);
    var choices = Util.idNamePairsFromEnum(OrgAuthTypes);
    return (_jsx(_Fragment, { children: _jsx(Formik, __assign({ initialValues: props.initialValues || {}, onSubmit: function (values, actions) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    props.onSubmit(values);
                    return [2 /*return*/];
                });
            }); } }, { children: function (_a) {
                var submitForm = _a.submitForm, values = _a.values, setFieldValue = _a.setFieldValue, errors = _a.errors, touched = _a.touched, validateForm = _a.validateForm, setFieldTouched = _a.setFieldTouched;
                return (_jsx(Box, { children: _jsxs(FormContainer, { children: [_jsxs(DialogTitle, __assign({ display: "flex", justifyContent: "space-between", alignItems: "center", minWidth: '90%' }, { children: [_jsx(Box, __assign({ display: "flex", justifyContent: "space-between", alignItems: "center" }, { children: _jsx(Typography, __assign({ variant: "h5" }, { children: props.isUpdate ? ' Update Org Domain' : 'Create Org Domain' })) })), _jsx(IconButton, __assign({ style: { float: 'right' }, onClick: props.onClose, size: "large" }, { children: _jsx(CloseIcon, { sx: dialogClasses.closeIcon }) }))] })), _jsxs(DialogContent, { children: [_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Selector, { name: "type", items: choices, label: "Select User Type", selectMultiple: false }) })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Field, { component: TextField, name: "emailDomain", label: "Email Domain", placeholder: "Enter Email Domain", type: "text", fullWidth: true, variant: "outlined" }) }))] }), _jsx(DialogActions, { children: _jsxs(Box, __assign({ display: "flex", justifyContent: "space-between", alignItems: "center" }, { children: [_jsx(Button, __assign({ color: "primary", onClick: function () { return props.onClose(); } }, { children: "Cancel" })), _jsxs(Button, __assign({ color: "primary", onClick: function () {
                                                props.onSubmit(values);
                                            } }, { children: [' ', props.isUpdate ? ' Update' : 'Create'] }))] })) })] }) }));
            } })) }));
}
export var EditOrgDomain = function (props) {
    var _a = useState([]), rowsSelected = _a[0], setRowsSelected = _a[1];
    var _b = useState(false), editingDomain = _b[0], setEditingDomain = _b[1];
    var _c = useState(false), removeDomain = _c[0], setRemoveDomain = _c[1];
    var _d = useState(null), selectedDomainType = _d[0], setSelectedDomainType = _d[1];
    var _e = useState(props.emailDomain), emailDomain = _e[0], setEmailDomain = _e[1];
    var orgService = useOrgService();
    var errorHandler = useErrorHandler('EditOrg');
    var toastDispatch = useContext(ToastDispatchContext);
    var scenarioDataTableOptions = {
        pagination: false,
        download: false,
        print: false,
        filter: false,
        search: false,
        viewColumns: false,
        sort: true,
        selectableRows: 'none',
        rowsSelected: rowsSelected,
        textLabels: {
            body: {
                noMatch: 'No Mapping Found',
            },
        },
    };
    var columns = [
        { name: 'type', label: 'User Type' },
        { name: 'emailDomain', label: 'Email Domain' },
        {
            name: 'edit',
            label: 'Edit',
            options: {
                customBodyRender: function (value, tableMeta) {
                    var rowData = tableMeta.rowData;
                    return (_jsx(Button, __assign({ color: "primary", variant: "contained", onClick: function () {
                            setSelectedDomainType(rowData[0]);
                            setEditingDomain(true);
                        } }, { children: "Edit" })));
                },
                customHeadRender: function () { return _jsx("td", {}, "edit"); },
            },
        },
        {
            name: 'remove',
            label: 'Remove',
            options: {
                customBodyRender: function (value, tableMeta) {
                    console.log('value and tableMeta: ', { value: value, tableMeta: tableMeta });
                    var rowData = tableMeta.rowData;
                    return (_jsx(Box, __assign({ display: "flex", justifyContent: "space-evenly", alignItems: "center" }, { children: _jsx(IconButton, __assign({ onClick: function () {
                                DEBUG && ClientLogger.debug('custom render click', '', value);
                                setSelectedDomainType(rowData[0]);
                                setRemoveDomain(true);
                            }, color: "primary" }, { children: _jsx(DeleteIcon, {}) })) })));
                },
                customHeadRender: function () { return _jsx("td", {}, "remove"); },
            },
        },
    ];
    var data = emailDomain
        ? Object.keys(emailDomain).map(function (type) { return ({
            type: type,
            emailDomain: emailDomain[type] || '',
        }); })
        : [];
    function onAddDomain(value) {
        return __awaiter(this, void 0, void 0, function () {
            var newEmailDomain;
            var _a;
            return __generator(this, function (_b) {
                if (!value.type) {
                    toastDispatch({ severity: SEVERITY.ERROR, msg: "Must be select any user type" });
                    return [2 /*return*/];
                }
                if (!value.emailDomain) {
                    toastDispatch({ severity: SEVERITY.ERROR, msg: "Must be provide Email Domain" });
                    return [2 /*return*/];
                }
                newEmailDomain = __assign(__assign({}, emailDomain), (_a = {}, _a[value.type] = value.emailDomain, _a));
                setEmailDomain(newEmailDomain);
                onClearDomain();
                return [2 /*return*/];
            });
        });
    }
    function onClearDomain() {
        setSelectedDomainType(null);
        if (editingDomain)
            setEditingDomain(false);
        if (removeDomain)
            setRemoveDomain(false);
    }
    function onRemoveDomain() {
        if (selectedDomainType === null || !emailDomain || !emailDomain[selectedDomainType]) {
            toastDispatch({ severity: SEVERITY.ERROR, msg: "Must be select any domain type" });
            return;
        }
        var newEmailDomain = __assign({}, emailDomain);
        delete newEmailDomain[selectedDomainType];
        setEmailDomain(newEmailDomain);
        onClearDomain();
    }
    function onSubmit() {
        return __awaiter(this, void 0, void 0, function () {
            var updatedResponse, id;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(props.editingId && emailDomain)) return [3 /*break*/, 2];
                        return [4 /*yield*/, orgService.orgUpdate(props.editingId, { emailDomain: __assign({}, emailDomain) })];
                    case 1:
                        updatedResponse = _a.sent();
                        DEBUG && ClientLogger.debug('EditOrg', 'update org', { resp: updatedResponse });
                        if (!updatedResponse || !updatedResponse.data || updatedResponse.errors) {
                            errorHandler.handleErrors({ graphQLErrors: updatedResponse.errors });
                            return [2 /*return*/];
                        }
                        id = updatedResponse.data.orgUpdate.id;
                        if (id) {
                            toastDispatch({ severity: SEVERITY.SUCCESS, msg: "Org Domain updated successfully" });
                            props.onClose();
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    }
    return (_jsxs(_Fragment, { children: [_jsxs(Grid, __assign({ item: true, xs: 12 }, { children: [_jsx(IconButton, __assign({ style: { float: 'right' }, onClick: props.onClose, size: "large" }, { children: _jsx(CloseIcon, { sx: dialogClasses.closeIcon }) })), _jsx(MUIDataTable, { title: "", options: scenarioDataTableOptions, columns: columns, data: data }), _jsx(DialogActions, { children: _jsx(IconButton, __assign({ style: { float: 'right', background: '#00539D', color: '#ffffff' }, onClick: function () {
                                setEditingDomain(true);
                                setSelectedDomainType(null);
                            }, size: "large" }, { children: _jsx(AddIcon, {}) })) }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ onClick: function () { return props.onClose(); }, color: "primary" }, { children: "Cancel" })), _jsx(Button, __assign({ onClick: function () { return onSubmit(); }, color: "primary" }, { children: "Save" }))] })] })), _jsx(Dialog, __assign({ open: editingDomain, onClose: function () { return onClearDomain(); }, maxWidth: "sm", fullWidth: true }, { children: _jsx(EditEmailDomainModal, { onClose: function () { return onClearDomain(); }, editingId: props.editingId, initialValues: !!selectedDomainType ? { type: selectedDomainType, emailDomain: emailDomain[selectedDomainType] } : null, isUpdate: !!selectedDomainType && !!emailDomain[selectedDomainType], onSubmit: onAddDomain }) })), _jsxs(Dialog, __assign({ maxWidth: "sm", fullWidth: true, open: removeDomain }, { children: [_jsx(DialogContent, { children: "Are you sure you want to delete" }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ onClick: function () { return onClearDomain(); }, color: "primary" }, { children: "No" })), _jsx(Button, __assign({ color: "primary", autoFocus: true, onClick: function () { return onRemoveDomain(); } }, { children: "Yes" }))] })] }))] }));
};

import {
  ANTLRInputStream,
  CommonTokenStream,
  ANTLRErrorListener,
  RecognitionException,
  Recognizer,
  CharStream,
  CharStreams,
  Token,
  ParserRuleContext,
} from 'antlr4ts';

import { AbstractParseTreeVisitor } from 'antlr4ts/tree/AbstractParseTreeVisitor';

import {
  NowContext,
  Years_elementContext,
  Month_elementContext,
  Weeks_elementContext,
  Days_elementContext,
  Hours_elementContext,
  Minutes_elementContext,
  Seconds_elementContext,
  Duration_elementContext,
  Print_exprContext,
  UndefinedContext,
  Null_exprContext,
  Max_exprContext,
  Min_exprContext,
  OptionsContext,
  Include_secondsContext,
  Add_suffixContext,
  Date_format_distance_funcContext,
  Option_elementContext,
} from './lib/ExpressionsParserGrammarParser';

import { DurationContext } from './lib/ExpressionsParserGrammarParser';
import { DateAddFuncContext } from './lib/ExpressionsParserGrammarParser';
import { DateSubtractFuncContext } from './lib/ExpressionsParserGrammarParser';
import { Not_exprContext } from './lib/ExpressionsParserGrammarParser';
import { Or_exprContext } from './lib/ExpressionsParserGrammarParser';
import { And_exprContext } from './lib/ExpressionsParserGrammarParser';
import { Add_exprContext } from './lib/ExpressionsParserGrammarParser';
import { Dif_in_minutesContext } from './lib/ExpressionsParserGrammarParser';
import { Dif_in_hoursContext } from './lib/ExpressionsParserGrammarParser';
import { Dif_in_daysContext } from './lib/ExpressionsParserGrammarParser';
import { DateContext } from './lib/ExpressionsParserGrammarParser';
import { Mul_div_exprContext } from './lib/ExpressionsParserGrammarParser';
import { Brackets_exprContext } from './lib/ExpressionsParserGrammarParser';
import { Power_exprContext } from './lib/ExpressionsParserGrammarParser';
import { First_val_funcContext } from './lib/ExpressionsParserGrammarParser';
import { First_str_funcContext } from './lib/ExpressionsParserGrammarParser';
import { Last_val_funcContext } from './lib/ExpressionsParserGrammarParser';
import { Last_str_funcContext } from './lib/ExpressionsParserGrammarParser';
import { Submission_val_funcContext } from './lib/ExpressionsParserGrammarParser';
import { Submission_str_funcContext } from './lib/ExpressionsParserGrammarParser';
import { Date_format_funcContext } from './lib/ExpressionsParserGrammarParser';
import { Process_numberContext } from './lib/ExpressionsParserGrammarParser';
import { Process_strContext } from './lib/ExpressionsParserGrammarParser';
import { ExpressionContext } from './lib/ExpressionsParserGrammarParser';
import { Equality_operationContext } from './lib/ExpressionsParserGrammarParser';
import { Identity_operationContext } from './lib/ExpressionsParserGrammarParser';
import { Process_boolContext } from './lib/ExpressionsParserGrammarParser';
import { Question_exprContext } from './lib/ExpressionsParserGrammarParser';

import { ExpressionsParserGrammarParser } from './lib/ExpressionsParserGrammarParser';
import { ExpressionsParserGrammarLexer } from './lib/ExpressionsParserGrammarLexer';

import { ExpressionsParserGrammarVisitor } from './lib/ExpressionsParserGrammarVisitor';

import { DEFAULT_DATE_TIME_FORMAT } from './date-util';
import { format, parse, parseISO, isValid, formatDistance } from 'date-fns';
import { format as formatTz, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { differenceInHours, differenceInDays, differenceInMinutes, add, sub } from 'date-fns';

import { MultiEnvLogger } from './MultiEnvLogger';
import { ParseTree } from 'antlr4ts/tree/ParseTree';

const DEBUG_DATE = false;

export { ExpressionContext } from './lib/ExpressionsParserGrammarParser';
export interface ILanguageError {
  startLineNumber: number;
  startColumn: number;
  endLineNumber: number;
  endColumn: number;
  message: string;
  code: string;
}

interface Callbacks {
  firstVal: (fieldSpec: string, config?: string) => Promise<any>;
  firstStr: (fieldSpec: string) => Promise<string>;
  lastVal: (fieldSpec: string, config?: string) => Promise<any>;
  lastStr: (fieldSpec: string) => Promise<string>;
  submissionVal: (fieldSpec: string) => Promise<any>;
  submissionStr: (fieldSpec: string, format?: string) => Promise<string>;
  dateFormat: (fieldSpec: string, format?: string) => string;
}

interface TimeValue {
  timeKey: string;
  timeValue: number;
}

interface OptionValue {
  optionKey: string;
  optionValue: boolean;
}

export interface ExpressionResult extends ExpressionParserResult {
  value: any;
  lexResult: any;
}

export interface ExpressionParserResult {
  ast: ExpressionContext;
  parseErrors: ILanguageError[];
  lexErrors: ILanguageError[];
  hasError: boolean;
}

function getStringValue(t: Token): string {
  var lastFuncStr: string = t != undefined ? (t.text as string) : '';
  lastFuncStr = lastFuncStr.substring(1, lastFuncStr.length - 1);
  return lastFuncStr;
}

function getStringValueRule(ctx: ParserRuleContext): string {
  var lastFuncStr: string = ctx.text as string;
  lastFuncStr = lastFuncStr.substring(1, lastFuncStr.length - 1);
  return lastFuncStr;
}

class ExpressionsErrorListener implements ANTLRErrorListener<any> {
  private errors: ILanguageError[] = [];
  syntaxError(
    recognizer: Recognizer<any, any>,
    offendingSymbol: any,
    line: number,
    charPositionInLine: number,
    message: string,
    e: RecognitionException | undefined
  ): void {
    this.errors.push({
      startLineNumber: line,
      endLineNumber: line,
      startColumn: charPositionInLine,
      endColumn: charPositionInLine + 1, //Let's suppose the length of the error is only 1 char for simplicity
      message,
      code: '1', // This the error code you can customize them as you want
    });
  }

  getErrors(): ILanguageError[] {
    return this.errors;
  }
}

function parseDateIfNeeded(date: string | Date): Date {
  if (typeof date === 'string') {
    return parseISO(date);
  }
  return date;
}

class ExpressionParserVisitor extends AbstractParseTreeVisitor<any> implements ExpressionsParserGrammarVisitor<any> {
  private _dateFormat: (fieldSpec: string, format?: string) => string;
  private _asAt: Date | undefined;
  private _callbacks: Callbacks;
  _firstValMap: Map<string, any> = new Map();
  _firstStrMap: Map<string, any> = new Map();
  _lastValMap: Map<string, any> = new Map();
  _lastStrMap: Map<string, any> = new Map();
  _submissionValMap: Map<string, any> = new Map();
  _submissionStrMap: Map<string, any> = new Map();
  constructor(dateFormat: (fieldSpec: string, format?: string) => string, callbacks: Callbacks, asAt?: Date) {
    super();
    DEBUG_DATE && MultiEnvLogger.debug('ExpressionParserVisitor Constructor', JSON.stringify({ asAt: asAt }));

    this._dateFormat = dateFormat;
    this._asAt = asAt;
    this._callbacks = callbacks;
  }

  //	async visit(/*@NotNull*/ tree: ParseTree) {
  //		return await super.visit(tree)
  //	}

  protected defaultResult() {
    throw new Error('Method not implemented.');
  }

  /**
   * Visit a parse tree produced by the `not_expr`
   * labeled alternative in `ExpressionsParserGrammarParser.expression`.
   * @param ctx the parse tree
   * @return the visitor result
   */
  async visitNot_expr(ctx: Not_exprContext) {
    let left = await this.visit(ctx._expr);
    return !left;
  }

  /**
   * Visit a parse tree produced by the `and_expr`
   * labeled alternative in `ExpressionsParserGrammarParser.expression`.
   * @param ctx the parse tree
   * @return the visitor result
   */
  async visitAnd_expr(ctx: And_exprContext) {
    let left = await this.visit(ctx._left);
    let right = await this.visit(ctx._right);
    return left && right;
  }

  /**
   * Visit a parse tree produced by the `or_expr`
   * labeled alternative in `ExpressionsParserGrammarParser.expression`.
   * @param ctx the parse tree
   * @return the visitor result
   */
  async visitOr_expr(ctx: Or_exprContext) {
    return (await this.visit(ctx._left)) || (await this.visit(ctx._right));
  }

  visitNow(ctx: NowContext) {
    DEBUG_DATE && MultiEnvLogger.debug('now', JSON.stringify({ asAt: this._asAt, date: new Date() }));

    if (this._asAt) {
      return parseDateIfNeeded(this._asAt);
    }
    return new Date();
  }

  visitDate(ctx: DateContext) {
    var expr: ExpressionContext[] = ctx.expression();
    if (expr.length > 7 || expr.length < 2) {
      throw new Error("It can't be constructor with data'");
    }
    type DateArgs = [number, number, number?, number?, number?, number?, number?];
    const args: DateArgs = [0, 0];
    expr.map((e) => parseInt(this.visit(e))).forEach((fe, index) => (args[index] = fe));

    return new Date(...args);
  }

  async visitDif_in_minutes(ctx: Dif_in_minutesContext) {
    var leftVal = parseDateIfNeeded(await this.visit(ctx._left));
    var rightVal = parseDateIfNeeded(await this.visit(ctx._right));
    if (!(leftVal instanceof Date && rightVal instanceof Date)) {
      throw new Error('not date expression in difference in Minutes');
    }
    return differenceInMinutes(leftVal, rightVal);
  }

  async visitDif_in_days(ctx: Dif_in_daysContext) {
    var leftVal = parseDateIfNeeded(await this.visit(ctx._left));
    var rightVal = parseDateIfNeeded(await this.visit(ctx._right));
    if (typeof leftVal === 'undefined' || typeof rightVal === 'undefined') {
      return undefined;
    }
    if (!(leftVal instanceof Date && rightVal instanceof Date)) {
      const errorMessage = `not date object in difference in Days ${JSON.stringify({
        leftVal,
        leftValType: typeof leftVal,
        rightVal,
        rightValType: typeof rightVal,
      })}`;
      MultiEnvLogger.error('visitDif_in_days', errorMessage);
      throw new Error(errorMessage);
    }
    //DEBUG_DATE && MultiEnvLogger.debug('visitDif_in_days', JSON.stringify({ leftVal: leftVal, rightVal: rightVal }));
    return differenceInDays(leftVal, rightVal);
  }

  async visitDif_in_hours(ctx: Dif_in_hoursContext) {
    var leftVal = parseDateIfNeeded(await this.visit(ctx._left));
    var rightVal = parseDateIfNeeded(await this.visit(ctx._right));
    if (typeof leftVal === 'undefined' || typeof rightVal === 'undefined') {
      return undefined;
    }

    if (!(leftVal instanceof Date && rightVal instanceof Date)) {
      throw new Error('not date expression in difference in days');
    }
    return differenceInHours(leftVal, rightVal);
  }
  /**
   * Visit a parse tree produced by the `add_expr`
   * labeled alternative in `ExpressionsParserGrammarParser.expression`.
   * @param ctx the parse tree
   * @return the visitor result
   */
  async visitAdd_expr(ctx: Add_exprContext) {
    var leftResult: number = (await this.visit(ctx._left)) as number;
    var rightResult: number = (await this.visit(ctx._right)) as number;

    return ctx._plus_minus.text == '+' ? leftResult + rightResult : leftResult - rightResult;
  }

  /**
   * Visit a parse tree produced by the `mul_div_expr`
   * labeled alternative in `ExpressionsParserGrammarParser.expression`.
   * @param ctx the parse tree
   * @return the visitor result
   */
  async visitMul_div_expr(ctx: Mul_div_exprContext) {
    var leftResult: number = (await this.visit(ctx._left)) as number;
    var rightResult: number = (await this.visit(ctx._right)) as number;

    return ctx._mul_or_div.text == '*' ? leftResult * rightResult : leftResult / rightResult;
  }

  /**
   * Visit a parse tree produced by the `brackets_expr`
   * labeled alternative in `ExpressionsParserGrammarParser.expression`.
   * @param ctx the parse tree
   * @return the visitor result
   */
  async visitBrackets_expr(ctx: Brackets_exprContext) {
    return await this.visit(ctx._expr);
  }

  /**
   * Visit a parse tree produced by the `power_expr`
   * labeled alternative in `ExpressionsParserGrammarParser.expression`.
   * @param ctx the parse tree
   * @return the visitor result
   */
  async visitPower_expr(ctx: Power_exprContext) {
    return await Math.pow(await this.visit(ctx._expr1), await this.visit(ctx._expr2));
  }

  /**
   * Visit a parse tree produced by the `last_val_func`
   * labeled alternative in `ExpressionsParserGrammarParser.expression`.
   * @param ctx the parse tree
   * @return the visitor result
   */
  async visitFirst_val_func(ctx: First_val_funcContext): Promise<any> {
    var str: string = getStringValue(ctx._first_str_func);
    var firstFuncStr: string = getStringValue(ctx._first_str_func);
    var config: string | undefined = ctx._config != undefined ? getStringValue(ctx._config) : undefined;
    let firstVal;
    await this._callbacks.firstVal(firstFuncStr, config).then((val) => {
      firstVal = val;
    });

    return firstVal;
  }

  /**
   * Visit a parse tree produced by the `last_str_func`
   * labeled alternative in `ExpressionsParserGrammarParser.expression`.
   * @param ctx the parse tree
   * @return the visitor result
   */
  async visitFirst_str_func(ctx: First_str_funcContext): Promise<any> {
    var str: string = getStringValue(ctx._first_str_func);
    let firstStr;
    await this._callbacks.firstStr(str).then((val) => {
      firstStr = val;
    });

    return firstStr;
  }
  /**
   * Visit a parse tree produced by the `last_val_func`
   * labeled alternative in `ExpressionsParserGrammarParser.expression`.
   * @param ctx the parse tree
   * @return the visitor result
   */
  async visitLast_val_func(ctx: Last_val_funcContext) {
    var lastFuncStr: string = getStringValue(ctx._last_str_func);
    var config: string | undefined = ctx._config != undefined ? getStringValue(ctx._config) : undefined;

    await this._callbacks.lastVal(lastFuncStr, config).then((val) => {
      this._lastValMap.set(lastFuncStr, val);
    });
    let value = this._lastValMap.get(lastFuncStr);

    return value;
  }

  async visitSubmission_val_func(ctx: Submission_val_funcContext) {
    var submissionVal: string = getStringValue(ctx._sumission_val_str);

    await this._callbacks.submissionVal(submissionVal).then((val) => {
      this._submissionValMap.set(submissionVal, val);
    });

    return this._submissionValMap.get(submissionVal);
  }

  async visitSubmission_str_func(ctx: Submission_str_funcContext): Promise<any> {
    var str: string = getStringValue(ctx._submission_str);

    var lastStr: string = getStringValue(ctx._submission_str);
    let submissionStr;
    await this._callbacks.submissionStr(lastStr).then((val) => {
      submissionStr = val;
    });

    return submissionStr;
  }

  /**
   * Visit a parse tree produced by the `process_number`
   * labeled alternative in `ExpressionsParserGrammarParser.expression`.
   * @param ctx the parse tree
   * @return the visitor result
   */
  visitProcess_number(ctx: Process_numberContext) {
    let num = Number(ctx._numLiteral.text);
    return num;
  }

  /**
   * Visit a parse tree produced by the `process_str`
   * labeled alternative in `ExpressionsParserGrammarParser.expression`.
   * @param ctx the parse tree
   * @return the visitor result
   */
  visitProcess_str(ctx: Process_strContext): any {
    return getStringValue(ctx._strValueLiteral);
  }

  /**
   * Visit a parse tree produced by the `last_str_func`
   * labeled alternative in `ExpressionsParserGrammarParser.expression`.
   * @param ctx the parse tree
   * @return the visitor result
   */
  async visitLast_str_func(ctx: Last_str_funcContext) {
    const lastStrContent = getStringValue(ctx._last_str_func);

    let lastStr;
    await this._callbacks.lastStr(lastStrContent).then((val) => {
      lastStr = val;
    });

    return lastStr;
  }

  /**
   * Visit a parse tree produced by `ExpressionsParserGrammarParser.dateFormatFunction`.
   * @param ctx the parse tree
   * @return the visitor result
   */
  async visitDate_format_func(ctx: Date_format_funcContext) {
    const dateFormatRes = await this.visit(ctx._date_format_expr);
    const params = ctx._formatParam.text;
    const strippedQuotes = params?.split('"').join('');
    return this._dateFormat(dateFormatRes, strippedQuotes);
  }

  visitUndefined(ctx: UndefinedContext) {
    return undefined;
  }

  visitNull_expr(ctx: Null_exprContext) {
    return null;
  }
  async visitIdentity_operation(ctx: Identity_operationContext) {
    const leftRes = await this.visit(ctx._left);
    const rightRes = await this.visit(ctx._right);

    return ctx._identity_operation.text == '===' ? leftRes === rightRes : leftRes !== rightRes;
  }

  async visitEquality_operation(ctx: Equality_operationContext) {
    switch (ctx._equality_operation.text) {
      case '>': {
        return (await this.visit(ctx._left)) > (await this.visit(ctx._right));
      }
      case '<': {
        return (await this.visit(ctx._left)) < (await this.visit(ctx._right));
      }
      case '>=': {
        return (await this.visit(ctx._left)) >= (await this.visit(ctx._right));
      }
      case '<=': {
        return (await this.visit(ctx._left)) <= (await this.visit(ctx._right));
      }
      case '!=': {
        return (await this.visit(ctx._left)) != (await this.visit(ctx._right));
      }
      case '==': {
        return (await this.visit(ctx._left)) == (await this.visit(ctx._right));
      }
    }
    return undefined;
  }

  /**
   * Visit a parse tree produced by the `process_bool`
   * labeled alternative in `ExpressionsParserGrammarParser.expression`.
   * @param ctx the parse tree
   * @return the visitor result
   */
  visitProcess_bool(ctx: Process_boolContext) {
    return ctx._boolLiteral.text == 'true';
  }

  /**
   * Visit a parse tree produced by the `question_expr`
   * labeled alternative in `ExpressionsParserGrammarParser.expression`.
   * @param ctx the parse tree
   * @return the visitor result
   */
  async visitQuestion_expr(ctx: Question_exprContext) {
    const cond = await this.visit(ctx._condition);

    const retVal = cond ? await this.visit(ctx._true_exp) : await this.visit(ctx._false_exp);
    return retVal;
  }

  /**
   * Visit a parse tree produced by the `dateAddFunc`
   * labeled alternative in `ExpressionsParserGrammarParser.expression`.
   * @param ctx the parse tree
   * @return the visitor result
   */
  async visitDateAddFunc(ctx: DateAddFuncContext) {
    let visitDate = await this.visit(ctx._date);

    const date = parseDateIfNeeded(visitDate);

    if (typeof date === 'undefined') {
      return undefined;
    }
    if (!(date instanceof Date)) {
      throw new Error(`First parameter in the addDate must have the 'Date' type or iso string.  Got ${typeof ctx._date} (${ctx._date})`);
    }
    let duration: Duration = await this.visit(ctx.duration());

    return add(date, duration);
  }

  /**
   * Visit a parse tree produced by the `dateSubtractFunc`
   * labeled alternative in `ExpressionsParserGrammarParser.expression`.
   * @param ctx the parse tree
   * @return the visitor result
   */
  async visitDateSubtractFunc(ctx: DateSubtractFuncContext) {
    const date = parseDateIfNeeded(await this.visit(ctx._date));
    if (typeof date === 'undefined') {
      return undefined;
    }
    if (!(date instanceof Date)) {
      throw new Error("First parameter in the subtractDate must have the 'Date' type or iso string");
    }

    let duration: Duration = await this.visit(ctx.duration());

    return sub(date, duration);
  }

  /**
   * Visit a parse tree produced by `ExpressionsParserGrammarParser.duration`.
   * @param ctx the parse tree
   * @return the visitor result
   */

  async visitDuration(ctx: DurationContext) {
    let dataMap: Map<String, Number> = new Map([
      ['years', 0],
      ['months', 0],
      ['weeks', 0],
      ['days', 0],
      ['hours', 0],
      ['minutes', 0],
      ['seconds', 0],
    ]);

    for (var i: number = 0; i < ctx.duration_element().length; i++) {
      let el: Duration_elementContext = ctx.duration_element()[i];
      let mapToEl: any;
      await this.visit(el).then((val: any) => {
        mapToEl = val;
      });

      dataMap.set(mapToEl.timeKey, mapToEl.timeValue as number);
    }

    return {
      years: dataMap.get('years') as number,
      months: dataMap.get('months') as number,
      weeks: dataMap.get('weeks') as number,
      days: dataMap.get('days') as number,
      hours: dataMap.get('hours') as number,
      minutes: dataMap.get('minutes') as number,
      seconds: dataMap.get('seconds') as number,
    };
  }

  /**
   * Visit a parse tree produced by `ExpressionsParserGrammarParser.years_element`.
   * @param ctx the parse tree
   * @return the visitor result
   */
  async visitYears_element(ctx: Years_elementContext) {
    return {
      timeKey: 'years',
      timeValue: await this.visit(ctx._value),
    };
  }

  /**
   * Visit a parse tree produced by `ExpressionsParserGrammarParser.month_element`.
   * @param ctx the parse tree
   * @return the visitor result
   */
  async visitMonth_element(ctx: Month_elementContext) {
    return {
      timeKey: 'months',
      timeValue: await this.visit(ctx._value),
    };
  }
  /**
   * Visit a parse tree produced by `ExpressionsParserGrammarParser.weeks_element`.
   * @param ctx the parse tree
   * @return the visitor result
   */
  async visitWeeks_element(ctx: Weeks_elementContext) {
    return {
      timeKey: 'weeks',
      timeValue: await this.visit(ctx._value),
    };
  }

  /**
   * Visit a parse tree produced by `ExpressionsParserGrammarParser.days_element`.
   * @param ctx the parse tree
   * @return the visitor result
   */
  async visitDays_element(ctx: Days_elementContext) {
    let timeValue = await this.visit(ctx._value);

    return {
      timeKey: 'days',
      timeValue: timeValue,
    };
  }

  /**
   * Visit a parse tree produced by `ExpressionsParserGrammarParser.hours_element`.
   * @param ctx the parse tree
   * @return the visitor result
   */
  async visitHours_element(ctx: Hours_elementContext) {
    return {
      timeKey: 'hours',
      timeValue: await this.visit(ctx._value),
    };
  }

  /**
   * Visit a parse tree produced by `ExpressionsParserGrammarParser.minutes_element`.
   * @param ctx the parse tree
   * @return the visitor result
   */
  async visitMinutes_element(ctx: Minutes_elementContext) {
    return {
      timeKey: 'minutes',
      timeValue: await this.visit(ctx._value),
    };
  }
  /**
   * Visit a parse tree produced by `ExpressionsParserGrammarParser.seconds_element`.
   * @param ctx the parse tree
   * @return the visitor result
   */
  async visitSeconds_element(ctx: Seconds_elementContext) {
    return {
      timeKey: 'seconds',
      timeValue: await this.visit(ctx._value),
    };
  }

  async visitOption_element(ctx: Option_elementContext) {
    if (ctx.include_seconds() != undefined) {
      return await this.visit(ctx.include_seconds() as Include_secondsContext);
    }
    return await this.visit(ctx.add_suffix() as Add_suffixContext);
  }

  async visitOptions(ctx: OptionsContext) {
    let dataMap: Map<String, boolean> = new Map([
      ['includeSeconds', false],
      ['addSuffix', false],
    ]);

    for (var i: number = 0; i < ctx.option_element().length; i++) {
      var el = ctx.option_element()[i];
      var dEl = await this.visit(el);
      let dataElement: OptionValue = dEl as OptionValue;

      dataMap.set(dataElement.optionKey, dataElement.optionValue);
    }

    return {
      includeSeconds: dataMap.get('includeSeconds') as boolean,
      addSuffix: dataMap.get('addSuffix') as boolean,
    };
  }

  async visitInclude_seconds(ctx: Include_secondsContext): Promise<OptionValue> {
    return {
      optionKey: 'includeSeconds',
      optionValue: await this.visit(ctx._seconds),
    };
  }

  async visitAdd_suffix(ctx: Add_suffixContext): Promise<OptionValue> {
    let suffix: boolean = await this.visit(ctx._suffix);

    return {
      optionKey: 'addSuffix',
      optionValue: suffix,
    };
  }

  async visitDate_format_distance_func(ctx: Date_format_distance_funcContext) {
    let left = await this.visit(ctx._left);
    let right = await this.visit(ctx._right);
    let options: Object = new Object();
    if (ctx._opt != null) {
      options = await this.visit(ctx._opt);
    }

    var leftVal = parseDateIfNeeded(await this.visit(ctx._left));
    var rightVal = parseDateIfNeeded(await this.visit(ctx._right));
    if (typeof leftVal === 'undefined' || typeof rightVal === 'undefined') {
      return undefined;
    }
    if (!(leftVal instanceof Date && rightVal instanceof Date)) {
      const errorMessage = `not date object in difference in Days ${JSON.stringify({
        leftVal,
        leftValType: typeof leftVal,
        rightVal,
        rightValType: typeof rightVal,
      })}`;
      MultiEnvLogger.error('visitDif_in_days', errorMessage);
      throw new Error(errorMessage);
    }

    return formatDistance(leftVal, rightVal, options);
  }
  /**
   * Visit a parse tree produced by `ExpressionsParserGrammarParser.duration_element`.
   * @param ctx the parse tree
   * @return the visitor result
   */
  async visitDuration_element(ctx: Duration_elementContext) {
    if (ctx.years_element() != null) {
      return await this.visit(ctx.years_element() as Years_elementContext);
    }

    if (ctx.month_element() != null) {
      return await this.visit(ctx.month_element() as Month_elementContext);
    }
    if (ctx.days_element() != null) {
      return await this.visit(ctx.days_element() as Days_elementContext);
    }
    if (ctx.weeks_element() != null) {
      return await this.visit(ctx.weeks_element() as Weeks_elementContext);
    }
    if (ctx.hours_element() != null) {
      return await this.visit(ctx.hours_element() as Hours_elementContext);
    }
    if (ctx.minutes_element() != null) {
      return await this.visit(ctx.minutes_element() as Minutes_elementContext);
    }
    if (ctx.seconds_element() != null) {
      return await this.visit(ctx.seconds_element() as Seconds_elementContext);
    }

    throw new Error('visitDuration_element problem');
  }

  async visitPrint_expr(ctx: Print_exprContext) {
    let resultArray: Array<any> = new Array();

    for (var i: number = 0; i < ctx.expression().length; i++) {
      var x = ctx.expression()[i];
      resultArray.push(await this.visit(x));
    }
    let retVar = resultArray[resultArray.length - 1];

    MultiEnvLogger.log('ExpParserVisitor', `Log ${resultArray}`);

    return retVar;
  }

  async visitMax_expr(ctx: Max_exprContext) {
    return await this.aggregate(ctx.expression(), Math.max);
  }

  async visitMin_expr(ctx: Min_exprContext) {
    return await this.aggregate(ctx.expression(), Math.min);
  }

  async aggregate(expressions: ExpressionContext[], fct: (...args: any) => any) {
    let lastType: number | string = -1;
    let isDate: boolean | undefined = undefined;

    let values: Array<any> = new Array<any>();

    for (var i: number = 0; i < expressions.length; i++) {
      let expr = expressions[i];

      let visited = await this.visit(expr);

      if (visited === undefined || visited === null) {
        // Filtered next step
        values.push(visited);
        continue;
      }
      let curType = typeof visited;
      if (curType === 'string') {
        let parsable: boolean;
        let parsed: Date | undefined;
        try {
          parsed = parseISO(visited);
          parsable = true;
        } catch (e) {
          parsable = false;
        }
        if (parsable) {
          curType = 'object';
          isDate = true;
          visited = parsed;
        }
      }
      const curIsDate = (isDate = visited instanceof Date);
      if (lastType !== -1) {
        if (lastType !== curType) {
          throw new Error(`Aggregate function ${fct.name} cannot be applied to ${curType} and ${lastType}`);
        }
      }
      if (isDate !== undefined) {
        if (curIsDate !== isDate) {
          throw new Error(`Aggregate function ${fct.name} cannot be applied to ${curType} and ${lastType}`);
        }
      }
      lastType = curType;
      isDate = curIsDate;

      values.push(visited);
    }

    const noUndefinedOrNull = values.filter((x) => x !== undefined && x !== null);

    let retVal: any = fct(...noUndefinedOrNull);
    if (noUndefinedOrNull.length === 0) {
      retVal = undefined;
    } else if (isDate) {
      retVal = new Date(retVal);
    }

    return retVal;
  }
}

export function parseTree(text: string): ExpressionParserResult {
  var chars: CharStream = CharStreams.fromString(text);
  const lexer = new ExpressionsParserGrammarLexer(chars);

  var lexerErrorListener: ExpressionsErrorListener = new ExpressionsErrorListener();
  var parserErrorListener: ExpressionsErrorListener = new ExpressionsErrorListener();
  lexer.removeErrorListeners();
  lexer.addErrorListener(lexerErrorListener);
  const tokens = new CommonTokenStream(lexer);

  const parser = new ExpressionsParserGrammarParser(tokens);
  parser.removeErrorListeners();

  parser.addErrorListener(parserErrorListener);
  const tree = parser.expression();

  return {
    ast: tree,
    parseErrors: parserErrorListener.getErrors(),
    lexErrors: lexerErrorListener.getErrors(),
    hasError: lexerErrorListener.getErrors().length > 0 || parserErrorListener.getErrors().length > 0,
  };
}

export async function calc(text: string, callbacks?: Callbacks, asAt?: Date): Promise<ExpressionResult> {
  if (!callbacks) {
    // That's OK, must be for syntax checking
    callbacks = {
      firstVal: async (_v, _config) => 'firstVal',
      firstStr: async (_v) => 'firstStr',
      lastVal: async (_v, _config) => 'lastVal',
      lastStr: async (_v) => 'lastStr',
      submissionVal: async (_v) => 'submission',
      submissionStr: async (_v) => 'submissionStr',
      dateFormat: (_v, _config) => 'dateFormat',
    };
  }

  const parsed = parseTree(text);

  var exprParsingVisitor: ExpressionParserVisitor = new ExpressionParserVisitor(callbacks.dateFormat, callbacks, asAt);
  let exprVisit: any;
  try {
    exprVisit = await exprParsingVisitor.visit(parsed.ast);
  } catch (e) {
    MultiEnvLogger.warning('ExpParserVisitor', `Error caught ${MultiEnvLogger.errorToText(e)}`);
  }
  return {
    value: exprVisit,
    lexResult: 1,
    ast: parsed.ast,
    parseErrors: parsed.parseErrors,
    lexErrors: parsed.lexErrors,
    hasError: parsed.hasError,
  };
}

export function errorText(expression: string, result: ExpressionResult): string {
  if (!result.hasError) {
    return '';
  }
  return `Expression '${expression}' had error(s): '${JSON.stringify({
    lexErrors: result.lexErrors,
    parseErrors: result.parseErrors,
  })}'`;
}

export function formatFunction(fieldSpecValue: string, noQuotesDateFormat?: string): string {
  const supportedDateFormats = [DEFAULT_DATE_TIME_FORMAT];
  let fieldSpecDate: Date | undefined;
  for (const fmt of supportedDateFormats) {
    try {
      fieldSpecDate = parse(fieldSpecValue, fmt, new Date()); // TODO probably neeeds a timezone to seed a sample Date for parsing
      break;
    } catch (e) {}
  }
  let formattedValue: string;
  try {
    // parsing other formats earlier did nto succeed - parse ISO format
    if (!fieldSpecDate || !isValid(fieldSpecDate)) {
      fieldSpecDate = parseISO(fieldSpecValue);
    }

    const tzDate = utcToZonedTime(fieldSpecDate, 'America/Toronto'); // TODO: Backend and front end could run on different time zones. Develop a solution if we still want to reuse shared-lib
    formattedValue = format(tzDate, noQuotesDateFormat ? noQuotesDateFormat : '');
    // TODO: this is a temporary hack. We must establish a proper solution for using time zones in the system (like session.timezone obtained from the browser)

    return formattedValue;
  } catch (e) {
    // return original value returned from sub-expression
    return fieldSpecValue;
  }
}

export type fieldReferencingFunction = 'firstVal' | 'firstStr' | 'lastVal' | 'lastStr' | 'submissionStr' | 'submissionVal';

export function allDataReferences(expression: string, callback: (fieldSpec: string, fctName: fieldReferencingFunction) => void) {
  return calc(expression, {
    firstVal: async (fieldSpec: string) => {
      callback(fieldSpec, 'firstVal');
    },
    firstStr: async (fieldSpec: string): Promise<string> => {
      callback(fieldSpec, 'firstStr');
      return '';
    },
    lastVal: async (fieldSpec: string) => {
      callback(fieldSpec, 'lastVal');
    },
    lastStr: async (fieldSpec: string): Promise<string> => {
      callback(fieldSpec, 'lastStr');
      return '';
    },
    submissionVal: async (fieldSpec: string) => {
      callback(fieldSpec, 'submissionVal');
    },
    submissionStr: async (fieldSpec: string): Promise<string> => {
      callback(fieldSpec, 'submissionStr');
      return '';
    },
    dateFormat: formatFunction,
  });
}

export async function getAllDataReferences(expression: string): Promise<string[]> {
  const result: string[] = [];
  await allDataReferences(expression, (fieldSpec: string) => {
    result.push(fieldSpec);
  });
  return result;
}

var _a, _b, _c, _d;
import { theme } from './theme';
export var dialogClasses = {
    dialogTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    inputField: {
        width: '100%',
        marginTop: '12px',
    },
    closeIcon: {
        color: '#000',
    },
    infoIcon: {
        color: '#06c',
    },
    btnSpacing: {
        marginTop: '12px',
    },
    caption: {
        width: '100%',
    },
    errorBlock: {
        '& .MuiFormLabel-root': {
            color: theme.palette.error.main,
        },
    },
    errorMsg: {
        color: theme.palette.error.main,
        width: '100%',
        '& $span': {
            marginLeft: theme.spacing(1),
        },
    },
    fullWidthField: {
        width: '100%',
    },
};
export var tableClasses = {
    tableHolder: {
        width: '100%',
    },
};
export var containerClasses = {
    formHolder: {
        position: 'relative',
    },
    formContainer: {
        margin: '0 auto 64px auto',
        width: 400,
        maxWidth: '90vw',
        position: 'relative',
        minHeight: '40vh',
        padding: 24,
        sm: {
            margin: '4px auto',
            padding: '48px 16px 16px 16px',
        },
    },
    itemHolder: {
        marginBottom: 16,
        width: '100%',
    },
};
export var formClasses = {
    formError: {
        color: theme.palette.error.main,
    },
};
export var authConfigClasses = {
    secretField: {
        display: 'flex',
        alignItems: 'end',
    },
    generateButton: {
        borderRadius: 2,
        padding: '0 35px',
        maxWidth: '50px',
        maxHeight: '50px',
        minWidth: '50px',
        minHeight: '50px',
    },
};
export var spacing = theme.spacing(2);
export var signupClasses = {
    formHolder: {
        position: 'relative',
    },
    formContainer: (_a = {
            margin: '20vh auto 0 auto',
            width: '400px',
            maxWidth: '90vw',
            position: 'relative',
            minHeight: '40vh',
            padding: '24px'
        },
        _a[theme.breakpoints.down('sm')] = {
            margin: '4px auto',
            padding: '48px 16px 16px 16px',
        },
        _a),
    inputField: {
        width: '100%',
        marginBottom: spacing,
    },
    btnHolder: {
        width: '100%',
        marginBottom: spacing,
    },
    emailImg: {
        width: '100px',
        marginBottom: '12px',
    },
    dividerHor: {
        width: '100%',
        margin: '0 10px',
        borderColor: theme.palette.primary.main,
    },
    orSeparator: {
        margin: '12px',
    },
    itemHolder: {
        marginBottom: '16px',
        width: '100%',
    },
};
export var lpClasses = {
    lpTR: (_b = {
            pointerEvents: 'none',
            position: 'fixed',
            top: 0,
            right: 0,
            width: '250px'
        },
        _b[theme.breakpoints.down('md')] = {
            display: 'none',
        },
        _b),
    lpBL: (_c = {
            pointerEvents: 'none',
            position: 'fixed',
            left: '20px',
            bottom: '72px',
            width: '250px'
        },
        _c[theme.breakpoints.down('md')] = {
            display: 'none',
        },
        _c),
    lpBR: (_d = {
            pointerEvents: 'none',
            position: 'fixed',
            right: '4px',
            bottom: '90px',
            width: '260px'
        },
        _d[theme.breakpoints.down('md')] = {
            display: 'none',
        },
        _d),
};

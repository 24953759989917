var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ApolloLink } from '@apollo/client';
import { isJwtExpired, logout, refreshTokenFct, tokenHolder } from '../api/use-auth-service';
import { ClientLogger } from '../client-logger';
import { OperationQueuing } from './queuing';
var DEBUG = false;
export function authHeader() {
    var accessToken = tokenHolder.accessToken;
    var jwtHeader = accessToken ? "Bearer ".concat(accessToken) : '';
    var ret = {
        headers: {
            authorization: jwtHeader,
        },
    };
    DEBUG && ClientLogger.debug('TokenRefreshLink.authHeader', 'returning', JSON.stringify({ ret: ret }));
    return ret;
}
// Adopted from https://github.com/newsiberian/apollo-link-token-refresh/tree/master/src
var TokenRefreshLink = /** @class */ (function (_super) {
    __extends(TokenRefreshLink, _super);
    function TokenRefreshLink() {
        var _this = _super.call(this) || this;
        _this.queue = new OperationQueuing();
        _this.fetching = false;
        return _this;
    }
    TokenRefreshLink.prototype.request = function (operation, forward) {
        var _this = this;
        var _a, _b, _c, _d;
        DEBUG && ClientLogger.debug('TokenRefreshLink.request', 'start', { operation: operation });
        if (typeof forward !== 'function') {
            throw new Error('[Token Refresh Link]: Token Refresh Link is non-terminating link and should not be the last in the composed chain');
        }
        // If token does not exists, which could means that this is a not registered
        // user request, or if it is does not expired -- act as always
        var context = operation.getContext();
        if (context.noAuth) {
            DEBUG && ClientLogger.debug('TokenRefreshLink.request', 'No Auth Needed for this request. Forwarding', { operation: operation, context: context });
            return forward(operation);
        }
        if (!this.fetching) {
            if (!isJwtExpired()) {
                DEBUG &&
                    ClientLogger.debug('TokenRefreshLink.request', 'Jwt is OK. Forwarding', {
                        operation: operation,
                        context: context,
                        expiryReadable: ((_a = tokenHolder.jwt) === null || _a === void 0 ? void 0 : _a.exp) ? new Date(((_b = tokenHolder.jwt) === null || _b === void 0 ? void 0 : _b.exp) * 1000) : 'undefined',
                    });
                operation.setContext(__assign({}, authHeader()));
                return forward(operation);
            }
            DEBUG &&
                ClientLogger.debug('TokenRefreshLink.request', 'Jwt is NOT OK. Requesting', {
                    operation: operation,
                    context: context,
                    expiryReadable: ((_c = tokenHolder.jwt) === null || _c === void 0 ? void 0 : _c.exp) ? new Date(((_d = tokenHolder.jwt) === null || _d === void 0 ? void 0 : _d.exp) * 1000) : 'undefined',
                });
            this.fetching = true;
            DEBUG && ClientLogger.debug('TokenRefreshLink.request', 'Calling exchangeRefreshTokenForJwtToken');
            refreshTokenFct()
                .then(function (refreshResp) {
                DEBUG && ClientLogger.debug('TokenRefreshLink.request', 'Refresh Token Response', { refreshResp: refreshResp });
                if (refreshResp) {
                    _this.queue.consumeQueue();
                    DEBUG && ClientLogger.debug('TokenRefreshLink.request', "successful exchangeRefreshTokenForJwtToken");
                }
                else {
                    ClientLogger.error('TokenRefreshLink.exchangeRefreshTokenForJwtToken', 'Token not renewed');
                    logout();
                    _this.queue.nukeQueue();
                }
            })
                .catch(function (e) {
                ClientLogger.error('TokenRefreshLink.exchangeRefreshTokenForJwtToken', 'Error renewing token', e);
                logout();
                _this.queue.nukeQueue();
            })
                .finally(function () {
                _this.fetching = false;
                DEBUG && ClientLogger.debug('TokenRefreshLink.request', "finally");
            });
        }
        DEBUG && ClientLogger.debug('TokenRefreshLink.request', 'end');
        return this.queue.enqueueRequest({
            operation: operation,
            forward: forward,
        });
    };
    return TokenRefreshLink;
}(ApolloLink));
export { TokenRefreshLink };

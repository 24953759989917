import { MenuItem, Select, Input, FormControl, InputLabel, Typography } from '@mui/material';
import ReactQuill, { Quill } from 'react-quill';
import { Field, FieldProps } from 'formik';
import * as React from 'react';
import 'react-quill/dist/quill.snow.css';
import './style.css';

import { useRef } from 'react';
import { imageCompressor } from './imageCompress';
import { debounce } from 'lodash';

interface IProps {
  name: string;
  label: string;
  disabled?: boolean;
  onChange?: (key: string) => void;
  dataTest?: string;
  required?: boolean;
  showError?: boolean;
  style?: React.CSSProperties;
}

const DEBUG = false;

Quill.register('modules/imageCompressor', imageCompressor);

export const RichText = (props: IProps) => {
  const { label, name, dataTest, disabled, required, showError, style } = props;
  const richRef: any = useRef();

  const validate = (values: any) => {
    const errors: any = {};
    if (errors[name]) {
      richRef.current.focus();
    }
    return errors;
  };

  DEBUG && console.log('RichText', 'render', props);
  return (
    <Field name={name}>
      {(formikProps: FieldProps) => {
        const errors = formikProps.form.errors;
        const error = errors[name];
        const values = formikProps.form.values;
        const debouncedSetFieldValue = debounce((value: any) => formikProps.form.setFieldValue(name, value), 250);

        DEBUG && console.log('Selector', 'render field ', { formikProps, error, errors });
        return (
          <FormControl variant="standard" margin="normal" fullWidth error={!!error} disabled={disabled} sx={{ marginTop: '0' }}>
            <InputLabel htmlFor={label}>{label}</InputLabel>
            <ReactQuill
              style={{
                marginTop: '2.75em',
                ...style,
              }}
              value={values[name] || ''}
              ref={richRef}
              data-test={dataTest}
              formats={['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image']}
              modules={{
                toolbar: {
                  container: [
                    [{ header: [1, 2, 3, 4, 5, false] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote', 'image'],
                    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                  ],
                },
                imageCompressor: {
                  quality: 0.9,
                  maxWidth: 200,
                  maxHeight: 200,
                  imageType: 'image/jpeg',
                  // toastDispatch: toastDispatch,
                },
                clipboard: {
                  matchVisual: true,
                },
              }}
              onChange={(value: any) => {
                DEBUG && console.debug('RichText', 'rich text change', { value });
                // must debounce because of performance issues
                formikProps.form.setFieldValue(name, value);
                // debouncedSetFieldValue(value); // grr.  I'll figure this our later...
              }}
              theme="snow"
            />
            {showError ? <Typography color="red">{error}</Typography> : null}
          </FormControl>
        );
      }}
    </Field>
  );
};

export var AccountType;
(function (AccountType) {
    AccountType["NORMAL"] = "NORMAL";
    AccountType["SINGLE_USE"] = "SINGLE_USE";
    AccountType["SINGLE_USE_INVITE"] = "SINGLE_USE_INVITE";
})(AccountType || (AccountType = {}));
export var ArchiveType;
(function (ArchiveType) {
    ArchiveType["USER_DATA_V1"] = "USER_DATA_V1";
})(ArchiveType || (ArchiveType = {}));
export var CarePlanStatus;
(function (CarePlanStatus) {
    CarePlanStatus["CLOSED"] = "CLOSED";
    CarePlanStatus["OPEN"] = "OPEN";
})(CarePlanStatus || (CarePlanStatus = {}));
export var ChannelMemberPermissions;
(function (ChannelMemberPermissions) {
    ChannelMemberPermissions["ACTIVE_MEMBER"] = "ACTIVE_MEMBER";
    ChannelMemberPermissions["REMOVED_MEMBER"] = "REMOVED_MEMBER";
})(ChannelMemberPermissions || (ChannelMemberPermissions = {}));
export var ChannelType;
(function (ChannelType) {
    ChannelType["BOT"] = "BOT";
    ChannelType["COC"] = "COC";
    ChannelType["COC_SUBSET"] = "COC_SUBSET";
    ChannelType["SIMULATOR"] = "SIMULATOR";
    ChannelType["TASK_ASSIGNMENT_CHANGE"] = "TASK_ASSIGNMENT_CHANGE";
    ChannelType["WORK_QUEUE_ITEM"] = "WORK_QUEUE_ITEM";
})(ChannelType || (ChannelType = {}));
export var ChatSettings;
(function (ChatSettings) {
    ChatSettings["NOTIFICATIONS_ALWAYS"] = "NOTIFICATIONS_ALWAYS";
    ChatSettings["NOTIFICATIONS_NEVER"] = "NOTIFICATIONS_NEVER";
    ChatSettings["NO_CHAT"] = "NO_CHAT";
})(ChatSettings || (ChatSettings = {}));
export var CircleOfCareUserType;
(function (CircleOfCareUserType) {
    CircleOfCareUserType["ADMIN"] = "ADMIN";
    CircleOfCareUserType["STAFF"] = "STAFF";
    CircleOfCareUserType["USER"] = "USER";
})(CircleOfCareUserType || (CircleOfCareUserType = {}));
export var ContactType;
(function (ContactType) {
    ContactType["EMAIL"] = "EMAIL";
    ContactType["PHONE"] = "PHONE";
})(ContactType || (ContactType = {}));
export var DataModelFieldType;
(function (DataModelFieldType) {
    DataModelFieldType["ARRAY"] = "ARRAY";
    DataModelFieldType["BOOLEAN"] = "BOOLEAN";
    DataModelFieldType["CHOICE"] = "CHOICE";
    DataModelFieldType["CHOICES"] = "CHOICES";
    DataModelFieldType["DATE"] = "DATE";
    DataModelFieldType["DATETIME"] = "DATETIME";
    DataModelFieldType["FILE"] = "FILE";
    DataModelFieldType["NONE"] = "NONE";
    DataModelFieldType["NO_VALUE"] = "NO_VALUE";
    DataModelFieldType["NUMBER"] = "NUMBER";
    DataModelFieldType["OBJECT"] = "OBJECT";
    DataModelFieldType["TEXT"] = "TEXT";
    DataModelFieldType["TIME"] = "TIME";
})(DataModelFieldType || (DataModelFieldType = {}));
export var EnumCircleOfCareMemberVisibility;
(function (EnumCircleOfCareMemberVisibility) {
    EnumCircleOfCareMemberVisibility["DEFAULT"] = "DEFAULT";
    EnumCircleOfCareMemberVisibility["FIRST_NAME_ONLY"] = "FIRST_NAME_ONLY";
})(EnumCircleOfCareMemberVisibility || (EnumCircleOfCareMemberVisibility = {}));
export var ErrorCode;
(function (ErrorCode) {
    ErrorCode["ACCOUNT_DISABLED"] = "ACCOUNT_DISABLED";
    ErrorCode["ADMIN_NOT_FOUND"] = "ADMIN_NOT_FOUND";
    ErrorCode["ALREADY_REGISTERED"] = "ALREADY_REGISTERED";
    ErrorCode["CANNOT_CANCEL_JOB"] = "CANNOT_CANCEL_JOB";
    ErrorCode["CANNOT_LOGIN_AS_TEST_USER"] = "CANNOT_LOGIN_AS_TEST_USER";
    ErrorCode["CANNOT_PAUSE_JOB"] = "CANNOT_PAUSE_JOB";
    ErrorCode["CANNOT_RESUME_JOB"] = "CANNOT_RESUME_JOB";
    ErrorCode["CANNOT_UPDATE_ACTIVE_TEMPLATE_DEFINITION"] = "CANNOT_UPDATE_ACTIVE_TEMPLATE_DEFINITION";
    ErrorCode["CARE_PLAN_MAX_ONE"] = "CARE_PLAN_MAX_ONE";
    ErrorCode["CODE_EXPIRED"] = "CODE_EXPIRED";
    ErrorCode["DOB_LASTNAME_NOT_FOUND"] = "DOB_LASTNAME_NOT_FOUND";
    ErrorCode["EMAIL_ADDRESS_INVALID"] = "EMAIL_ADDRESS_INVALID";
    ErrorCode["EMAIL_ADDRESS_IN_USE"] = "EMAIL_ADDRESS_IN_USE";
    ErrorCode["EMAIL_ADDRESS_IS_NOT_FOUND"] = "EMAIL_ADDRESS_IS_NOT_FOUND";
    ErrorCode["END_DATE_NOT_AFTER_START_DATE"] = "END_DATE_NOT_AFTER_START_DATE";
    ErrorCode["ERROR_SELF_SIGNUP_ALREADY_ONBOARDED"] = "ERROR_SELF_SIGNUP_ALREADY_ONBOARDED";
    ErrorCode["ERROR_SENDING_PASSWORDLESS_CODE"] = "ERROR_SENDING_PASSWORDLESS_CODE";
    ErrorCode["ERROR_STARTING_PASSWORDLESS_LOGIN"] = "ERROR_STARTING_PASSWORDLESS_LOGIN";
    ErrorCode["EXPIRED_LINK"] = "EXPIRED_LINK";
    ErrorCode["EXPRESSION_ERROR"] = "EXPRESSION_ERROR";
    ErrorCode["FORM_ASSIGNMENT_EXISTS_IN_PREVIOUS_FORM_REVISION"] = "FORM_ASSIGNMENT_EXISTS_IN_PREVIOUS_FORM_REVISION";
    ErrorCode["FORM_ASSIGNMENT_NO_REVISION"] = "FORM_ASSIGNMENT_NO_REVISION";
    ErrorCode["FORM_ASSIGNMENT_RULE_ERROR"] = "FORM_ASSIGNMENT_RULE_ERROR";
    ErrorCode["FORM_ASSIGNMENT_TEMPLATE_RULE_ERROR"] = "FORM_ASSIGNMENT_TEMPLATE_RULE_ERROR";
    ErrorCode["FORM_REVISION_NO_REVISIONS"] = "FORM_REVISION_NO_REVISIONS";
    ErrorCode["FORM_WAS_OPENED_BY_OTHER_USER"] = "FORM_WAS_OPENED_BY_OTHER_USER";
    ErrorCode["INSURANCE_ID_NOT_FOUND"] = "INSURANCE_ID_NOT_FOUND";
    ErrorCode["INTERNAL_SERVER"] = "INTERNAL_SERVER";
    ErrorCode["INVALID_CARE_PLAN_STATUS"] = "INVALID_CARE_PLAN_STATUS";
    ErrorCode["INVALID_CODE"] = "INVALID_CODE";
    ErrorCode["INVALID_EMAIL_DOMAIN"] = "INVALID_EMAIL_DOMAIN";
    ErrorCode["INVALID_LINK"] = "INVALID_LINK";
    ErrorCode["INVITATION_ALREADY_COMPLETE_OR_NOT_FOUND"] = "INVITATION_ALREADY_COMPLETE_OR_NOT_FOUND";
    ErrorCode["IP_NOT_WHITE_LISTED_IP_IS_PENDING"] = "IP_NOT_WHITE_LISTED_IP_IS_PENDING";
    ErrorCode["IP_NOT_WHITE_LISTED_PENDING_CREATED"] = "IP_NOT_WHITE_LISTED_PENDING_CREATED";
    ErrorCode["IP_NOT_WHITE_LISTED_TOO_MANY_PENDING"] = "IP_NOT_WHITE_LISTED_TOO_MANY_PENDING";
    ErrorCode["IP_WHITE_LIST_EXISTS_IS_ACTIVE"] = "IP_WHITE_LIST_EXISTS_IS_ACTIVE";
    ErrorCode["IP_WHITE_LIST_EXISTS_IS_PENDING"] = "IP_WHITE_LIST_EXISTS_IS_PENDING";
    ErrorCode["JOB_ALREADY_RUNNING"] = "JOB_ALREADY_RUNNING";
    ErrorCode["MFA_CODE_INVALID"] = "MFA_CODE_INVALID";
    ErrorCode["MISSING_REQUIRED_ROLE"] = "MISSING_REQUIRED_ROLE";
    ErrorCode["NOT_CODED"] = "NOT_CODED";
    ErrorCode["NOT_PERMITTED"] = "NOT_PERMITTED";
    ErrorCode["OPTIMISTIC_LOCK_FAILED"] = "OPTIMISTIC_LOCK_FAILED";
    ErrorCode["PHONE_NUMBER_INVALID"] = "PHONE_NUMBER_INVALID";
    ErrorCode["PLAN_ALREADY_ASSIGNED"] = "PLAN_ALREADY_ASSIGNED";
    ErrorCode["PLAN_NOT_FOUND"] = "PLAN_NOT_FOUND";
    ErrorCode["TOO_MANY_MESSAGES"] = "TOO_MANY_MESSAGES";
    ErrorCode["UNAUTHENTICATED"] = "UNAUTHENTICATED";
    ErrorCode["UNRECOGNIZED_PATIENT"] = "UNRECOGNIZED_PATIENT";
    ErrorCode["USER_DOES_NOT_HAVE_CREDENTIALS_FOR_ROLE"] = "USER_DOES_NOT_HAVE_CREDENTIALS_FOR_ROLE";
    ErrorCode["WORK_QUEUE_NOT_FOUND"] = "WORK_QUEUE_NOT_FOUND";
})(ErrorCode || (ErrorCode = {}));
export var ExternalMessageChannelType;
(function (ExternalMessageChannelType) {
    ExternalMessageChannelType["EMAIL"] = "EMAIL";
    ExternalMessageChannelType["SMS"] = "SMS";
})(ExternalMessageChannelType || (ExternalMessageChannelType = {}));
export var ExternalMessageTemplate;
(function (ExternalMessageTemplate) {
    ExternalMessageTemplate["INVITE_TO_SYSTEM"] = "INVITE_TO_SYSTEM";
})(ExternalMessageTemplate || (ExternalMessageTemplate = {}));
export var ExternalMessageType;
(function (ExternalMessageType) {
    ExternalMessageType["INVITE"] = "INVITE";
    ExternalMessageType["NOTICE"] = "NOTICE";
    ExternalMessageType["OTP"] = "OTP";
})(ExternalMessageType || (ExternalMessageType = {}));
export var FormAssignmentQuerySortOrder;
(function (FormAssignmentQuerySortOrder) {
    FormAssignmentQuerySortOrder["DEFAULT"] = "DEFAULT";
    FormAssignmentQuerySortOrder["LEAST_TO_MOST_RECENT"] = "LEAST_TO_MOST_RECENT";
})(FormAssignmentQuerySortOrder || (FormAssignmentQuerySortOrder = {}));
export var FormAssignmentType;
(function (FormAssignmentType) {
    FormAssignmentType["INITIAL"] = "INITIAL";
    FormAssignmentType["MANUAL"] = "MANUAL";
    FormAssignmentType["RULES"] = "RULES";
})(FormAssignmentType || (FormAssignmentType = {}));
export var FormRevisionStatus;
(function (FormRevisionStatus) {
    FormRevisionStatus["ACTIVE"] = "ACTIVE";
    FormRevisionStatus["IN_REVIEW"] = "IN_REVIEW";
})(FormRevisionStatus || (FormRevisionStatus = {}));
export var FormSubmissionCountType;
(function (FormSubmissionCountType) {
    FormSubmissionCountType["SUBMISSIONS"] = "SUBMISSIONS";
    FormSubmissionCountType["USERS"] = "USERS";
})(FormSubmissionCountType || (FormSubmissionCountType = {}));
export var FormType;
(function (FormType) {
    FormType["APPOINTMENT"] = "APPOINTMENT";
    FormType["EDUCATION"] = "EDUCATION";
    FormType["FORM"] = "FORM";
    FormType["LINK"] = "LINK";
    FormType["TRACKER"] = "TRACKER";
    FormType["VIDEO"] = "VIDEO";
})(FormType || (FormType = {}));
export var HasMore;
(function (HasMore) {
    HasMore["NO"] = "NO";
    HasMore["UNKNOWN"] = "UNKNOWN";
    HasMore["YES"] = "YES";
})(HasMore || (HasMore = {}));
export var IPWhiteListStatus;
(function (IPWhiteListStatus) {
    IPWhiteListStatus["ACTIVE"] = "ACTIVE";
    IPWhiteListStatus["PENDING"] = "PENDING";
})(IPWhiteListStatus || (IPWhiteListStatus = {}));
export var InboundIntegrationEventType;
(function (InboundIntegrationEventType) {
    InboundIntegrationEventType["CHECK_IN_SCAN"] = "CHECK_IN_SCAN";
    InboundIntegrationEventType["INTEGRATION_BUS"] = "INTEGRATION_BUS";
    InboundIntegrationEventType["LANDING_PAGE_PARAMS"] = "LANDING_PAGE_PARAMS";
})(InboundIntegrationEventType || (InboundIntegrationEventType = {}));
export var InviteStatus;
(function (InviteStatus) {
    InviteStatus["ACCEPTED"] = "ACCEPTED";
    InviteStatus["PENDING"] = "PENDING";
    InviteStatus["REJECTED"] = "REJECTED";
})(InviteStatus || (InviteStatus = {}));
export var InviteType;
(function (InviteType) {
    InviteType["CIRCLE_OF_CARE_INVITE"] = "CIRCLE_OF_CARE_INVITE";
    InviteType["CIRCLE_OF_CARE_REQUEST"] = "CIRCLE_OF_CARE_REQUEST";
})(InviteType || (InviteType = {}));
export var JobDefinitionId;
(function (JobDefinitionId) {
    JobDefinitionId["TEST_JOB"] = "TEST_JOB";
    JobDefinitionId["UNREAD_CHAT_NOTIFICATIONS"] = "UNREAD_CHAT_NOTIFICATIONS";
    JobDefinitionId["USER_ARCHIVE_JOB"] = "USER_ARCHIVE_JOB";
})(JobDefinitionId || (JobDefinitionId = {}));
export var JobInstanceStatus;
(function (JobInstanceStatus) {
    JobInstanceStatus["CANCELLED"] = "CANCELLED";
    JobInstanceStatus["CANCEL_REQUESTED"] = "CANCEL_REQUESTED";
    JobInstanceStatus["COMPLETED"] = "COMPLETED";
    JobInstanceStatus["FAILED"] = "FAILED";
    JobInstanceStatus["PAUSED"] = "PAUSED";
    JobInstanceStatus["PAUSE_REQUESTED"] = "PAUSE_REQUESTED";
    JobInstanceStatus["PENDING_RESUME"] = "PENDING_RESUME";
    JobInstanceStatus["PENDING_START"] = "PENDING_START";
    JobInstanceStatus["RUNNING"] = "RUNNING";
})(JobInstanceStatus || (JobInstanceStatus = {}));
export var JobLogLevel;
(function (JobLogLevel) {
    JobLogLevel["ERROR"] = "ERROR";
    JobLogLevel["INFO"] = "INFO";
    JobLogLevel["WARN"] = "WARN";
})(JobLogLevel || (JobLogLevel = {}));
export var LoadTestNextUserAlgorithm;
(function (LoadTestNextUserAlgorithm) {
    LoadTestNextUserAlgorithm["STAFF_AND_NEW_USERS"] = "STAFF_AND_NEW_USERS";
    LoadTestNextUserAlgorithm["TEMPLATE_ROLES"] = "TEMPLATE_ROLES";
    LoadTestNextUserAlgorithm["USERS_AND_STAFF"] = "USERS_AND_STAFF";
})(LoadTestNextUserAlgorithm || (LoadTestNextUserAlgorithm = {}));
export var MFAType;
(function (MFAType) {
    MFAType["GOOGLE_AUTHENTICATOR"] = "GOOGLE_AUTHENTICATOR";
})(MFAType || (MFAType = {}));
export var MessageCode;
(function (MessageCode) {
    MessageCode["CARE_PLAN_ID_CREATED"] = "CARE_PLAN_ID_CREATED";
    MessageCode["FORM_ASSIGNMENT_TEMPLATE_CREATED"] = "FORM_ASSIGNMENT_TEMPLATE_CREATED";
    MessageCode["FORM_CREATE"] = "FORM_CREATE";
    MessageCode["FORM_REVISION_CREATE"] = "FORM_REVISION_CREATE";
    MessageCode["FORM_REVISION_IDENTICAL"] = "FORM_REVISION_IDENTICAL";
    MessageCode["FORM_REVISION_OVERWRITE"] = "FORM_REVISION_OVERWRITE";
    MessageCode["INTEGRATIONS_OVERWRITTEN"] = "INTEGRATIONS_OVERWRITTEN";
    MessageCode["INTEGRATION_ADDED"] = "INTEGRATION_ADDED";
    MessageCode["NOT_CODED"] = "NOT_CODED";
    MessageCode["PUBLIC_DEVICE_CREATED"] = "PUBLIC_DEVICE_CREATED";
    MessageCode["PUBLIC_DEVICE_REVISION_IDENTICAL"] = "PUBLIC_DEVICE_REVISION_IDENTICAL";
    MessageCode["PUBLIC_DEVICE_REVISION_ID_SAVED"] = "PUBLIC_DEVICE_REVISION_ID_SAVED";
    MessageCode["SECTION_TEMPLATE_WORK_QUEUE_UPDATED"] = "SECTION_TEMPLATE_WORK_QUEUE_UPDATED";
    MessageCode["TEMPLATE_REVISION_IDENTICAL"] = "TEMPLATE_REVISION_IDENTICAL";
    MessageCode["TEMPLATE_REVISION_ID_SAVED"] = "TEMPLATE_REVISION_ID_SAVED";
    MessageCode["TEMPLATE_REVISION_ID_USED"] = "TEMPLATE_REVISION_ID_USED";
    MessageCode["VERSION_NUMBER_IGNORED"] = "VERSION_NUMBER_IGNORED";
    MessageCode["WORK_QUEUE_CREATED"] = "WORK_QUEUE_CREATED";
    MessageCode["WORK_QUEUE_DISPLAY_CREATED"] = "WORK_QUEUE_DISPLAY_CREATED";
    MessageCode["WORK_QUEUE_DISPLAY_REVISION_IDENTICAL"] = "WORK_QUEUE_DISPLAY_REVISION_IDENTICAL";
    MessageCode["WORK_QUEUE_DISPLAY_REVISION_ID_SAVED"] = "WORK_QUEUE_DISPLAY_REVISION_ID_SAVED";
    MessageCode["WORK_QUEUE_REVISION_IDENTICAL"] = "WORK_QUEUE_REVISION_IDENTICAL";
    MessageCode["WORK_QUEUE_REVISION_ID_SAVED"] = "WORK_QUEUE_REVISION_ID_SAVED";
})(MessageCode || (MessageCode = {}));
export var MessageEventType;
(function (MessageEventType) {
    MessageEventType["READ_MESSAGE"] = "READ_MESSAGE";
})(MessageEventType || (MessageEventType = {}));
export var MessageFetchType;
(function (MessageFetchType) {
    MessageFetchType["FROM_DATE"] = "FROM_DATE";
    MessageFetchType["INITIAL"] = "INITIAL";
    MessageFetchType["LATEST"] = "LATEST";
    MessageFetchType["ONLY_UNREAD"] = "ONLY_UNREAD";
    MessageFetchType["PRIOR_TO_DATE"] = "PRIOR_TO_DATE";
})(MessageFetchType || (MessageFetchType = {}));
export var MessageType;
(function (MessageType) {
    MessageType["ADDED_TO_COC"] = "ADDED_TO_COC";
    MessageType["CARE_PLAN_CHAT"] = "CARE_PLAN_CHAT";
    MessageType["FORM_SUBMISSION"] = "FORM_SUBMISSION";
    MessageType["HEARTBEAT"] = "HEARTBEAT";
    MessageType["REMOVED_FROM_COC"] = "REMOVED_FROM_COC";
    MessageType["SIMULATION_FORM_ASSIGNMENT_CHECK_COMPLETE"] = "SIMULATION_FORM_ASSIGNMENT_CHECK_COMPLETE";
    MessageType["SIMULATION_FORM_ASSIGNMENT_CHECK_REQUEST"] = "SIMULATION_FORM_ASSIGNMENT_CHECK_REQUEST";
    MessageType["SIMULATION_FORM_SUBMISSION_COMPLETE"] = "SIMULATION_FORM_SUBMISSION_COMPLETE";
    MessageType["SIMULATION_FORM_SUBMISSION_RECORDED"] = "SIMULATION_FORM_SUBMISSION_RECORDED";
    MessageType["SIMULATION_FORM_SUBMISSION_REQUEST"] = "SIMULATION_FORM_SUBMISSION_REQUEST";
    MessageType["SIMULATION_RECORDING_FINISHED"] = "SIMULATION_RECORDING_FINISHED";
    MessageType["SIMULATION_RECORDING_STARTED"] = "SIMULATION_RECORDING_STARTED";
    MessageType["SIMULATION_SET_AS_AT"] = "SIMULATION_SET_AS_AT";
    MessageType["SIMULATION_SET_AS_AT_COMPLETE"] = "SIMULATION_SET_AS_AT_COMPLETE";
    MessageType["SIMULATION_USER_READY"] = "SIMULATION_USER_READY";
    MessageType["TASK_ASSIGNMENT_CHANGE"] = "TASK_ASSIGNMENT_CHANGE";
    MessageType["USER_LOG_OUT"] = "USER_LOG_OUT";
    MessageType["WORK_QUEUE_DISPLAY"] = "WORK_QUEUE_DISPLAY";
    MessageType["WORK_QUEUE_ITEM"] = "WORK_QUEUE_ITEM";
})(MessageType || (MessageType = {}));
export var NoticeType;
(function (NoticeType) {
    NoticeType["ADDING_PLAN"] = "ADDING_PLAN";
    NoticeType["NEW_USER"] = "NEW_USER";
})(NoticeType || (NoticeType = {}));
export var PersonOrgRole;
(function (PersonOrgRole) {
    PersonOrgRole["ADMIN"] = "ADMIN";
    PersonOrgRole["STAFF"] = "STAFF";
    PersonOrgRole["USER"] = "USER";
})(PersonOrgRole || (PersonOrgRole = {}));
export var PersonStatus;
(function (PersonStatus) {
    PersonStatus["ACTIVE"] = "ACTIVE";
    PersonStatus["DELETED"] = "DELETED";
})(PersonStatus || (PersonStatus = {}));
export var PublicDeviceLaunchType;
(function (PublicDeviceLaunchType) {
    PublicDeviceLaunchType["DEVICE"] = "DEVICE";
    PublicDeviceLaunchType["PHONE"] = "PHONE";
    PublicDeviceLaunchType["SCAN_FOR_INTEGRATION_EVENT"] = "SCAN_FOR_INTEGRATION_EVENT";
})(PublicDeviceLaunchType || (PublicDeviceLaunchType = {}));
export var PublicDeviceRevisionStatus;
(function (PublicDeviceRevisionStatus) {
    PublicDeviceRevisionStatus["ACTIVE"] = "ACTIVE";
    PublicDeviceRevisionStatus["IN_REVIEW"] = "IN_REVIEW";
})(PublicDeviceRevisionStatus || (PublicDeviceRevisionStatus = {}));
export var RelationshipToPatient;
(function (RelationshipToPatient) {
    RelationshipToPatient["CARE_PROVIDER"] = "CARE_PROVIDER";
    RelationshipToPatient["DAUGHTER"] = "DAUGHTER";
    RelationshipToPatient["FAMILY_MEMBER"] = "FAMILY_MEMBER";
    RelationshipToPatient["FRIEND"] = "FRIEND";
    RelationshipToPatient["GRAND_DAUGHTER"] = "GRAND_DAUGHTER";
    RelationshipToPatient["GRAND_SON"] = "GRAND_SON";
    RelationshipToPatient["PARENT"] = "PARENT";
    RelationshipToPatient["SELF"] = "SELF";
    RelationshipToPatient["SON"] = "SON";
    RelationshipToPatient["SPOUSE"] = "SPOUSE";
})(RelationshipToPatient || (RelationshipToPatient = {}));
export var Role;
(function (Role) {
    Role["ADMIN"] = "ADMIN";
    Role["STAFF"] = "STAFF";
    Role["USER"] = "USER";
})(Role || (Role = {}));
export var RuleTopLevelOp;
(function (RuleTopLevelOp) {
    RuleTopLevelOp["all"] = "all";
    RuleTopLevelOp["any"] = "any";
})(RuleTopLevelOp || (RuleTopLevelOp = {}));
export var ScheduledEventType;
(function (ScheduledEventType) {
    ScheduledEventType["FORM_SUBMISSION_DUE"] = "FORM_SUBMISSION_DUE";
    ScheduledEventType["REMINDER"] = "REMINDER";
})(ScheduledEventType || (ScheduledEventType = {}));
export var SimulationEventType;
(function (SimulationEventType) {
    SimulationEventType["SIMULATION_FORM_ASSIGNMENT_CHECK_COMPLETE"] = "SIMULATION_FORM_ASSIGNMENT_CHECK_COMPLETE";
    SimulationEventType["SIMULATION_FORM_ASSIGNMENT_CHECK_REQUEST"] = "SIMULATION_FORM_ASSIGNMENT_CHECK_REQUEST";
    SimulationEventType["SIMULATION_FORM_SUBMISSION_COMPLETE"] = "SIMULATION_FORM_SUBMISSION_COMPLETE";
    SimulationEventType["SIMULATION_FORM_SUBMISSION_RECORDED"] = "SIMULATION_FORM_SUBMISSION_RECORDED";
    SimulationEventType["SIMULATION_FORM_SUBMISSION_REQUEST"] = "SIMULATION_FORM_SUBMISSION_REQUEST";
    SimulationEventType["SIMULATION_RECORDING_FINISHED"] = "SIMULATION_RECORDING_FINISHED";
    SimulationEventType["SIMULATION_RECORDING_STARTED"] = "SIMULATION_RECORDING_STARTED";
    SimulationEventType["SIMULATION_SET_AS_AT"] = "SIMULATION_SET_AS_AT";
    SimulationEventType["SIMULATION_SET_AS_AT_COMPLETE"] = "SIMULATION_SET_AS_AT_COMPLETE";
    SimulationEventType["SIMULATION_USER_READY"] = "SIMULATION_USER_READY";
})(SimulationEventType || (SimulationEventType = {}));
export var SimulationRunType;
(function (SimulationRunType) {
    SimulationRunType["EXPLORE"] = "EXPLORE";
    SimulationRunType["RECORD"] = "RECORD";
    SimulationRunType["RUN"] = "RUN";
})(SimulationRunType || (SimulationRunType = {}));
export var SortOrder;
(function (SortOrder) {
    SortOrder["asc"] = "asc";
    SortOrder["desc"] = "desc";
})(SortOrder || (SortOrder = {}));
export var SystemSelfSignUp;
(function (SystemSelfSignUp) {
    SystemSelfSignUp["CONTACT_OPTIONAL"] = "CONTACT_OPTIONAL";
    SystemSelfSignUp["CONTACT_REQUIRED"] = "CONTACT_REQUIRED";
    SystemSelfSignUp["NONE"] = "NONE";
})(SystemSelfSignUp || (SystemSelfSignUp = {}));
export var TaskAssignmentChangeType;
(function (TaskAssignmentChangeType) {
    TaskAssignmentChangeType["NEW_ASSIGNMENT"] = "NEW_ASSIGNMENT";
    TaskAssignmentChangeType["PLAN_CLOSED"] = "PLAN_CLOSED";
    TaskAssignmentChangeType["TASK_OPENED"] = "TASK_OPENED";
    TaskAssignmentChangeType["TASK_SUBMITTED"] = "TASK_SUBMITTED";
})(TaskAssignmentChangeType || (TaskAssignmentChangeType = {}));
export var TemplateImportSeverity;
(function (TemplateImportSeverity) {
    TemplateImportSeverity["FATAL"] = "FATAL";
    TemplateImportSeverity["INFO"] = "INFO";
    TemplateImportSeverity["WARNING"] = "WARNING";
})(TemplateImportSeverity || (TemplateImportSeverity = {}));
export var TemplateImportWarning;
(function (TemplateImportWarning) {
    TemplateImportWarning["ERROR_READING"] = "ERROR_READING";
    TemplateImportWarning["ERROR_SAVING"] = "ERROR_SAVING";
    TemplateImportWarning["FORM_AND_FORM_ASSIGNMENTS_DONT_MATCH"] = "FORM_AND_FORM_ASSIGNMENTS_DONT_MATCH";
    TemplateImportWarning["FORM_CREATE"] = "FORM_CREATE";
    TemplateImportWarning["FORM_IN_SYSTEM_HAS_HIGHER_VERSION"] = "FORM_IN_SYSTEM_HAS_HIGHER_VERSION";
    TemplateImportWarning["FORM_REVISION_CREATE"] = "FORM_REVISION_CREATE";
    TemplateImportWarning["FORM_REVISION_IDENTICAL"] = "FORM_REVISION_IDENTICAL";
    TemplateImportWarning["FORM_REVISION_OVERWRITE"] = "FORM_REVISION_OVERWRITE";
    TemplateImportWarning["MISSING_TEMPLATE_NAME"] = "MISSING_TEMPLATE_NAME";
    TemplateImportWarning["NO_FORMS"] = "NO_FORMS";
    TemplateImportWarning["NO_FORM_ASSIGNMENTS"] = "NO_FORM_ASSIGNMENTS";
    TemplateImportWarning["SCHEMA_UPGRADED"] = "SCHEMA_UPGRADED";
    TemplateImportWarning["TEMPLATE_ALREADY_EXISTS"] = "TEMPLATE_ALREADY_EXISTS";
    TemplateImportWarning["TEMPLATE_CREATE"] = "TEMPLATE_CREATE";
    TemplateImportWarning["TEMPLATE_IN_SYSTEM_HAS_HIGHER_VERSION"] = "TEMPLATE_IN_SYSTEM_HAS_HIGHER_VERSION";
    TemplateImportWarning["TEMPLATE_NAME_DOES_NOT_MATCH"] = "TEMPLATE_NAME_DOES_NOT_MATCH";
    TemplateImportWarning["TEMPLATE_REVISION_CREATE"] = "TEMPLATE_REVISION_CREATE";
    TemplateImportWarning["TEMPLATE_REVISION_IDENTICAL"] = "TEMPLATE_REVISION_IDENTICAL";
    TemplateImportWarning["TEMPLATE_REVISION_OVERWRITE"] = "TEMPLATE_REVISION_OVERWRITE";
    TemplateImportWarning["VERSION_NUMBER_IGNORED"] = "VERSION_NUMBER_IGNORED";
    TemplateImportWarning["WORK_QUEUE_CREATE"] = "WORK_QUEUE_CREATE";
    TemplateImportWarning["WORK_QUEUE_REVISION_IDENTICAL"] = "WORK_QUEUE_REVISION_IDENTICAL";
})(TemplateImportWarning || (TemplateImportWarning = {}));
export var TemplateRevisionStatus;
(function (TemplateRevisionStatus) {
    TemplateRevisionStatus["ACTIVE"] = "ACTIVE";
    TemplateRevisionStatus["IN_REVIEW"] = "IN_REVIEW";
})(TemplateRevisionStatus || (TemplateRevisionStatus = {}));
export var TemplateType;
(function (TemplateType) {
    TemplateType["FORM_ASSIGNMENT"] = "FORM_ASSIGNMENT";
    TemplateType["INTEGRATION"] = "INTEGRATION";
})(TemplateType || (TemplateType = {}));
export var UserType;
(function (UserType) {
    UserType["ADMIN"] = "ADMIN";
    UserType["STAFF"] = "STAFF";
    UserType["USER"] = "USER";
})(UserType || (UserType = {}));
export var WorkQueueDisplayRevisionStatus;
(function (WorkQueueDisplayRevisionStatus) {
    WorkQueueDisplayRevisionStatus["ACTIVE"] = "ACTIVE";
    WorkQueueDisplayRevisionStatus["IN_REVIEW"] = "IN_REVIEW";
})(WorkQueueDisplayRevisionStatus || (WorkQueueDisplayRevisionStatus = {}));
export var WorkQueueDisplaySectionOrder;
(function (WorkQueueDisplaySectionOrder) {
    WorkQueueDisplaySectionOrder["CREATED_AT_ASCENDING"] = "CREATED_AT_ASCENDING";
    WorkQueueDisplaySectionOrder["CREATED_AT_DESCENDING"] = "CREATED_AT_DESCENDING";
    WorkQueueDisplaySectionOrder["PRIORITY_ASCENDING"] = "PRIORITY_ASCENDING";
    WorkQueueDisplaySectionOrder["PRIORITY_DESCENDING"] = "PRIORITY_DESCENDING";
    WorkQueueDisplaySectionOrder["QUEUE_POSITION_ASCENDING"] = "QUEUE_POSITION_ASCENDING";
    WorkQueueDisplaySectionOrder["QUEUE_POSITION_DESCENDING"] = "QUEUE_POSITION_DESCENDING";
})(WorkQueueDisplaySectionOrder || (WorkQueueDisplaySectionOrder = {}));
export var WorkQueueDisplaySectionTextDisplay;
(function (WorkQueueDisplaySectionTextDisplay) {
    WorkQueueDisplaySectionTextDisplay["CUSTOM_TEXT"] = "CUSTOM_TEXT";
    WorkQueueDisplaySectionTextDisplay["FIRST_INITIAL_LAST_NAME"] = "FIRST_INITIAL_LAST_NAME";
    WorkQueueDisplaySectionTextDisplay["FULL_NAME"] = "FULL_NAME";
    WorkQueueDisplaySectionTextDisplay["LAST_FOUR_DIGITS_OF_PHONE"] = "LAST_FOUR_DIGITS_OF_PHONE";
    WorkQueueDisplaySectionTextDisplay["PARTIAL_EMAIL"] = "PARTIAL_EMAIL";
    WorkQueueDisplaySectionTextDisplay["TICKET_NUMBER"] = "TICKET_NUMBER";
})(WorkQueueDisplaySectionTextDisplay || (WorkQueueDisplaySectionTextDisplay = {}));

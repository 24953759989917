import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { Selector } from 'ui-lib';
import { useIdTypeService } from 'lib/api/use-id-type-service';
import { ClientLogger } from 'lib/client-logger';
var DEBUG = false;
export function IdTypeSelector(props) {
    var _a;
    var idTypeService = useIdTypeService();
    var idTypesQuery = idTypeService.useIdTypesQuery({});
    var data = ((_a = idTypesQuery.data) === null || _a === void 0 ? void 0 : _a.idTypes.edges) || [];
    var choices = data.map(function (r) { return ({ id: r.node.id, name: r.node.id }); }) || [];
    var loading = idTypesQuery.loading;
    DEBUG &&
        ClientLogger.debug('IdTypeSelector', 'render', {
            choices: choices,
        });
    if (loading) {
        return _jsx(_Fragment, { children: " " });
    }
    return (_jsx(_Fragment, { children: _jsx(Selector, { selectMultiple: props.selectMultiple, name: "".concat(props.name), items: choices, label: "Id Type" }) }));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useContext, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { dialogClasses, containerClasses } from '../../style/sharedCssClasses';
import { FormContainer } from '../../components/form/FormContainer';
import { ROUTES } from 'Routes';
import { UserContext } from 'lib/auth/user-context';
import { ToastDispatchContext, SEVERITY } from 'ui-lib';
import { useAuthService } from 'lib/api/use-auth-service';
import { ClientLogger } from 'lib/client-logger';
var DEBUG = false;
export function ChangePassword() {
    var _a;
    var navigate = useNavigate();
    var userContext = useContext(UserContext);
    var toastDispatch = useContext(ToastDispatchContext);
    // const classes = useClasses();
    var authApi = useAuthService();
    var params = useParams();
    var location = useLocation();
    var reset_token = (_a = location === null || location === void 0 ? void 0 : location.state) === null || _a === void 0 ? void 0 : _a.reset_token;
    var _b = useState(false), showNewPassword = _b[0], setShowNewPassword = _b[1];
    var _c = useState(false), showConfirmPassword = _c[0], setShowConfirmPassword = _c[1];
    var _d = useState(false), passwordSet = _d[0], setPasswordSet = _d[1];
    var goToLogin = function () {
        navigate(ROUTES.LOGIN);
    };
    DEBUG && ClientLogger.debug('ChangePassword', 'render', { reset_token: reset_token, params: params, location: location });
    return (_jsx(Grid, __assign({ container: true, flexDirection: "column", justifyContent: "center" }, { children: _jsx(Paper, __assign({ sx: containerClasses.formContainer }, { children: _jsx(Grid, __assign({ item: true, sx: containerClasses.itemHolder }, { children: _jsx(Grid, __assign({ container: true, direction: "column" }, { children: !passwordSet ? (_jsxs(_Fragment, { children: [_jsx(Box, __assign({ mt: 1, mb: 3 }, { children: _jsx(Typography, __assign({ variant: "h3" }, { children: "Enter a new password" })) })), _jsx(Formik, __assign({ initialValues: {
                                    password: '',
                                    confirmPassword: '',
                                    token: reset_token || '',
                                }, validate: function (values) {
                                    var errors = {};
                                    if (values.password != values.confirmPassword) {
                                        errors.confirmPassword = 'Passwords do not match';
                                    }
                                    if (values.password.length < 8) {
                                        errors.confirmPassword = 'Password must be at least 8 characters long';
                                    }
                                    return errors;
                                }, onSubmit: function (values, _a) {
                                    var setSubmitting = _a.setSubmitting;
                                    DEBUG && ClientLogger.debug('ChangePassword', 'onSubmit:values', { values: values });
                                    authApi
                                        .passwordResetFinish(values.token, values.password)
                                        .then(function (result) {
                                        setSubmitting(false);
                                        if (result) {
                                            setPasswordSet(true);
                                        }
                                        else {
                                            DEBUG && ClientLogger.debug('changePassword', 'failed');
                                            toastDispatch({ msg: 'Failed to change password', severity: SEVERITY.ERROR });
                                        }
                                    })
                                        .catch(function (e) {
                                        setSubmitting(false);
                                        ClientLogger.error('ChangePassword', 'Error', e);
                                        toastDispatch({ msg: 'Failed to change password', severity: SEVERITY.ERROR });
                                    });
                                } }, { children: function (_a) {
                                    var submitForm = _a.submitForm, isSubmitting = _a.isSubmitting;
                                    return (_jsxs(FormContainer, { children: [_jsx(Grid, __assign({ item: true, sx: dialogClasses.inputField }, { children: _jsx(Field, { component: TextField, variant: "outlined", type: showNewPassword ? 'text' : 'password', label: "New Password", name: "password", fullWidth: true, "data-test": "input-new-pswd", InputProps: {
                                                        endAdornment: (_jsx(Typography, __assign({ onClick: function () { return (showNewPassword ? setShowNewPassword(false) : setShowNewPassword(true)); }, variant: "caption" }, { children: showNewPassword ? 'Hide' : 'Show' }))),
                                                    } }) })), _jsx(Grid, __assign({ item: true, xs: 12, sx: dialogClasses.inputField }, { children: _jsx(Field, { fullWidth: true, sx: dialogClasses.inputField, component: TextField, type: showConfirmPassword ? 'text' : 'password', label: "Confirm New Password", name: "confirmPassword", variant: "outlined", InputProps: {
                                                        endAdornment: (_jsx(Typography, __assign({ onClick: function () { return (showConfirmPassword ? setShowConfirmPassword(false) : setShowConfirmPassword(true)); }, variant: "caption" }, { children: showConfirmPassword ? 'Hide' : 'Show' }))),
                                                    } }) })), _jsx(Grid, __assign({ item: true }, { children: _jsx(Button, __assign({ type: "submit", variant: "contained", color: "primary", fullWidth: true, disabled: isSubmitting, onClick: submitForm }, { children: "Set New Password" })) }))] }));
                                } }))] })) : (_jsxs(_Fragment, { children: [_jsx(Box, __assign({ mt: 1, mb: 2 }, { children: _jsx(Typography, __assign({ variant: "h3" }, { children: "Success" })) })), _jsx(Box, __assign({ mb: 8 }, { children: _jsx(Typography, { children: "Your new password has been successfully set." }) })), _jsx(Grid, __assign({ item: true }, { children: _jsx(Button, __assign({ type: "button", variant: "contained", color: "primary", fullWidth: true, onClick: goToLogin }, { children: "Login" })) }))] })) })) })) })) })));
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import * as React from 'react';
var classes = {
    spinner: {
        marginTop: 36,
        marginBottom: 18,
        alignSelf: 'center',
    },
};
export function Loading(props) {
    var messages = props.messages, showItem = props.showItem;
    var anim = showItem || 'spinner';
    return (_jsxs(Grid, __assign({ container: true, direction: "column", alignContent: "center" }, { children: [anim === 'spinner' && (_jsx(Box, __assign({ sx: classes.spinner }, { children: _jsx(CircularProgress, {}) }))), messages && (_jsx(Box, { children: messages.map(function (message) {
                    return (_jsx(Typography, __assign({ align: "center" }, { children: message }), message));
                }) }))] })));
}

import { LocalizedString } from './localized-string';
const tuple = <T extends string[]>(...args: T) => args;
export const UserTypes = tuple('ADMIN', 'STAFF', 'USER');
export declare type UserType = typeof UserTypes[number];
export type IdentityVisibilityType = 'DEFAULT' | 'FIRST_NAME_ONLY';
export type ChatSettingsType = 'NOTIFICATIONS_NEVER' | 'NOTIFICATIONS_ALWAYS' | 'NO_CHAT'; // To do: Add according to user preference option;

export interface CircleOfCareRole {
  id: string;
  name: LocalizedString;
  description: LocalizedString;
  userTypes: UserType[];
  required: boolean;
  visibility: IdentityVisibilityType;
  primary: boolean;
  chatSettings?: ChatSettingsType; // Not specified is NOTIFICATIONS_NEVER
  maxUsers?: number; // Not specified is 1
}
export interface CircleOfCareRoleLocalized {
  id: string;
  name: string;
  description: string;
  userTypes: UserType[];
  required: boolean; // Optional by default
  visibility: IdentityVisibilityType; // DEFAULT is Default
  primary: boolean;
}

export type CircleOfCareRoles = CircleOfCareRole[];

export interface CircleOfCareAssignment {
  // Parity with CarePlanRoleAssignment
  roleId: string;
  userId: string;
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { NetworkStatus, useMutation, useQuery, } from '@apollo/client';
import { getVeridApolloClient, getVeridApolloClientWithoutContext } from './apollo-client';
import { UserContext } from '../auth/user-context';
import { useContext } from 'react';
export function useApi() {
    var userContext = useContext(UserContext);
    return {
        query: function (options) {
            return getVeridApolloClient().query(__assign({ errorPolicy: 'all', context: userContext }, options));
        },
        queryWithoutContext: function (options) {
            return getVeridApolloClientWithoutContext().query(__assign({ errorPolicy: 'all' }, options));
        },
        mutate: function (options) {
            return getVeridApolloClient().mutate(__assign({ errorPolicy: 'all', context: userContext }, options));
        },
        useMutation: function (mutation, options) {
            return useMutation(mutation, __assign({ client: getVeridApolloClient(), context: userContext }, options));
        },
        useQuery: function (query, options) {
            return useQuery(query, __assign({ client: getVeridApolloClient(), context: userContext }, options));
        },
        generateErrorResponse: function (error) {
            return {
                errors: [error],
                loading: false,
                networkStatus: NetworkStatus.error,
                stale: false,
            };
        },
        userContext: userContext,
    };
}

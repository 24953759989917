import * as _ from 'lodash';
var DEBUG = false;
var TemplateUtil = /** @class */ (function () {
    function TemplateUtil() {
    }
    TemplateUtil.enumDisplayName = function (enumText) {
        return _.startCase(enumText === null || enumText === void 0 ? void 0 : enumText.toLowerCase());
    };
    TemplateUtil.allRoles = function () {
        // ToDo: Should reference template revision roles object or Prof Design table
        var oldEnum = ['EXTERNALLY_PROVIDED', 'LOCALLY_PROVIDED', 'OPEN_ID', 'OAUTH', 'SAML'];
        return oldEnum.map(function (key) {
            return { id: key, name: TemplateUtil.enumDisplayName(key) };
        });
    };
    return TemplateUtil;
}());
export { TemplateUtil };
var AuthConfigUtil = /** @class */ (function () {
    function AuthConfigUtil() {
    }
    AuthConfigUtil.convertArrayToString = function (values) {
        if (typeof values == 'string') {
            return values;
        }
        else if (!values.length) {
            return '';
        }
        return values.join(', ');
    };
    AuthConfigUtil.convertStringToArray = function (value) {
        if (typeof value == 'object') {
            return value;
        }
        else if (!value) {
            return [];
        }
        var valArray = value.toUpperCase().split(',');
        return valArray.map(function (value) {
            return value.trim();
        });
    };
    return AuthConfigUtil;
}());
export { AuthConfigUtil };

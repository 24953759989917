var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, DialogContent, DialogTitle, IconButton, InputLabel, MenuItem, Select, Typography, TextField, InputAdornment, } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AuthApiKeyType } from 'lib/api/api-types';
import { ClientLogger } from 'lib/client-logger';
import { useEffect, useRef, useState } from 'react';
import { DataTable } from 'ui-lib';
import { dialogClasses, formClasses, tableClasses } from '../../style/sharedCssClasses';
import CloseIcon from '@mui/icons-material/Clear';
import { useAuthApiKeyService } from 'lib/api/use-auth-api-key-service';
import { CopyAll } from '@mui/icons-material';
import { ErrorMessage, Field, Formik } from 'formik';
import { usePersonOrgService } from 'lib/api/use-person-org-serivce';
var initialValues = {
    name: '',
    keyType: AuthApiKeyType.ADMIN,
    origin: '',
    personOrgId: '',
};
var DEBUG = false;
var useClasses = makeStyles(__assign({}, formClasses));
export function ManageOrgApiKeys(props) {
    var _this = this;
    var orgId = props.orgId;
    var formRef = useRef(null);
    var authApiKeyService = useAuthApiKeyService();
    var personOrgService = usePersonOrgService();
    var dialogTitle = 'Edit API Keys';
    var _a = useState(), newApiKeyValue = _a[0], setNewApiKeyValue = _a[1];
    var _b = useState(false), newAPiKeyCreatedDialogOpen = _b[0], setNewApiKeyCreatedDialogOpen = _b[1];
    var _c = useState([]), sysAccounts = _c[0], setSysAccounts = _c[1];
    var classes = useClasses();
    var authApiKeysForOrgDataTableOptions = {
        pagination: false,
        download: false,
        print: false,
        filter: false,
        search: false,
        viewColumns: false,
        sort: true,
        selectableRows: 'none',
    };
    var columns = {
        id: { display: 'excluded' },
        name: { label: 'Name' },
        description: { label: 'Description' },
        origin: { label: 'Origin' },
        keyType: { label: 'Key Type' },
        enabled: {
            label: 'Enabled',
            customBodyRender: function (value) {
                return _jsx(_Fragment, { children: "".concat(value) });
            },
        },
        createdAt: { label: 'Created At' },
        expiresAt: { label: 'Expires At' },
        buttons: {
            label: 'Action',
            customBodyRender: function (buttonData) {
                if (buttonData !== undefined && buttonData !== null && buttonData.enabled) {
                    return (_jsx(Button, __assign({ color: "error", variant: "contained", onClick: function () {
                            revokeAuthApiKey(buttonData.id);
                        } }, { children: "Revoke" })));
                }
            },
        },
    };
    useEffect(function () {
        personOrgService.sysAccountsForOrg(orgId).then(function (response) {
            var sysAccounts = transformQueryResultToPersonOrg(response.data);
            setSysAccounts(sysAccounts);
        });
    }, []);
    var transformQueryResultToPersonOrg = function (resp) {
        if (!resp || !resp.personOrgs.edges || !resp.personOrgs.edges) {
            return [];
        }
        return resp.personOrgs.edges.map(function (edge) { return (__assign({}, edge.node)); });
    };
    var revokeAuthApiKey = function (id) { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, authApiKeyService.authApiKeyRevoke(id)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var renderSelectApiKeyTypes = function () {
        var authApiKeyTypes = Object.values(AuthApiKeyType);
        return authApiKeyTypes.map(function (authApiKeyType) {
            return (_jsx(MenuItem, __assign({ value: authApiKeyType }, { children: authApiKeyType }), authApiKeyType));
        });
    };
    var generateNewApiKey = function (values, _a) {
        var resetForm = _a.resetForm;
        return __awaiter(_this, void 0, void 0, function () {
            var authAPiKeyCreateInput, response;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        DEBUG && ClientLogger.debug('EditOrgApiKeys', "onSubmit values", values);
                        authAPiKeyCreateInput = {
                            keyType: AuthApiKeyType[values.keyType],
                            name: values.name,
                            orgId: orgId,
                            origin: values.origin,
                            description: values.description,
                            personOrgId: values.personOrgId,
                        };
                        return [4 /*yield*/, authApiKeyService.authApiKeyCreate(authAPiKeyCreateInput)];
                    case 1:
                        response = _c.sent();
                        setNewApiKeyValue((_b = response.data) === null || _b === void 0 ? void 0 : _b.authApiKeyCreate.value);
                        setNewApiKeyCreatedDialogOpen(true);
                        resetForm();
                        return [2 /*return*/];
                }
            });
        });
    };
    var copyNewApiKeyValue = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!('clipboard' in navigator)) return [3 /*break*/, 2];
                    return [4 /*yield*/, navigator.clipboard.writeText(newApiKeyValue || '')];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var transformResponseToRows = function (resp) {
        if (!resp || !resp.authApiKeysForOrg.edges || !resp.authApiKeysForOrg.edges) {
            return [];
        }
        return resp.authApiKeysForOrg.edges.map(function (authApiKeyForOrg) { return (__assign(__assign({}, authApiKeyForOrg.node), { buttons: { id: authApiKeyForOrg.node.id, enabled: authApiKeyForOrg.node.enabled } })); });
    };
    var validateForm = function (values) {
        var errors = {};
        if (!values.name) {
            errors.name = 'Required';
        }
        if (!values.origin) {
            errors.origin = 'Required';
        }
        if (!values.keyType) {
            errors.keyType = 'Required';
        }
        if (!values.personOrgId) {
            errors.personOrgId = 'Required';
        }
        return errors;
    };
    var tableProps = {
        useQuery: authApiKeyService.useAuthApiKeysForOrgQuery({ variables: { query: { orgId: orgId } } }),
        initialQuery: {},
        transformResponseToRows: transformResponseToRows,
        columnAttributes: columns,
        tableAttributes: authApiKeysForOrgDataTableOptions,
    };
    DEBUG && ClientLogger.debug('EditOrgApiKeys', 'render', { props: props });
    return (_jsx(Formik, __assign({ innerRef: formRef, initialValues: initialValues, validate: validateForm, onSubmit: generateNewApiKey }, { children: function (_a) {
            var submitForm = _a.submitForm, isSubmitting = _a.isSubmitting, errors = _a.errors, values = _a.values, setFieldValue = _a.setFieldValue;
            DEBUG && ClientLogger.debug('EditOrg', 'render form', { errors: errors, values: values, submitForm: submitForm });
            return (_jsxs(_Fragment, { children: [_jsxs(DialogTitle, __assign({ sx: dialogClasses.dialogTitle }, { children: [dialogTitle, _jsx(IconButton, __assign({ onClick: props.onClose, size: "large" }, { children: _jsx(CloseIcon, { sx: dialogClasses.closeIcon }) }))] })), _jsxs(DialogContent, { children: [_jsx(Typography, __assign({ variant: "h6" }, { children: "Generate New Api Key" })), _jsxs(Box, __assign({ mt: 2 }, { children: [_jsx(Field, { component: TextField, name: "name", label: "Name", type: "text", fullWidth: true, variant: "outlined", sx: dialogClasses.inputField, onChange: function (ev) {
                                            setFieldValue('name', ev.target.value);
                                        } }), _jsx(ErrorMessage, { component: Typography, className: classes.formError, name: "name" })] })), _jsx(Box, __assign({ mt: 2 }, { children: _jsx(Field, { component: TextField, name: "description", label: "Description (Optional)", type: "text", fullWidth: true, variant: "outlined", sx: dialogClasses.inputField, onChange: function (ev) {
                                        setFieldValue('description', ev.target.value);
                                    } }) })), _jsxs(Box, __assign({ mt: 2 }, { children: [_jsx(Field, { component: TextField, name: "origin", label: "Origin URL", type: "text", fullWidth: true, variant: "outlined", sx: dialogClasses.inputField, onChange: function (ev) {
                                            setFieldValue('origin', ev.target.value);
                                        } }), _jsx(ErrorMessage, { component: Typography, className: classes.formError, name: "origin" })] })), _jsxs(Box, __assign({ mt: 2 }, { children: [_jsx(Field, __assign({ name: "keyType", fullWidth: true, variant: "outlined", sx: dialogClasses.inputField }, { children: function (_a) {
                                            var field = _a.field;
                                            return (_jsxs(_Fragment, { children: [_jsx(InputLabel, __assign({ id: "inputLabelSelectKeyType" }, { children: "Key Type" })), _jsx(Select, __assign({ fullWidth: true, labelId: "inputLabelSelectKeyType", defaultValue: AuthApiKeyType.ADMIN, onChange: function (ev) {
                                                            setFieldValue(field.name, ev.target.value);
                                                        } }, { children: renderSelectApiKeyTypes() }))] }));
                                        } })), _jsx(ErrorMessage, { component: Typography, className: classes.formError, name: "keyType" })] })), _jsxs(Box, __assign({ mt: 2 }, { children: [_jsx(Field, __assign({ name: "personOrgId", fullWidth: true, variant: "outlined", sx: dialogClasses.inputField }, { children: function (_a) {
                                            var field = _a.field;
                                            return (_jsxs(_Fragment, { children: [_jsx(InputLabel, __assign({ id: "inputLabelSelectKeyType" }, { children: "System Account" })), _jsx(Select, __assign({ fullWidth: true, labelId: "inputLabelSelectSysAccount", onChange: function (ev) {
                                                            setFieldValue(field.name, ev.target.value);
                                                        } }, { children: sysAccounts.map(function (sysAccount) {
                                                            return (_jsx(MenuItem, __assign({ value: sysAccount.id }, { children: "".concat(sysAccount.person.firstName, " ").concat(sysAccount.person.lastName) }), sysAccount.id));
                                                        }) }))] }));
                                        } })), _jsx(ErrorMessage, { component: Typography, className: classes.formError, name: "personOrgId" })] })), _jsx(Box, __assign({ mt: 2 }, { children: _jsx(Button, __assign({ type: "submit", color: "primary", disabled: isSubmitting, onClick: submitForm }, { children: "Generate" })) })), newAPiKeyCreatedDialogOpen && (_jsxs(Box, __assign({ mt: 2 }, { children: [_jsx(Typography, __assign({ variant: "h6" }, { children: "Please store this API Key safely" })), _jsx(TextField, { disabled: true, fullWidth: true, value: newApiKeyValue, type: "text", InputProps: 'clipboard' in navigator
                                            ? {
                                                endAdornment: (_jsx(InputAdornment, __assign({ position: "end" }, { children: _jsx(IconButton, __assign({ onClick: copyNewApiKeyValue }, { children: _jsx(CopyAll, {}) })) }))),
                                            }
                                            : {} })] }))), _jsxs(Box, __assign({ mt: 12, sx: tableClasses.tableHolder }, { children: [_jsx(Typography, __assign({ variant: "h6" }, { children: "Existing Api Keys" })), _jsx(DataTable, __assign({}, tableProps))] }))] })] }));
        } })));
}

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { gql } from '@apollo/client';
import { useApi } from './use-api';
var DEBUG = false;
export var AGREEMENT_FIELDS = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  fragment AgreementFragment on Agreement {\n    id\n    createdAt\n    updatedAt\n    name\n    status\n    latestVersion\n    roles\n    content\n    requiresAcceptance\n  }\n"], ["\n  fragment AgreementFragment on Agreement {\n    id\n    createdAt\n    updatedAt\n    name\n    status\n    latestVersion\n    roles\n    content\n    requiresAcceptance\n  }\n"])));
export var AGREEMENT_VERSION_FIELDS = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  fragment AgreementVersionFragment on AgreementVersion {\n    id\n    createdAt\n    version\n    requiresAcceptance\n    content\n  }\n"], ["\n  fragment AgreementVersionFragment on AgreementVersion {\n    id\n    createdAt\n    version\n    requiresAcceptance\n    content\n  }\n"])));
export var AGREEMENT_REQURIED_FIELDS = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  fragment AgreementRequiredFragment on AgreementRequired {\n    agreementId\n    agreementName\n    content\n    orgName\n    version\n    versionId\n  }\n"], ["\n  fragment AgreementRequiredFragment on AgreementRequired {\n    agreementId\n    agreementName\n    content\n    orgName\n    version\n    versionId\n  }\n"])));
export function useAgreementService() {
    var api = useApi();
    var agreementssQuery = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    ", "\n    query agreements($name: String, $orgId: String!, $orderBy: AgreementOrder, $first: Int, $after: String) {\n      agreements(query: { name: $name, orgId: $orgId }, orderBy: $orderBy, first: $first, after: $after) {\n        edges {\n          cursor\n          node {\n            ...AgreementFragment\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n          hasPreviousPage\n          startCursor\n        }\n        totalCount\n      }\n    }\n  "], ["\n    ", "\n    query agreements($name: String, $orgId: String!, $orderBy: AgreementOrder, $first: Int, $after: String) {\n      agreements(query: { name: $name, orgId: $orgId }, orderBy: $orderBy, first: $first, after: $after) {\n        edges {\n          cursor\n          node {\n            ...AgreementFragment\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n          hasPreviousPage\n          startCursor\n        }\n        totalCount\n      }\n    }\n  "])), AGREEMENT_FIELDS);
    return {
        useAgreementQuery: function (options) {
            function fct() {
                return api.useQuery(agreementssQuery, options);
            }
            return fct;
        },
        agreement: function (id) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, api.query({
                            query: gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n          ", "\n          ", "\n          query agreement($id: String!) {\n            agreement(id: $id) {\n              ...AgreementFragment\n              versions {\n                ...AgreementVersionFragment\n              }\n            }\n          }\n        "], ["\n          ", "\n          ", "\n          query agreement($id: String!) {\n            agreement(id: $id) {\n              ...AgreementFragment\n              versions {\n                ...AgreementVersionFragment\n              }\n            }\n          }\n        "])), AGREEMENT_FIELDS, AGREEMENT_VERSION_FIELDS),
                            variables: { id: id },
                            fetchPolicy: 'network-only',
                        })];
                });
            });
        },
        agreementCreate: function (data) {
            return __awaiter(this, void 0, Promise, function () {
                var retVal;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, api.mutate({
                                mutation: gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n          mutation agreementCreate($data: AgreementCreateInput!) {\n            agreementCreate(data: $data) {\n              id\n            }\n          }\n        "], ["\n          mutation agreementCreate($data: AgreementCreateInput!) {\n            agreementCreate(data: $data) {\n              id\n            }\n          }\n        "]))),
                                variables: { data: data },
                                refetchQueries: ['agreements'],
                            })];
                        case 1:
                            retVal = _a.sent();
                            return [2 /*return*/, retVal];
                    }
                });
            });
        },
        agreementUpdate: function (agreementId, data) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, api.mutate({
                            mutation: gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n          mutation agreementUpdate($id: String!, $data: AgreementUpdateInput!) {\n            agreementUpdate(data: $data, id: $id) {\n              id\n            }\n          }\n        "], ["\n          mutation agreementUpdate($id: String!, $data: AgreementUpdateInput!) {\n            agreementUpdate(data: $data, id: $id) {\n              id\n            }\n          }\n        "]))),
                            variables: { data: data, id: agreementId },
                            refetchQueries: ['agreements'],
                        })];
                });
            });
        },
        agreementsRequired: function () {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, api.query({
                            query: gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n          ", "\n          query agreementsRequired {\n            agreementsRequired {\n              ...AgreementRequiredFragment\n            }\n          }\n        "], ["\n          ", "\n          query agreementsRequired {\n            agreementsRequired {\n              ...AgreementRequiredFragment\n            }\n          }\n        "])), AGREEMENT_REQURIED_FIELDS),
                        })];
                });
            });
        },
        agreementAcceptanceCreate: function (data) {
            return __awaiter(this, void 0, Promise, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, api.mutate({
                            mutation: gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n          mutation agreementAcceptanceCreate($data: AgreementAcceptanceCreateInput!) {\n            agreementAcceptanceCreate(data: $data) {\n              id\n            }\n          }\n        "], ["\n          mutation agreementAcceptanceCreate($data: AgreementAcceptanceCreateInput!) {\n            agreementAcceptanceCreate(data: $data) {\n              id\n            }\n          }\n        "]))),
                            variables: { data: data },
                            refetchQueries: ['agreementsRequired'],
                        })];
                });
            });
        },
    };
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid, Typography } from '@mui/material';
import { Form } from 'formik';
import React from 'react';
import { FormError } from './FormError';
/**
 * A wrapper for forms to enforce standard borders, headers and form level errors
 *
 * @param children
 * @param errorMessage
 * @constructor
 */
export var FormContainer = function (_a) {
    var children = _a.children, errorMessage = _a.errorMessage, title = _a.title, className = _a.className;
    return (_jsxs(Form, __assign({ className: className }, { children: [title ? (_jsx(Typography, __assign({ variant: "h4", align: "center" }, { children: title }))) : (_jsx(_Fragment, {})), errorMessage && _jsx(FormError, { errorMessage: errorMessage }), _jsx(Grid, __assign({ container: true, direction: "column", justifyContent: "center", alignItems: "center" }, { children: children }))] })));
};

export enum FormViewType {
  SCHEDULE = 'SCHEDULE',
  SUMMARY = 'SUMMARY',
  ASSIGNMENT = 'ASSIGNMENT',
  GALLERY = 'GALLERY',
  ACTIVITY = 'ACTIVITY',
}

export interface FormView {
  name: string;
  displayName: string;
  formName: string;
  type: FormViewType;
  sortField?: string;
  richText: string;
  forRole?: string;
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Dialog, Grid } from '@mui/material';
import React, { useState } from 'react';
import { DataTable } from 'ui-lib';
import { ClientLogger } from 'lib/client-logger';
import { EditDesignation } from 'verid-ui/src/pages/designation/EditDesignation';
import { useDesignationService } from 'lib/api/use-designation-service';
import { TopBackBtnBar } from 'verid-ui/src/components/navigation/TopBackBtnBar';
import makeStyles from '@mui/styles/makeStyles';
import { tableClasses } from 'verid-ui/src/style/sharedCssClasses';
var useClasses = makeStyles(__assign({}, tableClasses));
var DEBUG = true;
export var DesignationList = function () {
    var classes = useClasses();
    var designationService = useDesignationService();
    var _a = useState(false), editOpen = _a[0], setEditOpen = _a[1]; //false not open, undefined open with new, string orgId beiong edited
    var dataTableOptions = {
        pagination: true,
        download: true,
        print: true,
        filter: false,
        search: false,
        viewColumns: false,
        sort: true,
        selectableRows: 'none',
    };
    var columns = {
        id: { label: 'Name (id)' },
        updatedAt: { display: 'excluded' },
        description: { label: 'Description' },
        createdAt: { label: 'Created At' },
        status: { label: 'Status' },
    };
    var transformResponseToRows = function (resp) {
        var _a;
        if (!resp || !resp.designations || !((_a = resp.designations) === null || _a === void 0 ? void 0 : _a.edges)) {
            return [];
        }
        return resp.designations.edges.map(function (designations) { return (__assign({}, designations.node)); });
    };
    var rowClicked = function (row) {
        setEditOpen(row.id);
    };
    var tableProps = {
        useQuery: designationService.useDesignationsQuery,
        initialQuery: {},
        transformResponseToRows: transformResponseToRows,
        columnAttributes: columns,
        tableAttributes: dataTableOptions,
        onAdd: function () {
            DEBUG && ClientLogger.debug('onAdd', '', {});
            setEditOpen(undefined);
        },
        onRowClick: rowClicked,
    };
    DEBUG && ClientLogger.debug('OrgList', 'render', { tableProps: tableProps });
    return (_jsxs(_Fragment, { children: [_jsxs(Grid, __assign({ container: true }, { children: [_jsx(TopBackBtnBar, { title: "Designations" }), _jsx(Box, __assign({ mx: 2, mt: 6, mb: 1, className: classes.tableHolder }, { children: _jsx(DataTable, __assign({}, tableProps)) }))] })), _jsx(Dialog, __assign({ open: !(editOpen === false), onClose: function () { return setEditOpen(false); }, maxWidth: "sm", fullWidth: true }, { children: _jsx(EditDesignation, { onClose: function () {
                        setEditOpen(false);
                    }, editingId: typeof editOpen === 'string' ? editOpen : undefined }) }))] }));
};

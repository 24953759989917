import { ApolloClient, createHttpLink, ApolloLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { TokenRefreshLink } from '../auth/token-refresh-link';
import { logout } from './use-auth-service';
import { ClientLogger } from '../client-logger';
import { Util } from 'ui-lib';
export var apiContext = function (userContext) {
    return {
        endPoint: 'api',
        userContext: userContext,
    };
};
var DEBUG = false;
ClientLogger.log('Loading API', "apollo endpoint for api = ".concat(Util.getEnvVar('REACT_APP_VERID_API')));
var singleton = null;
var singletonWithoutContext = null;
// https://www.apollographql.com/docs/link/composition/
export var getVeridApolloClient = function () {
    if (singleton) {
        DEBUG && ClientLogger.debug('getVeridApolloClient', 'Returning existing apolloClient');
        return singleton.apolloClient;
    }
    DEBUG && ClientLogger.debug('getVeridApolloClient', 'creating new apolloClient');
    var tokenRenewalLink = ApolloLink.from([new TokenRefreshLink()]);
    var errorLink = onError(function (_a) {
        var graphQLErrors = _a.graphQLErrors, networkError = _a.networkError, response = _a.response, operation = _a.operation;
        ClientLogger.error('getVeridApolloClient', 'graphQLErrors', { graphQLErrors: graphQLErrors, networkError: networkError, response: response, operation: operation });
        if (graphQLErrors) {
            graphQLErrors.forEach(function (_a) {
                var extensions = _a.extensions, message = _a.message, locations = _a.locations, path = _a.path;
                ClientLogger.error('apollo-client.getVeridApolloClient', "[GraphQL error]: Message: ".concat(message, ", Location: ").concat(locations, ", Path: ").concat(path));
                if ((extensions === null || extensions === void 0 ? void 0 : extensions.code) === 'UNAUTHENTICATED') {
                    ClientLogger.error('apollo-client.getVeridApolloClient', "detected unauthenticated - why was this not picked up by token-refresh?}");
                    // AuthClient.logout(userContext, '/');  // Causes endless loop - need further condition
                    logout();
                }
            });
        }
        if (networkError) {
            if (networkError.name && networkError.name.indexOf('Error writing result to store for query') > -1) {
                ClientLogger.log('getVeridApolloClient', 'Error writing result to store for query detected. Assumed to be a faulty cache.');
            }
            ClientLogger.log('getVeridApolloClient', "Network error: ".concat(JSON.stringify(networkError)));
        }
    });
    var apiLinks = ApolloLink.from([
        tokenRenewalLink,
        errorLink,
        createHttpLink({
            uri: Util.getEnvVar('REACT_APP_VERID_API', DEBUG),
            credentials: 'include',
        }),
    ]);
    singleton = {
        apolloClient: new ApolloClient({
            link: apiLinks,
            cache: new InMemoryCache({ typePolicies: { Form: { keyFields: ['name'] } } }),
            connectToDevTools: true,
        }),
    };
    return singleton === null || singleton === void 0 ? void 0 : singleton.apolloClient;
};
export var getVeridApolloClientWithoutContext = function () {
    if (singletonWithoutContext) {
        DEBUG && ClientLogger.debug('getVeridApolloClient', 'Returning existing apolloClient');
        return singletonWithoutContext.apolloClient;
    }
    DEBUG && ClientLogger.debug('getVeridApolloClient', 'creating new apolloClient');
    var errorLink = onError(function (_a) {
        var graphQLErrors = _a.graphQLErrors, networkError = _a.networkError, response = _a.response, operation = _a.operation;
        ClientLogger.error('getVeridApolloClient', 'graphQLErrors', { graphQLErrors: graphQLErrors, networkError: networkError, response: response, operation: operation });
        if (graphQLErrors) {
            graphQLErrors.forEach(function (_a) {
                var extensions = _a.extensions, message = _a.message, locations = _a.locations, path = _a.path;
                ClientLogger.error('apollo-client.getVeridApolloClient', "[GraphQL error]: Message: ".concat(message, ", Location: ").concat(locations, ", Path: ").concat(path));
                if ((extensions === null || extensions === void 0 ? void 0 : extensions.code) === 'UNAUTHENTICATED') {
                    ClientLogger.error('apollo-client.getVeridApolloClient', "detected unauthenticated - why was this not picked up by token-refresh?}");
                    // AuthClient.logout(userContext, '/');  // Causes endless loop - need further condition
                    logout();
                }
            });
        }
        if (networkError) {
            if (networkError.name && networkError.name.indexOf('Error writing result to store for query') > -1) {
                ClientLogger.log('getVeridApolloClient', 'Error writing result to store for query detected. Assumed to be a faulty cache.');
            }
            ClientLogger.log('getVeridApolloClient', "Network error: ".concat(JSON.stringify(networkError)));
        }
    });
    var apiLinks = ApolloLink.from([
        errorLink,
        createHttpLink({
            uri: Util.getEnvVar('REACT_APP_VERID_API', DEBUG),
        }),
    ]);
    singletonWithoutContext = {
        apolloClient: new ApolloClient({
            link: apiLinks,
            cache: new InMemoryCache({ typePolicies: { Form: { keyFields: ['name'] } } }),
            connectToDevTools: true,
        }),
    };
    return singletonWithoutContext === null || singletonWithoutContext === void 0 ? void 0 : singletonWithoutContext.apolloClient;
};

import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { ROUTES } from './Routes';
import { ClientLogger } from 'lib/client-logger';
import { useNavigate } from 'react-router-dom';
var DEBUG = false;
function parseQueryString(searchString, key) {
    var query = searchString.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === key) {
            return decodeURIComponent(pair[1]);
        }
    }
    return undefined;
}
export function QueryChecker() {
    var navigate = useNavigate();
    useEffect(function () {
        var queryStrings = window.location.search;
        var debugLocation = 'Root.useEffect';
        if (queryStrings) {
            DEBUG && ClientLogger.debug(debugLocation, "Detected query strings ".concat(queryStrings));
            var reset_token = parseQueryString(queryStrings, 'reset_token');
            if (reset_token) {
                DEBUG && ClientLogger.debug(debugLocation, "Detected reset_token ".concat(reset_token));
                navigate(ROUTES.RESET_PASSWORD_FINISH, { state: { reset_token: reset_token } });
            }
        }
    }, []);
    return _jsx(_Fragment, {});
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DataTable } from 'ui-lib';
import DeleteIcon from '@mui/icons-material/Delete';
import { ClientLogger } from 'lib/client-logger';
import { EditPersonOrg } from './EditPersonOrg';
import { useErrorHandler } from 'lib/use-error-handler';
import { SelectorNoForm } from 'ui-lib';
import { UserContext } from 'lib/auth/user-context';
import { usePersonOrgService } from 'lib/api/use-person-org-serivce';
import { Loading } from 'components/widgets/Loading';
import { AddPersonOrg } from './AddPersonOrg';
import { TopBackBtnBar } from '../../components/navigation/TopBackBtnBar';
import makeStyles from '@mui/styles/makeStyles';
import { tableClasses } from '../../style/sharedCssClasses';
var useClasses = makeStyles(__assign({}, tableClasses));
var DEBUG = false;
export var PersonOrgList = function () {
    var classes = useClasses();
    var personOrgService = usePersonOrgService();
    var userContext = useContext(UserContext);
    var params = useParams();
    var errorHandler = useErrorHandler('PersonOrgList');
    var specifiedOrgId = params.orgId;
    var _a = useState(false), editOpen = _a[0], setEditOpen = _a[1]; //false not open, undefined open with new, string orgId beiong edited
    var _b = useState(false), removeOpen = _b[0], setRemoveOpen = _b[1]; //false not open, undefined open with new, string orgId beiong edited
    var _c = useState(specifiedOrgId || userContext.$.currentOrgId()), currentOrgId = _c[0], setCurrentOrgId = _c[1];
    var _d = useState(userContext.$.getOrgs()), orgsList = _d[0], setOrgsList = _d[1];
    var _e = useState(false), reload = _e[0], setReload = _e[1];
    useEffect(function () {
        var orgs = userContext.$.getOrgs();
        var currentId = userContext.$.currentOrgId();
        setOrgsList(orgs);
        if (currentId && orgs.find(function (org) { return org.id === currentOrgId; }) === undefined) {
            setReload(true);
            setCurrentOrgId(currentId);
        }
    }, [userContext]);
    useEffect(function () {
        if (currentOrgId) {
            setReload(false);
        }
    }, [currentOrgId]);
    var dataTableOptions = {
        pagination: true,
        download: true,
        print: true,
        filter: false,
        search: false,
        viewColumns: false,
        sort: true,
        selectableRows: 'none',
    };
    var columns = {
        title: { display: 'excluded' },
        firstName: { label: 'First Name' },
        middleName: { display: 'excluded' },
        lastName: { label: 'Last Name' },
        updatedAt: { display: 'excluded' },
        gender: { display: 'excluded' },
        dateOfBirth: { display: 'excluded' },
        createdAt: { label: 'Created At' },
        status: { label: 'Status' },
        personOrgs: { display: 'excluded' },
        id: { display: 'excluded' },
        attributes: { display: 'excluded' },
        currentOrg: { display: 'excluded' },
        role: { label: 'Role' },
        isTest: {
            label: 'Test',
            customBodyRender: function (value) {
                return _jsx(_Fragment, { children: "".concat(value) });
            },
        },
        systemAccount: {
            label: 'System Account',
            customBodyRender: function (value) {
                return _jsx(_Fragment, { children: "".concat(value !== null ? value : false) });
            },
        },
        buttons: {
            label: '',
            customBodyRender: function (value) {
                if (value !== undefined && value !== null) {
                    return (_jsx(Box, __assign({ display: "flex", justifyContent: "space-evenly", alignItems: "start" }, { children: _jsx(IconButton, __assign({ onClick: function (e) {
                                e.stopPropagation();
                                DEBUG && ClientLogger.debug('custom render click', '', value);
                                setRemoveOpen(value);
                            }, color: "primary" }, { children: _jsx(DeleteIcon, {}) })) })));
                }
                else {
                    return _jsx(_Fragment, {});
                }
            },
            customHeadRender: function () { return _jsx("td", {}, "edit"); },
        },
    };
    var transformResponseToRows = function (resp) {
        var _a;
        if (!resp || !resp.persons || !((_a = resp.persons) === null || _a === void 0 ? void 0 : _a.edges)) {
            return [];
        }
        return resp.persons.edges.map(function (person) {
            var _a;
            var personOrg = personOrgService.personOrgCalc(person.node, currentOrgId);
            return __assign(__assign({}, person.node), { role: (_a = person.node.personOrgs.find(function (n) { return n.orgId === currentOrgId; })) === null || _a === void 0 ? void 0 : _a.role, buttons: { id: (personOrg === null || personOrg === void 0 ? void 0 : personOrg.id) || '', personId: (personOrg === null || personOrg === void 0 ? void 0 : personOrg.personId) || '', email: (personOrg === null || personOrg === void 0 ? void 0 : personOrg.email) || '' } });
        });
    };
    var rowClicked = function (row) {
        var personOrg = personOrgService.personOrgCalc(row, currentOrgId);
        if (personOrg) {
            setEditOpen(personOrg.id);
        }
    };
    var tableProps = {
        useQuery: personOrgService.usePersonsQuery({ errorPolicy: 'all', variables: { query: { orgId: currentOrgId || '' } } }),
        initialQuery: {},
        transformResponseToRows: transformResponseToRows,
        columnAttributes: columns,
        tableAttributes: dataTableOptions,
        onAdd: function () {
            DEBUG && ClientLogger.debug('onAdd', '', {});
            setEditOpen(undefined);
        },
        onRowClick: rowClicked,
    };
    DEBUG && ClientLogger.debug('PersonList', 'render', { tableProps: tableProps });
    var orgsSelectList = orgsList.map(function (org) { return ({ id: org.id, name: org.name }); });
    var setSelectedOrgId = function (orgId) {
        setCurrentOrgId(orgId);
        userContext.$.saveCurrentOrgId(orgId);
    };
    function removePersonOrg(personOrgId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, personOrgService
                            .personOrgDelete(personOrgId)
                            .then(function (resp) {
                            if (resp.errors) {
                                errorHandler.handleErrors({ graphQLErrors: resp.errors });
                            }
                        })
                            .catch(function (err) {
                            errorHandler.handleErrors({ error: err });
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    function removePerson(personId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, personOrgService
                            .personDelete(personId)
                            .then(function (resp) {
                            if (resp.errors) {
                                errorHandler.handleErrors({ graphQLErrors: resp.errors });
                            }
                        })
                            .catch(function (err) {
                            errorHandler.handleErrors({ error: err });
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    }
    if (reload) {
        return _jsx(Loading, {});
    }
    return (_jsxs(Grid, __assign({ container: true }, { children: [_jsx(TopBackBtnBar, { title: "People" }), _jsxs(Box, __assign({ mx: 2, mt: 6, mb: 1, className: classes.tableHolder }, { children: [!specifiedOrgId && _jsx(SelectorNoForm, { items: orgsSelectList, value: currentOrgId, onChange: function (item) { return setSelectedOrgId(item.id); } }), !currentOrgId ? (_jsx(Loading, {})) : (_jsxs(_Fragment, { children: [_jsx(DataTable, __assign({}, tableProps)), _jsx(Dialog, __assign({ open: !(editOpen === false), onClose: function () { return setEditOpen(false); }, maxWidth: "sm", fullWidth: true }, { children: editOpen === undefined ? (_jsx(AddPersonOrg, { onClose: function () {
                                        DEBUG && ClientLogger.debug('AddPersonOrg', 'onClose', {});
                                        setEditOpen(false);
                                    }, orgId: currentOrgId })) : (_jsx(EditPersonOrg, { onClose: function () {
                                        setEditOpen(false);
                                    }, editingId: editOpen || '', orgId: currentOrgId })) })), _jsxs(Dialog, __assign({ open: !!removeOpen, onClose: function () { return setRemoveOpen(false); }, maxWidth: "sm", fullWidth: true }, { children: [_jsxs(DialogTitle, { children: ["Remove ", removeOpen && removeOpen.email] }), _jsx(DialogContent, { children: _jsxs(Typography, __assign({ variant: "body1" }, { children: ["Are you sure you want to remove ", removeOpen && removeOpen.email, "?"] })) }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ onClick: function () {
                                                    setRemoveOpen(false);
                                                } }, { children: "Cancel" })), _jsx(Button, __assign({ onClick: function (item) {
                                                    DEBUG && ClientLogger.debug('PersonOrgList', 'person-org remove related', { item: item });
                                                    setRemoveOpen(false);
                                                    if (removeOpen && removeOpen.id) {
                                                        removePersonOrg(removeOpen.id);
                                                    }
                                                } }, { children: "Remove From Org" })), removeOpen && removeOpen.personId && removeOpen.personId !== userContext.userState.me.id && (_jsx(Button, __assign({ onClick: function (item) {
                                                    DEBUG && ClientLogger.debug('PersonOrgList', 'person remove related', { item: item });
                                                    setRemoveOpen(false);
                                                    removePerson(removeOpen.personId);
                                                } }, { children: "Remove Person" })))] })] }))] }))] }))] })));
};

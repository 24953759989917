export const PRODUCTION_DEPLOYMENT_URL = 'https://healix.me'; // ToDo: Make configurable https://nwtoht.atlassian.net/browse/CSA-1223

export function nameObjToString(
  objWithName:
    | {
        title?: string | null | undefined;
        firstName?: string | null | undefined;
        middleName?: string | null | undefined;
        lastName?: string | null | undefined;
      }
    | null
    | undefined
): string {
  if (!objWithName) {
    return '';
  }
  const name = `${objWithName.title || ''} ${objWithName.firstName || ''} ${objWithName.middleName || ''} ${
    objWithName.lastName || ''
  }`.trim();
  return name.replace(/\s\s+/g, ' ');
}

export type ContentType = 'image' | 'json-upload';

export function getContentPath(contentType: ContentType): string {
  switch (contentType) {
    case 'image':
      return '/image';
    case 'json-upload':
      return '/json';
    default:
      throw new Error(`Unknown contentType ${contentType}`);
  }
}

export type ContentAccessType = 'public-device' | 'user' | 'archive';

export function validateContentAccessType(value: string): ContentAccessType {
  switch (value) {
    case 'public-device':
    case 'user':
    case 'archive':
      return value;
    default:
      throw new Error(`Invalid ContentAccessType: ${value}`);
  }
}

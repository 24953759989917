var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Navbar } from 'components/navigation/Navbar';
import React from 'react';
import { layoutStyles } from './layout-styles';
var useClasses = makeStyles(__assign({}, layoutStyles));
export var LayoutHamburgerMenuBar = function (_a) {
    var children = _a.children;
    var classes = useClasses();
    return (_jsxs(Box, __assign({ className: classes.hamburgerMenuWrapper }, { children: [_jsx(Box, __assign({ className: classes.hamburgBar }, { children: _jsx(Navbar, {}) })), _jsx(Box, { className: classes.baseBg }), _jsx(Box, __assign({ className: classes.appWrapper }, { children: children }))] })));
};

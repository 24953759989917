var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import { AuthConfigUtil } from 'lib/TemplateUtil';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Clear';
import { dialogClasses } from '../../style/sharedCssClasses';
import { SEVERITY, ToastDispatchContext } from 'ui-lib';
import MUIDataTable from 'mui-datatables';
import React, { useState, useEffect, useContext } from 'react';
import { OrgAuthConfigType } from 'verid-shared-lib';
import { useOrgService } from 'lib/api/use-org-service';
import { ClientLogger } from 'lib/client-logger';
import { useErrorHandler } from 'lib/use-error-handler';
import { AuthConfigModal } from './AuthConfigModal';
var DEBUG = false;
var DefaultOrgAuthConfig = {
    id: '',
    authName: '',
    veridRoles: [],
    authConfig: null,
};
export var AuthConfigList = function (props) {
    var _a = useState([]), rowsSelected = _a[0], setRowsSelected = _a[1];
    var _b = useState([]), orgAuthConfigs = _b[0], setOrgAuthConfigs = _b[1];
    var _c = useState(false), editingOrgAuthConfig = _c[0], setEditingOrgAuthConfig = _c[1];
    var _d = useState(false), removeOrgAuthConfig = _d[0], setRemoveOrgAuthConfig = _d[1];
    var _e = useState(null), selectedConfig = _e[0], setSelectedConfig = _e[1];
    var orgService = useOrgService();
    var errorHandler = useErrorHandler('EditOrg');
    var toastDispatch = useContext(ToastDispatchContext);
    useEffect(function () {
        var values = (props === null || props === void 0 ? void 0 : props.initialValues) ? __spreadArray([], props.initialValues, true) : [];
        setOrgAuthConfigs(values);
    }, []);
    var scenarioDataTableOptions = {
        pagination: false,
        download: false,
        print: false,
        filter: false,
        search: false,
        viewColumns: false,
        sort: true,
        selectableRows: 'none',
        rowsSelected: rowsSelected,
        textLabels: {
            body: {
                noMatch: 'No Mapping Found',
            },
        },
    };
    var columns = [
        { name: 'authName', label: 'Auth Name' },
        { name: 'veridRoles', label: 'Verid Roles' },
        { name: 'type', label: 'Type' },
        { name: 'openIdRoles', label: 'OpenID Roles' },
        {
            name: 'edit',
            label: 'Edit',
            options: {
                customBodyRender: function (value, tableMeta) {
                    var rowIndex = tableMeta.rowIndex;
                    return (_jsxs(Box, __assign({ display: "flex", justifyContent: "space-evenly", alignItems: "center" }, { children: [_jsx(Button, __assign({ color: "primary", variant: "contained", onClick: function () {
                                    setSelectedConfig(rowIndex);
                                    setEditingOrgAuthConfig(true);
                                } }, { children: "Edit" })), _jsx(IconButton, __assign({ onClick: function () {
                                    setSelectedConfig(rowIndex);
                                    setRemoveOrgAuthConfig(true);
                                }, color: "primary" }, { children: _jsx(DeleteIcon, {}) }))] })));
                },
                customHeadRender: function () { return _jsx("td", {}, "edit"); },
            },
        },
    ];
    var data = orgAuthConfigs.map(function (list, index) { return ({
        authName: list.authName,
        veridRoles: AuthConfigUtil.convertArrayToString(list.veridRoles),
        type: list.authConfig.type,
        openIdRoles: AuthConfigUtil.convertArrayToString(list.authConfig.openIdRoles || []),
    }); });
    function validationFields(value) {
        var isExistingAuthID = orgAuthConfigs.some(function (orgAuthConfig, index) {
            return selectedConfig != index && orgAuthConfig.id.toLowerCase() === value.id.toLowerCase();
        });
        var isExistingAuthName = orgAuthConfigs.some(function (orgAuthConfig, index) {
            return selectedConfig != index && orgAuthConfig.authName.toLowerCase() === value.authName.toLowerCase();
        });
        if (isExistingAuthID) {
            return 'Auth ID must be unique';
        }
        if (isExistingAuthName) {
            return 'Auth Name must be unique';
        }
        if (!value.veridRoles || !value.veridRoles.length) {
            return 'Verid roles must be fill up';
        }
        var isExistingRole = orgAuthConfigs.some(function (orgAuthConfig, index) {
            return selectedConfig != index && value.veridRoles.some(function (role) { return orgAuthConfig.veridRoles.includes(role); });
        });
        if (isExistingRole) {
            return 'Provided role is already existed';
        }
        if (!value.authConfig) {
            return 'Auth config must be fill up';
        }
        return undefined;
    }
    function onAddConfig(value, isUpdateConfig) {
        return __awaiter(this, void 0, void 0, function () {
            var error, newOrgAuthConfig, openIdRoles, newOrgAuthConfigs;
            return __generator(this, function (_a) {
                error = validationFields(value);
                if (error) {
                    toastDispatch({ severity: SEVERITY.ERROR, msg: error });
                    return [2 /*return*/];
                }
                newOrgAuthConfig = __assign({}, value);
                if (value.authConfig && value.authConfig.type === OrgAuthConfigType.OPEN_ID) {
                    openIdRoles = value.authConfig.openIdRoles;
                    newOrgAuthConfig = __assign(__assign({}, value), { authConfig: __assign(__assign({}, value.authConfig), { openIdRoles: AuthConfigUtil.convertStringToArray(openIdRoles) }) });
                }
                if (!!isUpdateConfig) {
                    newOrgAuthConfigs = orgAuthConfigs.map(function (orgAuthConfig, index) {
                        if (index === selectedConfig) {
                            return newOrgAuthConfig;
                        }
                        return orgAuthConfig;
                    });
                    setOrgAuthConfigs(newOrgAuthConfigs);
                }
                else {
                    setOrgAuthConfigs(__spreadArray(__spreadArray([], orgAuthConfigs, true), [newOrgAuthConfig], false));
                }
                onClearOrgAuthConfig();
                return [2 /*return*/];
            });
        });
    }
    function onClearOrgAuthConfig() {
        setSelectedConfig(null);
        if (editingOrgAuthConfig)
            setEditingOrgAuthConfig(false);
        if (removeOrgAuthConfig)
            setRemoveOrgAuthConfig(false);
    }
    function onRemoveConfig() {
        //Remove config from array of orgAC through authName
        if (selectedConfig === null) {
            toastDispatch({ severity: SEVERITY.ERROR, msg: "Must be select any config" });
            return;
        }
        var removeConfig = orgAuthConfigs[selectedConfig];
        var newOrgAuthConfigs = orgAuthConfigs.filter(function (orgAuthConfig) {
            return orgAuthConfig.authName != removeConfig.authName;
        });
        setOrgAuthConfigs(newOrgAuthConfigs);
        onClearOrgAuthConfig();
    }
    function onSubmit() {
        return __awaiter(this, void 0, void 0, function () {
            var updatedResponse, id;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        //Remove empty string secret in the authConfig object
                        orgAuthConfigs.forEach(function (orgAuthConfig) {
                            var authConfig = orgAuthConfig.authConfig;
                            if (authConfig) {
                                if (authConfig.hasOwnProperty('HMAC_secret') && !authConfig.HMAC_secret)
                                    delete authConfig.HMAC_secret;
                                if (authConfig.hasOwnProperty('clientSecret') && !authConfig.clientSecret)
                                    delete authConfig.clientSecret;
                                if (authConfig.hasOwnProperty('identityProviderSecret') && !authConfig.identityProviderSecret) {
                                    delete authConfig.identityProviderSecret;
                                }
                            }
                        });
                        return [4 /*yield*/, orgService.orgUpdate(props.editingId, { authConfig: __spreadArray([], orgAuthConfigs, true) })];
                    case 1:
                        updatedResponse = _a.sent();
                        DEBUG && ClientLogger.debug('EditOrg', 'update org', { resp: updatedResponse });
                        if (!updatedResponse || !updatedResponse.data || updatedResponse.errors) {
                            errorHandler.handleErrors({ graphQLErrors: updatedResponse.errors });
                            return [2 /*return*/];
                        }
                        id = updatedResponse.data.orgUpdate.id;
                        if (id) {
                            toastDispatch({ severity: SEVERITY.SUCCESS, msg: "Org AuthConfig updated successfully" });
                            props.onClose();
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    return (_jsxs(_Fragment, { children: [_jsx(DialogTitle, { children: _jsx(IconButton, __assign({ style: { float: 'right' }, onClick: props.onClose, size: "large" }, { children: _jsx(CloseIcon, { sx: dialogClasses.closeIcon }) })) }), _jsxs(Grid, __assign({ item: true, xs: 12 }, { children: [_jsx(MUIDataTable, { title: "", options: scenarioDataTableOptions, columns: columns, data: data }), _jsx(DialogActions, { children: _jsx(IconButton, __assign({ style: { float: 'right', background: '#00539D', color: '#ffffff' }, onClick: function () {
                                setSelectedConfig(null);
                                setEditingOrgAuthConfig(true);
                            }, size: "large" }, { children: _jsx(AddIcon, {}) })) }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ onClick: function () { return props.onClose(); }, color: "primary" }, { children: "Cancel" })), _jsx(Button, __assign({ onClick: function () { return onSubmit(); }, color: "primary" }, { children: "Save" }))] })] })), _jsx(Dialog, __assign({ open: editingOrgAuthConfig, onClose: function () { return onClearOrgAuthConfig(); }, maxWidth: "sm", fullWidth: true }, { children: _jsx(AuthConfigModal, { onClose: function () { return onClearOrgAuthConfig(); }, editingId: props.editingId, initialValues: selectedConfig === null ? DefaultOrgAuthConfig : orgAuthConfigs[selectedConfig], isUpdate: selectedConfig != null, onSubmit: onAddConfig }) })), _jsxs(Dialog, __assign({ maxWidth: "sm", fullWidth: true, open: removeOrgAuthConfig }, { children: [_jsx(DialogTitle, __assign({ id: "alert-dialog-title" }, { children: 'Remove RoleMapping' })), _jsx(DialogContent, { children: "Are you sure you want to delete" }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ onClick: function () { return onClearOrgAuthConfig(); }, color: "primary" }, { children: "No" })), _jsx(Button, __assign({ color: "primary", autoFocus: true, onClick: function () { return onRemoveConfig(); } }, { children: "Yes" }))] })] }))] }));
};

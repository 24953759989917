var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { gql } from '@apollo/client';
import { useApi } from './use-api';
import { ClientLogger } from '../client-logger';
import jwt_decode from 'jwt-decode';
import { getVeridApolloClient } from './apollo-client';
import { useContext } from 'react';
import { blankUserState, UserContext } from 'lib/auth/user-context';
import { useErrorHandler } from 'lib/use-error-handler';
import { ROUTES } from 'Routes';
var DEBUG = false;
export var tokenHolder = {
    accessToken: undefined,
    jwt: undefined,
};
export var NEW_AUTH_MESSAGE_ID = 'login_state_message';
function decodeToken(token) {
    return jwt_decode(token);
}
function updateJwtToken(accessToken) {
    DEBUG && ClientLogger.debug('AuthClient.updateJwtToken', "accessToken = ".concat(accessToken));
    tokenHolder.accessToken = accessToken;
    tokenHolder.jwt = accessToken ? decodeToken(accessToken) : undefined;
    DEBUG && ClientLogger.debug('AuthClient.updateJwtToken', "sending message");
    window.postMessage(NEW_AUTH_MESSAGE_ID);
}
export function refreshTokenFct() {
    var _a, _b;
    return __awaiter(this, void 0, Promise, function () {
        var client, result;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    client = getVeridApolloClient();
                    return [4 /*yield*/, client.mutate({
                            mutation: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      mutation refreshToken {\n        refreshToken {\n          accessToken\n        }\n      }\n    "], ["\n      mutation refreshToken {\n        refreshToken {\n          accessToken\n        }\n      }\n    "]))),
                            variables: {},
                            context: { noAuth: true },
                            errorPolicy: 'all',
                        })];
                case 1:
                    result = _c.sent();
                    DEBUG && ClientLogger.debug('AuthClient.refreshToken', 'response', { result: result });
                    if ((_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.refreshToken) === null || _b === void 0 ? void 0 : _b.accessToken) {
                        updateJwtToken(result.data.refreshToken.accessToken);
                    }
                    else {
                        ClientLogger.log('refreshTokenFct', 'No or stale refresh token');
                        updateJwtToken(undefined);
                    }
                    return [2 /*return*/, result];
            }
        });
    });
}
export function isJwtExpired() {
    var _a;
    var expiry = (_a = tokenHolder.jwt) === null || _a === void 0 ? void 0 : _a.exp;
    if (!expiry) {
        return true;
    }
    var curDate = Date.now();
    var cur = curDate / 1000;
    return expiry < cur;
}
export function logout(to) {
    return __awaiter(this, void 0, void 0, function () {
        var client, result, redirect;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    client = getVeridApolloClient();
                    DEBUG && ClientLogger.debug('AuthClient', 'logout - started', { tokenHolder: tokenHolder });
                    return [4 /*yield*/, client.mutate({
                            mutation: gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      mutation logOut {\n        logOut\n      }\n    "], ["\n      mutation logOut {\n        logOut\n      }\n    "]))),
                            variables: {},
                            context: { noAuth: true },
                        })];
                case 1:
                    result = _a.sent();
                    DEBUG && ClientLogger.debug('AuthClient', 'logout - response', result);
                    updateJwtToken(undefined);
                    redirect = to || ROUTES.HOME;
                    ClientLogger.log('AuthClient.logout', "Logging out...");
                    if (redirect !== window.location.pathname) {
                        ClientLogger.log('AuthClient.logout', "redirecting to ".concat(redirect, "..."));
                        window.location.assign(redirect);
                    }
                    return [2 /*return*/];
            }
        });
    });
}
export var PERSON_FIELDS = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  fragment PersonFragment on Person {\n    id\n    createdAt\n    updatedAt\n    title\n    firstName\n    middleName\n    lastName\n    gender\n    dateOfBirth\n    attributes\n    isTest\n    status\n    currentOrg\n    systemAccount\n  }\n"], ["\n  fragment PersonFragment on Person {\n    id\n    createdAt\n    updatedAt\n    title\n    firstName\n    middleName\n    lastName\n    gender\n    dateOfBirth\n    attributes\n    isTest\n    status\n    currentOrg\n    systemAccount\n  }\n"])));
export var PERSON_ORG_FIELDS = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  fragment PersonOrgFragment on PersonOrg {\n    id\n    email\n    role\n    orgId\n    personId\n  }\n"], ["\n  fragment PersonOrgFragment on PersonOrg {\n    id\n    email\n    role\n    orgId\n    personId\n  }\n"])));
export var PERSON_VERIFICATION_FIELDS = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  fragment PersonVerificationFragment on PersonVerification {\n    id\n    createdAt\n    updatedAt\n    methodId\n    personId\n    title\n    firstName\n    middleName\n    lastName\n    dateOfBirth\n    gender\n    idTypeId\n    idNumber\n    idExpiry\n    status\n  }\n"], ["\n  fragment PersonVerificationFragment on PersonVerification {\n    id\n    createdAt\n    updatedAt\n    methodId\n    personId\n    title\n    firstName\n    middleName\n    lastName\n    dateOfBirth\n    gender\n    idTypeId\n    idNumber\n    idExpiry\n    status\n  }\n"])));
export function useAuthService() {
    var api = useApi();
    var userContext = useContext(UserContext);
    var errorHandler = useErrorHandler('login');
    return {
        login: function (data) {
            var _a, _b, _c, _d;
            return __awaiter(this, void 0, Promise, function () {
                var result;
                return __generator(this, function (_e) {
                    switch (_e.label) {
                        case 0: return [4 /*yield*/, api.mutate({
                                mutation: gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n          mutation login($data: LoginInput!) {\n            login(data: $data) {\n              accessToken\n            }\n          }\n        "], ["\n          mutation login($data: LoginInput!) {\n            login(data: $data) {\n              accessToken\n            }\n          }\n        "]))),
                                variables: { data: data },
                                context: { noAuth: true },
                            })];
                        case 1:
                            result = _e.sent();
                            DEBUG && ClientLogger.debug('AuthClient.login', 'response', { result: result });
                            if ((_b = (_a = result.data) === null || _a === void 0 ? void 0 : _a.login) === null || _b === void 0 ? void 0 : _b.accessToken) {
                                updateJwtToken(result.data.login.accessToken);
                            }
                            else {
                                ClientLogger.error('login', 'Error', (_d = (_c = result.data) === null || _c === void 0 ? void 0 : _c.login) === null || _d === void 0 ? void 0 : _d.error);
                            }
                            return [2 /*return*/, result];
                    }
                });
            });
        },
        passwordResetStart: function (email) {
            return __awaiter(this, void 0, Promise, function () {
                var result;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, api.mutate({
                                mutation: gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n          mutation passwordResetStart($email: String!) {\n            passwordResetStart(email: $email)\n          }\n        "], ["\n          mutation passwordResetStart($email: String!) {\n            passwordResetStart(email: $email)\n          }\n        "]))),
                                variables: { email: email },
                                context: { noAuth: true },
                            })];
                        case 1:
                            result = _a.sent();
                            DEBUG && ClientLogger.debug('AuthClient.passwordResetStart', 'response', { result: result });
                            return [2 /*return*/, result];
                    }
                });
            });
        },
        passwordResetFinish: function (token, newPassword) {
            return __awaiter(this, void 0, Promise, function () {
                var result;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, api.mutate({
                                mutation: gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n          mutation passwordResetFinish($token: String!, $newPassword: String!) {\n            passwordResetFinish(token: $token, newPassword: $newPassword)\n          }\n        "], ["\n          mutation passwordResetFinish($token: String!, $newPassword: String!) {\n            passwordResetFinish(token: $token, newPassword: $newPassword)\n          }\n        "]))),
                                variables: { token: token, newPassword: newPassword },
                                context: { noAuth: true },
                            })];
                        case 1:
                            result = _a.sent();
                            DEBUG && ClientLogger.debug('AuthClient.passwordResetFinish', 'response', { result: result });
                            return [2 /*return*/, result];
                    }
                });
            });
        },
        changePassword: function (personOrgId, oldPassword, newPassword) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/, api.mutate({
                            mutation: gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n          mutation changePassword($personOrgId: String!, $newPassword: String!, $oldPassword: String!) {\n            changePassword(personOrgId: $personOrgId, data: { newPassword: $newPassword, oldPassword: $oldPassword }) {\n              id\n            }\n          }\n        "], ["\n          mutation changePassword($personOrgId: String!, $newPassword: String!, $oldPassword: String!) {\n            changePassword(personOrgId: $personOrgId, data: { newPassword: $newPassword, oldPassword: $oldPassword }) {\n              id\n            }\n          }\n        "]))),
                            variables: { data: { personOrgId: personOrgId, newPassword: newPassword, oldPassword: oldPassword } },
                        })];
                });
            });
        },
        getMe: function () {
            return __awaiter(this, void 0, Promise, function () {
                var resp, theNewMe, newState, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 4, , 5]);
                            DEBUG && ClientLogger.debug('useCsaApi.getMe', "Called: me", tokenHolder);
                            if (!tokenHolder.accessToken) return [3 /*break*/, 2];
                            return [4 /*yield*/, api.query({
                                    query: gql(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n              ", "\n              ", "\n              query me {\n                me {\n                  ...PersonFragment\n                  personOrgs {\n                    ...PersonOrgFragment\n                    org {\n                      id\n                      name\n                      type\n                    }\n                  }\n                }\n              }\n            "], ["\n              ", "\n              ", "\n              query me {\n                me {\n                  ...PersonFragment\n                  personOrgs {\n                    ...PersonOrgFragment\n                    org {\n                      id\n                      name\n                      type\n                    }\n                  }\n                }\n              }\n            "])), PERSON_FIELDS, PERSON_ORG_FIELDS),
                                    variables: {},
                                    fetchPolicy: 'network-only',
                                })];
                        case 1:
                            resp = _a.sent();
                            DEBUG && ClientLogger.debug('useCsaApi.getMe', 'Response', resp);
                            if (resp && resp.data && resp.data.me) {
                                DEBUG && ClientLogger.debug('getMe', 'setting me', resp);
                                theNewMe = resp.data.me;
                                userContext.setUserState({ me: theNewMe, isLoggedIn: true, ready: true });
                            }
                            else {
                                DEBUG && ClientLogger.debug('getMe', 'me retrieval failed', resp);
                                errorHandler.handleQueryResponse(resp);
                            }
                            return [2 /*return*/, resp];
                        case 2:
                            DEBUG && ClientLogger.debug('getMe', 'me called without token - setting to not logged in');
                            newState = __assign({}, blankUserState);
                            newState.ready = true;
                            userContext.setUserState(newState);
                            return [2 /*return*/, null];
                        case 3: return [3 /*break*/, 5];
                        case 4:
                            error_1 = _a.sent();
                            ClientLogger.error('getMe', 'Catch', error_1);
                            return [2 /*return*/, null];
                        case 5: return [2 /*return*/];
                    }
                });
            });
        },
        logout: logout,
        serverInfo: function () {
            return __awaiter(this, void 0, Promise, function () {
                var resp;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            DEBUG && ClientLogger.debug('serverInfo', "started");
                            return [4 /*yield*/, api.query({
                                    query: gql(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n          query serverInfo {\n            serverInfo {\n              branch\n              buildDate\n              buildId\n              commit\n              dbConnectTest\n              reactAppAddress\n            }\n          }\n        "], ["\n          query serverInfo {\n            serverInfo {\n              branch\n              buildDate\n              buildId\n              commit\n              dbConnectTest\n              reactAppAddress\n            }\n          }\n        "]))),
                                    variables: {},
                                    fetchPolicy: 'network-only',
                                    context: { noAuth: true },
                                })];
                        case 1:
                            resp = _a.sent();
                            DEBUG && ClientLogger.debug('serverInfo', 'Response', resp);
                            return [2 /*return*/, resp];
                    }
                });
            });
        },
        resetData: function (comment, branch) {
            return __awaiter(this, void 0, Promise, function () {
                var resp;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            ClientLogger.warning('resetData', "started");
                            return [4 /*yield*/, api.mutate({
                                    mutation: gql(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n          mutation resetData($comment: String!, $branch: String!) {\n            resetData(comment: $comment, branch: $branch)\n          }\n        "], ["\n          mutation resetData($comment: String!, $branch: String!) {\n            resetData(comment: $comment, branch: $branch)\n          }\n        "]))),
                                    variables: { comment: comment, branch: branch },
                                    fetchPolicy: 'network-only',
                                })];
                        case 1:
                            resp = _a.sent();
                            DEBUG && ClientLogger.debug('resetData', 'Response', resp);
                            return [2 /*return*/, resp];
                    }
                });
            });
        },
    };
}
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useContext } from 'react';
import { OrgListAuth, SEVERITY, ToastDispatchContext, Util } from 'ui-lib';
import { Grid, Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../Routes';
import { OrgAuthConfigType } from 'verid-shared-lib';
import { getVeridApolloClientWithoutContext } from 'lib/api/apollo-client';
import { signupClasses } from 'style/sharedCssClasses';
import { theme } from 'style/theme';
import canadaFlag from '../../assets/Canada-Flag.svg';
var useClasses = makeStyles(__assign(__assign({}, signupClasses), { healixLogo: {
        width: '225px',
    }, ctaBox: (_a = {
            maxWidth: '90vw',
            padding: '24px'
        },
        _a[theme.breakpoints.up('sm')] = {
            marginTop: '48px',
            width: '380px',
        },
        _a), flag: {
        height: '24px',
        marginRight: '4px',
        verticalAlign: 'middle',
    }, btnSpacing: {
        margin: '6px auto',
        width: '80%',
        fontWeight: 'bold',
    }, loginBtns: {
        width: '80%',
        display: 'flex',
        justifyContent: 'space-around',
    } }));
var callbackURL;
var currentAppId;
export function LoginOrgsList() {
    var _this = this;
    var classes = useClasses();
    var navigate = useNavigate();
    var toastDispatch = useContext(ToastDispatchContext);
    var apolloClientVerid = getVeridApolloClientWithoutContext();
    var getAuthUrl = function (authConfig, state) {
        return (authConfig.authorizationEndpoint +
            "?client_id=".concat(authConfig.clientId) +
            "&redirect_uri=".concat(Util.getEnvVar('REACT_APP_VERID_API_HOST'), "/redirect") +
            "&state=".concat(state) +
            '&scope=openid profile email' +
            '&response_type=code' +
            '&response_mode=form_post' +
            '&nonce=roles');
    };
    var onSelectAuthConfig = function (orgAuthConfig, currentOrgId) { return __awaiter(_this, void 0, void 0, function () {
        var authConfig, roles, authName, redirect;
        return __generator(this, function (_a) {
            authConfig = orgAuthConfig.authConfig;
            if (authConfig === null) {
                toastDispatch({ severity: SEVERITY.ERROR, msg: "Org Auth is not completely configured" });
                return [2 /*return*/];
            }
            roles = authConfig.roles;
            authName = orgAuthConfig.authName;
            if (authConfig.type === OrgAuthConfigType.LOCALLY_PROVIDED || authConfig.type === OrgAuthConfigType.EXTERNALLY_PROVIDED) {
                //Need to configure and after validate user should navigate to their initiate place
                navigate("".concat(ROUTES.AUTH_ORG, "/").concat(currentOrgId, "/authConfig/").concat(orgAuthConfig.id));
            }
            else if (authConfig.type === OrgAuthConfigType.OPEN_ID) {
                redirect = getAuthUrl(authConfig, JSON.stringify({ currentOrgId: currentOrgId, currentAppId: currentAppId, roles: roles, authName: authName, callbackURL: callbackURL }));
                window.open(redirect, 'popup', 'width=600,height=600');
            }
            return [2 /*return*/];
        });
    }); };
    return (_jsx(Grid, __assign({ container: true, justifyContent: "center", flexDirection: "column" }, { children: _jsx(Grid, __assign({ container: true, item: true, xs: 12, md: 6, direction: "column", alignItems: "center", className: classes.formContainer }, { children: _jsx(Box, __assign({ className: classes.formHolder }, { children: _jsx(Box, { children: _jsxs(Grid, __assign({ container: true, alignItems: "center", flexDirection: "column" }, { children: [_jsx(Typography, __assign({ variant: "h1", align: "center" }, { children: "Welcome." })), _jsx(OrgListAuth, { veridApolloClient: apolloClientVerid, readFromVerid: true, theme: theme, onSelectAuthConfig: function (orgId, authConfig) { return onSelectAuthConfig(authConfig, orgId); } }), _jsxs(Box, __assign({ mt: 1 }, { children: [_jsx(Box, { component: "img", src: canadaFlag, alt: "Canada Flag", className: classes.flag }), _jsx(Typography, __assign({ component: "span", variant: "subtitle2" }, { children: "Proudly Canadian" }))] }))] })) }) })) })) })));
}

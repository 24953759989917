var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Loading } from 'components/widgets/Loading';
import { Formik } from 'formik';
import { Status, TeamRole } from 'lib/api/api-types';
import { ClientLogger } from 'lib/client-logger';
import { useErrorHandler } from 'lib/use-error-handler';
import React, { useEffect, useRef, useState } from 'react';
import { dialogClasses } from '../../style/sharedCssClasses';
import CloseIcon from '@mui/icons-material/Clear';
import { useTeamMemberService } from 'lib/api/use-team-member-service';
import { TeamRoleSelector } from 'components/form/TeamRoleSelector';
import { StatusSelector } from 'components/form/StatusSelector';
var DEBUG = false;
export function EditTeamMember(props) {
    var teamMemberService = useTeamMemberService();
    var errorHandler = useErrorHandler('EditTeamMember');
    var formRef = useRef(null);
    var _a = useState(true), loading = _a[0], setLoading = _a[1];
    useEffect(function () {
        if (formRef.current) {
            if (props.editingId) {
                teamMemberService
                    .teamMember(props.editingId)
                    .then(function (resp) {
                    var _a;
                    var teamMember = resp.data.teamMember;
                    (_a = formRef.current) === null || _a === void 0 ? void 0 : _a.setValues({ status: teamMember.status, role: teamMember.role });
                    setLoading(false);
                })
                    .catch(function (err) {
                    errorHandler.handleErrors({ error: err });
                    setLoading(false);
                });
            }
            else {
                setLoading(false);
            }
        }
    }, [props.editingId, formRef.current]);
    function save(values) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var resp;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, teamMemberService.teamMemberUpdate(props.editingId, values)];
                    case 1:
                        resp = _b.sent();
                        DEBUG && ClientLogger.debug('EditOrg', 'save', { resp: resp });
                        if ((_a = resp.data) === null || _a === void 0 ? void 0 : _a.teamMemberUpdate.id) {
                            props.onClose();
                        }
                        else {
                            errorHandler.handleErrors({ graphQLErrors: resp.errors });
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    DEBUG && ClientLogger.debug('EditTeam', 'render', { props: props, formRef: JSON.stringify(formRef.current) });
    return (_jsx(Formik, __assign({ innerRef: formRef, initialValues: {
            status: Status.ACTIVE,
            role: TeamRole.STAFF,
        }, validate: function (values) {
            var errors = {};
            return errors;
        }, onSubmit: function (values, actions) {
            DEBUG && ClientLogger.debug('EditTeam', "onSubmit values", values);
            save(values);
        } }, { children: function (_a) {
            var submitForm = _a.submitForm, isSubmitting = _a.isSubmitting, errors = _a.errors, values = _a.values, setFieldValue = _a.setFieldValue, touched = _a.touched, setFieldError = _a.setFieldError, setFieldTouched = _a.setFieldTouched, dirty = _a.dirty;
            DEBUG && ClientLogger.debug('EditTeam', 'render form', { isSubmitting: isSubmitting, errors: errors, values: values, touched: touched, dirty: dirty, submitForm: submitForm });
            if (loading) {
                return _jsx(Loading, {});
            }
            return (_jsxs(_Fragment, { children: [_jsxs(DialogTitle, __assign({ sx: dialogClasses.dialogTitle }, { children: [props.dialogTitle, _jsx(IconButton, __assign({ onClick: props.onClose, size: "large" }, { children: _jsx(CloseIcon, { sx: dialogClasses.closeIcon }) }))] })), _jsxs(DialogContent, { children: [_jsx(TeamRoleSelector, { name: 'role' }), _jsx(StatusSelector, { name: 'status' })] }), _jsxs(DialogActions, { children: [_jsx(Button, __assign({ color: "primary", disabled: isSubmitting, onClick: function () { return props.onClose(); } }, { children: "Cancel" })), _jsx(Button, __assign({ type: "submit", color: "primary", disabled: isSubmitting, onClick: function () { return save(values); } }, { children: "Save" }))] })] }));
        } })));
}

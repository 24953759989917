var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Dialog, Grid } from '@mui/material';
import React, { useContext, useState } from 'react';
import { DataTable } from 'ui-lib';
import { ClientLogger } from 'lib/client-logger';
import { EditVerificationMethod } from './EditVerificationMethod';
import { TopBackBtnBar } from '../../components/navigation/TopBackBtnBar';
import makeStyles from '@mui/styles/makeStyles';
import { tableClasses } from '../../style/sharedCssClasses';
import { SelectorNoForm } from 'ui-lib';
import { UserContext } from 'lib/auth/user-context';
import { Loading } from 'components/widgets/Loading';
import { useVerificationMethodService } from 'lib/api/use-verification-method-service';
var useClasses = makeStyles(__assign({}, tableClasses));
var DEBUG = false;
export var VerificationMethodList = function (props) {
    var classes = useClasses();
    var verificationMethodService = useVerificationMethodService();
    var _a = useState(false), editOrgOpen = _a[0], setEditOrgOpen = _a[1]; //false not open, undefined open with new, string teamId beiong edited
    var _b = useState(false), memberEdit = _b[0], setMemberEdit = _b[1]; //false not open,  string teamId beiong edited
    var userContext = useContext(UserContext);
    var orgs = userContext.$.getOrgs();
    var orgsSelectList = orgs.map(function (org) { return ({ id: org.id, name: org.name }); });
    var _c = useState(userContext.$.currentOrgId()), currentOrgId = _c[0], setCurrentOrgId = _c[1];
    var _d = useState([]), existingOpenIdConfigs = _d[0], setExistingOpenIdConfigs = _d[1];
    var title = 'Verification Methods';
    var setSelectedOrgId = function (orgId) {
        setCurrentOrgId(orgId);
        userContext.$.saveCurrentOrgId(orgId);
    };
    var dataTableOptions = {
        pagination: true,
        download: true,
        print: true,
        filter: false,
        search: false,
        viewColumns: false,
        sort: true,
        selectableRows: 'none',
    };
    var columns = {
        id: { display: 'excluded' },
        name: { label: 'Name' },
        description: { display: 'excluded' },
        orgId: { display: 'excluded' },
        updatedAt: { display: 'excluded' },
        instructions: { display: 'excluded' },
        requiresPicture: { display: 'excluded' },
        idTypes: { display: 'excluded' },
        createdAt: { label: 'Created At' },
        status: { label: 'Status' },
        type: { label: 'Type' },
    };
    var transformResponseToRows = function (resp) {
        var _a;
        if (!resp || !resp.verificationMethods || !((_a = resp.verificationMethods) === null || _a === void 0 ? void 0 : _a.edges)) {
            return [];
        }
        var configs = resp.verificationMethods.edges
            .map(function (team) {
            return {
                id: team.node.id,
                config: team.node.openIdConfig || '',
            };
        })
            .filter(function (openIdConfig) { return !!openIdConfig.config; });
        setExistingOpenIdConfigs(configs);
        var retVal = resp.verificationMethods.edges.map(function (team) { return (__assign({}, team.node)); });
        DEBUG && ClientLogger.debug('transformResponseToRows', '', retVal);
        return retVal;
    };
    var rowClicked = function (row) {
        setEditOrgOpen(row.id);
    };
    var tableProps = {
        useQuery: verificationMethodService.useVerificationMethodsQuery({ variables: { orgId: currentOrgId || '' } }),
        initialQuery: {},
        transformResponseToRows: transformResponseToRows,
        columnAttributes: columns,
        tableAttributes: dataTableOptions,
        emptyMessage: 'No teams found. Click the + button to create a new team.',
        onAdd: function () {
            DEBUG && ClientLogger.debug('onAdd', '', {});
            setEditOrgOpen(undefined);
        },
        onRowClick: rowClicked,
    };
    DEBUG && ClientLogger.debug('VerificationMethodList', 'render', { tableProps: tableProps });
    return (_jsxs(_Fragment, { children: [_jsxs(Grid, __assign({ container: true }, { children: [_jsx(TopBackBtnBar, { title: title }), _jsxs(Box, __assign({ mx: 2, mt: 6, mb: 1, className: classes.tableHolder }, { children: [_jsx(SelectorNoForm, { items: orgsSelectList, value: currentOrgId, onChange: function (item) { return setSelectedOrgId(item.id); } }), !currentOrgId ? (_jsx(Loading, {})) : (_jsx(_Fragment, { children: _jsx(DataTable, __assign({}, tableProps)) }))] }))] })), _jsx(Dialog, __assign({ open: !(editOrgOpen === false), onClose: function () { return setEditOrgOpen(false); }, maxWidth: "sm", fullWidth: true }, { children: _jsx(EditVerificationMethod, { onClose: function () {
                        setEditOrgOpen(false);
                    }, editingId: typeof editOrgOpen === 'string' ? editOrgOpen : undefined, dialogTitle: title, orgId: currentOrgId, existingOpenIdConfigs: existingOpenIdConfigs }) }))] }));
};

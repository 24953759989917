import { ENTITY_ID } from './entity';

export interface FieldSpec {
  entityId: ENTITY_ID;
  categoryId?: string | null | undefined;
  fieldName: string;
}

export interface ParsePathResult extends FieldSpec {
  levels: 2 | 3;
  errors: string[];
  subFieldKey?: string;
}

export const SUB_FIELD_TOKEN = '_';

export class PathParser {
  public static parsePath(path: string): ParsePathResult {
    const result: ParsePathResult = {
      entityId: 'unknown' as ENTITY_ID,
      categoryId: undefined,
      errors: [],
      levels: 2,
      fieldName: 'unknown',
    };
    const split = path.split('.');
    if (split.length < 2 || split.length > 3) {
      return { ...result, errors: [`expect a string with at least one . but got ${path}`] };
    }
    result.entityId = split[0] as ENTITY_ID;
    if (split[2]) {
      result.categoryId = split[1];
      result.fieldName = split[2];
    } else {
      result.fieldName = split[1];
    }
    if (split.length !== 2 && split.length !== 3) {
      throw new Error(`DataSource.parsePath - expected length of 2 or 3`);
    }
    result.levels = split.length;
    // split out choices
    const { fieldName } = result;
    if (!fieldName) {
      return { ...result, errors: [`Missing field name in ${path}`] };
    }
    const _pos = fieldName.indexOf(SUB_FIELD_TOKEN, 1); // 1 in order to skip leading _ of system fields
    if (_pos !== -1) {
      // found a split
      result.subFieldKey = fieldName.substr(_pos + 1);
      result.fieldName = fieldName.substring(0, _pos);
    }
    return result;
  }

  public static fieldSpecToString(fieldSpec: FieldSpec): string {
    return `${fieldSpec.entityId}.${fieldSpec.categoryId ? `${fieldSpec.categoryId}.` : ''}${fieldSpec.fieldName}`;
  }
}
